import {
  BorderColor,
  Card,
  TextColor,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  toTextColor,
} from '@aircarbon/ui';

import type { ScreenSizes } from 'state/ui';

export const Wrapper = styled.div<{ screenSize?: ScreenSizes }>`
  display: flex;
  overflow: hidden;
  padding: ${({ theme }) => toSpacing(theme)(4)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  font-size: 13.5px;

  a.active {
    filter: brightness(1);
  }

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `height: auto; min-height: 100%; flex-direction: column;`;
    } else {
      return `height: 100%;`;
    }
  }}
`;

export const Sidebar = styled(Card)<{ screenSize?: ScreenSizes }>`
  overflow: hidden;
  overflow-y: auto;

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `width: 100%;`;
    } else {
      return `
        max-width: 330px;
        min-width: 330px;
      `;
    }
  }}
`;

export const BalanceSummaryWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

export const PricesWrapper = styled.div<{ screenSize?: ScreenSizes }>`
  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `height: auto;`;
    } else {
      return `
        max-height: 300px;
      `;
    }
  }}
  overflow: auto;
  margin-bottom: 10px;
`;

export const BidInfoWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: start;
  margin: 10px 0;

  &.large {
    gap: 4.5rem;
  }

  &.normal {
    gap: 4rem;
  }
`;

export const BidTable = styled.table<{ screenSizes?: ScreenSizes }>`
  ${(props) => {
    if (props.screenSizes === 'small' || props.screenSizes === 'medium') {
      return `width: auto;`;
    } else {
      return `width: 100%;`;
    }
  }}
  color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  th,
  td {
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)} !important;
    background: ${({ theme }) => toLayerBackground(theme)('layer')};
    padding: 6px 1rem;
    text-align: center;

    &.assetName {
      width: 70px;
    }

    &.largeNumber {
      width: 160px;
    }
  }

  th {
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  th:not(:last-child) {
    border: 0;
  }

  td {
    font-weight: 300;
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }

  tr {
    &.FILLING,
    &.PENDING {
      td {
        font-weight: bold;
      }
    }
  }

  input {
    background: ${({ theme }) => toLayerBackground(theme)('field')};
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};

    &:hover,
    &:focus {
      border-color: ${({ theme }) => toBorderColor(theme)(BorderColor.active)};
      outline: none;
    }
  }

  tfoot {
    font-weight: 700;

    td {
      border-top: 2px solid ${({ theme }) => toBorderColor(theme)(BorderColor.active)};
    }
  }

  a {
    color: ${({ theme }) => toTextColor(theme)(TextColor.info)};
  }
`;

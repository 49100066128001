import { ApiClient } from 'generated';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import {
  getCriteriaMetaValueBy,
  getProjectMetaBy,
  prepareActionsBarData,
  prepareDetailsData,
  prepareProgressItems,
} from 'refreshed-pages/settlement/helpers';
import { getOrderSide } from 'refreshed-pages/settlement/utils/SettleOrderSide';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  InputTextSize,
  Layer,
  Modal,
  ModalSettlementReserveCarbon,
  type ModalSettlementReserveCarbonRef,
  type ProgressItem,
  Select,
  SettlementProgress,
  Text,
  TextAlign,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import {
  AssetCategory,
  type AssetCategoryCode,
  SettleStatus,
  assetCategoryUom,
  formatter,
  helpers,
  logger,
  matching,
} from '@aircarbon/utils-common';

import { usePairs } from 'pages/account/trading/hooks';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAddressData from 'hooks/useAddressData';
import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import useProjectInfo from 'hooks/useProjectInfo';
import useProjectsMetadata from 'hooks/useProjectsMetadata';

import emitter from 'utils/emitter';

import { urlMapping } from '../../route';
import Loader from '../Loader';
import PendingAlerts, { PendingActionType } from '../PendingAlerts';
import { ActionsBar } from './components/ActionsBar';
import { Details } from './components/Details';
import { ReserveREC, type ReserveRECRef } from './components/ReserveREC';
import { Summary } from './components/Summary';
import { toPendingAlerts } from './utils/toPendingAlerts';

export const SettlementDetails = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    selector: { mainCcyCode },
  } = Entity.useContainer();
  const {
    selector: { getAuthToken, getUserId, getAccountAddress },
    status: { canManageSettlement },
  } = User.useContainer();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isAcceptModalVisible, setIsAcceptModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [selectedRejectReasonId, setSelectedRejectReasonId] = useState<string>();
  const [selectedContractId, setSelectedContractId] = useState<string>();

  const { marketSettings } = useMarketSettings({});
  const reserveCarbonModalRef = useRef<ModalSettlementReserveCarbonRef>(null);
  const reserveRecModalRef = useRef<ReserveRECRef>(null);

  // fetch settlement details
  const fetchSettlementDetails = async (id: string) => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });
    const result = await apiClient.settle.viewSettle({
      id,
      xEntityDomain: helpers.getHostFromUrl(window.location.origin),
    });

    if (result?.status === 'success') {
      return result.data;
    }

    showToast({
      variant: ToastVariant.Danger,
      message: result?.message,
    });
    throw new Error(result?.message);
  };

  const fetchRejectReason = async () => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });
    return apiClient.settle.listRejectReason({
      xEntityDomain: helpers.getHostFromUrl(window.location.origin),
    });
  };

  const approveSettlement = async () => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });
    try {
      const result = await apiClient.settle.approveSettle({
        id,
        xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      });
      if (result?.status === 'error') {
        showToast({
          variant: ToastVariant.Danger,
          message: result?.message,
        });
      } else {
        showToast({
          variant: ToastVariant.Success,
          message: 'Settlement approved successfully',
        });
      }
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error.body?.message ?? error?.message,
      });
    }
    setIsActionLoading(false);
  };

  const rejectSettlement = async (reasonId: string) => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });
    try {
      const result = await apiClient.settle.rejectSettle({
        id,
        requestBody: {
          reasonId,
        },
        xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      });
      if (result?.status === 'error') {
        showToast({
          variant: ToastVariant.Danger,
          message: result?.message,
        });
      } else {
        showToast({
          variant: ToastVariant.Success,
          message: 'Settlement rejected successfully',
        });
      }
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error.body?.message ?? error?.message,
      });
    }
    setIsActionLoading(false);
  };

  const cancelSettlement = async () => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });
    try {
      const result = await apiClient.settle.cancelSettle({
        id,
        xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      });
      if (result?.status === 'error') {
        showToast({
          variant: ToastVariant.Danger,
          message: result?.message,
        });
      } else {
        showToast({
          variant: ToastVariant.Success,
          message: 'Settlement cancelled successfully',
        });
      }
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error.body?.message ?? error?.message,
      });
    }
    setIsActionLoading(false);
  };

  const reserveCarbon = async (selectedBatchIds?: Record<string, number>) => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });
    try {
      const result = await apiClient.settle.reserveSettle({
        id,
        requestBody: {
          batches: selectedBatchIds
            ? Object.keys(selectedBatchIds).map((batchId) => ({
                batchId: Number(batchId),
                quantity: selectedBatchIds[batchId],
              }))
            : null,
          selectedContractId,
        },
        xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      });
      if (result?.status === 'error') {
        showToast({
          variant: ToastVariant.Danger,
          message: 'Failed to reserve',
        });
      } else {
        showToast({
          variant: ToastVariant.Success,
          message: `${isSeller ? 'Assets' : 'Funds'} reserved successfully`,
        });
      }
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error.body?.message ?? error?.message,
      });
    }
    setIsActionLoading(false);
  };

  const { data: settlement, isLoading } = useQuery(
    ['settlement', id, isActionLoading],
    () => fetchSettlementDetails(id),
    {
      enabled: !!id,
    },
  );

  const detailsData =
    settlement &&
    prepareDetailsData({
      userId: getUserId(),
      settlement,
      product: settlement.assetCategoryId?.toString(),
    });
  const { project, isLoading: isFetchingProject } = useProjectInfo(detailsData?.projectId ?? '');

  const {
    carbonMetaOptions: { registries, countries },
  } = useCarbonMetaOptions({
    query: {
      assetCategory: settlement?.assetCategoryId === AssetCategory.token ? 'token' : 'rec',
    },
    options: {
      enabled: !isLoading,
    },
  });

  // Change product type base on the settlement details
  useEffect(() => {
    if (settlement?.assetCategoryId) {
      emitter.emit('CHANGE_MARKET_PRODUCT', settlement.assetCategoryId);
    }
  }, [settlement?.assetCategoryId]);

  const uom = assetCategoryUom[settlement?.assetCategoryId as AssetCategory];

  const { pairs } = usePairs({
    assetCategories: settlement?.assetCategoryId
      ? ([AssetCategory[settlement?.assetCategoryId]] as AssetCategoryCode[])
      : [],
  });

  // Only call for reserve asset for REC
  const isRec = settlement?.assetCategoryId && Number(settlement?.assetCategoryId) === Number(AssetCategory.rec);
  const { selectors } = useAddressData({ address: getAccountAddress(), options: { enabled: !!settlement && isRec } });
  const batches = selectors.getAllBatches();
  const projectsMetadata = useProjectsMetadata(
    batches,
    getCriteriaMetaValueBy('REGISTRY_ID', settlement?.criteria ?? [], ''),
  );

  const { data: rejectReason, isLoading: isFetchingRejectReason } = useQuery(
    ['reject-reason'],
    () => fetchRejectReason(),
    {
      enabled: !!id,
    },
  );

  // Redirect to list page if not participant
  const userId = getUserId();
  if (
    userId > 0 &&
    settlement &&
    ![settlement.sellerUserId, settlement.buyerUserId, settlement.createdBy].includes(userId)
  ) {
    history.push(urlMapping.list);
    return null;
  }

  const showCancel =
    settlement?.status === SettleStatus.SUBMITTED &&
    settlement.sellerStatus === SettleStatus.SUBMITTED &&
    settlement.buyerStatus === SettleStatus.SUBMITTED &&
    settlement?.createdBy === userId;

  const orderSide =
    getOrderSide({
      currentUserId: getUserId(),
      buyerUserId: settlement?.buyerUserId ?? 0,
      sellerUserId: settlement?.sellerUserId ?? 0,
    })?.toUpperCase() ?? '';

  const getTotals = () => {
    if (!settlement) {
      return { totalPay: 0, totalReceive: 0 };
    }

    const total = settlement.quantity * settlement.price;
    const totalPay = total + settlement.buyerFee + settlement.originatorFee;
    const totalReceive = total - settlement.sellerFee - settlement.originatorFee;
    switch (orderSide) {
      case 'BUY':
        return { totalPay, totalReceive: 0 };
      case 'SELL':
        return { totalPay: 0, totalReceive };
      default:
        return { totalPay, totalReceive };
    }
  };

  const onPressAccept = () => {
    setIsAcceptModalVisible(true);
  };

  const onPressReject = () => {
    setIsRejectModalVisible(true);
  };

  const onPressConfirmReserve = (selectedBatchIds?: Record<string, number>) => {
    if (orderSide === 'SELL' && !settlement?.xPair?.id && !selectedContractId) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Please select a contract',
      });
      return;
    }
    showToast({
      variant: ToastVariant.Info,
      message: 'Reserving...',
    });
    setIsActionLoading(true);
    reserveCarbon(selectedBatchIds);
    reserveCarbonModalRef.current?.hide();
  };

  const onChangeCarbonContract = (newContract: string) => {
    setSelectedContractId(newContract);
    reserveCarbonModalRef.current?.setModalData((prevModalData) => ({
      ...prevModalData,
      contract: newContract,
      onPressConfirmReserveCarbon: onPressConfirmReserve,
    }));
  };

  // NOTE: Seller will reserve asset, buyer will reserve fund
  const isSeller = settlement?.sellerUserId === getUserId();

  const onPressReserve = () => {
    if (settlement?.assetCategoryId === AssetCategory.rec) {
      // NOTE: Make it consistent with the Reserve Carbon vs Reserve REC
      reserveRecModalRef.current?.show();
      return;
    }
    reserveCarbonModalRef.current?.show({
      contract: settlement?.xPair?.id?.toString() ?? selectedContractId ?? undefined,
      contracts:
        pairs?.map((pair) => ({
          value: pair.id.toString(),
          title: pair.name,
        })) ?? [],
      isSelectContractDisabled: settlement?.xPair?.name !== undefined,
      isSelectContractHidden: !isSeller,
      summary: [
        {
          label: 'Side',
          value: orderSide,
          valueColor: orderSide === 'SELL' ? TextColor.error : TextColor.success,
        },
        {
          label: 'Project',
          value: project?.name ?? detailsData?.projectId ?? '',
        },
        {
          label: 'Registry Project ID',
          value: detailsData?.projectId ?? '',
        },
        {
          label: 'Vintage',
          value: detailsData?.vintage ?? '',
        },
        {
          label: 'Contract',
          value: settlement?.xPair?.name ?? '-',
        },
        {
          label: 'Settlement Date',
          value: detailsData?.settlementDate ?? '',
        },
      ],
      totals: [
        {
          label: 'Quantity',
          value: settlement?.quantity ? formatter.formatNumber(settlement?.quantity, 0) : '',
        },
        {
          label: `Price (per ${uom})`,
          value: settlement?.price ? `${mainCcyCode}${formatter.formatNumber(settlement?.price)}` : '',
        },
        {
          label: 'Exchange fee',
          value: settlement?.buyerFee ? `${mainCcyCode}${formatter.formatNumber(settlement?.buyerFee)}` : '',
        },
        ...(Number((settlement?.originatorFee ?? 0) > 0)
          ? [
              {
                label: 'Arranger fee',
                value: settlement?.originatorFee
                  ? `${mainCcyCode}${formatter.formatNumber(settlement?.originatorFee)}`
                  : '0',
              },
            ]
          : []),
        {
          label: 'Total',
          value: `${mainCcyCode}${settlement?.buyerUserId === getUserId() ? formatter.formatNumber(getTotals().totalPay) : formatter.formatNumber(getTotals().totalReceive)}`,
          isTotal: true,
        },
      ],
      accountName: settlement?.createdByUser?.firstName
        ? `${settlement?.createdByUser?.firstName} ${settlement?.createdByUser?.lastName}`
        : '',
    });
  };

  const onPressPositiveCancel = () => {
    setIsActionLoading(true);
    cancelSettlement();
    setIsCancelModalVisible(false);
  };

  const onPressPositiveAccept = () => {
    setIsActionLoading(true);
    approveSettlement();
    setIsAcceptModalVisible(false);
  };

  const onPressPositiveReject = (reasonId: string) => {
    setIsActionLoading(true);
    rejectSettlement(reasonId);
    setIsRejectModalVisible(false);
  };

  const { steps, currentStep } = prepareProgressItems({
    logs: settlement?.logs ?? [],
    sellerUserId: settlement?.sellerUserId ?? 0,
    buyerUserId: settlement?.buyerUserId ?? 0,
    settlementStatus: settlement?.status ?? '',
    settlementDate: settlement?.settlementDate || '',
  });

  const actionBarData = prepareActionsBarData({
    settlement,
    currentUserId: getUserId(),
    onPressControl: {
      onPressAccept,
      onPressReject,
      onPressReserve: onPressReserve,
    },
    otcMarketSettings: {
      otcEditEnabled: marketSettings?.otcEditEnabled ?? 1,
    },
    isActionLoading,
  });

  const filterRecProjectsByCriteria = () => {
    const baseAsset = settlement?.asset?.baseAsset;
    if (!baseAsset) {
      return [];
    }

    // Convert registry_id to registry name
    const filterByCriteria = (settlement?.criteria ?? []).map((item) => {
      if (item.metaKeyCode === 'REGISTRY_ID') {
        const registry = registries?.find((registry) => registry.id === Number(item.metaValue));
        return {
          ...item,
          metaValue: registry?.registryName ?? 'Unknown',
        };
      }
      return item;
    });

    const projects = selectors.userProjects();

    const validProjects = Object.keys(projects ?? {}).filter((projectId) => {
      const project = projects?.[projectId];
      if (!project) {
        return false;
      }
      const batchMetaData = {
        ...project.metaData,
        PROJECT_NAME: getProjectMetaBy('PROJECT_NAME', project.metaData),
        REC_TECHNOLOGY:
          projectsMetadata.projects.find((item) => item.project?.registryProjectId === projectId)?.project
            ?.technology ?? 'Unknown',
      };
      logger.info({ batchMetaData, filterByCriteria, projects: projectsMetadata.projects }, 'Filtering by batch');
      return (
        matching.filterBatchesByType({
          type: 'rec',
          batches: [
            {
              metaData: batchMetaData,
            },
          ],
          criteria: filterByCriteria.map((item) => {
            return {
              metaKeyCode: item.metaKeyCode,
              metaValue: item.metaValue,
            };
          }),
        }).length > 0
      );
    });

    const result: {
      id: string;
      balance: number;
      name: string;
    }[] = [];
    // only filter to tokens which is REC
    validProjects.forEach((projectId) => {
      const project = projects?.[projectId];
      if (project) {
        const tokens = project.tokens.filter((token) => token.tokTypeId === baseAsset.scId);
        tokens.forEach((token) => {
          result.push({
            id: String(token.batchId),
            name: getProjectMetaBy('PROJECT_NAME', project.metaData), // NOTE: Consider to add vintage and registry if needed
            balance: token.qty / baseAsset.lotQtySize,
          });
        });
      }
    });

    logger.info({ result, filterByCriteria, projects }, 'Filtered projects');
    return result;
  };

  const handleAlertActionPress = (actionType: PendingActionType) => {
    if (actionType === PendingActionType.Accept) {
      // open accept modal
      setIsAcceptModalVisible(true);
    }

    if (actionType === PendingActionType.Reject) {
      // open reject modal
      setIsRejectModalVisible(true);
    }

    if (actionType === PendingActionType.Reserve) {
      onPressReserve();
    }
  };

  return (
    <>
      <ModalSettlementReserveCarbon
        ref={reserveCarbonModalRef}
        onChangeContract={onChangeCarbonContract}
        onPressConfirm={() => onPressConfirmReserve()}
      />
      {settlement?.assetCategoryId === AssetCategory.rec && (
        <ReserveREC
          isLoading={projectsMetadata.isLoading}
          onReserveAsset={(batches) => {
            onPressConfirmReserve(batches);
          }}
          summaryDetails={{
            summary: [
              {
                label: 'Side',
                value: orderSide,
                valueColor: orderSide === 'SELL' ? TextColor.error : TextColor.success,
              },
              {
                label: 'Registry',
                value:
                  registries?.find((registry) => Number(registry.id) === Number(detailsData?.registry))?.registryName ??
                  '-',
              },
              {
                label: 'Country',
                value: countries[detailsData?.countryCode ?? ''] ?? '',
              },
              {
                label: 'Vintage',
                value: detailsData?.vintage ?? '',
              },
              {
                label: 'Technology',
                value: detailsData?.technology ?? '',
              },
              {
                label: 'Settlement Date',
                value: detailsData?.settlementDate ?? '',
              },
            ],
            totals: [
              {
                label: 'Quantity',
                value: settlement?.quantity?.toString() ?? '',
              },
              {
                label: `Price (per ${uom})`,
                value: `${mainCcyCode}${settlement?.price?.toFixed(2) ?? ''}`,
              },
              {
                label: 'Exchange fee',
                value: `${mainCcyCode}${settlement?.buyerFee?.toFixed(2) ?? ''}`,
              },
              ...(settlement?.originatorFee > 0
                ? [
                    {
                      label: 'Arranger fee',
                      value: `${mainCcyCode}${settlement?.originatorFee?.toFixed(2) ?? '0'}`,
                    },
                  ]
                : []),
              {
                label: 'Total',
                value: `${mainCcyCode}${settlement?.buyerUserId === getUserId() ? getTotals().totalPay.toFixed(2) : getTotals().totalReceive.toFixed(2)}`,
                isTotal: true,
              },
            ],
            accountName: settlement?.createdByUser?.firstName
              ? `${settlement?.createdByUser?.firstName} ${settlement?.createdByUser?.lastName}`
              : '',
          }}
          projects={filterRecProjectsByCriteria()}
          settlementDetails={settlement}
          ref={reserveRecModalRef}
          type={isSeller ? 'asset' : 'fund'}
        />
      )}
      <Wrapper>
        <Container>
          <PendingAlerts
            onPressSecondaryAction={(action) => {
              if (!action.secondaryActionType) {
                return;
              }
              handleAlertActionPress(action.secondaryActionType);
            }}
            onPressPrimaryAction={(action) => {
              handleAlertActionPress(action.primaryActionType);
            }}
            items={toPendingAlerts({ settlement, currentUserId: getUserId() })}
          />
          <PageHolder>
            <HeaderContainer>
              <PageHeader title={`Instruction #${id}`} onBack={() => history.push(urlMapping.list)} />
              {showCancel && (
                <Button
                  onPress={() => setIsCancelModalVisible(true)}
                  size={ButtonSize.s}
                  variant={ButtonVariant.secondary}
                >
                  Cancel
                </Button>
              )}
            </HeaderContainer>

            <Layer>
              <PageSections>
                <Content>
                  <Modal
                    title={`Cancel Instruction #${id}`}
                    isVisible={isCancelModalVisible}
                    onClose={() => setIsCancelModalVisible(false)}
                    onPressNegativeButton={() => setIsCancelModalVisible(false)}
                    onPressPositiveButton={() => onPressPositiveCancel()}
                    positiveButtonText="Cancel"
                    negativeButtonText="No"
                  >
                    <TextContainer>
                      <Text align={TextAlign.center} variant={TypographyVariant.body1} color={TextColor.secondary}>
                        Are you sure you want to cancel this instruction?
                      </Text>
                    </TextContainer>
                  </Modal>

                  <Modal
                    title={`Accept Instruction #${id}`}
                    isVisible={isAcceptModalVisible}
                    onClose={() => setIsAcceptModalVisible(false)}
                    onPressNegativeButton={() => setIsAcceptModalVisible(false)}
                    onPressPositiveButton={() => onPressPositiveAccept()}
                    positiveButtonText="Accept"
                    negativeButtonText="No"
                  >
                    <TextContainer>
                      <Text align={TextAlign.center} variant={TypographyVariant.body1} color={TextColor.secondary}>
                        {`Once you accept the instruction, you are confirming availability of funds/${settlement?.assetCategoryId === AssetCategory.rec ? 'assets' : 'credits'} and confirming
                        to process with the trade.`}
                      </Text>
                      <Text align={TextAlign.center} variant={TypographyVariant.subtitle1}>
                        Do you want to proceed?
                      </Text>
                    </TextContainer>
                  </Modal>
                  <Modal
                    title={`Reject Instruction #${id}`}
                    isVisible={isRejectModalVisible}
                    onClose={() => setIsRejectModalVisible(false)}
                    onPressNegativeButton={() => setIsRejectModalVisible(false)}
                    onPressPositiveButton={() => {
                      if (!selectedRejectReasonId) {
                        showToast({
                          variant: ToastVariant.Danger,
                          message: 'Please select a reason',
                        });
                        return;
                      }
                      onPressPositiveReject(selectedRejectReasonId);
                    }}
                    isPositiveButtonLoading={isFetchingRejectReason}
                    positiveButtonText="Reject"
                    negativeButtonText="No"
                  >
                    <RejectReasonSelect
                      items={
                        rejectReason?.status === 'success'
                          ? rejectReason?.data.map((reason) => ({
                              title: reason?.reason,
                              value: reason?.id?.toString(),
                            }))
                          : []
                      }
                      label="Reject Reason"
                      onChange={(props: { value: string }) => {
                        setSelectedRejectReasonId(props.value);
                      }}
                      placeholder="Select"
                      searchPlaceholder="Select"
                      size={InputTextSize.m}
                      value={selectedRejectReasonId}
                    />
                  </Modal>
                  {(isLoading || isFetchingProject) && <Loader />}
                  {settlement && (
                    <>
                      <SettlementDetail>
                        <Details
                          {...detailsData}
                          settleId={id}
                          country={countries[detailsData?.countryCode ?? '-']}
                          registry={
                            registries?.find((registry) => Number(registry.id) === Number(detailsData?.registry))
                              ?.registryName
                          }
                          projectName={project?.name ?? detailsData?.projectId}
                        />
                        <Summary
                          quantity={settlement?.quantity ?? 0}
                          price={settlement?.price ?? 0}
                          exchangeFee={settlement?.buyerFee || settlement?.sellerFee || 0}
                          arrangerFee={settlement?.originatorFee ?? 0}
                          ccyCode={mainCcyCode}
                          uom={uom}
                          totalPay={getTotals().totalPay}
                          totalReceive={getTotals().totalReceive}
                        />
                      </SettlementDetail>
                      <StyledCard>
                        <Layer>
                          <SettlementProgress
                            currentStep={currentStep}
                            items={steps as [ProgressItem, ProgressItem?][]}
                          />
                        </Layer>
                      </StyledCard>
                    </>
                  )}
                </Content>
              </PageSections>
            </Layer>
          </PageHolder>
        </Container>
        {canManageSettlement() && actionBarData.isActionBarVisible && (
          <ActionsBar
            label={actionBarData.label}
            description={actionBarData.description}
            buttonLabel={actionBarData.buttonLabel}
            onPress={actionBarData.onPress}
            secondaryButtonLabel={actionBarData.secondaryButtonLabel}
            secondaryOnPress={actionBarData.secondaryOnPress}
            isButtonDisabled={actionBarData.isButtonDisabled}
          />
        )}
      </Wrapper>
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(16)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  container-type: inline-size;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
`;

const Content = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  padding-bottom: ${({ theme }) => toSpacing(theme)(8)};
  flex-direction: column;

  @container (min-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const SettlementDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${toSpacing(theme)(16)} ${toSpacing(theme)(12)}`};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(16)};
`;

const RejectReasonSelect = styled(Select)`
  width: 100%;
`;

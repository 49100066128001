import { Icon, IconName, Text, TextAs, TextColor, Tooltip, TypographyVariant, useTextColor } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';
import type { Pair } from '@aircarbon/utils-common/src/dto';

import Table from '../components/Table';
import { Wrapper } from '../components/styled';
import type { AssetCategoryCode } from '../layouts/trading.hook';

type Props = {
  watchlist: { items: Pair[]; total: number } | null;
  position?: 'absolute' | 'realative';
  backgroundColor?: string;
  onClick?: (key?: string) => void;
  style?: React.CSSProperties;
  withCustomScrollBar?: boolean;
  size?: 'default' | 'big';
  assetCategory?: AssetCategoryCode;
};

const Watchlist = ({
  watchlist,
  position,
  onClick,
  style,
  withCustomScrollBar = true,
  size,
  assetCategory = 'token',
}: Props) => {
  let watchlistList = [
    {
      pair: <Text variant={TypographyVariant.caption}>-</Text>,
      bid: <Text variant={TypographyVariant.caption}>-</Text>,
      ask: <Text variant={TypographyVariant.caption}>-</Text>,
      lastTrade: <Text variant={TypographyVariant.caption}>-</Text>,
      _key: '0',
    },
  ];
  const { textColor } = useTextColor();

  if (watchlist && watchlist?.items?.length > 0) {
    watchlistList = watchlist?.items?.map((pair) => {
      const lastBidPrice = pair.marketData?.lastBidPrice;
      const lastAskPrice = pair.marketData?.lastAskPrice;
      const lastTradedPrice = pair.marketData?.lastTradedPrice;
      const orderEntryEnabled = pair.marketFlags?.orderEntryEnabled ?? 1;
      const orderEditEnabled = pair.marketFlags?.orderEditEnabled ?? 1;
      const orderCancelationEnabled = pair.marketFlags?.orderCancelationEnabled ?? 1;
      const matchingEngineEnabled = pair.marketFlags?.matchingEngineEnabled ?? 1;
      const isAnyFlagDisabled =
        orderEntryEnabled !== 1 ||
        orderEditEnabled !== 1 ||
        orderCancelationEnabled !== 1 ||
        matchingEngineEnabled !== 1;

      const flagsDescription = () => (
        <table className="table-auto">
          <thead>
            <tr>
              <th className="text-left">Flag</th>
              <th className="ml-20 text-right">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="mr-10">
                <Text variant={TypographyVariant.caption}>Order Entry</Text>
              </td>
              <td className="text-right">{orderEntryEnabled === 1 ? 'On' : 'Off'}</td>
            </tr>
            <tr>
              <td className="mr-10">Order Cancelation</td>
              <td className="text-right">{orderCancelationEnabled === 1 ? 'On' : 'Off'}</td>
            </tr>
            <tr>
              <td className="mr-10">Matching Engine</td>
              <td className="text-right">{matchingEngineEnabled === 1 ? 'On' : 'Off'}</td>
            </tr>
          </tbody>
        </table>
      );

      const disabledFlags = () => (
        <Tooltip
          value={
            <Text as={TextAs.span} variant={TypographyVariant.caption}>
              {flagsDescription()}
            </Text>
          }
        >
          <img
            style={{
              right: 0,
            }}
            className="absolute ml-1 text-red-200"
            height="15px"
            width="15px"
            src="/info-red.png"
            alt="info"
          />
        </Tooltip>
      );

      return {
        pair: <div>{pair.name}</div>,
        bid: (
          <div
            style={{
              color: textColor(TextColor.success),
              position: 'relative',
            }}
          >
            {lastBidPrice === null ||
            lastBidPrice === undefined ||
            Number.isNaN(lastBidPrice) ||
            (lastBidPrice === 0 && assetCategory === 'token')
              ? '-'
              : formatter.formatNumber(Number(lastBidPrice))}
          </div>
        ),
        ask: (
          <div
            style={{
              color: textColor(TextColor.error),
            }}
          >
            {lastAskPrice === null ||
            lastAskPrice === undefined ||
            Number.isNaN(lastAskPrice) ||
            (lastAskPrice === 0 && assetCategory === 'token')
              ? '-'
              : formatter.formatNumber(Number(lastAskPrice))}
          </div>
        ),
        lastTrade: (
          <div
            style={{
              paddingRight: '10px',
            }}
            className="flex flex-row items-center self-end"
          >
            {lastTradedPrice === null ||
            lastTradedPrice === undefined ||
            Number.isNaN(lastTradedPrice) ||
            (lastTradedPrice === 0 && assetCategory === 'token')
              ? '-'
              : formatter.formatNumber(lastTradedPrice)}
            {isAnyFlagDisabled && disabledFlags()}
          </div>
        ),
        _key: pair.name,
      };
    });
  }

  const table = (
    <Table
      config={{
        size,
        withCustomScrollBar,
        onClick: (item) => {
          onClick?.(item._key);
        },
        columns: {
          pair: {
            label: 'PAIR',
            font: 'code',
          },
          bid: {
            label: 'BID',
            font: 'code',
          },
          ask: {
            label: 'ASK',
            font: 'code',
          },
          lastTrade: {
            label: (
              <div className="flex flex-row items-center">
                LAST{' '}
                <Tooltip value="Last Traded Price">
                  <Icon name={IconName.InfoCircle} />
                </Tooltip>
              </div>
            ),
            font: 'code',
          },
        },
        rows: watchlistList,
      }}
    />
  );

  if (!withCustomScrollBar) {
    return table;
  } else {
    return (
      <Wrapper style={style} position={position} className="flex flex-col w-full h-full">
        <div className="flex relative flex-row flex-auto h-full">{table}</div>
      </Wrapper>
    );
  }
};

export default Watchlist;

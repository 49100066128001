import {
  Icon,
  IconName,
  Text,
  TextColor,
  Tooltip,
  TypographyVariant,
  styled,
  toLayerBackground,
  toTextColor,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

const BezeroIcon = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  border-radius: ${({ theme }) => theme.system.border.radius.s};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
`;

export interface DisclaimerUrl {
  url: string;
  title: string;
}

export const ProjectRatingBadge = ({
  projectRating,
  ratingType,
  urls,
  className,
  title,
}: {
  projectRating: string;
  ratingType: Dto.ProjectRatingType;
  urls?: DisclaimerUrl[];
  className?: string;
  title?: {
    color?: TextColor;
  };
}) => {
  return (
    <div className={`flex flex-col ${className ?? ''}`}>
      <Text color={title?.color ?? TextColor.secondary} variant={TypographyVariant.body2}>
        {ratingType === Dto.ProjectRatingType.BezeroCarbonRating ? 'BeZero Carbon Rating' : 'Sylvera Rating Category'}{' '}
        {!!urls?.length && (
          <Tooltip
            value={
              <>
                <div className="p-1">Disclaimer:</div>
                {urls.map((url) => (
                  <div className="p-1 underline">
                    <a
                      href={`${url.url}`}
                      className="flex flex-row items-center gap-xs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {url.title}
                    </a>
                  </div>
                ))}
              </>
            }
          >
            <Icon name={IconName.InfoCircleOutlined} />
          </Tooltip>
        )}
      </Text>
      <BezeroIcon>
        <Text variant={TypographyVariant.subtitle2}>{projectRating}</Text>
      </BezeroIcon>
    </div>
  );
};

import { BorderColor, toBorderColor, toLayerBackground, useTheme } from '@aircarbon/ui';

export const ProjectCardLoader = () => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 1092 164" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H732.5V151.5H12C5.64872 151.5 0.5 146.351 0.5 140V12Z"
        fill={toLayerBackground(theme)('layer')}
      />
      <rect x="24" y="32" width="601" height="20" rx="10" fill={'url("#fill")'} />
      <rect x="24" y="32" width="601" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="24" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="24" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="24" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="24" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="155" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="155" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="155" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="155" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="286" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="286" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="286" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="286" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="417" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="417" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <g opacity="0.7">
        <rect x="417" y="84" width="48" height="14" rx="7" fill={'url("#fill")'} />
      </g>
      <rect x="417" y="106" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <path
        d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H732.5V151.5H12C5.64872 151.5 0.5 146.351 0.5 140V12Z"
        stroke={toBorderColor(theme)(BorderColor.neutral)}
      />
      <path
        d="M733.5 0.5H1080C1086.35 0.5 1091.5 5.64873 1091.5 12V140C1091.5 146.351 1086.35 151.5 1080 151.5H733.5V0.5Z"
        fill={toLayerBackground(theme)('layer')}
      />
      <rect x="757" y="30" width="83" height="20" rx="10" fill={'url("#fill")'} />
      <rect x="948" y="30" width="120" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="757" y="80" width="311" height="48" rx="8" fill={'url("#fill")'} />
      <path
        d="M733.5 0.5H1080C1086.35 0.5 1091.5 5.64873 1091.5 12V140C1091.5 146.351 1086.35 151.5 1080 151.5H733.5V0.5Z"
        stroke={toBorderColor(theme)(BorderColor.neutral)}
      />
      <defs>
        <linearGradient id="fill">
          <stop offset="0.599964" stop-color={toLayerBackground(theme)('field')} stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
          <stop offset="1.59996" stop-color={toLayerBackground(theme)('fieldAccent')} stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
          <stop offset="2.59996" stop-color={toLayerBackground(theme)('field')} stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

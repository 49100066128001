import {
  Icon,
  IconName,
  Text,
  TextColor,
  Tooltip,
  TypographyVariant,
  styled,
  toSpacing,
  toTextColor,
} from '@aircarbon/ui';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import Loading from './Loading';

const marketStatusCodeToColorMap = {
  CLOSED: TextColor.error,
  OPEN: TextColor.success,
  'PRE-CLOSE': TextColor.success,
  'PRE-OPEN': TextColor.warning,
};

export const MarketStatus = () => {
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({
    includeMarketStatus: true,
  });

  if (isLoadingMarketSettings) return <Loading isOverLay={false} size="small" />;

  if (!marketSettings?.marketStatusObj?.label) {
    return <></>;
  }

  return (
    <>
      <Text variant={TypographyVariant.body2}>Market Status:</Text>
      {/* TODO: make a new colors map for marketStatusObj?.color */}
      <StyledStatusText
        $statusColor={marketStatusCodeToColorMap[marketSettings?.marketStatusObj.code]}
        variant={TypographyVariant.body2}
      >
        {marketSettings?.marketStatusObj?.label}
        {!!marketSettings?.marketStatusObj?.description && (
          <Tooltip value={marketSettings?.marketStatusObj?.description}>
            <Icon name={IconName.InfoCircleOutlined} />
          </Tooltip>
        )}
      </StyledStatusText>
    </>
  );
};

const StyledStatusText = styled(Text)<{
  $statusColor: TextColor;
}>`
  color: ${({ $statusColor, theme }) => toTextColor(theme)($statusColor)};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(1)};
`;

import { ApiClient } from 'generated';
import { useHistory } from 'react-router-dom';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { AssetCategory, helpers, logger } from '@aircarbon/utils-common';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import SettlementRequestForm, { type SettlementRequestFormValues } from './components/SettlementRequestForm';
import { urlMapping } from './route';

function NewSettlementRequest() {
  const history = useHistory();
  const {
    user,
    selector: { getAuthToken },
  } = User.useContainer();
  const { product } = useMarketplaceProduct();

  const {
    selector: { mainCcyCode },
  } = Entity.useContainer();

  const onCreateNewSettlementRequest = async (formValues: SettlementRequestFormValues) => {
    showToast({
      variant: ToastVariant.Info,
      message: 'Creating new request',
    });
    try {
      const token = await getAuthToken();
      const apiClient = new ApiClient({
        BASE: window.location.origin,
        TOKEN: token,
      });

      const assetType = Number(product) === Number(AssetCategory.rec) ? 'REC' : 'Carbon';
      const pairId = assetType === 'Carbon' ? Number(formValues.pairId) : undefined;

      const result = await apiClient.settle.createSettle({
        xEntityDomain: helpers.getHostFromUrl(window.location.origin),
        requestBody: {
          settle: {
            ...(pairId ? { pairId } : {}),
            criteria: formValues.criteria.map((item) => ({
              key: item.metaKey,
              value: item.metaValue,
            })),
            buyerEmail: formValues.buyerEmail,
            sellerEmail: formValues.sellerEmail,
            price: formValues.price,
            quantity: formValues.quantity,
            settlementDate: formValues.settlementDate,
          },
          assetType,
        },
      });
      logger.info(result, 'Create new settlement request');
      showToast({
        variant: ToastVariant.Success,
        message: 'Your request has been created.',
      });
      history.push(urlMapping.list);
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error.body?.message ?? error.message ?? 'Failed to create new request',
      });
    }
  };
  const tokenUnit = Number(product) === Number(AssetCategory.token) ? 'tCo2' : 'Mwh';

  return (
    <PageHolder>
      <PageHeader title={'New Settlement Instruction'} margin={'24px'} onBack={() => history.push(urlMapping.list)} />
      <PageSections>
        <SettlementRequestForm
          currencyUnit={mainCcyCode}
          tokenUnit={tokenUnit}
          productType={Number(product) === Number(AssetCategory.token) ? 'Carbon' : 'REC'}
          email={user?.username ?? ''}
          onSubmit={onCreateNewSettlementRequest}
        />
      </PageSections>
    </PageHolder>
  );
}

export default NewSettlementRequest;

import { format } from 'date-fns';
import { useRef } from 'react';
import { CopyInput } from 'refreshed-component/atoms/CopyInput';

import {
  Badge,
  BadgeSize,
  BadgeVariant,
  BorderColor,
  IconName,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toSpacing,
  useBorderColor,
} from '@aircarbon/ui';

import useTransactions from 'hooks/useTransactions';

import Loading from './Loading';

const TransactionsRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: min-content;
  width: 440px;
  justify-items: stretch;
  overflow: hidden;
  max-height: 100%;
  > .list {
    max-height: 400px;
    overflow: auto;
    position: relative;
    display: block;
  }
`;

export const TransactionsOverlay = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { allTx, isLoadingAllTx } = useTransactions();
  const { borderColor } = useBorderColor();

  return (
    <TransactionsRoot>
      <div
        className="flex flex-col border-b gap-base"
        style={{
          borderColor: borderColor(BorderColor.neutral),
        }}
      >
        <div className="flex flex-row px-large py-base">
          <Text variant={TypographyVariant.h6Title}>Transactions</Text>
        </div>
      </div>
      <div className="list" ref={ref}>
        <div className="flex flex-col w-full">
          {!allTx || isLoadingAllTx ? (
            <div className="p-large">
              <Loading size="medium" isOverLay={false} />
            </div>
          ) : (
            allTx?.items.map(
              (tx: {
                txStatus: string;
                exchangeTxType: string;
                txHash: string;
                id: number;
                createdAt: Date | string;
              }) => {
                return (
                  <TransactionItem key={`item-${tx.id}`}>
                    <div className="flex flex-row justify-between items-center">
                      <Text variant={TypographyVariant.subtitle1}>{tx?.exchangeTxType}</Text>
                      {tx?.txStatus === 'new' ? (
                        <Badge value={'Processing'} size={BadgeSize.s} icon={IconName.X} variant={BadgeVariant.Alert} />
                      ) : ['error', 'failed'].includes(tx?.txStatus) ? (
                        <Badge
                          value={'Transaction failed'}
                          size={BadgeSize.s}
                          icon={IconName.X}
                          variant={BadgeVariant.Danger}
                        />
                      ) : (
                        <Badge
                          value={tx?.txStatus.toUpperCase()}
                          size={BadgeSize.s}
                          icon={IconName.Check}
                          variant={BadgeVariant.Success}
                        />
                      )}
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <div>
                        {tx?.txHash && <CopyInput variant={TypographyVariant.body2} text={tx?.txHash} length={10} />}
                      </div>
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        {format(new Date(tx?.createdAt), 'HH:mm:ss MMM d yyyy ')}
                      </Text>
                    </div>
                  </TransactionItem>
                );
              },
            )
          )}
        </div>
      </div>
    </TransactionsRoot>
  );
};

const TransactionItem = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  padding: ${({ theme }) => toSpacing(theme)(6)};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }
`;

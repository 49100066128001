import { Auth } from 'aws-amplify';
import * as React from 'react';

import { useLayerBackground } from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';
import type { Pair } from '@aircarbon/utils-common/src/dto';

import { ThemeMode, type ThemeModeType } from '../../components/ThemeMode';
import {
  type ChartingLibraryWidgetOptions,
  type IChartingLibraryWidget,
  type ResolutionString,
  widget,
} from '../charting_library';
import { UDFCompatibleDatafeed } from '../datafeeds/udf/src/udf-compatible-datafeed';
import './index.css';

export interface ChartContainerProps {
  themeMode: ThemeModeType;
  paneBackgroundColor: string;
  interval: ChartingLibraryWidgetOptions['interval'];
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
  authToken?: string;
  pair: Pair;
}

export class TVChartContainerClass extends React.PureComponent<Partial<ChartContainerProps>, unknown> {
  public static defaultProps: Omit<
    ChartContainerProps,
    'container' | 'pair' | 'theme' | 'themeMode' | 'paneBackgroundColor' | 'datafeedUrl'
  > = {
    interval: '1' as ResolutionString,
    libraryPath: '/charting_library/',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  private tvWidget: IChartingLibraryWidget | null = null;
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  public componentDidMount(): void {
    if (!this.ref.current) {
      return;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.pair?.name ?? '',
      datafeed: new UDFCompatibleDatafeed(this.props.datafeedUrl, this.props.authToken),
      interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
      container: this.ref.current,
      library_path: this.props.libraryPath,
      locale: 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'header_compare',
        'symbol_info',
        'timeframes_toolbar',
      ],
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      theme: this.props.themeMode === 'dark' ? 'Dark' : 'Light',
      header_widget_buttons_mode: 'compact',
      custom_css_url: '/tv.css',
      loading_screen: {
        foregroundColor: '#FFFFFF00',
        backgroundColor: '#FFFFFF00',
      },
      overrides: {
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': this.props.paneBackgroundColor,
      },
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      this.isChartReady = true;
      this.setSymbol();
    });
  }

  isChartReady = false;
  setSymbol() {
    if (this.props.pair?.name) this.tvWidget?.setSymbol(this.props.pair?.name, '1' as ResolutionString, () => {});
  }

  componentDidUpdate(prevProps: Readonly<ChartContainerProps>, prevState: Readonly<any>) {
    if (this.tvWidget && prevProps.pair?.name !== this.props.pair?.name && this.isChartReady) this.setSymbol();
  }

  public componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  public render(): JSX.Element {
    return <div ref={this.ref} className={'TVChartContainer'} />;
  }
}

export const TVChartContainer = (props: Partial<ChartContainerProps & { isPublic?: boolean; authToken: never }>) => {
  const { themeMode } = ThemeMode.useContainer();
  const { layerBackground } = useLayerBackground();
  const [authToken, setAuthToken] = React.useState('');
  const { isPublic } = props;

  // Check if user is logged in and set token if not public page
  React.useEffect(() => {
    if (!isPublic) {
      Auth.currentSession()
        .then((token) => {
          const jwtToken = token.getAccessToken().getJwtToken();
          // Change the token only if it's not the same as the one in the state
          if (jwtToken !== authToken) {
            setAuthToken(jwtToken);
          }
        })
        .catch((err) => {
          logger.error(err);
          setAuthToken('');
        });
    }
  }, [authToken]);

  if (!isPublic && !authToken) return <div>loading...</div>;

  return (
    <TVChartContainerClass
      {...props}
      key={`${themeMode}-${authToken}`}
      authToken={props.isPublic ? undefined : authToken}
      datafeedUrl={props.isPublic ? '/api/public' : '/api/user/chart'}
      themeMode={themeMode}
      paneBackgroundColor={layerBackground('layer')}
    />
  );
};

import type { ReactNode } from 'react';

import {
  BorderColor,
  Icon,
  IconName,
  Link,
  Text,
  TextColor,
  Tooltip,
  TypographyVariant,
  styled,
  toBorderColor,
  useSpacing,
  useTheme,
} from '@aircarbon/ui';
import { Dto, formatter, projects } from '@aircarbon/utils-common';

import { DetailedCard, DetailedCardDescriptionItem } from '../DetailedCard';
import { ProjectRatingBadge } from '../project-rating/ProjectRatingBadge';
import useProjectRatingDisclaimers from '../project-rating/hooks/useProjectRatingDisclaimers';

const MarketPlaceProjectCardRoot = styled.div`
  display: contents;

  .controls {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const MarketPlaceProjectCard = ({
  type = 'light',
  info: { carbonProject, tokenAsset, id, indicators, carbonProjectVintageYear, onClick },
  controls,
}: {
  type?: 'light' | 'white';
  info: {
    carbonProject: any;
    tokenAsset?: any | undefined;
    id: string;
    carbonProjectVintageYear?: number;
    onClick?: () => void;
    indicators?: string[];
  };
  controls?: ReactNode;
}) => {
  const { spacing } = useSpacing();
  const theme = useTheme();
  const { bezeroDisclaimerUrls, sylveraDisclaimerUrls } = useProjectRatingDisclaimers();

  const bezeroRating = carbonProject?.__ratings__?.find(
    (r: any) => r.ratingType === Dto.ProjectRatingType.BezeroCarbonRating,
  )?.rating;
  const sylveraRating = carbonProject?.__ratings__?.find(
    (r: any) => r.ratingType === Dto.ProjectRatingType.SylveraRatingCategory,
  )?.rating;

  const handleProjectRegistryClick = (event: { preventDefault: () => void }) => {
    const url = carbonProject?.registryProjectUrl;
    event.preventDefault();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <MarketPlaceProjectCardRoot>
      <DetailedCard type={type} className={`project-item`}>
        <DetailedCardDescriptionItem
          onClick={onClick}
          className={`flex flex-col flex-1 h-auto p-large gap-base justify-between ${onClick ? 'cursor-pointer' : ''}`}
        >
          <div className="flex flex-col w-full h-auto gap-base">
            <div className="flex flex-col justify-between w-full md:flex-row">
              <div className="flex flex-col md:flex-row gap-base">
                <Text marginHorizontal={spacing(8)} marginVertical={spacing(2)} className="w-min whitespace-pre">
                  {id}
                </Text>
                <Text variant={TypographyVariant.h5Title}>
                  {tokenAsset?.symbol}{' '}
                  {!!tokenAsset?.symbol && (
                    <Tooltip value="INDICATIVE">
                      <Icon name={IconName.InfoCircleOutlined} />
                    </Tooltip>
                  )}
                </Text>
              </div>
            </div>
            <Text variant={TypographyVariant.h6Title}>
              {carbonProject.name} {carbonProjectVintageYear ? `- Vintage: ${carbonProjectVintageYear}` : ''}
            </Text>
            <div className="flex flex-row flex-wrap w-full gap-xl">
              {carbonProject.__registry__?.registryName && (
                <div className="flex flex-col">
                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                    REGISTRY / PROJECT ID
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {carbonProject.__registry__?.registryName} | ID{' '}
                    <a
                      href={carbonProject?.registryProjectUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleProjectRegistryClick}
                    >
                      {carbonProject?.registryProjectId ?? '-'}
                    </a>
                  </Text>
                </div>
              )}
              {carbonProjectVintageYear && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    VINTAGE
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>{carbonProjectVintageYear}</Text>
                </div>
              )}
              {carbonProject?.__unSectoralScope__ && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    SECTORAL SCOPE
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {carbonProject?.__unSectoralScope__?.unSectoralScopeId}.{' '}
                    {carbonProject?.__unSectoralScope__?.unSectoralScopeName}
                  </Text>
                </div>
              )}
              {carbonProject?.countryCode && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    COUNTRY
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {carbonProject.countryCode
                      .split(',')
                      .map((country: string | number) => projects.countryNames[country])
                      .join(', ')}
                  </Text>
                </div>
              )}
              {carbonProject?.stateOrProvince && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    STATE OR PROVINCE
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>{carbonProject?.stateOrProvince}</Text>
                </div>
              )}

              {!!carbonProject?.technology && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    TECHNOLOGY
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>{carbonProject.technology}</Text>
                </div>
              )}
              {carbonProject?.capacity && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    CAPACITY
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {formatter.formatNumber(carbonProject.capacity, 6)} MW
                  </Text>
                </div>
              )}
              {carbonProject?.volumeMwh && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    VOLUME
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {formatter.formatNumber(carbonProject.volumeMwh, 6)} MWh
                  </Text>
                </div>
              )}
              {!!carbonProject?.primaryFuel && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    PRIMARY FUEL
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>{carbonProject.primaryFuel}</Text>
                </div>
              )}
              {!!carbonProject?.fuelCode && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    FUEL CODE
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>{carbonProject.fuelCode}</Text>
                </div>
              )}
              {carbonProject?.isSupportedByGovSubsidies !== null && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    SUPPORTED BY GOVERNMENT SUBSIDIES
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {!!carbonProject?.isSupportedByGovSubsidies ? 'Supported' : 'Not Supported'}
                  </Text>
                </div>
              )}
              {carbonProject?.worldMapLink && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    Seagrass Carbon Map
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    <Link
                      href={carbonProject.worldMapLink}
                      className="flex flex-row items-center gap-xs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Project Link
                    </Link>
                  </Text>
                </div>
              )}
              {bezeroRating && (
                <ProjectRatingBadge
                  projectRating={bezeroRating}
                  urls={bezeroDisclaimerUrls}
                  ratingType={Dto.ProjectRatingType.BezeroCarbonRating}
                  className="gap-small"
                />
              )}
              {sylveraRating && (
                <ProjectRatingBadge
                  projectRating={sylveraRating}
                  urls={sylveraDisclaimerUrls}
                  ratingType={Dto.ProjectRatingType.SylveraRatingCategory}
                  className="gap-small"
                />
              )}
              {carbonProject.carbonProjectCcbStandards?.length > 0 && (
                <div className="flex flex-col">
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    CCB STANDARDS
                  </Text>
                  <Text variant={TypographyVariant.subtitle2}>
                    {carbonProject.carbonProjectCcbStandards
                      ?.map((standard: { __ccbStandard__: { name: any } }) => standard?.__ccbStandard__?.name)
                      .join(',')}
                  </Text>
                </div>
              )}
            </div>
            {carbonProject.carbonProjectSdgGoals?.length > 0 && (
              <div className="flex flex-col flex-wrap flex-1 w-full gap-small ju">
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  SDG GOALS
                </Text>
                <div className="flex flex-row flex-wrap flex-1 w-full gap-base">
                  {carbonProject.carbonProjectSdgGoals?.map((goal: { carbonSdgGoalId: number }) => (
                    <div
                      key={`sdg-icon-${goal.carbonSdgGoalId}`}
                      style={{
                        width: '48px',
                        height: '48px',
                        backgroundImage: 'url(/sdg_icons/E-WEB-Goal-' + goal.carbonSdgGoalId + '.png)',
                        backgroundSize: 'contain',
                        borderRadius: theme.system.border.radius.s,
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {indicators && (
            <IndicatorsContainer className="flex flex-col w-full h-auto gap-base pt-large">
              <div className="flex flex-row gap-base">
                {indicators.map((label) => {
                  return (
                    <Text marginHorizontal={spacing(2)} marginVertical={spacing(4)}>
                      {label}
                    </Text>
                  );
                })}
              </div>
            </IndicatorsContainer>
          )}
        </DetailedCardDescriptionItem>
        {controls && (
          <DetailedCardDescriptionItem className="flex flex-col flex-1 justify-between h-auto controls p-large gap-base">
            {controls}
          </DetailedCardDescriptionItem>
        )}
      </DetailedCard>
    </MarketPlaceProjectCardRoot>
  );
};

const IndicatorsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
`;

import { useState } from 'react';
import { useQuery } from 'react-query';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { AssetCategory } from '@aircarbon/utils-common';

import type { IncomingTradeRequestsResponse } from 'pages/account/block-trade/types';
import type { User as UserType } from 'pages/account/block-trade/types';
import { usePagination } from 'pages/account/trading/hooks/usePagination';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

export const useIncomingRequest = (params?: { status?: string }) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { product } = useMarketplaceProduct();
  const [selectedBroker, setSelectedBroker] = useState<number | string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(params?.status);

  const { actions, filterBy } = Contract.useContainer();
  const pagination = usePagination();
  const {
    data: tradeRequestsResponse,
    refetch,
    isLoading,
  } = useQuery(
    [
      `/api/user/block-trade/incoming-requests`,
      pagination.page,
      pagination.pageSize,
      filterBy.range?.endDate,
      filterBy.range?.startDate,
      product,
      selectedBroker,
      selectedStatus,
    ],
    async (): Promise<IncomingTradeRequestsResponse> => {
      // TODO: Implement data-provider
      const authToken = await getAuthToken();
      const response = await fetch(
        `/api/user/block-trade/incoming-requests?startDate=${filterBy.range?.startDate ?? ''}&endDate=${
          filterBy.range?.endDate ?? ''
        }&brokerUserId=${selectedBroker && selectedBroker !== 'ALL' ? selectedBroker : ''}&status=${
          selectedStatus && selectedStatus !== 'ALL STATUS' ? selectedStatus : ''
        }&page=${pagination.page}&limit=${pagination.pageSize}&assetCategory=${AssetCategory[Number(product)]}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: `Bearer ${authToken}`,
          },
        },
      );
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    },
  );

  const { data: brokerList } = useQuery(['broker-list'], async (): Promise<UserType[]> => {
    // TODO: Implement data-provider
    const authToken = await getAuthToken();
    return fetch(`/api/user/participant/brokers`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    }).then((resp: Response) => resp.json());
  });

  const items = tradeRequestsResponse?.items;
  const tradeAction = async ({ id, type, isSeller }: { id: number; type: 'accept' | 'reject'; isSeller: boolean }) => {
    return new Promise<void>(async (resolve, error) => {
      const trade = items?.find((item) => item.id === id);
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const response = await fetch(`/api/user/block-trade/request/${id}/${type}`, {
        method: 'PUT',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          type,
          side: isSeller ? 'seller' : 'buyer',
        }),
      });
      if (response.ok) {
        showToast({
          variant: ToastVariant.Success,
          message: `Trade request ${type.toLowerCase()}ed.`,
        });
        if (trade) {
          trade.status = type === 'reject' ? 'REJECTED' : trade.status;
          if (isSeller) {
            trade.sellerConfirmationStatus = type === 'accept' ? 'ACCEPTED' : 'REJECTED';
          } else {
            trade.buyerConfirmationStatus = type === 'accept' ? 'ACCEPTED' : 'REJECTED';
          }
        }
        resolve();
      } else {
        const tradeItem = items?.find((item) => item.id === id);
        if (tradeItem && trade) {
          tradeItem.status = trade.status;
          tradeItem.sellerConfirmationStatus = trade.sellerConfirmationStatus;
          tradeItem.buyerConfirmationStatus = trade.buyerConfirmationStatus;
        }
        const result = await response.json();
        showToast({
          variant: ToastVariant.Danger,
          message: result.message || response.statusText,
        });
        error();
      }

      if (type) refetch();
    });
  };

  return {
    selectedStatus,
    setSelectedStatus,
    selectedBroker,
    setSelectedBroker,
    actions,
    pagination,
    filterBy,
    response: tradeRequestsResponse,
    isLoading,
    refetch,
    brokerList,
    tradeAction,
  };
};

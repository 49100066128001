import type * as React from 'react';
import Popover from 'refreshed-component/atoms/Popover';

import {
  BorderColor,
  Layer,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

import type { FooterMenuItem } from 'data-provider/entity';

const MenuRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  .item {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(6)};
    white-space: pre;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

interface Props {
  items: FooterMenuItem[];
}

const FooterLinks: React.FC<Props> = ({ items }) => {
  return (
    <>
      <Popover
        trigger={'click'}
        placement={'bottomRight'}
        view="transparent"
        content={() => (
          <Layer initialLayer={1}>
            <MenuRoot>
              {items.map((item) => (
                <a
                  key={`a-${item.name}`}
                  href={item.url}
                  target={item.openInNewPage ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                >
                  <Text variant={TypographyVariant.body2} className="item" key={item.name}>
                    {item.name}
                  </Text>
                </a>
              ))}
            </MenuRoot>
          </Layer>
        )}
      >
        {() => {
          return (
            <div className="cursor-pointer item">
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                Information
              </Text>
            </div>
          );
        }}
      </Popover>
    </>
  );
};

export default FooterLinks;

import { BorderColor, TextColor, styled, toBorderColor, toLayerBackground, toTextColor } from '@aircarbon/ui';

export const Announcement = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  width: 100%;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 600;
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
  &.warning {
    background: rgba(255, 0, 0, 0.4);
  }
  &.support-link {
    a {
      color: ${({ theme }) => toTextColor(theme)(TextColor.info)};
    }
  }
`;

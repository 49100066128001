import { type PropsWithChildren, useState } from 'react';

import { Button, ButtonVariant, IconName, ToastVariant, showToast } from '@aircarbon/ui';

import { User } from 'state/user';

interface Props {
  url: string;
  fileName: string;
}

const onDownload = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFile = (url: string, authToken: string, fileName: string) =>
  // TODO: Implement data-mutation
  fetch(url, {
    method: 'POST',
    headers: { authorization: `Bearer ${authToken}` },
  })
    .then((resp) => resp.blob())
    .then((blob) => onDownload(blob, fileName))
    .catch((error) =>
      showToast({
        variant: ToastVariant.Danger,
        message: error.messages,
      }),
    );

const DownloadButton: React.FunctionComponent<PropsWithChildren<Props>> = ({ url, fileName, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  return (
    <Button
      onPress={async () => {
        if (isLoading) return;
        setIsLoading(true);
        const authToken = await getAuthToken();
        await downloadFile(url, authToken, fileName);
        setIsLoading(false);
      }}
      variant={ButtonVariant.outlined}
      endIcon={IconName.Download}
      isLoading={isLoading}
    >
      {children}
    </Button>
  );
};

export default DownloadButton;

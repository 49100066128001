import { useRef } from 'react';

import { Search, Text, TextAlign, TextColor, TypographyVariant, styled, toSpacing } from '@aircarbon/ui';

import { SearchResult } from './components/SearchResult';
import { useSearchProject } from './hooks/useSearchProject';
import { searchProjects } from './utils/searchProjects';
import { UIEvent, UIState } from './utils/toUIState';

interface SearchProps {
  assetCategoryId: string;
  onSelectProject(project: { id: number; label: string }): void;
}

interface SearchResult {
  title: string;
  description: string;
  id: number;
}

export const SearchProject: React.FunctionComponent<SearchProps> = (props) => {
  const { onSelectProject, assetCategoryId } = props;
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const { uiState, searchResults, searchValue, dispatch, setSearchValue } = useSearchProject(
    assetCategoryId,
    searchProjects,
  );

  const onChangeInput = ({ value }: { value: string }) => {
    setSearchValue(value);
    dispatch({
      event: UIEvent.ChangeSearchValue,
      payload: value,
    });
  };

  const onPressProject = (project: { id: number; label: string }) => () => {
    setSearchValue('');
    dispatch({
      event: UIEvent.SelectProject,
    });
    onSelectProject(project);
  };

  const shouldShowDropdown = [UIState.SearchInProgress, UIState.ShowingEmptyResults, UIState.ShowingResults].includes(
    uiState,
  );
  const shouldShowLoader = uiState === UIState.SearchInProgress;
  const shouldShowSearchResults = [UIState.ShowingResults, UIState.IdleAfterShowingResults].includes(uiState);
  const shouldShowNothingFound = [UIState.ShowingEmptyResults, UIState.IdleAfterShowingEmptyResults].includes(uiState);

  return (
    <StyledSearch
      value={searchValue}
      isShowingContent={shouldShowDropdown}
      placeholder="Search Project"
      onChange={onChangeInput}
      content={
        <ResultsContainer ref={resultsContainerRef}>
          {shouldShowSearchResults &&
            searchResults.map((item, index) => (
              <SearchResult
                {...item}
                key={index}
                onPress={onPressProject({
                  id: item.id,
                  label: item.title,
                })}
              />
            ))}
          {shouldShowLoader && [1, 2, 3].map((key) => <SearchResult.Loader key={key} />)}
          {shouldShowNothingFound && (
            <NothingFound>
              <Text variant={TypographyVariant.h6Title} align={TextAlign.center}>
                No projects found
              </Text>
              <Text variant={TypographyVariant.body2} align={TextAlign.center} color={TextColor.secondary}>
                Please adjust your search to see available projects
              </Text>
            </NothingFound>
          )}
        </ResultsContainer>
      }
      onChangeActive={(isActive) => {
        if (!isActive) {
          if (uiState === UIState.Idle) {
            return;
          }

          dispatch({
            event: UIEvent.Blur,
          });
          return;
        }

        dispatch({
          event: UIEvent.Focus,
          payload: searchValue,
        });
      }}
    />
  );
};

const StyledSearch = styled(Search)`
  position: relative;
  width: 275px;
`;

const ResultsContainer = styled.div`
  padding: ${({ theme }) => `${toSpacing(theme)(4)} ${toSpacing(theme)(8)}`};
  max-height: 260px !important;
  overflow-y: auto;
`;

const NothingFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 240px;
`;

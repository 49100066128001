import { Fragment } from 'react';

import { Text, TextColor, TypographyVariant, styled, toLayerBackground, toSpacing } from '@aircarbon/ui';

export const BiddingTable: React.FC<{
  items: Array<{
    id: string;
    token: string;
    bid: string;
    ask: string;
    last: string;
  }>;
  onPressRow(id: string): void;
}> = (props) => {
  return (
    <StyledBiddingTable>
      <RowWrapper>
        <Row variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
          Pair
        </Row>
      </RowWrapper>
      <RowWrapper>
        <Row variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
          Bid
        </Row>
      </RowWrapper>
      <RowWrapper>
        <Row variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
          Ask
        </Row>
      </RowWrapper>
      <RowWrapper>
        <Row variant={TypographyVariant.subtitle2} color={TextColor.secondary}>
          Last
        </Row>
      </RowWrapper>
      {props.items.map((item, index) => (
        <Fragment key={index}>
          <RowWrapper onClick={() => props.onPressRow(item.id)}>
            <Row variant={TypographyVariant.body2}>{item.token}</Row>
          </RowWrapper>
          <RowWrapper onClick={() => props.onPressRow(item.id)}>
            <Row variant={TypographyVariant.body2} color={TextColor.success}>
              {item.bid}
            </Row>
          </RowWrapper>
          <RowWrapper onClick={() => props.onPressRow(item.id)}>
            <Row variant={TypographyVariant.body2} color={TextColor.error}>
              {item.ask}
            </Row>
          </RowWrapper>
          <RowWrapper onClick={() => props.onPressRow(item.id)}>
            <Row variant={TypographyVariant.body2} color={TextColor.secondary}>
              {item.last}
            </Row>
          </RowWrapper>
        </Fragment>
      ))}
    </StyledBiddingTable>
  );
};

const StyledBiddingTable = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => toSpacing(theme)(6)};
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  padding: ${({ theme }) => toSpacing(theme)(12)};
`;

const RowWrapper = styled.div`
  text-align: center;
  &:nth-child(4n + 1),
  &:first-child {
    text-align: start;
  }
  &:nth-child(4n) {
    text-align: end;
  }
`;

const Row = styled(Text)`
  text-transform: uppercase;
  display: block;
  justify-self: stretch;

  &:hover {
    cursor: pointer;
  }
`;

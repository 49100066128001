import { ButtonSize, ButtonVariant, IconName, Knob, styled, toSpacing } from '@aircarbon/ui';

import { clipboardCopy } from 'utils/helpers';

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  padding-right: 25px;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

export interface CopyInputProps {
  text: string;
  children?: React.ReactNode;
}

const CopyInput: React.FC<CopyInputProps> = ({ children, text }) => (
  <Wrapper>
    {children}
    <Knob size={ButtonSize.s} variant={ButtonVariant.ghost} icon={IconName.Copy} onPress={() => clipboardCopy(text)} />
  </Wrapper>
);

export default CopyInput;

import { PeriodInformation } from 'refreshed-pages/project-exchange/components/PeriodInformation';
import { ProjectCardLayout } from 'refreshed-pages/project-exchange/components/ProjectCardLayout';
import { SDGGoals } from 'refreshed-pages/project-exchange/components/SDGGoals';

import {
  Badge,
  BadgeVariant,
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  IconName,
  styled,
  toBorderColor,
  toSpacing,
} from '@aircarbon/ui';

import { MarketsTable } from '../MarketsTable';
import { ProjectCardLoader } from './components/Loader';

type ProjectCardProps = {
  id: number;
  apxId: number;
  title: string;
  subtitle: string;
  description: string;
  vintageYear: string;
  isTrending: boolean;
  markets: Array<{ label: string; value: string }>;
  sdgGoals: Array<{ label: string; value: string }>;
  sectoralScope: string;
  country: string;
  onPressTrade(): void;
  onPressDetails(): void;
};

export const ProjectCard: React.FC<ProjectCardProps> & {
  Loader: typeof ProjectCardLoader;
} = (props) => {
  const { title, subtitle, description, isTrending, country, sectoralScope, sdgGoals, onPressTrade, onPressDetails } =
    props;

  return (
    <ProjectCardLayout
      header={
        isTrending && (
          <BadgeContainer>
            <Badge variant={BadgeVariant.Info1} icon={IconName.Check} value="Trending" />
          </BadgeContainer>
        )
      }
      title={title}
      description={description}
      subtitle={subtitle}
      information={[
        {
          label: 'Country',
          value: country,
        },
        {
          label: 'Sectoral Scope',
          value: sectoralScope,
        },
      ]}
      footer={sdgGoals.length ? <SDGGoals goals={sdgGoals} /> : undefined}
      endSection={
        <>
          <PeriodInformation label={'Vintage Year'} value={props.vintageYear} />
          <MarketsTable markets={props.markets} />
          <ActionsContainer>
            <Button onPress={onPressDetails} size={ButtonSize.l} variant={ButtonVariant.outlined}>
              Details
            </Button>
            <Button size={ButtonSize.l} onPress={onPressTrade}>
              Trade
            </Button>
          </ActionsContainer>
        </>
      }
    />
  );
};

ProjectCard.Loader = ProjectCardLoader;

const BadgeContainer = styled.div`
  display: inline-flex;
`;

const ActionsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

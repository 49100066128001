import QuickLRU from 'quick-lru';
import { useEffect } from 'react';
import { queryCache } from 'react-query';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import emitter from 'utils/emitter';

import { useOrderBook, usePairs } from './hooks';

const lru = new QuickLRU({ maxSize: 100 });

type EVENT_TYPE =
  | 'ORDER_BOOK'
  | 'ORDER_CREATE_REQUEST'
  | 'ORDER_CANCEL_REQUEST'
  | 'ORDER_REJECT_OP'
  | 'ORDER_FILL'
  | 'ORDER_MATCHED'
  | 'ORDER_EXPIRED'
  | 'CONFIRMED_TRADE'
  | 'ORDER_CANCEL'
  | 'ORDER_CANCEL_BY_SYSTEM'
  | 'ORDER_REJECT';

type EFFECT_TYPE = 'balance' | 'orderbook' | 'watchlist' | 'order' | 'trades' | 'userTrades';

const effectMapper = {
  balance: [
    'account-balances',
    'account-balance',
    'oms-user-open-token-balance',
    'oms-user-open-currency-balance',
    'oms-user-open-currency-balances',
    'oms-web3-user-balance',
  ],
  order: ['oms-orders'],
  orderbook: ['oms-orders'],
  trades: ['oms-trades'],
  userTrades: ['oms-user-trades', 'oms-user-trades-request'],
  watchlist: ['oms-pairs'],
};

const refreshAction = (effects: EFFECT_TYPE[]) => {
  effects.forEach((effect) => {
    effectMapper[effect]?.forEach((queryKey) => queryCache.invalidateQueries(queryKey));
  });
};

const toastMessage = (type: EVENT_TYPE, id?: number, message?: string) => {
  switch (type) {
    case 'ORDER_CREATE_REQUEST':
      showToast({
        variant: ToastVariant.Info,
        message: `Order ${id ? `#${id}` : ''} submitted${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_CANCEL_REQUEST':
      showToast({
        variant: ToastVariant.Info,
        message: `Order ${id ? `(order #${id})` : ''} cancellation request submitted${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_REJECT_OP':
      showToast({
        variant: ToastVariant.Danger,
        message: `Order ${id ? `#${id}` : ''} rejected${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_REJECT':
      showToast({
        variant: ToastVariant.Danger,
        message: `Order ${id ? `#${id}` : ''} rejected${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_CANCEL':
      showToast({
        variant: ToastVariant.Success,
        message: `Order ${id ? `#${id}` : ''} cancelled${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_CANCEL_BY_SYSTEM':
      showToast({
        variant: ToastVariant.Danger,
        message: `Order ${id ? `#${id}` : ''} cancelled${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_MATCHED':
      showToast({
        variant: ToastVariant.Success,
        message: `Order ${id ? `#${id}` : ''} matched${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_FILL':
      showToast({
        variant: ToastVariant.Success,
        message: `Order ${id ? `#${id}` : ''} filled${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'ORDER_EXPIRED':
      showToast({
        variant: ToastVariant.Danger,
        message: `Order ${id ? `#${id}` : ''} expired${message ? `: ${message}` : '.'}`,
      });
      break;
    case 'CONFIRMED_TRADE':
      showToast({
        variant: ToastVariant.Success,
        message: `Trade ${id ? `#${id}` : ''} confirmed${message ? `: ${message}` : '.'}`,
      });
      break;
    default:
      logger.warn(`${type} published`);
      break;
  }
};

type Props = {
  orderBookPairId: number | undefined;
  children: React.ReactNode;
};

const OrderNotification = ({ orderBookPairId, children }: Props) => {
  const { refreshOrderBookFromMQ } = useOrderBook(orderBookPairId);
  const { refreshPairsFromMQ } = usePairs({
    assetCategories: ['token', 'rec', 'biofuel', 'fct'],
  });

  useEffect(() => {
    const onListeners = (eventData: {
      eventName: string;
      args: { type: EVENT_TYPE; entity: any; message: string; effects: Array<EFFECT_TYPE> };
    }) => {
      const { eventName, args } = eventData;
      const lruKeyIdentifier = ['ORDER_BOOK', 'WATCHLIST'].includes(args?.type)
        ? args?.entity?.sentAt
        : args?.entity?.id;
      const lruKey = `${args.type}${lruKeyIdentifier ? `_${lruKeyIdentifier}` : ''}`;
      // only show 1 toast message for event
      if (!lru.has(lruKey)) {
        lru.set(lruKey, args.type);
        logger.warn('OrderNotification', eventName, args);
        const { type, entity, message } = args;
        toastMessage(type, entity?.id, message);
      }
      // refesh order book and watchlist if needed
      const { entity, effects } = args;
      refreshAction(effects);
      if (entity?.data) {
        if (effects.includes('orderbook')) refreshOrderBookFromMQ(entity?.data, entity?.id);
        if (effects.includes('watchlist')) refreshPairsFromMQ(entity?.data);
      }
    };

    emitter.on('WS_SOCKET_DATA', onListeners);
    return () => {
      emitter.off('WS_SOCKET_DATA', onListeners);
    };
  }, [refreshOrderBookFromMQ, refreshPairsFromMQ]);

  return <>{children}</>;
};

export default OrderNotification;

import { queryCache, useMutation } from 'react-query';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { helpers } from '@aircarbon/utils-common';
import { logger } from '@aircarbon/utils-common';

import { Account } from 'state/account';
import { UI } from 'state/ui';
import { User } from 'state/user';

import AddUserForm from './CorpAddUserForm';

const { generatePassword } = helpers;

type Props = {
  totalCurrentUsers: {
    admins: number;
    traders: number;
    clients: number;
    monitors: number;
  };
  onSuccess: () => void;
  isMember?: number;
};

const CorpAddUser: React.FC<Props> = ({ totalCurrentUsers, onSuccess, isMember }) => {
  const {
    selector: { getAuthToken, getUserId, getUserSettings },
  } = User.useContainer();
  const { fetchAccountUsers } = Account.useContainer();
  const { getSetting } = UI.useContainer();

  const userId = getUserId();

  // Default maximum of new accounts allowed
  const maxAllowedAdmins = Number(getSetting('web_settings_maxNumAdmins') ?? 3);
  const maxAllowedTraders = Number(getSetting('web_settings_maxNumTraders') ?? 5);
  const maxAllowedMonitors = Number(getSetting('web_settings_maxNumMonitors') ?? 5);

  // Maximum of new accounts allowed for current user (if none, then default)
  const userSettings = getUserSettings();
  const numAllowedAdmins = Number(userSettings?.maxNumCorpAdmins ?? maxAllowedAdmins);
  const numAllowedTraders = Number(userSettings?.maxNumCorpTraders ?? maxAllowedTraders);
  const numAllowedMonitors = Number(userSettings?.maxNumCorpMonitors ?? maxAllowedMonitors);
  const maxAllowedMemberClients = Number(getSetting('web_settings_maxNumMemberClients') ?? 5);

  const remainingAllowedAccounts = {
    admins: numAllowedAdmins - totalCurrentUsers.admins,
    traders: numAllowedTraders - totalCurrentUsers.traders,
    monitors: numAllowedMonitors - totalCurrentUsers.monitors,
    clients: maxAllowedMemberClients - totalCurrentUsers.clients,
  };

  const [addUser, { isLoading }] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(formData.accountType === 'Monitor' ? '/api/account/user/monitor' : '/api/account/user/trader', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ...formData,
          password: generatePassword(),
          parentId: userId,
        }),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          fetchAccountUsers();
          queryCache.invalidateQueries('addressesData');
          showToast({
            variant: ToastVariant.Success,
            message: 'User was added.',
          });
        } else {
          const error = await data.json();
          showToast({
            variant: ToastVariant.Danger,
            message: error?.message ?? 'Something went wrong!',
          });
          logger.error(error);
        }
      },
      onError: (error: Error) => {
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
        logger.error(error);
      },
    },
  );

  const handleSubmit = (
    formData: Record<string, any>,
    handlers: { onSuccess?: (() => void) | undefined } | null | undefined,
  ) => {
    addUser(formData, {
      onSuccess: (data) => {
        if (data.ok && handlers?.onSuccess) {
          handlers?.onSuccess();
          onSuccess();
        }
      },
    });
  };

  return (
    <AddUserForm
      isMember={isMember}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      remainingAllowedAccounts={remainingAllowedAccounts}
    />
  );
};

export default CorpAddUser;

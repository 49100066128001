import { useMemo, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { createContainer } from 'unstated-next';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { AssetCategory, type AssetCategoryCode, UserType, formatter } from '@aircarbon/utils-common';
import type { Asset } from '@aircarbon/utils-common/src/dto';

import { User } from 'state/user';

import useAddressesData from 'hooks/useAddressesData';
import useCurrencies from 'hooks/useCurrencies';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchAccountUsers } from 'data-provider/user/fetchAccountUsers';

import { totalTokenQty } from 'utils/helpers';

import { Entity } from './entity';

const { hex2int } = formatter;

export interface UserResponse {
  first_name: string;
  last_name: string;
  cynopsis_full_name: null | string;
  account: string;
  user_id: number;
  parent_id: number | null;
  user_name: string;
  email: string;
  phone: string;
  cognito_id: string;
  status: string;
  role: string;
  trading_name: string;
  account_type: string;
  is_member?: number;
  is_parent_member?: number;
  kyc_status?: string;
}

export interface UsersResponse {
  users: UserResponse[];
}

export interface AccountUser extends UserResponse {
  fullName: string;
  isRoot: boolean;
  isMember: boolean;
  isMemberClient: boolean;
}

const isMemberClientUserTypes: string[] = [UserType.CORPORATE_CLIENT_DMA, UserType.CORPORATE_CLIENT_READ_ONLY];

const fetchUsers = async () =>
  fetchAccountUsers().then((result: UsersResponse) =>
    result.users.map((user: UserResponse) => {
      return {
        ...user,
        isRoot: user?.parent_id === null,
        isMember: user?.is_member === 1 || user?.is_parent_member === 1,
        isMemberClient: isMemberClientUserTypes.includes(user?.account_type),
        fullName: user.cynopsis_full_name || `${user.first_name} ${user.last_name}`,
      };
    }),
  );

export function useAccount() {
  const {
    user,
    status: {
      canManageUser,
      canRetireToken,
      canDeliverToken,
      canTransferAssets,
      canViewOverView,
      canUserTradeObo,
      canAccessRecs,
    },
    selector: { getUserProfile, getUserId, isGuest },
    helpers: { memberOBOUserTypes, memberClientUserTypes },
  } = User.useContainer();
  const [accountOpenOrders, setAccountOpenOrders] = useState<Array<Record<string, any>>>([]);
  const [refreshing, setRefreshing] = useState(false);
  const isRecsEnabled = canAccessRecs();

  const assetCategories = useMemo(() => {
    if (isRecsEnabled) {
      return [AssetCategory[AssetCategory.token], AssetCategory[AssetCategory.rec]];
    }

    return [AssetCategory[AssetCategory.token]];
  }, [isRecsEnabled]);
  const { tokenTypes } = useTokenTypes({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
  });
  const { currencies, activeCurrenciesScIds } = useCurrencies({});
  const {
    selector: { mainCcySymbol },
  } = Entity.useContainer();

  const userCanManageUser = canManageUser();
  const userCanTransferAssets = canTransferAssets();
  const userCanViewOverView = canViewOverView();
  const canCustodyCarbon = canRetireToken() || canDeliverToken();
  const userCanTradeObo = canUserTradeObo();

  const {
    data: accountUsers = [],
    refetch: fetchAccountUsers,
    isLoading: isLoadingLinkedUsers,
  } = useQuery<Array<AccountUser>>(
    ['fetchUsers', userCanManageUser, canCustodyCarbon, userCanTransferAssets, userCanViewOverView, userCanTradeObo],
    () => {
      if (userCanManageUser || canCustodyCarbon || userCanTransferAssets || userCanViewOverView || userCanTradeObo) {
        return fetchUsers();
      }

      const { cynopsisFullName, ...profile } = getUserProfile();
      return [
        {
          ...profile,
          cynopsis_full_name: cynopsisFullName,
          phone: '',
          status: '',
          role: '',
          trading_name: '',
          kyc_stats: '',
        },
      ];
    },
    { enabled: !!user && !isGuest() },
  );

  const memberOboAccounts = accountUsers?.filter(
    (account) => memberOBOUserTypes.includes(account.account_type) || account.user_id === getUserId(),
  );

  const memberClientAccounts = accountUsers?.filter((account) => memberClientUserTypes.includes(account.account_type));

  const accountAddresses = accountUsers
    ?.filter((user: Record<string, any>) => !!user.account)
    ?.map((user: Record<string, any>) => user.account);

  const totalAccountAddresses = accountAddresses.length;

  const {
    accountUsersData,
    isLoading: accountUsersDataLoading,
    refetch: fetchAccountUsersData,
  } = useAddressesData({
    addresses: accountAddresses,
    options: { enabled: totalAccountAddresses > 0 && tokenTypes.length > 0 },
  });

  let consolidatedBalance: Record<string, any> = {};

  if (accountUsersData().length > 0) {
    consolidatedBalance = accountUsersData()?.reduce((result: Record<string, number>, account: Record<string, any>) => {
      const cloneResult = { ...result };
      const tokens = account?.tokens ?? [];
      const accountCcys = account?.ccys ?? [];

      accountCcys
        .filter((ccy: Record<string, any>) => activeCurrenciesScIds().includes(ccy.ccyTypeId))
        .forEach((ccy: Record<string, any>) => {
          const amount = ccy.balance;
          const currency = currencies.find((curr) => Number(curr.scId) === Number(ccy.ccyTypeId));
          const ccyTypeName = currency?.symbol ?? '';

          if (!cloneResult[ccyTypeName]) cloneResult[ccyTypeName] = 0;
          cloneResult[ccyTypeName] += Number(amount);
        });

      tokenTypes?.forEach((tokenType) => {
        const symbol = tokenType.symbol ?? 'N/A';
        const qty = totalTokenQty(
          tokens.filter((token: Record<string, any>) => hex2int(token.tokTypeId) === Number(tokenType.scId)),
          tokenType.uom?.scRatio,
        );

        if (!cloneResult[symbol]) cloneResult[symbol] = 0;
        cloneResult[symbol] += Number(qty);
      });

      return cloneResult;
    }, {});
  }

  let consolidatedTokens: {
    [assetName: string]: {
      qty: number;
      name: string;
      isCurrency?: boolean;
      asset: Asset;
      users: {
        user?: UserResponse;
        qty: number;
      }[];
    };
  } = {};

  if (accountUsersData().length > 0) {
    consolidatedTokens = accountUsersData()?.reduce(
      (
        result: Record<
          string,
          {
            qty: number;
            name: string;
            isCurrency?: boolean;
            asset: Asset;
            users: {
              user?: UserResponse;
              qty: number;
            }[];
          }
        >,
        account: Record<string, any>,
      ) => {
        const cloneResult = { ...result };
        const tokens = account?.tokens ?? [];
        const accountCcys = account?.ccys ?? [];
        const user = accountUsers?.find((user) => user.account === account.address);

        accountCcys
          .filter((ccy: Record<string, any>) => activeCurrenciesScIds().includes(ccy.ccyTypeId))
          .forEach((ccy: Record<string, any>) => {
            const amount = ccy.balance;
            const currency = currencies.find((curr) => Number(curr.scId) === Number(ccy.ccyTypeId));
            const ccyTypeName = currency?.symbol ?? '';
            if (!cloneResult[ccyTypeName])
              cloneResult[ccyTypeName] = {
                asset: currency as Asset,
                name: ccyTypeName,
                qty: 0,
                isCurrency: true,
                users: [],
              };
            cloneResult[ccyTypeName].qty += Number(amount);
            cloneResult[ccyTypeName].users.push({
              qty: Number(amount),
              user,
            });
          });

        tokenTypes?.forEach((tokenType) => {
          const symbol = tokenType.symbol ?? 'N/A';
          const qty = totalTokenQty(
            tokens.filter((token: Record<string, any>) => hex2int(token.tokTypeId) === Number(tokenType.scId)),
            tokenType?.uom?.scRatio,
          );
          if (!cloneResult[symbol])
            cloneResult[symbol] = {
              asset: tokenType as Asset,
              name: symbol,
              qty: 0,
              users: [],
            };
          cloneResult[symbol].qty += Number(qty);
          cloneResult[symbol].users.push({
            qty: Number(qty),
            user,
          });
        });

        return cloneResult;
      },
      {},
    );
  }

  const accountHoldingTypes = Object.keys(consolidatedBalance ?? {}).filter(
    (key: string) => consolidatedBalance[key] > 0,
  );

  const totalAssetQty = Object.keys(consolidatedBalance ?? {})
    .map((key: string) => (key === mainCcySymbol ? 0 : consolidatedBalance[key]))
    .reduce((previousValue, current) => previousValue + current, 0);

  const refetchAccountData = async () => {
    setRefreshing(true);
    await fetchAccountUsers();
    await fetchAccountUsersData();
    setRefreshing(false);
  };

  const syncRefetchAccountData = async () => {
    fetchAccountUsers();
    fetchAccountUsersData();
  };

  const refreshTxSidebar = () => {
    queryCache.invalidateQueries('account-transactions-pending');
    queryCache.invalidateQueries('account-transactions');
  };

  const onTxRequestSuccess = (toastMessage: string) => {
    showToast({
      variant: ToastVariant.Success,
      message: toastMessage,
    });
    refreshTxSidebar();
  };

  return {
    refreshing,
    isLoading: accountUsersDataLoading || isLoadingLinkedUsers,
    accountUsers: accountUsers ?? [],
    accountUsersData: accountUsersData(),
    accountAddresses: () => accountAddresses,
    consolidatedBalance,
    consolidatedTokens,
    totalAssetQty,
    accountHoldingTypes,
    accountOpenOrders,
    memberOboAccounts,
    memberClientAccounts,
    isLoadingLinkedUsers,
    setAccountOpenOrders,
    fetchAccountUsers,
    fetchAccountUsersData,
    refetchAccountData,
    syncRefetchAccountData,
    refreshTxSidebar,
    onTxRequestSuccess,
  };
}

export const Account = createContainer(useAccount);

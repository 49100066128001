import { Auth, type CognitoUser } from '@aws-amplify/auth';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import QRCode from 'qrcode';
import type * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'refreshed-component/atoms/Input';

import {
  BorderColor,
  Button,
  ButtonVariant,
  Layer,
  Text,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  useTheme,
} from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';

import { Entity } from 'state/entity';
import { User } from 'state/user';

const QRWrapper = styled.div`
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  box-shadow: 8px 13px 15px -9px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => toSpacing(theme)(12)};
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};

  .qr-code {
    width: 80%;
    margin: 0 auto;
  }
`;

interface Props {
  signInUser?: CognitoUser | null;
  onCancel?: () => void;
}
const MFA: React.FC<Props> = ({ signInUser, onCancel }) => {
  const { entity } = Entity.useContainer();
  const { user } = User.useContainer();
  const [qrCode, setQrCode] = useState('');
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<{
    code: string;
  }>();

  const [isSaving, setIsSaving] = useState(false);
  const { theme } = useTheme();

  const onSubmit = async (values: { code: string }) => {
    if (!values.code) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'No TOTP Code provided',
      });
      return;
    }

    setIsSaving(true);

    try {
      if (user) {
        await Auth.verifyTotpToken(user, values.code);
        await Auth.setPreferredMFA(user, 'TOTP');

        const data = await fetch('/api/user/user/verify-user-mfa', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
          },
        });
        const result = await data.json();
        if (!result.ok) {
          showToast({
            variant: ToastVariant.Danger,
            message: result.statusText ?? 'Error, cannot validate otp',
          });
          setIsSaving(false);
          return;
        }

        showToast({
          variant: ToastVariant.Success,
          message: 'MFA setup successfully',
        });
        setQrCode('');
        setIsSaving(false);
      } else if (signInUser) {
        try {
          const userName = (signInUser as any)?.username;
          const session = (signInUser as any)?.Session;
          const data = await fetch('/api/user/user/respond-to-auth-challenge', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              code: values.code,
              session,
              userName,
              challengeName: 'SOFTWARE_TOKEN_MFA',
            }),
          });

          const result = await data.json();
          if (!result?.ok) {
            showToast({
              variant: ToastVariant.Danger,
              message: result?.statusText ?? 'Error, cannot validate otp',
            });
            setIsSaving(false);
            return;
          }
          const userSession = new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: result?.data?.IdToken }),
            RefreshToken: new CognitoRefreshToken({ RefreshToken: result?.data?.RefreshToken }),
            AccessToken: new CognitoAccessToken({ AccessToken: result?.data?.AccessToken }),
          });
          const currentUser = await (Auth as any).createCognitoUser(
            userSession.getIdToken().decodePayload()['cognito:username'],
          );
          await currentUser.setSignInUserSession(userSession);
        } catch (error) {
          logger.error(error);
          showToast({
            variant: ToastVariant.Danger,
            message: (error as Error).message,
          });
        }
        setIsSaving(false);
        // NOTE: This is a hack to force a reload of the page and clear the cache. Also helps to apply new deployments.
        window.location.reload();
      }
    } catch (error) {
      showToast({
        variant: ToastVariant.Danger,
        message: (error as Error).message,
      });
      setIsSaving(false);
    }
  };

  // override qr code from amplify
  useEffect(() => {
    const generateQR = async (secretKey: string) => {
      const issuer = entity.name;
      const code = `otpauth://totp/${issuer}:${user?.username}?secret=${secretKey}&issuer=${issuer}`;
      return QRCode.toDataURL(code, {
        color: {
          dark: theme.global.colors.neutral[900],
          light: theme.global.colors.neutral[0],
        },
      });
    };

    const setMFA = async () => {
      const secretKey = await Auth.setupTOTP(user);
      const code = await generateQR(secretKey);
      setQrCode(code);
    };
    if (user && !signInUser) {
      setMFA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, signInUser]);

  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col flex-1 items-stretch w-full gap-base">
          {!signInUser && qrCode && (
            <Layer>
              <QRWrapper>
                <div className="flex flex-col w-full gap-base">
                  <Text className="flex-1">Scan the QR code with your preferred authenticator app</Text>
                  <Text className="flex-1">e.g. Google Authenticator, Authy, etc.</Text>
                  <img className="qr-code" src={qrCode} alt="qrCode" />
                </div>
              </QRWrapper>
            </Layer>
          )}
          {signInUser ? (
            <div className="flex flex-col w-full gap-base">
              <Text className="flex-1">Enter the code provided by the authenticator</Text>
              <Text className="flex-1">e.g. Google Authenticator, Authy, etc.</Text>
            </div>
          ) : (
            <Text className="flex-1">Enter the code provided by the authenticator</Text>
          )}
          <div className="flex flex-col flex-1 gap-xs">
            <Text variant={TypographyVariant.body2} className="w-full">
              Code
            </Text>
            <Input
              autoFocus
              config={{
                color: 'gray',
              }}
              type="number"
              formRegister={register('code', {
                required: 'Required',
              })}
              maxLength={6}
              minLength={6}
              placeholder="Authenticator code"
            />
            {errors.code?.message && (
              <Text variant={TypographyVariant.body2} color={TextColor.error}>
                {errors.code.message}
              </Text>
            )}
          </div>
          <Button isLoading={isSaving}>Verify</Button>
          {signInUser && onCancel && (
            <Button variant={ButtonVariant.outlined} className="cancel-button" onPress={() => onCancel()}>
              Cancel
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

export default MFA;

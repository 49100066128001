import type React from 'react';
import { useEffect, useState } from 'react';

import { Text, TextColor, TypographyVariant, styled, toLayerBackground, toSpacing } from '@aircarbon/ui';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  border-radius: ${({ theme }) => theme.system.border.radius.s};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  justify-content: center;

  > .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
type Props = {
  date: Date;
  endLabel?: string;
  onZero?: () => void;
};

const calculateTimeLeft = (endDate: Date, endLable = 'Ended') => {
  const end = new Date(endDate);
  const start = new Date();
  const diffTime = end.getTime() - start.getTime();

  const days = Math.floor(diffTime / (1000 * 3600 * 24));
  const hours = Math.floor(diffTime / (1000 * 3600)) - days * 24;
  const minutes = Math.floor((diffTime / (1000 * 60)) % 60);
  const seconds = Math.floor((diffTime / 1000) % 60);
  if (diffTime <= 0) {
    return {
      isEnded: true,
      view: (
        <Text variant={TypographyVariant.subtitle2} color={TextColor.error}>
          {endLable}
        </Text>
      ),
    };
  }
  return {
    isEnded: false,
    view: (
      <>
        <div className="item">
          <Text variant={TypographyVariant.h6Title}>{days}</Text>
          <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
            Days
          </Text>
        </div>
        <div className="item">
          <Text variant={TypographyVariant.h6Title}>{hours}</Text>
          <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
            Hours
          </Text>
        </div>
        <div className="item">
          <Text variant={TypographyVariant.h6Title}>{minutes}</Text>
          <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
            Minutes
          </Text>
        </div>
        <div className="item">
          <Text variant={TypographyVariant.h6Title}>{seconds}</Text>
          <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
            Seconds
          </Text>
        </div>
      </>
    ),
  };
};

const Countdown: React.FC<Props> = ({ date, endLabel: endLabelProp, onZero }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date, endLabelProp));
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (timeLeft && !timeLeft.isEnded) {
      timer = setTimeout(() => {
        const newTimeLeft = calculateTimeLeft(date, endLabelProp);
        if (newTimeLeft.isEnded) {
          onZero?.();
          clearTimeout(timer);
        }
        setTimeLeft(newTimeLeft);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return <Wrapper>{timeLeft.view}</Wrapper>;
};

export default Countdown;

import { addMinutes, format, isBefore } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import { Empty } from 'refreshed-component/atoms/Empty';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Input } from 'refreshed-component/atoms/Input';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import {
  type FilterCheckBox,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import Modal, { ModalContent, ModalFooter, type ModelChildrenParam } from 'refreshed-component/molecules/Modal';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';

import {
  Badge,
  BadgeVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  CardVariant,
  Icon,
  IconName,
  Knob,
  LabelWithDescription,
  LabelWithDescriptionSize,
  Layer,
  Pagination,
  Text,
  TextAlign,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import type { TradeRequest } from 'pages/account/block-trade/types';
import type { User as UserType } from 'pages/account/block-trade/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';

import { BlockRequestCard } from '../containers/BlockRequestCard';
import { Summery } from './containers/Summery';
import { useIncomingRequest } from './hooks/incomingTradeRequest';

const ConfirmButton = ({
  item,
  text,
  onOpen,
  onClose,
  acceptCallback,
  cancelCallback,
}: {
  item: TradeRequest;
  text: string;
  onOpen?: () => void;
  onClose?: () => void;
  acceptCallback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
  cancelCallback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
}) => (
  <ConfirmModal
    title={`Accept Request #${item.id}`}
    onClose={onClose}
    accept={{
      label: 'Confirm',
      callback: acceptCallback,
    }}
    cancel={{
      label: 'Cancel',
      callback: cancelCallback,
    }}
    action={
      <Button
        variant={ButtonVariant.secondary}
        className="flex-1"
        onPress={() => onOpen?.()}
        endIcon={IconName.ArrowEnd}
      >
        Accept
      </Button>
    }
  >
    <div className="flex flex-col items-center gap-base">
      <Text align={TextAlign.center} color={TextColor.secondary} variant={TypographyVariant.subtitle1}>
        {text}
      </Text>
      <Text align={TextAlign.center} variant={TypographyVariant.subtitle1}>
        Do you want to proceed?
      </Text>
    </div>
  </ConfirmModal>
);

const RejectButton = ({
  item,
  onOpen,
  onClose,
  acceptCallback,
  cancelCallback,
}: {
  item: TradeRequest;
  onOpen?: () => void;
  onClose?: () => void;
  acceptCallback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
  cancelCallback?: (param: { onClose: () => void; onLoading: (loading?: boolean | undefined) => void }) => void | false;
}) => (
  <ConfirmModal
    title={`Reject Trade Request #${item.id}`}
    onClose={onClose}
    accept={{
      label: 'Confirm',
      callback: acceptCallback,
    }}
    cancel={{
      label: 'Cancel',
      callback: cancelCallback,
    }}
    action={
      <Button variant={ButtonVariant.outlined} className="flex-1" onPress={() => onOpen?.()} endIcon={IconName.Ban}>
        Reject
      </Button>
    }
  >
    <div className="flex flex-col items-center gap-base">
      <Text align={TextAlign.center} variant={TypographyVariant.subtitle1}>
        Do you want to proceed?
      </Text>
    </div>
  </ConfirmModal>
);

function ParticipantBrokers(props: ModelChildrenParam) {
  const {
    selector: { getAuthToken, getUserId, getUserRootId },
    status: { canAddAccountBrokers, canRemoveAccountBrokers },
  } = User.useContainer();

  const [search, setSearch] = useState<string | undefined>();
  const [fetchSearchCache, setFetchSearchCache] = useState<string | undefined>(undefined);
  const [isFetchingUser, setFetchingUser] = useState(false);

  const {
    data: myBrokers,
    isLoading: isLoadingMyBrokers,
    refetch,
  } = useQuery(
    [`/api/user/participant/brokers`],
    async (): Promise<UserType[]> => {
      // TODO: Implement data-provider
      const authToken = await getAuthToken();
      return fetch(`/api/user/participant/brokers`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      }).then((resp: Response) => resp.json());
    },
    {},
  );

  const { data: allBrokers, isLoading: isLoadingAllBrokers } = useQuery(
    [`/api/user/brokers`],
    async (): Promise<UserType[]> => {
      // TODO: Implement data-provider
      const authToken = await getAuthToken();
      return fetch(`/api/user/brokers`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      }).then((resp: Response) => resp.json());
    },
    {},
  );

  const addBroker = async (user: UserType) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/participant/broker`, {
      method: 'POST',
      body: JSON.stringify({
        brokerUserId: user?.id,
        clientUserId: getUserRootId(),
        approvedBy: getUserId(),
      }),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      queryCache.invalidateQueries(['broker-list']);
      refetch();
      showToast({
        variant: ToastVariant.Success,
        message: `${user?.firstName} ${user?.lastName} successfully added as broker.`,
      });
      return;
    }
    const result = await response.json();
    const error = new Error(result.message || response.statusText);
    showToast({
      variant: ToastVariant.Danger,
      message: (error as Error).message,
    });
  };

  const removeBroker = async (user: UserType) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/participant/broker/${user?.id}`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      queryCache.invalidateQueries(['broker-list']);
      refetch();
      showToast({
        variant: ToastVariant.Success,
        message: `${user?.firstName} ${user?.lastName} successfully removed as broker.`,
      });
      return;
    }
    const result = await response.json();
    const error = new Error(result.message || response.statusText);
    showToast({
      variant: ToastVariant.Danger,
      message: (error as Error).message,
    });
  };

  const fetchBrokerUser = useCallback(async () => {
    const finalSearchValue = search?.trim();
    if (finalSearchValue && finalSearchValue !== fetchSearchCache) {
      setFetchSearchCache(finalSearchValue);
      setFetchingUser(true);
      // TODO: Implement data-provider
      const authToken = await getAuthToken();
      const data = await fetch(`/api/user/brokers?email=${encodeURIComponent(search as string)}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      });
      setFetchingUser(false);
    }
  }, [fetchSearchCache, search]);

  useEffect(() => {
    if (!isFetchingUser) {
      fetchBrokerUser();
    }
  }, [fetchBrokerUser, isFetchingUser]);

  useEffect(() => {
    if (search?.trim() && !isFetchingUser) fetchBrokerUser();
  }, [fetchBrokerUser, isFetchingUser, search]);

  const filteredBrokers = allBrokers?.filter(
    (broker) =>
      !myBrokers?.find((myBroker) => myBroker.id === broker.id) &&
      (search
        ? `${broker.firstName} ${broker.lastName}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        : true),
  );
  const filteredMyBrokers = myBrokers?.filter((user) =>
    search ? `${user.firstName} ${user.lastName}`.includes(search) : true,
  );

  const RemoveBroker = (user: UserType) => {
    return (
      <ConfirmModal
        title={'Are you sure?'}
        accept={{
          label: 'Yes',
          callback(param) {
            param.onLoading(true);
            removeBroker(user)
              .then(() => {
                param.onLoading(false);
                param.onClose();
              })
              .catch(() => {
                param.onLoading(false);
              });
            return false;
          },
        }}
        cancel={{
          label: 'No',
        }}
        action={<Knob icon={IconName.MinusCircle} variant={ButtonVariant.ghost} size={ButtonSize.xs} />}
      >
        <>
          <Text align={TextAlign.center}>
            Do you want to remove{' '}
            <Text variant={TypographyVariant.subtitle1}>
              {user.firstName} {user.lastName}
            </Text>{' '}
            as a broker.
          </Text>
        </>
      </ConfirmModal>
    );
  };

  const AddBroker = (user: UserType) => {
    return (
      <ConfirmModal
        title={'Are you sure?'}
        accept={{
          label: 'Yes',
          callback(param) {
            param.onLoading(true);
            addBroker(user)
              .then(() => {
                param.onLoading(false);
                param.onClose();
              })
              .catch(() => {
                param.onLoading(false);
              });
            return false;
          },
        }}
        cancel={{
          label: 'No',
        }}
        action={<Knob variant={ButtonVariant.ghost} size={ButtonSize.xs} icon={IconName.PlusCircle} />}
      >
        <>
          <Text align={TextAlign.center}>
            Do you want to add{' '}
            <Text variant={TypographyVariant.subtitle1}>
              {user.firstName} {user.lastName}
            </Text>{' '}
            as a broker.
          </Text>
        </>
      </ConfirmModal>
    );
  };

  if (!allBrokers || allBrokers?.length === 0) {
    return <Empty title="No public brokers" description="There are no public brokers available right now." />;
  }

  return (
    <div className="flex flex-col gap-large">
      <Input
        placeholder="Search"
        config={{
          size: 'base',
          color: 'gray',
          postfix: (
            <HitArea
              width={20}
              height={20}
              className="cursor-pointer"
              onClick={() => {
                setSearch('');
              }}
            >
              <Icon name={IconName.X} size="0.625rem" />
            </HitArea>
          ),
          prefix: <Icon name={IconName.Search} size="0.875rem" />,
        }}
        value={search}
        onChange={(event) => {
          setSearch(event.target.value.trim() || '');
        }}
      />
      {!isLoadingMyBrokers && filteredMyBrokers && filteredMyBrokers.length > 0 && (
        <>
          <Text variant={TypographyVariant.subtitle1}>My Brokers</Text>
          <div className="flex flex-col gap-small">
            {filteredMyBrokers?.map((user) => {
              return (
                <StyledCard
                  variant={CardVariant.Bordered}
                  className="flex-row items-center justify-between px-base py-medium"
                >
                  <Text>
                    {user.firstName} {user.lastName}
                  </Text>
                  {canRemoveAccountBrokers() ? <RemoveBroker {...user} /> : <div />}
                </StyledCard>
              );
            })}

            {isLoadingMyBrokers && <Loading isOverLay={false} />}
          </div>
        </>
      )}
      <Text variant={TypographyVariant.subtitle1}>Available Brokers</Text>
      {filteredBrokers && filteredBrokers.length > 0 ? (
        <div className="flex flex-col gap-small">
          {filteredBrokers?.map((user) => {
            return (
              <StyledCard
                variant={CardVariant.Bordered}
                className="flex-row items-center justify-between px-base py-medium"
              >
                <Text>
                  {user.firstName} {user.lastName}
                </Text>
                {canAddAccountBrokers() ? <AddBroker {...user} /> : <div />}
              </StyledCard>
            );
          })}
          {isLoadingAllBrokers && <Loading isOverLay={false} />}
        </div>
      ) : (
        <Text align={TextAlign.center}>No results found</Text>
      )}
    </div>
  );
}

const MyBroker = () => (
  <Modal title={`Manage Brokers`} action={<Button endIcon={IconName.Suitcase}>My Brokers</Button>}>
    {(param) => {
      return (
        <>
          <ModalContent
            style={{
              maxHeight: '600px',
            }}
          >
            <ParticipantBrokers {...param} />
          </ModalContent>
        </>
      );
    }}
  </Modal>
);

export const IncomingRequests = () => {
  const {
    selector: { getUserId },
    status: { canAcceptBlockRequest, canRejectBlockRequest, canAddAccountBrokers, canRemoveAccountBrokers },
  } = User.useContainer();
  const {
    selector: { isMarketOpen },
  } = useMarketSettings({});
  const { memberOboAccounts } = Account.useContainer();

  const { carbonMetaOptions } = useCarbonMetaOptions({});
  const registries = carbonMetaOptions?.registries ?? [];

  const tradeRequest = useIncomingRequest();
  const { getSetting } = UI.useContainer();

  const brokerFilterItems = tradeRequest.brokerList?.length
    ? tradeRequest.brokerList.map((broker) => ({
        id: broker.id,
        label: `${broker.firstName} ${broker.lastName}`,
      }))
    : [];

  const filters: {
    status: FilterCheckBox;
    account?: FilterRadioBox;
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 'ALL STATUS',
          label: 'ALL STATUS',
        },
        {
          id: 'APPROVED',
          label: 'APPROVED',
        },
        {
          id: 'NEW',
          label: 'NEW',
        },
        {
          id: 'PROCESSING',
          label: 'PROCESSING',
        },
        {
          id: 'REJECTED',
          label: 'REJECTED',
        },
        {
          id: 'EXECUTED',
          label: 'EXECUTED',
        },
        {
          id: 'CANCELLED',
          label: 'CANCELLED',
        },
      ],
    },
    ...(brokerFilterItems.length && {
      account: {
        type: 'radio-box',
        label: 'Accounts',
        list: brokerFilterItems,
      },
    }),
  };

  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();
  const acceptTimeLimitMinutes = Number(getSetting('blockTrade_acceptTimeLimitMinutes'));
  const executeTimeLimitDays = Number(getSetting('blockTrade_executeTimeLimitDays'));

  const {
    selector: { mainCcyNumDecimals, entityName },
  } = Entity.useContainer();

  useEffect(() => {
    tradeRequest.setSelectedStatus(filterSelections?.status?.selection?.[0].toString());
    tradeRequest.setSelectedBroker(filterSelections?.account?.selection?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelections]);

  const manageUserIds = [getUserId(), ...memberOboAccounts.map((obo) => obo.user_id)];
  const canViewMyBroker = canAddAccountBrokers() || canRemoveAccountBrokers();
  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="Incoming Requests"
          controls={{
            secondary: canViewMyBroker && <MyBroker />,
          }}
        />
      </PageSections>
      <PageSections>
        <Summery />
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <div className="flex flex-row justify-between items-center">
            <Text variant={TypographyVariant.h5Title}>Requests To Trade</Text>
            <div className="flex flex-row gap-4 justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          {!tradeRequest.isLoading && !!tradeRequest.response?.items.length ? (
            <Layer>
              {tradeRequest.response?.items.map((item) => {
                const isSeller = manageUserIds.includes(item?.sellerUserId);
                const confirmationStatus = isSeller ? item.sellerConfirmationStatus : item.buyerConfirmationStatus;
                const requestUser = isSeller ? item.sellerUser : item.buyerUser;
                const project = item.tradeRequestDetail?.carbonProject;
                const total = item.quantity * item.price;
                const exchangeFee = (isSeller ? item?.sellerTradeFee : item?.buyerTradeFee) ?? 0;
                const arrangerFee = isSeller ? item.sellerArrangerFeeAmount : item.buyerArrangerFeeAmount;
                const totalWithFees = isSeller ? total - exchangeFee - arrangerFee : total + exchangeFee + arrangerFee;
                const createdAtUtc = new Date(item.createdAtUtc);
                const now = new Date();
                const acceptanceExpiresOn = addMinutes(createdAtUtc, acceptTimeLimitMinutes);
                const isClosed = ['EXECUTED', 'CANCELLED', 'REJECTED'].includes(item.status);
                const canStillAccept = isBefore(now, acceptanceExpiresOn);

                const confirmationText = isSeller
                  ? `Once you confirm the trade, you are required to transfer the credits to ${entityName} Trustee carbon registry account within ${executeTimeLimitDays} business days.`
                  : `Once you confirm the trade, you are required to fund your ${entityName} account with funds within ${executeTimeLimitDays} business days.`;

                let confirmationStatusBadge;
                if (confirmationStatus === 'REJECTED') {
                  confirmationStatusBadge = <StyledBadge variant={BadgeVariant.Danger} value={confirmationStatus} />;
                } else if (confirmationStatus === 'ACCEPTED') {
                  confirmationStatusBadge = <StyledBadge variant={BadgeVariant.Success} value={confirmationStatus} />;
                } else {
                  confirmationStatusBadge = <StyledBadge variant={BadgeVariant.Alert} value={confirmationStatus} />;
                }

                const projectRegistry = registries?.find((registry) => registry.id === project?.registryId);

                return (
                  <BlockRequestCard
                    title={
                      project
                        ? `${projectRegistry?.registryName ?? ''} | ID ${project?.registryProjectId} | Vintage:${' '} ${item.tradeRequestDetail?.carbonProjectVintageYear}`
                        : undefined
                    }
                    description={project?.name || `#${item.id}`}
                    info={{
                      onClick: undefined,
                      fields: [
                        {
                          name: 'Request ID',
                          value: `#${item.id}`,
                        },
                        {
                          name: 'Account Name',
                          value: `${requestUser?.firstName} ${requestUser?.lastName}`,
                        },
                        {
                          name: 'Status',
                          value: <Badge value={item.status} />,
                        },
                        {
                          name: 'Asset',
                          value: `${item.baseAsset?.symbol}/${item.quoteAsset?.symbol}`,
                        },
                        {
                          name: 'Side',
                          value: isSeller ? 'SELL' : 'BUY',
                        },
                        {
                          name: `Qty (${item.baseAsset?.uom?.code || 'tCO2'})`,
                          value: formatter.formatNumber(item.quantity, 0),
                        },
                        {
                          name: 'Price',
                          value: formatter.formatNumber(item.price, mainCcyNumDecimals),
                        },
                        {
                          name: 'Amount',
                          value: formatter.formatNumber(total, mainCcyNumDecimals),
                        },
                        {
                          name: 'Exchange Fee',
                          value: formatter.formatNumber(exchangeFee, mainCcyNumDecimals),
                        },
                        {
                          name: 'Arranger Fee',
                          value: formatter.formatNumber(arrangerFee, mainCcyNumDecimals),
                        },
                        {
                          name: 'Receive/Pay',
                          value: formatter.formatNumber(totalWithFees, mainCcyNumDecimals),
                        },
                      ],
                    }}
                    control={{
                      fields: [
                        {
                          name: 'Created At',
                          value: format(new Date(item.createdAtUtc), 'MMM d yyyy h:mm:ss a'),
                        },
                      ],
                      actions: (
                        <>
                          {!isClosed && canStillAccept && confirmationStatus === 'PENDING' ? (
                            !isMarketOpen() ? (
                              <Badge variant={BadgeVariant.Danger} value={'Market is closed'} />
                            ) : (
                              (canAcceptBlockRequest() || canRejectBlockRequest()) && (
                                <Modal
                                  title={`Incoming Trade Request #${item.id}`}
                                  action={
                                    <Button variant={ButtonVariant.secondary} endIcon={IconName.ArrowEnd}>
                                      Respond
                                    </Button>
                                  }
                                >
                                  {(parentModelParam) => {
                                    return (
                                      <>
                                        <ModalContent>
                                          <div className="flex flex-col gap-base">
                                            <div className="flex flex-row gap-base">
                                              <Card variant={CardVariant.Bordered} className="flex-1 p-base">
                                                <LabelWithDescription
                                                  size={LabelWithDescriptionSize.l}
                                                  label={item.baseAsset?.symbol}
                                                  description={formatter.formatNumber(item.quantity, 0)}
                                                />
                                              </Card>
                                              <Card variant={CardVariant.Bordered} className="flex-1 p-base">
                                                <LabelWithDescription
                                                  size={LabelWithDescriptionSize.l}
                                                  label={item.quoteAsset?.symbol}
                                                  description={formatter.formatNumber(total, mainCcyNumDecimals)}
                                                />
                                              </Card>
                                            </div>
                                            <Layer>
                                              <StyledMetaCard>
                                                <div className="flex flex-col gap-2xs">
                                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                                    Asset
                                                  </Text>
                                                  <Text variant={TypographyVariant.subtitle1}>
                                                    {`${item.baseAsset?.symbol}/${item.quoteAsset?.symbol}`}
                                                  </Text>
                                                </div>
                                                <div className="flex flex-col gap-2xs">
                                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                                    Side
                                                  </Text>
                                                  <Text variant={TypographyVariant.subtitle1}>
                                                    {isSeller ? 'SELL' : 'BUY'}
                                                  </Text>
                                                </div>
                                                <div className="flex flex-col gap-2xs">
                                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                                    Qty ({item.baseAsset?.uom?.name || 'tCO2'})
                                                  </Text>
                                                  <Text variant={TypographyVariant.subtitle1}>
                                                    {formatter.formatNumber(item.quantity, 0)}
                                                  </Text>
                                                </div>
                                                <div className="flex flex-col gap-2xs">
                                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                                    Price
                                                  </Text>
                                                  <Text variant={TypographyVariant.subtitle1}>
                                                    {formatter.formatNumber(item.price, mainCcyNumDecimals)}
                                                  </Text>
                                                </div>
                                                <div className="flex flex-col gap-2xs">
                                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                                    Amount
                                                  </Text>
                                                  <Text variant={TypographyVariant.subtitle1}>
                                                    {formatter.formatNumber(total, mainCcyNumDecimals)}
                                                  </Text>
                                                </div>
                                              </StyledMetaCard>
                                            </Layer>
                                          </div>
                                        </ModalContent>
                                        <ModalFooter>
                                          <div className="flex flex-row justify-items-stretch w-full gap-base">
                                            {canRejectBlockRequest() && (
                                              <RejectButton
                                                item={item}
                                                acceptCallback={(param) => {
                                                  param.onLoading(true);
                                                  tradeRequest
                                                    .tradeAction({
                                                      id: item.id,
                                                      type: 'reject',
                                                      isSeller,
                                                    })
                                                    .then(() => {
                                                      param.onLoading(false);
                                                      parentModelParam.onClose();
                                                    })
                                                    .catch(() => {
                                                      param.onLoading(false);
                                                    });
                                                  return false;
                                                }}
                                              />
                                            )}
                                            {canAcceptBlockRequest() && (
                                              <ConfirmButton
                                                item={item}
                                                text={executeTimeLimitDays ? confirmationText : ''}
                                                acceptCallback={(param) => {
                                                  param.onLoading(true);
                                                  tradeRequest
                                                    .tradeAction({
                                                      id: item.id,
                                                      type: 'accept',
                                                      isSeller,
                                                    })
                                                    .then(() => {
                                                      param.onLoading(false);
                                                      parentModelParam.onClose();
                                                    })
                                                    .catch(() => {
                                                      param.onLoading(false);
                                                    });
                                                  return false;
                                                }}
                                              />
                                            )}
                                          </div>
                                        </ModalFooter>
                                      </>
                                    );
                                  }}
                                </Modal>
                              )
                            )
                          ) : (
                            confirmationStatusBadge
                          )}
                        </>
                      ),
                    }}
                  />
                );
              })}
            </Layer>
          ) : tradeRequest.isLoading ? (
            <Loading isOverLay={false} />
          ) : (
            <Empty title="No incoming requests" description="You have no incoming requests to trade yet." />
          )}
          {!!tradeRequest.response?.count && (
            <div>
              <Pagination
                currentPage={tradeRequest.pagination.page}
                pagesCount={Math.ceil(tradeRequest.response?.count / tradeRequest.pagination.pageSize)}
                onChange={(currentPage) => {
                  tradeRequest.pagination.setPage(currentPage);
                }}
              />
            </div>
          )}
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};

const StyledBadge = styled(Badge)`
  align-self: flex-start;
`;

const StyledCard = styled(Card)`
  flex-direction: row;
`;

const StyledMetaCard = styled(Card)`
  flex-direction: row;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  padding: ${({ theme }) => `${toSpacing(theme)(6)} ${toSpacing(theme)(8)}`};
  justify-content: space-around;
`;

import { add } from 'date-fns';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import type { CheckBoxProps } from 'refreshed-component/atoms/CheckBox';
import { Empty } from 'refreshed-component/atoms/Empty';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Input } from 'refreshed-component/atoms/Input';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import { FilterDropdown, FilterSelections } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import {
  Button,
  ButtonVariant,
  Icon,
  IconName,
  Layer,
  Pagination,
  Text,
  TextAlign,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import { formatter, logger } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';
import { usePagination } from 'pages/account/trading/hooks/usePagination';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { fetchMyCMBBid } from 'data-provider/market-board';

const statusLabel: Record<string, any> = {
  CANCELLED: { status: 'Canceled', message: 'Canceled by user' },
  CANCELLED_BY_OPS: { status: 'Canceled', message: 'Canceled By Operations' },
  FORFEIT: { status: 'Forfeited', message: '' },
  DONE: { status: 'Filled', message: 'Trade completed' },
  CONFIRMED_BY_SELLER: { status: 'In Progress', message: 'Please ensure you have enough funds to cover the trade' },
  REJECTED_BY_SELLER: { status: 'Rejected', message: 'Bid was rejected by seller.' },
  CREDITS_ACQUIRED: { status: 'Pending Payment', message: 'Please ensure you have enough funds to cover the trade' },
  NEW: { status: 'New', message: 'Contacting seller..' },
};

export const MyBids = () => {
  const {
    selector: { getAuthToken },
    status: { canBidCmbAsk },
  } = User.useContainer();
  const [search, setSearch] = useState('');
  const { getSetting } = UI.useContainer();
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const { product } = useMarketplaceProduct();
  const pagination = usePagination();
  const page = pagination.page;
  const limit = pagination.pageSize;

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const expiryDays = getSetting('global_emb_expireDays');
  const closingTime = getSetting('global_emb_closingTime');

  const bidsUrl = `/api/user/carbon/my-cmb-bid?assetCategoryId=${product}&page=${page}&limit=${limit}`;

  const { data, isLoading, refetch } = useQuery([bidsUrl, page, limit], () =>
    fetchMyCMBBid({ page, limit, assetCategoryId: product }),
  );
  const filters: {
    status: {
      type: 'check-box';
      label: string;
      list: CheckBoxProps[];
    };
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 1,
          label: 'New',
        },
        {
          id: 2,
          label: 'Accepted',
        },
        {
          id: 3,
          label: 'Rejected',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();
  const filteredBid = data?.items?.filter?.(
    (item: { status: string; __cmbAsk__: { __carbonProject__: { name: string | undefined } } }) => {
      const statusSelection = filterSelections?.status?.selection;
      if (!statusSelection?.length && !search) return true;
      if (statusSelection?.includes(1) && item.status === 'NEW') {
        return true;
      }
      if (
        statusSelection?.includes(2) &&
        ['CONFIRMED_BY_SELLER', 'CREDITS_ACQUIRED', 'DONE', 'PROCESS_FEE', 'PROCESS_TRADE'].includes(item.status)
      ) {
        return true;
      }
      if (
        statusSelection?.includes(3) &&
        ['REJECTED_BY_SELLER', 'CANCELLED', 'CANCELLED_BY_OPS', 'FORFEIT', 'REJECTED_BY_SELLER'].includes(item.status)
      ) {
        return true;
      }
      const name = item?.__cmbAsk__?.__carbonProject__?.name as string | undefined;
      if (search && name?.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())) {
        return true;
      }
      return false;
    },
  );

  const onCancel = async (bidId: number) => {
    showToast({
      variant: ToastVariant.Info,
      message: 'Cancelling bid..',
    });
    const authToken = await getAuthToken();
    const cancel = await fetch(`/api/user/carbon/cmb-bid/${bidId}/cancel`, {
      // TODO: Implement data-mutation
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });

    if (cancel.ok) {
      showToast({
        variant: ToastVariant.Success,
        message: `Bid #${bidId} was cancelled.`,
      });
      refetch();
    } else {
      const error = await cancel?.json();
      showToast({
        variant: ToastVariant.Danger,
        message: error?.message ?? 'Something went wrong!',
      });
      logger.warn({ error });
    }
  };

  const onCancelRequest = async (bidId: number) => {
    showToast({
      variant: ToastVariant.Info,
      message: 'Sending cancellation request..',
    });
    const authToken = await getAuthToken();
    const request = await fetch(`/api/user/carbon/cmb-bid/${bidId}/request-cancel`, {
      // TODO: Implement data-mutation
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });

    if (request.ok) {
      showToast({
        variant: ToastVariant.Success,
        message: 'Your bid cancellation request was sent.',
      });
    } else {
      const error = await request?.json();

      showToast({
        variant: ToastVariant.Danger,
        message: error?.message ?? 'Something went wrong!',
      });
      logger.warn({ error });
    }
    refetch();
  };

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <PageHolder>
      <PageSections>
        <PageControls title="My Bids" />
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <PageControls
            controls={{
              primary: (
                <>
                  <Input
                    placeholder="Search"
                    config={{
                      size: 'base',
                      color: 'gray',
                      postfix: (
                        <HitArea
                          width={20}
                          height={20}
                          className="cursor-pointer"
                          onClick={() => {
                            setSearch('');
                          }}
                        >
                          <Icon name={IconName.X} size="0.625rem" />
                        </HitArea>
                      ),
                      prefix: <Icon name={IconName.Search} size="0.875rem" />,
                    }}
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value || '');
                    }}
                  />
                  <FilterDropdown
                    selections={filterSelections}
                    onChange={(value) => setFilterSelections(value)}
                    list={filters}
                  />
                </>
              ),
              secondary: (data?.total ?? 0) > 0 && (
                <Pagination
                  currentPage={pagination.page}
                  pagesCount={Math.ceil(data.total / pagination.pageSize)}
                  onChange={(currentPage) => {
                    pagination.setPage(currentPage);
                  }}
                />
              ),
            }}
          />
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          <div className="flex flex-col w-full gap-large">
            {isLoading && <Loading isOverLay={false} />}
            <Layer>
              {filteredBid &&
                (Array.isArray(filteredBid) ? filteredBid : []).map((item) => {
                  const createDate = new Date(item.createdUtc ?? '');
                  const expireDate = add(new Date(item.confirmedUtc ?? ''), {
                    days: Number(expiryDays) ?? 5,
                  });
                  const status = statusLabel[item?.status]?.status ?? item?.status;
                  let statusMessage = statusLabel[item?.status]?.message ?? item?.status;
                  if (item.status === 'REJECTED_BY_SELLER') statusMessage = item.statusMessage ?? statusMessage;
                  if (['CONFIRMED_BY_SELLER', 'CREDITS_ACQUIRED'].includes(item.status))
                    statusMessage = (
                      <div>
                        {statusMessage} by{' '}
                        <strong>
                          {closingTime ? `${closingTime} on` : ''} {expireDate.toDateString()}
                        </strong>
                      </div>
                    );

                  const depositTxId = item.depositTxId || item.depositAcceptedTxId;
                  const amount = Number(item.depositAmount ?? 0) + Number(item.depositAcceptedAmount ?? 0);

                  let dateLabel = 'Created on';
                  let date = createDate;
                  if (item.confirmedUtc) {
                    dateLabel = 'Accepted on';
                    date = new Date(item.confirmedUtc);
                  }

                  const depositTransaction = item.__depositTransaction__;
                  const refundTransaction = item.__refundTransaction__;

                  const depositAmount = item?.depositAmount || item?.depositAcceptedAmount || 0;
                  const feeAmount = item?.feeAmount || 0;
                  const total = item?.price * item?.quantity + feeAmount;
                  const isObo = item.userId !== item.createdBy; // if bid was placed by member

                  const ccyAsset = item?.__cmbAsk__ && currenciesObjById?.[item?.__cmbAsk__?.quoteAssetId];
                  const tokenAsset = (item?.__cmbAsk__?.__tokenAsset__ as any | undefined) || {};

                  return (
                    <MarketPlaceProjectCard
                      info={{
                        tokenAsset,
                        carbonProject: {
                          ...item?.__cmbAsk__?.__carbonProject__,
                          vintageYear: item.__cmbAsk__?.carbonProjectVintageYear,
                        },
                        id: `Bid #${item.id}`,
                        carbonProjectVintageYear: item.__cmbAsk__?.carbonProjectVintageYear,
                      }}
                      controls={
                        <>
                          <div className="flex flex-row justify-between whitespace-pre gap-base">
                            <div>
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                {status}
                              </Text>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                              Offer ID: {item.cmbAskId}
                            </Text>
                            <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                              {dateLabel}: {date.toDateString()}
                            </Text>

                            <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                              Status:
                            </Text>
                            <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                              {statusMessage}
                            </Text>
                          </div>

                          <div className="flex flex-col w-full gap-3xs">
                            <div className="flex flex-row justify-between gap-small">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Qty ({item.__cmbAsk__?.__tokenAsset__?.__uom__?.code || 'tCO2'})
                              </Text>
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                {formatter.formatNumber(item?.quantity, 0)}
                              </Text>
                            </div>

                            <div className="flex flex-row justify-between gap-small">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Price (per {item.__cmbAsk__?.__tokenAsset__?.__uom__?.code || 'tCO2'})
                              </Text>
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                {ccyAsset?.code}
                                {formatter.formatNumber(item?.price, ccyAsset?.numDecimals)}
                              </Text>
                            </div>
                            <div className="flex flex-row justify-between gap-small">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Fee
                              </Text>
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                {ccyAsset?.code}
                                {formatter.formatNumber(feeAmount, ccyAsset?.numDecimals)}
                              </Text>
                            </div>
                            {depositTransaction && !refundTransaction && (
                              <>
                                <div className="flex flex-row justify-between gap-small">
                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                    Deposit
                                  </Text>
                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                    {ccyAsset?.code}
                                    {formatter.formatNumber(depositAmount, ccyAsset?.numDecimals)}
                                  </Text>
                                </div>
                                <div className="flex flex-row justify-between gap-small">
                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                    Pending
                                  </Text>
                                  <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                    {ccyAsset?.code}
                                    {formatter.formatNumber(total - depositAmount, ccyAsset?.numDecimals)}
                                  </Text>
                                </div>
                              </>
                            )}
                            <div className="flex flex-row justify-between gap-small">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Total
                              </Text>
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                {ccyAsset?.code}
                                {formatter.formatNumber(total, ccyAsset?.numDecimals)}
                              </Text>
                            </div>
                          </div>

                          <div className="flex flex-col">
                            {['NEW', 'CONFIRMED_BY_SELLER'].includes(item?.status) && (
                              <>
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Deposit {depositTxId ? 'Receipt' : 'to collect'}
                                </Text>
                                {!depositTxId && (
                                  <>
                                    <Text
                                      className="mb-2"
                                      color={TextColor.secondary}
                                      variant={TypographyVariant.body2}
                                    >
                                      {amount
                                        ? `${ccyAsset?.code}${formatter.formatNumber(amount || 0, ccyAsset?.numDecimals)}`
                                        : 'N/A'}
                                    </Text>
                                    <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                      Please ensure enough funds to cover deposit, otherwise bid may be cancelled.
                                    </Text>
                                  </>
                                )}
                                {depositTxId && (
                                  <>
                                    <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                      TX
                                      <a href={`/explore/tx/${depositTxId}`} target="_blank" rel="noreferrer">
                                        {formatter.truncateMiddle(depositTxId, 16)}
                                      </a>{' '}
                                    </Text>
                                  </>
                                )}
                              </>
                            )}
                            {['CANCELLED', 'CANCELLED_BY_OPS', 'REJECTED_BY_SELLER'].includes(item?.status) && (
                              <>
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Deposit ({item.refundTxId ? 'Refunded' : 'To refund'})
                                </Text>
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  {amount
                                    ? `${ccyAsset?.code}${formatter.formatNumber(
                                        amount || 0,
                                        ccyAsset?.numDecimals,
                                      )} ${ccyAsset?.symbol}`
                                    : 'N/A'}
                                </Text>
                                {item.refundTxId && (
                                  <>
                                    <Text
                                      className="mb-1"
                                      color={TextColor.secondary}
                                      variant={TypographyVariant.body2}
                                    >
                                      TX{' '}
                                      <a href={`/explore/tx/${item.refundTxId}`} target="_blank" rel="noreferrer">
                                        {formatter.truncateMiddle(item.refundTxId, 16)}
                                      </a>
                                    </Text>
                                  </>
                                )}
                              </>
                            )}
                            {['DONE'].includes(item?.status) && item.tradeTxId && (
                              <>
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Trade Confirmation
                                </Text>
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  <a href={`/explore/tx/${item.tradeTxId}`} target="_blank" rel="noreferrer">
                                    {formatter.truncateMiddle(item.tradeTxId, 16)}
                                  </a>
                                </Text>
                              </>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                              Account: {item.__user__?.firstName} {item.__user__?.lastName} [{item.userId}]{' '}
                            </Text>
                            <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                              Placed by: {item.__createdByUser__?.firstName} {item.__createdByUser__?.lastName} [
                              {item.createdBy}] {!isObo && '(me)'}
                            </Text>
                          </div>
                          <div className="flex flex-col gap-base">
                            {item.status === 'NEW' && canBidCmbAsk() && (
                              <ConfirmModal
                                title={`Cancel Bid #${item.id}`}
                                accept={{
                                  label: 'Cancel Bid',
                                  icon: 'no-icon',
                                  callback(param) {
                                    param.onLoading(true);
                                    onCancel(item.id)
                                      .then(() => {
                                        param.onClose();
                                        param.onLoading(false);
                                      })
                                      .catch(() => {
                                        param.onLoading(false);
                                      });
                                    return false;
                                  },
                                }}
                                cancel={{
                                  label: 'Keep',
                                  icon: 'no-icon',
                                }}
                                action={
                                  <Button
                                    isDisabled={marketSettings?.otcCancelationEnabled === 0}
                                    className="flex-1"
                                    variant={ButtonVariant.outlined}
                                    endIcon={IconName.XCircle}
                                  >
                                    Cancel Bid
                                  </Button>
                                }
                              >
                                <div className="flex flex-col items-center gap-base">
                                  <Text
                                    align={TextAlign.center}
                                    color={TextColor.secondary}
                                    variant={TypographyVariant.subtitle1}
                                  >
                                    Are you sure you want to{' '}
                                    <Text align={TextAlign.center} variant={TypographyVariant.subtitle1}>
                                      cancel bid #{item.id}?
                                    </Text>
                                  </Text>
                                </div>
                              </ConfirmModal>
                            )}

                            {!['CANCELLED', 'CANCELLED_BY_OPS', 'NEW', 'DONE', 'REJECTED_BY_SELLER'].includes(
                              item.status,
                            ) &&
                              canBidCmbAsk() && (
                                <Button
                                  isDisabled={marketSettings?.otcCancelationEnabled === 0}
                                  onPress={() => onCancelRequest(item.id)}
                                  className="flex-1"
                                  variant={ButtonVariant.outlined}
                                  endIcon={IconName.InfoCircle}
                                >
                                  Request Cancel
                                </Button>
                              )}
                          </div>
                        </>
                      }
                    />
                  );
                })}
            </Layer>

            {!isLoading && (Array.isArray(filteredBid) ? filteredBid : []).length === 0 && (
              <Empty
                title="No bids"
                description="You haven't place any bid yet. Start trading from the marketplace."
                buttonLabel="Start Trading"
                buttonLink="/account/market-board/marketplace"
              />
            )}
          </div>
          {/* TODO: better control this scenario. Perhaps telling the Pagination component if location is top or bottom. */}
          {/* If total items outside of view show bottom pagination */}
          {(data?.total ?? 0) > 4 && (
            <PageControls
              controls={{
                secondary: (data?.total ?? 0) > 0 && (
                  <div>
                    <Pagination
                      currentPage={pagination.page}
                      pagesCount={Math.ceil(data.total / pagination.pageSize)}
                      onChange={(currentPage) => {
                        pagination.setPage(currentPage);
                      }}
                    />
                  </div>
                ),
              }}
            />
          )}
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};

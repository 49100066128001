import { Text, TextColor, Tooltip, useTextColor } from '@aircarbon/ui';

import { useTradeAdjustment } from '../hooks';

type Props = {
  tradeId: number;
  replacingTradeId?: number;
};

const TradeReversalToolTip = ({ tradeId, replacingTradeId }: Props) => {
  const { tradeAdjustment, isLoading } = useTradeAdjustment(tradeId);
  const { textColor } = useTextColor();
  if (isLoading || !tradeAdjustment) {
    return <div>Empty</div>;
  }
  const tx = tradeAdjustment?.__xTradeAdjustmentTransactions__?.[0]?.__transaction__;

  const toolTipContent = (reverseReason: string, replacingTradeId?: number) => (
    <Text color={TextColor.error} className="p-1 mr-1">
      {replacingTradeId && <div>Replaced By Trade ID #{replacingTradeId}</div>}
      <div>Reason: {tradeAdjustment?.reverseReason}</div>
    </Text>
  );

  return (
    <>
      {tradeAdjustment?.reverseReason && (
        <Tooltip value={toolTipContent(tradeAdjustment?.reverseReason, replacingTradeId)}>
          <div className="flex flex-row items-center mr-1">
            <img className="ml-1 text-red-200" height="15px" width="15px" src="/info-red.png" alt="info" />
          </div>
        </Tooltip>
      )}
      {tx?.id && tx?.txHash && tx?.txStatus === 'confirmed' && (
        <a
          href={window.location.origin + '/explore/tx/' + tx?.txHash}
          className="flex flex-row justify-center items-center"
          target="_blank"
          rel="noreferrer"
        >
          <svg width="12" height="12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 5L10 5C8.89543 5 8 5.89543 8 7L8 41C8 42.1046 8.89543 43 10 43L38 43C39.1046 43 40 42.1046 40 41L40 24.75"
              stroke={textColor(TextColor.error)}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28 5H40V17"
              stroke={textColor(TextColor.error)}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.0002 23.9998L39.0001 6"
              stroke={textColor(TextColor.error)}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      )}
    </>
  );
};

export default TradeReversalToolTip;

import { Text, TextAs, TextColor, styled, toTextColor } from '@aircarbon/ui';

export const Wrapper = styled.div<{ position?: 'absolute' | 'realative' }>`
  position: ${(props) => `${props.position || 'absolute'}`};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #fff;
  gap: 10px;
  .spinner {
    width: 25px;
    height: 25px;
    top: 35%;
    left: 25%;
    &:after {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Hint = styled.div`
  position: relative;
  color: #999999;
  text-align: right;
`;

export const Label = styled.div<{ color?: string }>`
  color: ${(props) => props.color || '#bbb'};
  display: contents;
  color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
`;

export const TipLabel = styled.span`
  display: contents;
  color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
`;
export const TipValue = styled.span`
  display: contents;
  color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
`;
export const NoMargin = styled.div`
  margin: -16px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

import {
  BorderColor,
  Text,
  TextColor,
  TypographyVariant,
  keyframes,
  styled,
  toBorderColor,
  toTextColor,
} from '@aircarbon/ui';

interface SearchResultProps {
  title: string;
  description: string;
  onPress(): void;
}

export const SearchResult: React.FunctionComponent<SearchResultProps> & {
  Loader: React.FunctionComponent;
} = (props) => {
  return (
    <StyledSearchResult onClick={props.onPress}>
      <Text variant={TypographyVariant.subtitle2}>{props.title}</Text>
      <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
        {props.description}
      </Text>
    </StyledSearchResult>
  );
};

const StyledSearchResult = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }
`;

const SearchResultLoader = () => {
  return (
    <StyledSearchResultLoader>
      <LoaderBar style={{ width: '80%' }} />
      <LoaderBar style={{ width: '60%', height: '10px', borderRadius: '5px' }} />
    </StyledSearchResultLoader>
  );
};

// Create the keyframes
const loaderAnimation = keyframes`
  to {
    background-position: right -270px top 0;
  }
`;

const LoaderBar = styled.div`
  background-size: 270px 100%;
  background-repeat: no-repeat;
  background-position: left -270px top 0;
  animation: ${loaderAnimation} 1s ease infinite;
  border-radius: 6px;
  background-color: #f2f2f2;
  background-image: linear-gradient(
    90deg,
    transparent,
    ${({ theme }) => toTextColor(theme)(TextColor.inverse)},
    transparent
  );
  height: 12px;
`;

const StyledSearchResultLoader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: 260px;
  gap: 6px;

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }
`;

SearchResult.Loader = SearchResultLoader;

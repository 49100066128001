import { type Dispatch, type SetStateAction, useState } from 'react';

type usePaginationActions = {
  page: number;
  pageSize: number;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
};

export const usePagination = () => {
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  return {
    page,
    pageSize,
    setPage,
    setPageSize,
  } as usePaginationActions;
};

import { BorderColor, TextColor, styled, toBorderColor, toLayerBackground, toTextColor } from '@aircarbon/ui';

import type { ScreenSizes } from 'state/ui';

export const Wrapper = styled.div<{ screenSize?: ScreenSizes }>`
  overflow: hidden;
  overflow-y: auto;
  height: 100%;

  &.section-btn {
    border-radius: 6px;
  }

  .formTitle {
    background: ${({ theme }) => toLayerBackground(theme)('layer')};
    padding: 1rem 2rem;
    border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }

  .list-square {
    margin-left: 1rem;
    li {
      list-style: square;
    }
  }
`;

export const CriteriaFields = styled.div<{ screenSize?: ScreenSizes }>`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  color: ${({ theme }) => toTextColor(theme)(TextColor.secondary)};
  border-right: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  overflow-y: auto;

  & > div {
    align-self: self-start;
  }

  .select {
    border-color: transparent !important;
    outline: none !important;
    > div > div > div {
      border-radius: ${({ theme }) => theme.system.border.radius.m};
      > div:nth-child(2) {
        border-radius: ${({ theme }) => theme.system.border.radius.m};
      }
    }

    > div {
      background: ${({ theme }) => toLayerBackground(theme)('layerAccent')};
      border-color: ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
      border-radius: ${({ theme }) => theme.system.border.radius.m};
    }
  }
`;

export const Inventory = styled.div<{ screenSize?: ScreenSizes }>`
  font-size: 1.2em;
  font-weight: 500;
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  padding: 1rem 2rem;
  border-top: ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  flex-wrap: wrap;

  .label {
    span {
      display: block;
      color: ${({ theme }) => toTextColor(theme)(TextColor.secondary)};
      font-size: 90%;
      font-weight: 400;
    }
  }

  ${(props) => {
    if (props.screenSize === 'small') {
      return `
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .label {
          grid-column: span 2 / span 2;
        }
      `;
    } else {
      return `
        gap: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
      `;
    }
  }}
`;

export const InventoryItem = styled.button`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-weight: 300;
  gap: 5px;

  .tokenName {
    font-size: 0.85rem;
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    font-weight: 600;
  }

  &.not-available {
    opacity: 0.5;

    .tokenName {
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
  }
`;

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { Input } from 'refreshed-component/atoms/Input';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import { Button, ButtonVariant, IconName, Select, Text, TextColor, TypographyVariant } from '@aircarbon/ui';

import FormDevTool from 'components/FormDevTool';

import { UI } from 'state/ui';

interface Props {
  onSubmit: (
    values: Record<string, any>,
    handlers?: {
      onSuccess?: () => void;
    },
  ) => void;
  isLoading: boolean;
  remainingAllowedAccounts: {
    admins: number;
    traders: number;
    monitors: number;
    clients: number;
  };
  isMember?: number;
}

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountType: string;
};

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  accountType: yup.string().required(),
});

const AddUserForm = ({ onSubmit, isLoading, remainingAllowedAccounts, isMember }: Props) => {
  const { getSetting } = UI.useContainer();

  const enabledParticipantApprovalFlow =
    Number(getSetting('web_settings_enabled_participant_subAccount_approvalFlow') || 0) === 1;
  const traderAnnouncement = getSetting('web_settings_participant_trader_announcement') ?? '';
  const corpAdminAnnouncement = getSetting('web_settings_participant_corpAdmin_announcement') ?? '';

  const { handleSubmit, formState, control, watch, getValues, setValue } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
  });

  const accountTypeList = [
    {
      label: 'Trader',
      id: 'Trader',
    },
    {
      label: 'Admin',
      id: 'Admin',
    },
    {
      label: 'Monitor',
      id: 'Monitor',
    },
  ];

  if (isMember === 1) {
    accountTypeList.push({
      label: 'Client',
      id: 'Client_DMA',
    });
    accountTypeList.push({
      label: 'Client Read Only',
      id: 'Client_Read_Only',
    });
  }
  watch();

  const { errors } = formState;

  const onSubmitHandler = (data: any, evt: any) => {
    onSubmit(data, {
      onSuccess: () => evt.target.reset(),
    });
  };

  const { isSubmitting } = formState;
  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <ModalContent>
          <div className="flex flex-col gap-base">
            <div className={`flex flex-col sm:flex-row gap-base`}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text variant={TypographyVariant.body2}>First Name</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('firstName')}
                  onChange={(event) => {
                    setValue('firstName', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="First Name"
                />
                {errors.firstName?.message && (
                  <Text variant={TypographyVariant.body2} color={TextColor.error}>
                    {errors.firstName.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Text variant={TypographyVariant.body2}>Last Name</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('lastName')}
                  onChange={(event) => {
                    setValue('lastName', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Last Name"
                />
                {errors.lastName?.message && (
                  <Text variant={TypographyVariant.body2} color={TextColor.error}>
                    {errors.lastName.message}
                  </Text>
                )}
              </div>
            </div>
            <div className={`flex flex-col sm:flex-row gap-base`}>
              <div className="flex flex-col flex-1 gap-xs">
                <Text variant={TypographyVariant.body2}>Email</Text>
                <Input
                  config={{
                    color: 'gray',
                    size: 'base',
                  }}
                  value={getValues('email')}
                  onChange={(event) => {
                    setValue('email', (event?.target.value as any) || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  placeholder="Enter Email"
                />
                {errors.email?.message && (
                  <Text variant={TypographyVariant.body2} color={TextColor.error}>
                    {errors.email.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-xs">
                <Select
                  label="Type"
                  items={accountTypeList
                    .filter((item) => {
                      if (item.id === 'Trader') {
                        return remainingAllowedAccounts.traders > 0;
                      } else if (item.id === 'Admin') {
                        return remainingAllowedAccounts.admins > 0;
                      } else if (item.id === 'Monitor') {
                        return remainingAllowedAccounts.monitors > 0;
                      } else if (['Client_DMA', 'Client_Read_Only'].includes(item.id)) {
                        return remainingAllowedAccounts.clients > 0;
                      }
                      return true;
                    })
                    .map((item) => ({
                      value: String(item.id),
                      title: item.label,
                    }))}
                  value={String(getValues('accountType'))}
                  placeholder="Select type"
                  onChange={({ value }) => {
                    setValue('accountType', value || undefined, {
                      shouldValidate: true,
                    });
                  }}
                  error={errors.accountType?.message}
                />
              </div>
            </div>
            <div
              className="text-center"
              style={{
                maxWidth: '500px',
              }}
            >
              {isMember !== 1 && watch('accountType') === 'Trader' && enabledParticipantApprovalFlow && (
                <Text color={TextColor.warning} variant={TypographyVariant.subtitle2} className="mt-1 mb-4 text-left">
                  {traderAnnouncement}
                </Text>
              )}
              {isMember !== 1 && watch('accountType') === 'Admin' && enabledParticipantApprovalFlow && (
                <Text color={TextColor.warning} variant={TypographyVariant.subtitle2} className="mt-1 mb-4 text-left">
                  {corpAdminAnnouncement}
                </Text>
              )}

              {isLoading && <div className="mt-4">Registering new user. It may take a while.. </div>}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            className="flex-1"
            variant={ButtonVariant.secondary}
            isLoading={isSubmitting || isLoading}
            isDisabled={Object.keys(errors).length > 0}
            endIcon={IconName.ArrowEnd}
          >
            Confirm
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default AddUserForm;

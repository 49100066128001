import _ from 'lodash';
import { queryCache, useQuery } from 'react-query';

import { formatter } from '@aircarbon/utils-common';
import type { BalanceResponse } from '@aircarbon/utils-common/src/dto/user';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';

import { fetchAccountBalances } from 'data-provider/user/fetchAccountBalances';

import useTokenTypes from './useTokenTypes';

export default function useAccountBalances(userIds: Array<number>) {
  const { getSetting } = UI.useContainer();
  const {
    selector: { mainCcySymbol, mainCcyScId },
  } = Entity.useContainer();

  const isACXTradingEngineActive = getSetting('web_settings_enable_acxTradingEngine') === '1';

  // NOTE: HX uses all lowercase
  const buyLabel = isACXTradingEngineActive ? 'Buy' : 'buy';
  const sellLabel = isACXTradingEngineActive ? 'Sell' : 'sell';

  const { accountHoldingTypes } = Account.useContainer();
  const { tokenTypes } = useTokenTypes({});

  const scIdToScRatio =
    tokenTypes?.map((tokenType) => ({
      [tokenType.scId]: tokenType.uom.scRatio,
    })) ?? [];

  const { data, isLoading } = useQuery(['account-balances', userIds], () =>
    fetchAccountBalances(userIds, mainCcyScId).then((balances: BalanceResponse[]) => {
      return { balances: balances && balances.length > 0 ? balances : [] };
    }),
  );

  const refetchBalances = () => {
    queryCache.invalidateQueries(['account-balances', userIds]);
  };

  const getOrderByTokenType = ({
    address,
    tokenType,
    side,
  }: {
    address: string;
    tokenType: string;
    side: 'sell' | 'buy' | 'Sell' | 'Buy';
  }) => {
    if (['sell', 'Sell'].includes(side)) side = sellLabel;
    if (['buy', 'Buy'].includes(side)) side = buyLabel;
    return (
      data?.balances
        ?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase())
        ?.openOrders.filter((order) => order.symbol === `${tokenType}/${mainCcySymbol}` && order.side === side) ?? []
    );
  };

  const totalOpenOrdersByTokenType = ({
    address,
    tokenType,
    side,
  }: {
    address: string;
    tokenType: string;
    side: 'sell' | 'buy' | 'Sell' | 'Buy';
  }) =>
    getOrderByTokenType({ address, tokenType, side })?.reduce(
      (acc, order) => acc + Number(order?.quantity) - Number(order?.fill_quantity ?? 0),
      0,
    ) ?? 0;

  const totalPendingTransactionByTokenType = ({ address, tokenType }: { address: string; tokenType: string }) =>
    data?.balances
      ?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase())
      ?.pendingTransactionTokens?.reduce(
        (acc, order) => (order.symbol === `${tokenType}/${mainCcySymbol}` ? acc + Number(order?.token) : acc),
        0,
      ) ?? 0;

  const totalTokenQuantityByTokenTypeId = (address: string, tokenTypeId: number, scRatio: number) =>
    (data?.balances?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase())?.tokens ?? [])
      .filter((token) => formatter.hex2int(token.tokTypeId) === tokenTypeId)
      .reduce((acc, token) => acc + formatter.hex2int(token.currentQty), 0) / scRatio; // in tCO2e

  const totalTokenQuantity = (tokenTypeId: number, scRatio: number, accountAddress?: string) => {
    const balances = accountAddress
      ? data?.balances.filter((balance) => balance.accountAddress?.toLowerCase() === accountAddress?.toLowerCase())
      : data?.balances;

    const tokens = tokenTypeId
      ? (balances?.map(
          (balance) => balance?.tokens?.filter((token) => formatter.hex2int(token.tokTypeId) === tokenTypeId) ?? [],
        ) ?? [])
      : (balances?.map((balance) => balance?.tokens) ?? []);

    const reservedTokens = tokenTypeId
      ? (balances?.map(
          (balance) =>
            balance?.reservedTokens?.filter((token) => formatter.hex2int(token.tokTypeId) === tokenTypeId) ?? [],
        ) ?? [])
      : (balances?.map((balance) => balance?.reservedTokens) ?? []);

    const totalQuantity =
      tokens.reduce(
        (acc, tokens) => acc + (tokens.reduce((acc, token) => acc + formatter.hex2int(token.currentQty), 0) ?? 0),
        0,
      ) / scRatio;

    const totalReservedQuantity =
      reservedTokens.reduce(
        (acc, tokens) => acc + (tokens.reduce((acc, token) => acc + formatter.hex2int(token.currentQty), 0) ?? 0),
        0,
      ) / scRatio;

    return totalQuantity + totalReservedQuantity;
  };

  const totalOpenQuantities = ({
    accountAddress,
    pair,
  }: {
    accountAddress?: string;
    pair?: { symbol: string; baseTokenTypeId: number };
  }) => {
    const balances = accountAddress
      ? (data?.balances.filter((balance) => balance.accountAddress?.toLowerCase() === accountAddress?.toLowerCase()) ??
        [])
      : (data?.balances ?? []);

    const totalOpenOrders =
      balances.reduce((acc, balance) => {
        const openOrders = pair?.symbol
          ? balance?.openOrders?.filter((order) => order.symbol === pair?.symbol)
          : balance?.openOrders;
        return (
          acc +
          (openOrders?.reduce(
            (acc, order) =>
              acc +
              (order.side === sellLabel ? (Number(order?.quantity ?? 0) - Number(order?.fill_quantity ?? 0) ?? 0) : 0),
            0,
          ) ?? 0)
        );
      }, 0) ?? 0;

    const totalPendingTransactions =
      balances.reduce((acc, balance) => {
        const pendingTransactionTokens = pair?.symbol
          ? balance?.pendingTransactionTokens?.filter((transaction) => transaction.symbol === pair?.symbol)
          : balance?.pendingTransactionTokens;
        return acc + (pendingTransactionTokens?.reduce((acc, order) => acc + Number(order?.token), 0) ?? 0);
      }, 0) ?? 0;

    const totalReservedTokens = balances.reduce((acc, balance) => {
      const reservedTokens = pair?.baseTokenTypeId
        ? balance?.reservedTokens?.filter(
            (reservedToken) => formatter.hex2int(reservedToken.tokTypeId) === pair.baseTokenTypeId,
          )
        : balance?.reservedTokens;

      const reservedTokensQty = reservedTokens?.reduce((acc, token) => {
        const scId = formatter.hex2int(token.tokTypeId);
        const qty = formatter.hex2int(token.currentQty) / (scIdToScRatio.find((sc) => sc[scId])?.[scId] ?? 0);
        return acc + qty;
      }, 0);

      return acc + (reservedTokensQty ?? 0);
    }, 0);

    return totalOpenOrders + totalPendingTransactions + totalReservedTokens;
  };

  const getAvailableAmountByAddress = (address: string) => {
    const balance = data?.balances?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase());
    return balance?.availableAmount ?? 0;
  };

  const getTotalBalance = () => data?.balances?.reduce((acc, balance) => acc + balance?.balance, 0);
  const getTotalAvailableAmount = () => data?.balances.reduce((acc, balance) => acc + balance?.availableAmount, 0);

  const getTotalCurrencyBalanceByAddress = (address: string) => {
    const balance = data?.balances?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase());
    return balance?.balance ?? 0;
  };
  const getReservedCurrencyBalance = (address?: string) => {
    const balances = address
      ? (data?.balances?.filter((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase()) ?? [])
      : (data?.balances ?? []);

    return balances.reduce((acc, balance) => acc + balance.reservedBalance, 0);
  };
  const getOpenRFQByAddress = (address: string) => {
    const balance = data?.balances?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase());
    return balance?.openRFQRequests ?? [];
  };

  const getTotalBuyOrders = (address: string) =>
    data?.balances
      ?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase())
      ?.openOrders.filter((order) => order.side === buyLabel) ?? [];

  const getTotalSellOrdersByTokenType = (address: string, tokenType: string) =>
    getOrderByTokenType({ address, tokenType, side: sellLabel });

  const getAvailableTokensByAddress = (address: string) => {
    const balance = data?.balances?.find((balance) => balance.accountAddress?.toLowerCase() === address?.toLowerCase());
    const tokens = balance?.tokens ?? [];

    return _.uniqBy(
      tokens.map((item) => ({
        id: formatter.hex2int(item.tokTypeId),
        name: item.tokTypeName,
      })),
      'id',
    );
  };

  const getAvailableTokenQuantityByAddress = ({
    address,
    tokenType,
    tokenTypeId,
    scRatio,
  }: {
    address: string;
    tokenTypeId: number;
    tokenType: string;
    scRatio: number;
  }) =>
    totalTokenQuantityByTokenTypeId(address, tokenTypeId, scRatio) -
    totalOpenOrdersByTokenType({ address, tokenType, side: sellLabel }) -
    totalPendingTransactionByTokenType({ address, tokenType });

  const getUserBalances = (accountAddress: string) => {
    if (isLoading) return { isLoading };

    return accountHoldingTypes.reduce((balances: Record<string, any>, assetSymbol: string) => {
      const tokenType = tokenTypes?.find((tokenType) => tokenType.symbol === assetSymbol);
      const total =
        assetSymbol === mainCcySymbol
          ? getTotalCurrencyBalanceByAddress(accountAddress)
          : (totalTokenQuantityByTokenTypeId(accountAddress, tokenType?.scId ?? 0, tokenType?.uom?.scRatio ?? 1000) ??
            0);

      const available =
        assetSymbol === mainCcySymbol
          ? getAvailableAmountByAddress(accountAddress)
          : (getAvailableTokenQuantityByAddress({
              address: accountAddress,
              tokenTypeId: tokenType?.scId ?? 0,
              tokenType: assetSymbol,
              scRatio: tokenType?.uom?.scRatio ?? 1000,
            }) ?? 0);

      return {
        ...balances,
        [assetSymbol]: {
          total,
          available,
          open: total - available,
          openOrders:
            assetSymbol === mainCcySymbol
              ? (getTotalBuyOrders(accountAddress) ?? [])
              : (getTotalSellOrdersByTokenType(accountAddress, assetSymbol) ?? []),
          openRFQs: assetSymbol === mainCcySymbol ? getOpenRFQByAddress(accountAddress) : [],
        },
      };
    }, {});
  };

  return {
    refetchBalances,
    balances: data?.balances ?? [],
    selector: {
      getTotalBuyOrders,
      getUserBalances,
      getTotalCurrencyBalanceByAddress,
      getReservedCurrencyBalance,
      totalOpenOrdersByTokenType,
      totalTokenQuantityByTokenTypeId,
      getTotalAvailableAmount,
      getTotalBalance,
      getAvailableAmountByAddress,
      getAvailableTokenQuantityByAddress,
      getAvailableTokensByAddress,
      totalTokenQuantity,
      totalOpenQuantities,
    },
    isLoading,
  };
}

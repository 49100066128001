import { useCallback, useState } from 'react';
import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import { CopyInput } from 'refreshed-component/atoms/CopyInput';
import CorpAddUser from 'refreshed-component/forms/CorpAddUser';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import Modal from 'refreshed-component/molecules/Modal';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  InputText,
  Layer,
  Select,
  Text,
  TextAlign,
  TextColor,
  TypographyVariant,
} from '@aircarbon/ui';
import { UserType, logger } from '@aircarbon/utils-common';

import { Account } from 'state/account';
import { UI } from 'state/ui';
import { User } from 'state/user';

export const ManageUser = () => {
  const [searchUser, setSearchUser] = useState('');
  const { getSetting } = UI.useContainer();
  const { accountUsers, fetchAccountUsers } = Account.useContainer();
  const {
    selector: { getUserSettings, getAuthToken, getUserId },
    status: { isMember },
  } = User.useContainer();

  const maxAllowedAdmins = Number(getSetting('web_settings_maxNumAdmins') ?? 3);
  const maxAllowedTraders = Number(getSetting('web_settings_maxNumTraders') ?? 5);
  const maxAllowedMonitors = Number(getSetting('web_settings_maxNumMonitors') ?? 5);
  const maxAllowedMemberClients = Number(getSetting('web_settings_maxNumMemberClients') ?? 5);

  // Maximum of new accounts allowed for current user (if none, then default)
  const userSettings = getUserSettings();
  const numAllowedAdmins = Number(userSettings?.maxNumCorpAdmins ?? maxAllowedAdmins);
  const numAllowedTraders = Number(userSettings?.maxNumCorpTraders ?? maxAllowedTraders);
  const numAllowedMonitors = Number(userSettings?.maxNumCorpMonitors ?? maxAllowedMonitors);
  const numAllowedMemberClients = Number(userSettings?.maxNumMemberClients ?? maxAllowedMemberClients);

  // monitor doesn't have the account address
  const monitors = (accountUsers ?? []).filter((user: Record<string, any>) => {
    const { first_name, last_name, account_type } = user;
    const name = `${first_name} ${last_name}`.toLowerCase();
    return (
      account_type === UserType.CORPORATE_MONITOR && (searchUser === '' || name.includes(searchUser.toLowerCase()))
    );
  });

  // traders
  const traders = accountUsers?.filter((user: Record<string, any>) => {
    const { first_name, last_name, account_type } = user;
    const name = `${first_name} ${last_name}`.toLowerCase();
    return account_type === UserType.CORPORATE_TRADER && (searchUser === '' || name.includes(searchUser.toLowerCase()));
  });

  // member clients
  const clientsDma = accountUsers?.filter((user: Record<string, any>) => {
    const { first_name, last_name, account_type } = user;
    const name = `${first_name} ${last_name}`.toLowerCase();
    return (
      account_type === UserType.CORPORATE_CLIENT_DMA && (searchUser === '' || name.includes(searchUser.toLowerCase()))
    );
  });

  const clientsReadOnly = accountUsers?.filter((user: Record<string, any>) => {
    const { first_name, last_name, account_type } = user;
    const name = `${first_name} ${last_name}`.toLowerCase();
    return (
      account_type === UserType.CORPORATE_CLIENT_READ_ONLY &&
      (searchUser === '' || name.includes(searchUser.toLowerCase()))
    );
  });

  // Admins
  const admins = accountUsers?.filter((user: Record<string, any>) => {
    const { first_name, last_name, account_type } = user;
    const name = `${first_name} ${last_name}`.toLowerCase();
    return (
      account_type === UserType.CORPORATE_ADMIN &&
      user.parent_id > 0 &&
      (searchUser === '' || name.includes(searchUser.toLowerCase()))
    );
  });

  // Main account - Parent
  const root = accountUsers?.filter(
    (user: Record<string, any>) => user.account_type === UserType.CORPORATE_ADMIN && !user.parent_id,
  );

  const [tokenListUserDropdownItem, setTokenListUserDropdownItem] = useState('all');

  let allUser = [...(root || []), ...admins, ...traders, ...monitors, ...clientsDma, ...clientsReadOnly];
  if (tokenListUserDropdownItem === UserType.CORPORATE_ADMIN) {
    allUser = [...(root || []), ...admins];
  }
  if (tokenListUserDropdownItem === UserType.CORPORATE_TRADER) {
    allUser = [...traders];
  }
  if (tokenListUserDropdownItem === UserType.CORPORATE_CLIENT_DMA) {
    allUser = [...clientsDma];
  }
  if (tokenListUserDropdownItem === UserType.CORPORATE_CLIENT_READ_ONLY) {
    allUser = [...clientsReadOnly];
  }
  if (tokenListUserDropdownItem === UserType.CORPORATE_MONITOR) {
    allUser = [...monitors];
  }

  const lockUser = useCallback(
    async (userId: number) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      fetch(`/api/user/user/${userId}/lock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        } as any,
        body: JSON.stringify({
          userId,
        }),
      })
        .then(() => {
          fetchAccountUsers();
        })
        .catch(logger.error.bind(logger));
    },
    [fetchAccountUsers],
  );

  const unlockUser = useCallback(
    async (userId: number) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      fetch(`/api/user/user/${userId}/unlock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        } as any,
        body: JSON.stringify({
          userId,
        }),
      })
        .then(() => {
          fetchAccountUsers();
        })
        .catch(logger.error.bind(logger));
    },
    [fetchAccountUsers],
  );

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="Manage Users"
          controls={{
            secondary: (
              <Modal
                title={'Add User Account'}
                action={
                  <Button className="account-type" endIcon={IconName.UserAdd}>
                    Add User Account
                  </Button>
                }
              >
                {({ onClose }) => {
                  return (
                    <CorpAddUser
                      onSuccess={() => onClose()}
                      totalCurrentUsers={{
                        admins: admins.length,
                        traders: traders.length,
                        clients: [...clientsDma, ...clientsReadOnly].length,
                        monitors: monitors.length,
                      }}
                      isMember={root?.[0]?.is_member}
                    />
                  );
                }}
              </Modal>
            ),
          }}
        />
      </PageSections>
      <PageSections>
        <SummaryHolder>
          <SummaryCard
            icon={<Icon size="1.75rem" name={IconName.UserCircle} />}
            title={'Admins'}
            tooltip="Total admins / Maximum allowed admins"
            value={
              <>
                {admins.length + root.length}/{numAllowedAdmins}
              </>
            }
          />
          <SummaryCard
            icon={<Icon size="1.75rem" name={IconName.UserCircle} />}
            title={'Traders'}
            tooltip="Total traders / Maximum allowed traders"
            value={
              <>
                {traders.length}/{numAllowedTraders}
              </>
            }
          />
          <SummaryCard
            icon={<Icon size="1.75rem" name={IconName.Eye} />}
            title={'Monitors'}
            tooltip="Total monitors / Maximum allowed monitors"
            value={
              <>
                {monitors.length}/{numAllowedMonitors}
              </>
            }
          />
          {isMember() ? (
            <SummaryCard
              icon={<Icon size="1.75rem" name={IconName.UserCircle} />}
              title={'Clients'}
              tooltip="Total clients / Maximum allowed clients"
              value={
                <>
                  {[...clientsDma, ...clientsReadOnly].length}/{numAllowedMemberClients}
                </>
              }
            />
          ) : null}
        </SummaryHolder>
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <div className="flex flex-row justify-between w-full gap-base">
            <Select
              value={tokenListUserDropdownItem ?? 'all'}
              items={[
                {
                  value: 'all',
                  title: 'All accounts',
                },
                {
                  value: UserType.CORPORATE_ADMIN,
                  title: 'Admins',
                },
                {
                  value: UserType.CORPORATE_TRADER,
                  title: 'Traders',
                },
                {
                  value: UserType.CORPORATE_CLIENT_DMA,
                  title: 'Clients (DMA)',
                },
                {
                  value: UserType.CORPORATE_CLIENT_READ_ONLY,
                  title: 'Clients (Read Only)',
                },
                {
                  value: UserType.CORPORATE_MONITOR,
                  title: 'Monitors',
                },
              ]}
              onChange={({ value }) => {
                setTokenListUserDropdownItem(value);
              }}
            />

            <InputText
              value={searchUser}
              onChange={(event) => {
                setSearchUser(event.target.value.trim() || '');
              }}
              placeholder="Search"
              icon={IconName.Search}
            />
          </div>
          {accountUsers && (
            <Table
              key={accountUsers.length}
              config={{
                sticky: {
                  left: ['id'],
                  right: ['status'],
                },
                columns: {
                  id: {
                    label: '#',
                    minWidth: 50,
                  },
                  accountName: {
                    label: 'ACCOUNT NAME',
                  },
                  emailId: {
                    label: 'EMAIL ID',
                  },
                  accountType: {
                    label: 'ACCOUNT TYPE',
                  },
                  address: {
                    label: 'ADDRESS',
                  },
                  status: {
                    label: 'STATUS',
                  },
                },
                rows: allUser.map((user) => {
                  const isRootUser = root.find((rootUser) => rootUser.user_id === user.user_id);
                  const isActive = user.status.toLocaleLowerCase() === 'active';
                  // Show Disable/Enable button only for approved users
                  const isNew = user.status.toLocaleLowerCase() === 'new';
                  const isCurrentUser = getUserId() === user.user_id;
                  return {
                    _key: String(user.user_id),
                    id: user.user_id.toString(),
                    emailId: user.email,
                    accountName: `${user.first_name} ${user.last_name}`,
                    accountType: user.account_type,
                    address: user.account ? (
                      <CopyInput variant={TypographyVariant.subtitle2} text={user.account} length={16} />
                    ) : (
                      ''
                    ),
                    status:
                      !isRootUser && !isNew && !isCurrentUser ? (
                        <ConfirmModal
                          title={isActive ? 'Disable Account' : 'Enable Account'}
                          action={
                            <Button
                              variant={isActive ? ButtonVariant.outlined : ButtonVariant.secondary}
                              size={ButtonSize.s}
                              endIcon={isActive ? IconName.LockOpen : IconName.LockClosed}
                            >
                              {isActive ? 'Enabled' : 'Disabled'}
                            </Button>
                          }
                          accept={{
                            label: 'Accept',
                            callback(props) {
                              props.onLoading(true);
                              (isActive ? lockUser : unlockUser)(user.user_id)
                                .then(() => {
                                  props.onLoading(false);
                                  props.onClose();
                                })
                                .catch(() => {
                                  props.onLoading(false);
                                });
                              return false;
                            },
                          }}
                          cancel={{
                            label: 'Cancel',
                          }}
                        >
                          <Text
                            align={TextAlign.center}
                            color={TextColor.secondary}
                            variant={TypographyVariant.subtitle1}
                          >
                            Are you sure you want to{' '}
                            <Text align={TextAlign.center} variant={TypographyVariant.subtitle1}>
                              {isActive ? 'Disable Account' : 'Enable Account'}
                            </Text>
                            ?
                          </Text>
                        </ConfirmModal>
                      ) : (
                        <Text align={TextAlign.center}>{isActive ? 'Enabled' : 'Disabled'}</Text>
                      ),
                  };
                }),
              }}
            />
          )}
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};

import { type ForwardedRef, forwardRef } from 'react';

import { styled } from '@aircarbon/ui';

const HitAreaRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export type Props = {
  width?: number | 'auto';
  height?: number | 'auto';
};

export const HitArea = forwardRef(
  (
    {
      width = 'auto',
      height = 'auto',
      ref,
      ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Props,
    forwardRef: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <HitAreaRoot
        ref={forwardRef}
        {...props}
        style={{
          width: width === 'auto' ? width : `${width}px`,
          minWidth: width === 'auto' ? width : `${width}px`,
          height: height === 'auto' ? height : `${height}px`,
          minHeight: height === 'auto' ? height : `${height}px`,
          ...props.style,
        }}
      >
        {props.children}
      </HitAreaRoot>
    );
  },
);

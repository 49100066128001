import type { ReactNode } from 'react';

import { BorderColor, styled, toBorderColor, toSpacing } from '@aircarbon/ui';

const Wrapper = styled.div<{ border?: Border; borderStyle?: BorderStyle }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  ${(props) => {
    if (props.border === 'red') {
      return `border-top: 1px ${props.borderStyle} ${toBorderColor(props.theme)(BorderColor.error)}; border-bottom: 1px ${props.borderStyle} ${toBorderColor(props.theme)(BorderColor.error)};`;
    } else if (props.border === 'green') {
      return `border-top: 1px ${props.borderStyle} ${toBorderColor(props.theme)(BorderColor.success)}; border-bottom: 1px ${props.borderStyle} ${toBorderColor(props.theme)(BorderColor.success)};`;
    } else if (props.border) {
      return `border-top: 1px ${props.borderStyle} ${props.border}; border-bottom: 1px ${props.borderStyle} ${props.border};`;
    } else {
      return `border-top: 1px ${props.borderStyle} #444444; border-bottom: 1px ${props.borderStyle} #444444;`;
    }
  }}
  align-items: center;
  padding: ${({ theme }) => toSpacing(theme)(4)};
`;

type Border = 'red' | 'green' | 'gray' | string;
type BorderStyle = 'solid' | 'dotted' | 'dashed';

export default function InfoList(props: {
  border?: Border;
  borderStyle?: BorderStyle;
  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  info?: {
    label: ReactNode;
    value: ReactNode;
    _key?: string;
  }[];
}) {
  return (
    <Wrapper border={props.border} className={props.className} borderStyle={props.borderStyle || 'solid'}>
      {props.info?.map((item, index) => {
        return (
          <div key={`${item._key || item.label}${index}`} className="flex flex-row w-full">
            <div className="flex-1">{item.label}</div>
            <div>{item.value}</div>
          </div>
        );
      })}
    </Wrapper>
  );
}

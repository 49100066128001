import { isArray } from 'lodash';
import { type CSSProperties, useEffect, useState } from 'react';

import { TextColor, useTextColor } from '@aircarbon/ui';
import { formatter, trades as tradeUtils } from '@aircarbon/utils-common';

import Loading from 'components/styled/Loading';

import { formatACXDate } from 'utils/helpers';

import Table, { type TableHeaderOption } from '../components/Table';
import { useFobTypes } from '../hooks/useFobTypes';
import type { AssetCategoryCode } from '../layouts/trading.hook';

type Props = {
  trades: any;
  baseAssetId?: number;
  style?: CSSProperties;
  assetCategory?: AssetCategoryCode;
  showType?: boolean;
  withCustomScrollBar?: boolean;
};

type Trade = {
  price: number;
  type: string;
  quantity: string;
  time: string;
  fob: string;
  fobId: number;
  shipping: string;
  shippingId: number;
};

type TableHeaderOptionWithSelectedIds = TableHeaderOption & { selectedIds?: number[] | undefined };

const Trades = ({ trades, baseAssetId, style, assetCategory, showType, withCustomScrollBar = true }: Props) => {
  const { isLoading, fobTypes } = useFobTypes(baseAssetId ?? Number.NaN, assetCategory === 'biofuel');
  const shippingNameToId: { [key: string]: number } = Object.fromEntries(
    Object.entries(tradeUtils.ShippingTypeSelector).map((a) => a.reverse()),
  );

  const [tradeTableHeaderOption, setTradeTableHeaderOption] = useState<{
    [key: string]: TableHeaderOptionWithSelectedIds | undefined;
  }>({
    fob: {
      option: {
        list: fobTypes?.map((fob) => fob.name) ?? [],
        type: 'multiple',
        default: 'All',
      },
    },
    shipping: {
      option: {
        list: Object.entries(tradeUtils.ShippingTypeSelector).map(([key, value]) => value),
        type: 'multiple',
        default: 'All',
      },
    },
  });

  useEffect(() => {
    setTradeTableHeaderOption({
      fob: {
        option: {
          list: fobTypes?.map((fob) => fob.name) ?? [],
          type: 'multiple',
          default: 'All',
        },
      },
      shipping: {
        option: {
          list: Object.entries(tradeUtils.ShippingTypeSelector).map(([key, value]) => value),
          type: 'multiple',
          default: 'All',
        },
      },
    });
  }, [fobTypes]);

  const { textColor } = useTextColor();

  if (isLoading) {
    return <Loading />;
  }

  const tradeList =
    trades?.total > 0 &&
    trades.items
      .map((item: any) => {
        const fob =
          item.__makerOrder__?.__xOrderBiofuelDetails__?.__fobType__?.name ||
          item.__takerOrder__?.__xOrderBiofuelDetails__?.__fobType__?.name ||
          'Any';

        const shippingId =
          item.__makerOrder__?.__xOrderBiofuelDetails__?.shippingId <=
          item.__takerOrder__?.__xOrderBiofuelDetails__?.shippingId
            ? item.__makerOrder__?.__xOrderBiofuelDetails__?.shippingId
            : item.__takerOrder__?.__xOrderBiofuelDetails__?.shippingId;
        const numDecimals = item?.__makerOrder__?.__pair__?.baseAsset?.numDecimals ?? 0;

        return {
          price: formatter.formatNumber(item.price),
          type: item?.__tradeType__?.typeName,
          quantity: formatter.formatNumber(item.qty, numDecimals),
          time: formatACXDate({ date: item.createdAtUtc, week: false, year: false }),
          fobId: item.__makerOrder__?.__xOrderBiofuelDetails__?.fobId,
          fob,
          shippingId,
          shipping: tradeUtils.ShippingTypeSelector[shippingId],
        };
      })
      ?.filter(
        (t: Trade) =>
          (tradeTableHeaderOption.fob?.selectedIds ? tradeTableHeaderOption.fob.selectedIds.includes(t.fobId) : true) &&
          (tradeTableHeaderOption.shipping?.selectedIds
            ? tradeTableHeaderOption.shipping.selectedIds.includes(t.shippingId)
            : true),
      );

  return (
    <div style={style} className="flex relative flex-row flex-auto h-full">
      <Table
        config={{
          headerOption: {
            value: tradeTableHeaderOption,
            onChange: (value) => {
              const optionWithSelectedIds: { [key: string]: TableHeaderOptionWithSelectedIds | undefined } = value;

              if (optionWithSelectedIds.fob) {
                optionWithSelectedIds.fob.selectedIds =
                  isArray(value.fob?.option?.selected) && value.fob?.option?.selected?.length && fobTypes
                    ? (value.fob.option.selected.map(
                        (fobName: string) => fobTypes.find((t) => t.name === fobName)!.id,
                      ) ?? undefined)
                    : undefined;
              }

              if (optionWithSelectedIds.shipping) {
                optionWithSelectedIds.shipping.selectedIds =
                  isArray(value.shipping?.option?.selected) && value.shipping?.option?.selected?.length
                    ? (value.shipping?.option?.selected.map((shippingName: string) =>
                        Number(shippingNameToId[shippingName]),
                      ) ?? undefined)
                    : undefined;
              }

              setTradeTableHeaderOption(optionWithSelectedIds);
            },
          },
          withCustomScrollBar,
          columns: {
            ...(showType
              ? {
                  type: {
                    label: 'Type',
                    font: 'code',
                  },
                }
              : {}),
            price: {
              label: 'PRICE',
              font: 'code',
            },
            quantity: {
              label: 'QUANTITY',
              align: 'right',
              font: 'code',
            },
            ...(assetCategory === 'biofuel'
              ? {
                  fob: {
                    label: (
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 17C13.4183 17 17 13.4183 17 9M9 17C4.58172 17 1 13.4183 1 9M9 17C10.7673 17 12.2 
                          13.4183 12.2 9C12.2 4.58172 10.7673 1 9 1M9 17C7.23268 17 5.8 13.4183 5.8 9C5.8 4.58172 7.23268 
                          1 9 1M17 9C17 4.58172 13.4183 1 9 1M17 9H1M1 9C1 4.58172 4.58172 1 9 1M3.34316 3.45684C4.79088
                          4.90456 6.79088 5.8 9 5.8C11.2092 5.8 13.2092 4.90456 14.6569 3.45684M14.6569 14.5431C13.2092 
                          13.0954 11.2092 12.2 9 12.2C6.79088 12.2 4.79088 13.0954 3.34316 14.5431"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                      </svg>
                    ),
                    align: 'right',
                    font: 'code',
                  },
                  shipping: {
                    label: (
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.2 2.59998H1.8C1.35817 2.59998 1 2.95815 1 3.39997V16.1999C1 16.6418 1.35817 16.9999 1.8 16.9999H16.2C16.6418 16.9999 17 16.6418 17 16.1999V3.39997C17 2.95815 16.6418 2.59998 16.2 2.59998Z"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M1 7.40002H17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M1 12.2H17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M6.20007 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M11.7999 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M6.20007 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M11.7999 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M17 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M1 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M5 17H13"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                      </svg>
                    ),
                    align: 'right',
                    font: 'code',
                  },
                }
              : {}),
            time: {
              label: 'TIME',
              style: {
                whiteSpace: 'pre',
              },
            },
          },
          rows: tradeList || [],
        }}
      />
    </div>
  );
};

export default Trades;

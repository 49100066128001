import { BorderColor, styled, toBorderColor, toLayerBackground } from '@aircarbon/ui';

import type { ScreenSizes } from 'state/ui';

export const Wrapper = styled.div`
  .pagination-wrapper {
    margin-left: auto;

    & > div {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }
`;

export const BidExpandWrapper = styled.div`
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  padding: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 90;
  width: 100%;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
`;
export const BidExpandClose = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 1;
`;
export const BidsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 1rem;
`;
export const CriteriaWrapper = styled.div<{ screenSize?: ScreenSizes }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;
  align-items: center;

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return ``;
    } else {
      return `
        position: relative;
        width: auto;
        padding-left: 1em; 
      `;
    }
  }}
`;

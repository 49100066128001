import { useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  type PageTab,
  type UrlInfo,
  generatePath,
  handleBooleanProp,
  matchUrlAndQuery,
} from 'refreshed-component/templates/ContentHolder';

import { Avatar, ButtonSize, ButtonVariant, IconName, Knob, NavBar } from '@aircarbon/ui';
import { AssetCategory } from '@aircarbon/utils-common';

import { ThemeMode } from 'pages/account/trading/components/ThemeMode';
import { useNotifications } from 'pages/account/trading/hooks';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useQueryParams from 'hooks/useQueryParams';

import { openHelp } from 'utils/openHelp';

import { NotificationsOverlay } from '../Notifications';
import { ProfileMenu } from '../ProfileMenu';
import { TransactionsOverlay } from '../Transactions';
import { ActionPopover, type ActionPopoverRef } from './components/ActionPopover';

export type HeaderProps = {
  tabs?: PageTab[];
  name?: string;
  products: Array<AssetCategory>;
  isBurgerMenuVisible: boolean;
  onExpand?: () => void;
};

const productsMap: Partial<
  Record<
    AssetCategory,
    {
      value: AssetCategory;
      title: string;
      description: string;
      icon: IconName;
    }
  >
> = {
  [AssetCategory.token]: {
    value: AssetCategory.token,
    title: 'Carbon',
    description: 'Issued Carbon Credits',
    icon: IconName.Retire,
  },
  [AssetCategory.fct]: {
    value: AssetCategory.fct,
    title: 'FCT',
    description: 'Pre-issued Carbon Credits',
    icon: IconName.FCT,
  },
  [AssetCategory.rec]: {
    value: AssetCategory.rec,
    title: 'REC',
    description: 'Renewable Energy Certificates',
    icon: IconName.REC,
  },
  [AssetCategory.biofuel]: {
    value: AssetCategory.biofuel,
    title: 'ACXBiofuels',
    description: 'Biofuels Order Matching',
    icon: IconName.Drop,
  },
};

export const Header: React.FC<HeaderProps> = (props) => {
  const location = useLocation();
  const { product, changeProduct } = useMarketplaceProduct(props.products);
  const { entity } = Entity.useContainer();
  const { themeMode } = ThemeMode.useContainer();
  const queryParams = useQueryParams();
  const history = useHistory();
  const locationInfo: UrlInfo = {
    path: location.pathname,
    queryParams,
  };
  const {
    selector: { getUserProfile },
  } = User.useContainer();
  const {
    selector: { unreadCounter },
  } = useNotifications('All', '');
  const notificationsPopoverRef = useRef<ActionPopoverRef>(null);

  const user = getUserProfile();

  const productDropdownItems = useMemo(() => {
    return props.products
      .map(
        (product) =>
          ({ ...productsMap[product], value: String(productsMap[product]?.value || '') }) as {
            value: string;
            title: string;
            description: string;
            icon: IconName;
          },
      )
      .filter((p) => !!p);
  }, [props.products]);

  const filteredTabs = props.tabs?.filter((tab) => (tab.isDisabled ? !handleBooleanProp(tab.isDisabled) : tab));

  let activeTab = '0';

  filteredTabs?.every((tab, index) => {
    const isMatch = tab.path ? matchUrlAndQuery(tab, locationInfo) : false;
    const isTabActive = tab.isSelected ? tab.isSelected?.() : isMatch;

    if (isTabActive) {
      activeTab = String(index);
      return false;
    }

    return true;
  });

  const onPressTab = ({ value }: { value: string }) => {
    const tab = filteredTabs?.[Number(value)];

    if (!tab) {
      return;
    }

    history.push(generatePath(tab.path as string, tab.query));
  };

  return (
    <NavBar
      title={props.name}
      logo={
        props.isBurgerMenuVisible ? (
          <a href="/account/balances">
            <img src={themeMode === 'dark' ? entity.settings.logoDark : entity.settings.logoLight} />
          </a>
        ) : undefined
      }
      suffix={[
        ...(entity.config?.helpEnable && !props.isBurgerMenuVisible
          ? [
              <Knob
                key="help"
                size={ButtonSize.s}
                variant={ButtonVariant.ghost}
                icon={IconName.QuestionMarkCircle}
                onPress={() => {
                  openHelp();
                }}
              />,
            ]
          : []),

        <ActionPopover icon={IconName.TransactionStatus} key="transactions">
          <TransactionsOverlay />
        </ActionPopover>,
        <ActionPopover key="notifications" ref={notificationsPopoverRef} icon={IconName.Bell} counter={unreadCounter}>
          <NotificationsOverlay count={unreadCounter} remove={() => notificationsPopoverRef.current?.hide()} />
        </ActionPopover>,

        ...(props.isBurgerMenuVisible
          ? [
              <Knob
                key={'menu'}
                icon={IconName.Menu}
                variant={ButtonVariant.ghost}
                size={ButtonSize.s}
                onPress={props.onExpand}
              />,
            ]
          : [
              <ActionPopover
                key="profile-menu"
                icon={<Avatar initials={[user.first_name, user.last_name].map((str: string) => str[0]).join('')} />}
              >
                <ProfileMenu />
              </ActionPopover>,
            ]),
      ]}
      products={productDropdownItems}
      productActiveValue={product}
      onChangeProduct={changeProduct}
      links={filteredTabs?.map((tab, index) => ({ title: tab.title as string, value: String(index) }))}
      linkActiveValue={activeTab}
      onPressLink={onPressTab}
    />
  );
};

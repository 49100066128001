import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CopyInput } from 'refreshed-component/atoms/CopyInput';
import { Header } from 'refreshed-component/molecules/Header';
import { WebAnnouncement } from 'refreshed-component/molecules/WebAnnouncement';
import { SideBar, type SideBarMenuProps } from 'refreshed-component/organisms/SideBar';

import {
  Avatar,
  ButtonSize,
  ButtonVariant,
  Card,
  CardVariant,
  IconName,
  Knob,
  Layer,
  Link,
  LinkColor,
  ListItem,
  Screen,
  SidebarMobile,
  Text,
  TextAlign,
  TextColor,
  TypographyVariant,
  styled,
  useSpacing,
} from '@aircarbon/ui';
import type { AssetCategory } from '@aircarbon/utils-common';

import { setTheme, useThemeMode } from 'pages/account/trading/components/ThemeMode';

import { Entity } from 'state/entity';
import { User, logout } from 'state/user';

import { MarketplaceProductProvider } from 'providers/MarketplaceProductProvider';

import useQueryParams from 'hooks/useQueryParams';
import { useSettingsLink } from 'hooks/useSettingsLink';

import emitter from 'utils/emitter';
import { openHelp } from 'utils/openHelp';

import { Footer } from '../molecules/Footer';
import { MobileMenu } from './MobileMenu';

export interface PageTab {
  title?: string;
  path?: string;
  component?: ReactNode;
  query?: { [key: string]: string };
  isDisabled?: (() => boolean) | boolean;
  isSelected?: (urlInfo?: UrlInfo) => boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  availableProducts?: Array<AssetCategory>;
}

export const handleBooleanProp = (prop?: (() => boolean) | boolean) => {
  return typeof prop === 'function' ? prop() : prop;
};

export interface PageInfo extends PageTab {
  name?: string;
  tabs?: PageTab[];
}

export const getPathAndQuery = (pageInfo: PageInfo) => {
  const tabs = pageInfo.tabs?.filter((tab) => {
    return !handleBooleanProp(tab?.isDisabled);
  });
  if (tabs?.length) {
    return {
      path: tabs[0].path,
      query: tabs[0].query,
    };
  } else {
    return {
      path: pageInfo.path,
      query: pageInfo.query,
    };
  }
};

export type MapItem = (
  | {
      type: 'sidebar-separator';
    }
  | ({
      sideBarConfig?: {
        icon: IconName;
        name: string;
      };
    } & (
      | {
          type: 'link';
          page: PageInfo;
        }
      | {
          type: 'list';
          list: PageInfo[];
        }
    ))
) & {
  isDisabled?: boolean | (() => boolean);
};

export type ContentHolderProps = {
  config: MapItem[];
};

const ContentHolderRoot = styled.div`
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  height: -webkit-fill-available;
`;

export function matchUrlAndQuery(supply: PageInfo | PageTab, match: UrlInfo) {
  const finalPath = (supply.path || '').split('/*')[0];
  const endWithStarExtension = supply.path?.endsWith('/*');
  if (endWithStarExtension ? match.path.startsWith(finalPath) : supply.path === match.path) {
    if (!supply.query) {
      return true;
    } else {
      for (const [key, value] of Object.entries(supply.query)) {
        if (match.queryParams.get(key) !== value) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

export function generatePath(
  path: string,
  query?: {
    [key: string]: string;
  },
) {
  return `${(path || '').split('/*')[0]}?${Object.entries(query || {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
}

export type UrlInfo = {
  path: string;
  queryParams: URLSearchParams;
};

export const ContentHolder: React.FC<ContentHolderProps> = (props) => {
  const [isNavbarBurgerMenuVisible, setIsNavbarBurgerMenuVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { themeMode } = useThemeMode();

  const { entity } = Entity.useContainer();
  const locationInfo: UrlInfo = useMemo(
    () => ({
      path: location.pathname,
      queryParams,
    }),
    [location.pathname, queryParams],
  );

  const settingsLink = useSettingsLink();

  const { spacing } = useSpacing();

  const {
    selector: { getFullName, getUserProfile, getAccountAddress },
  } = User.useContainer();

  const finalConfig = useMemo(
    () =>
      props.config.filter((item) => {
        const isDisabled = handleBooleanProp(item.isDisabled);
        if (isDisabled) {
          return false;
        } else if (item.type === 'link') {
          return !handleBooleanProp(item.page.isDisabled);
        } else {
          return true;
        }
      }),
    [props.config],
  );

  const currentPageInfo = finalConfig
    .map((item) => {
      if (item.type === 'sidebar-separator') {
        return undefined;
      } else if (item.type === 'link') {
        const isMatch = item.page.path ? matchUrlAndQuery(item.page, locationInfo) : false;
        const isMatchTab = item.page.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
        const isValid = item.page.isSelected ? item.page.isSelected?.(locationInfo) : isMatch || isMatchTab;
        return isValid ? item.page : undefined;
      } else {
        return item.list.find((pageInfo) => {
          const isMatch = pageInfo.path ? matchUrlAndQuery(pageInfo, locationInfo) : false;
          const isMatchTab = pageInfo.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
          const isValid = pageInfo.isSelected ? pageInfo.isSelected?.(locationInfo) : isMatch || isMatchTab;
          return isValid;
        });
      }
    })
    .find((item) => !handleBooleanProp(item?.isDisabled) && item);

  const pageTabs = currentPageInfo?.tabs?.filter((tab) => {
    return !handleBooleanProp(tab?.isDisabled);
  });

  const pageTab = pageTabs?.find((tab) => {
    return matchUrlAndQuery(tab, locationInfo);
  });

  const isSidebarExpandBlocked = !(pageTab?.isExpandable ?? true) || !(currentPageInfo?.isExpandable ?? true);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    isSidebarExpandBlocked || (pageTab?.isExpanded ?? currentPageInfo?.isExpanded ?? false),
  );

  useEffect(() => {
    setIsSidebarCollapsed(isSidebarExpandBlocked || (pageTab?.isExpanded ?? currentPageInfo?.isExpanded ?? false));
  }, [pageTab?.isExpanded, currentPageInfo?.isExpanded, isSidebarExpandBlocked]);

  const pageComponent = pageTab?.component || currentPageInfo?.component;

  const pageProducts =
    (pageTab?.availableProducts || currentPageInfo?.availableProducts)?.filter((product) => !!product) || [];

  useEffect(() => {
    if (!pageComponent) {
      const tab = pageTabs?.[0];
      if (tab?.component && tab.path) {
        history.replace(
          `${(tab.path || '').split('/*')[0]}?${Object.entries(tab.query || {})
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageComponent]);

  const userProfile = getUserProfile();

  const onToggleSidebarCollapsed = useCallback(
    (isCollapsed) => {
      if (isSidebarExpandBlocked) {
        return;
      }

      setIsSidebarCollapsed(isCollapsed);
    },
    [isSidebarExpandBlocked],
  );

  const onChangeIsMobileSidebar = (isMobileSidebar: boolean) => {
    if (isMobileSidebar) {
      setIsSidebarCollapsed(true);
    }

    setIsNavbarBurgerMenuVisible(isMobileSidebar);
  };

  const onCollapseMobileSidebar = () => {
    setIsSidebarCollapsed(true);
  };

  return (
    <ContentHolderRoot>
      <MarketplaceProductProvider>
        <Screen
          isSidebarCollapsed={isSidebarCollapsed}
          onToggleSidebarCollapsed={onToggleSidebarCollapsed}
          header={
            <Layer>
              <Header
                isBurgerMenuVisible={isNavbarBurgerMenuVisible}
                onExpand={() => setIsSidebarCollapsed(false)}
                name={currentPageInfo?.title as string}
                tabs={pageTabs}
                products={pageProducts}
              />
            </Layer>
          }
          footer={<Footer />}
          sidebar={
            isNavbarBurgerMenuVisible ? (
              <Layer>
                <SidebarMobile
                  onPressClose={onCollapseMobileSidebar}
                  headerActions={
                    <>
                      {settingsLink && (
                        <Knob
                          size={ButtonSize.s}
                          variant={ButtonVariant.ghost}
                          icon={IconName.Cog}
                          onPress={() => {
                            setIsSidebarCollapsed(true);
                            history.push(settingsLink);
                          }}
                        />
                      )}
                      <Knob
                        size={ButtonSize.s}
                        variant={ButtonVariant.ghost}
                        icon={themeMode === 'light' ? IconName.Moon : IconName.Sun}
                        onPress={() => {
                          setTheme(themeMode === 'light' ? 'dark' : 'light');
                          emitter.emit('trading-screen-theme-changed');
                          setIsSidebarCollapsed(true);
                        }}
                      />
                      {entity.config?.helpEnable && (
                        <Knob
                          size={ButtonSize.s}
                          variant={ButtonVariant.ghost}
                          icon={IconName.QuestionMarkCircle}
                          onPress={() => {
                            openHelp();
                          }}
                        />
                      )}
                    </>
                  }
                  footer={
                    <Link
                      color={LinkColor.Danger}
                      onPress={() => {
                        logout();
                      }}
                      startIcon={IconName.Logout}
                      margin={spacing(8)}
                    >
                      Logout
                    </Link>
                  }
                >
                  <Layer>
                    <StyledProfileCard
                      margin={spacing(8)}
                      variant={CardVariant.Bordered}
                      footer={
                        <CopyInput
                          prefix={
                            <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                              Address:
                            </Text>
                          }
                          className="flex-1"
                          marginHorizontal={spacing(8)}
                          marginVertical={spacing(4)}
                          svgColor={TextColor.secondary}
                          variant={TypographyVariant.body2}
                          text={getAccountAddress()}
                          length={10}
                          align={TextAlign.start}
                        />
                      }
                    >
                      <ListItem
                        prefix={
                          <Avatar
                            initials={[userProfile.first_name, userProfile.last_name]
                              .map((str: string) => str[0])
                              .join('')}
                          />
                        }
                        isReadOnly
                        title={getFullName()}
                        description={userProfile.account_type}
                      />
                    </StyledProfileCard>
                  </Layer>
                  <MobileMenu
                    onChangeRoute={() => {
                      setIsSidebarCollapsed(true);
                    }}
                    items={toSidebarMenuProps({ routingConfig: finalConfig, locationInfo })}
                  />
                </SidebarMobile>
              </Layer>
            ) : (
              <SidebarContainer
                isHeaderVisible
                routingConfig={finalConfig}
                locationInfo={locationInfo}
                isExpandable={
                  pageTab?.isExpandable !== undefined ? pageTab.isExpandable : currentPageInfo?.isExpandable
                }
                isExpanded={!isSidebarCollapsed}
              />
            )
          }
          onChangeIsMobileSidebar={onChangeIsMobileSidebar}
        >
          <WebAnnouncement />
          {pageComponent}
        </Screen>
      </MarketplaceProductProvider>
    </ContentHolderRoot>
  );
};

const StyledProfileCard = styled(Card)`
  overflow: hidden;
`;

const toSidebarMenuProps = (props: { routingConfig: MapItem[]; locationInfo: UrlInfo }): SideBarMenuProps[] => {
  const { routingConfig, locationInfo } = props;

  return routingConfig
    .map((route) => {
      if (route.type === 'link' && route.sideBarConfig) {
        const isMatch = route.page.path ? matchUrlAndQuery(route.page, locationInfo) : false;
        const isMatchTab = route.page.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
        const pathAndQuery = getPathAndQuery(route.page);
        return {
          path: pathAndQuery.path,
          query: pathAndQuery.query,
          title: route.sideBarConfig.name,
          icon: route.sideBarConfig.icon,
          type: 'link',
          isSelected: route.page.isSelected ? route.page.isSelected?.(locationInfo) : isMatch || isMatchTab,
        };
      }
      if (route.type === 'list' && route.sideBarConfig) {
        return {
          title: route.sideBarConfig.name,
          icon: route.sideBarConfig.icon,
          type: 'list',
          list: route.list
            .filter((item) => !handleBooleanProp(item.isDisabled))
            .map((item) => {
              const isMatch = item.path ? matchUrlAndQuery(item, locationInfo) : false;
              const isMatchTab = item.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
              const pathAndQuery = getPathAndQuery(item);
              return {
                path: pathAndQuery.path,
                query: pathAndQuery.query,
                title: item.name || item.title,
                isSelected: item.isSelected ? item.isSelected?.(locationInfo) : isMatch || isMatchTab,
              };
            }),
        };
      }
      if (route.type === 'sidebar-separator') {
        return {
          type: 'Separator',
        };
      }

      return null;
    })
    .filter((item) => item) as SideBarMenuProps[];
};

const SidebarContainer: React.FunctionComponent<{
  isExpandable?: boolean;
  isExpanded?: boolean;
  isHeaderVisible: boolean;
  onCollops?: () => void;
  routingConfig: MapItem[];
  locationInfo: UrlInfo;
}> = React.memo(({ isExpandable, isExpanded, onCollops, routingConfig, locationInfo, isHeaderVisible }) => {
  const sidebarMenu = toSidebarMenuProps({ routingConfig, locationInfo });

  return (
    <SideBar
      isExpandable={isExpandable}
      isExpanded={isExpanded}
      onCollops={onCollops}
      menu={sidebarMenu}
      isHeaderVisible={isHeaderVisible}
    />
  );
});

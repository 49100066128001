import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryCache, useMutation, useQuery } from 'react-query';
import { Input } from 'refreshed-component/atoms/Input';
import Loading from 'refreshed-component/molecules/Loading';
import * as yup from 'yup';

import {
  Button,
  ButtonType,
  Select,
  Text,
  TextAs,
  TextColor,
  ToastVariant,
  Tooltip,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import { FeeType, formatter, helpers, logger, projects } from '@aircarbon/utils-common';
import type { Asset, Pair } from '@aircarbon/utils-common/src/dto';

import MetadataFilters from 'pages/account/carbon/components/MetadataFiltersFromIndexer';
import Simplebar from 'pages/account/trading/components/Simplebar';
import Slider from 'pages/account/trading/components/Slider';
import { useTokenPrice } from 'pages/account/trading/hooks';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FormDevTool from 'components/FormDevTool';
import SelectOboAccount, { type AccountDetails } from 'components/SelectOboAccount';
import CloseXButton from 'components/styled/CloseXButton';
import { InputError } from 'components/styled/Styled';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useAvailableBalance from 'hooks/useAvailableBalance';
import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { convertTextNumberToValue } from 'utils/helpers';

import { CriteriaFields, Inventory, InventoryItem, Wrapper } from './styled';

const { WEB_INDEXER_API } = process.env;

const { carbonProjectMetadataSchema } = projects;

const rfqFormSchema = (minQty: number, lotToTons: number, minPrice: number, maxPrice: number) =>
  yup.object().shape({
    hasFilter: yup.string().test('hasFilter', 'Please apply at least one criteria', function hasFilter() {
      return Object.keys(this.parent.metaData ?? []).some(
        (fieldKey: string) => this.parent.metaData?.[fieldKey]?.length,
      );
    }),
    quantity: yup
      .number()
      .required()
      .min(minQty, `Quantity must be greater than or equal to ${minQty}`)
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .test('hasEnoughAmount', 'Insufficient funds', function hasEnoughAmount(quantity: any) {
        const { price, currencyBalance, fee } = this.parent;
        if (price <= 0) return true;

        return Number(quantity * lotToTons * price) + fee <= Number(currencyBalance);
      }),
    price: yup
      .number()
      .required()
      .moreThan(0, 'Please enter price')
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .test('hasValidPrice', 'Invalid price', function hasValidPrice(price: any) {
        return minPrice <= Number(price) && Number(price) <= maxPrice;
      }),
  });

type FormValues = {
  quantity: number;
  price: number;
  currencyBalance: number;
  fee: number;
  ccyTokenId: number;
  minPrice: number;
  maxPrice: number;
  hasFilter: number;
  fillType: {
    value: string;
    label: string;
  };
  currency: {
    value: string;
    label: string;
  };
  tokenType: {
    value: string;
    label: string;
  };
  metaData: Record<string, any>;
};

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  ccyTypeId?: number;
  minQty: number;
  activeTokens: Array<Asset>;
  pairsWatchList?: Partial<Pair[]>;
}

export interface InventoryResponse {
  inventory: InventoryResult;
}

export interface InventoryResult {
  byTokenType: ByTokenType[];
}

export interface ByTokenType {
  tokTypeId: number;
  totalQty: number;
}

const RequestForQuoteForm: React.FC<Props> = ({
  onSubmit,
  onClose,
  minQty,
  ccyTypeId = 1,
  activeTokens,
  pairsWatchList,
}) => {
  const [error] = useState<string>();
  const {
    selector: { mainCcyScId, mainCcyCode, mainCcyNumDecimals, mainCcySymbol },
  } = Entity.useContainer();

  const pairMapper: Record<string, any> =
    pairsWatchList?.reduce((prev, curr) => {
      return {
        ...prev,
        [curr?.baseAsset?.scId ?? 0]: {
          id: curr?.id,
          pairId: curr?.pairId,
          baseAsset: curr?.baseAsset,
          limitUpPercentage: curr?.settings?.limitUpPercentage || 10,
          limitDownPercentage: curr?.settings?.limitDownPercentage || 10,
        },
      };
    }, {}) ?? {};

  // Enabled RFQ Filters (Only these filters will be shown on the form).
  // TODO: add this to utils-common/projects.ts file
  const enabledRFQFilters = [
    'TXT_BEZERO_RATING',
    'TXT_REGISTRY',
    'LIST_COUNTRY',
    'TXT_UN_SECTORAL_SCOPE',
    'TXT_SDG_DECLARATION_LEVEL',
    'LIST_SDG_CONTRIBUTIONS',
    'TXT_CARBON_MITIGATION_TYPE',
    'CALCULATED_VINTAGE_YEAR',
  ];

  const { screenSize } = UI.useContainer();

  const {
    selector: { getAuthToken, getUserId, getAccountAddress, getFullName },
    status: { canTradeRFQ, canTradeRfqObo },
  } = User.useContainer();
  const availableToken = activeTokens?.filter(
    (token) => pairsWatchList?.find((pair) => pair?.baseAsset?.scId === token.scId)?.marketFlags?.showInRfq === 1,
  );
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const [selectedToken, setSelectedToken] = useState<number>(availableToken?.[0]?.scId ?? 0);
  const { isLoadingTokenPrice, tokenPrice } = useTokenPrice({
    pairId: pairMapper?.[selectedToken]?.pairId,
    type: 'BUY',
  });

  const selectedPair = pairsWatchList?.find((pair) => pair?.baseAsset?.scId === selectedToken);
  const lotToTons = selectedPair?.baseAsset?.lotQtySize ?? 1;

  // Used for Members
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const priceStep = 1 * 10 ** -(mainCcyNumDecimals ?? 2);

  let minStlPrice = 0;
  let maxStlPrice = 0;

  if (tokenPrice?.settlementPrice) {
    minStlPrice = Number(
      (tokenPrice?.settlementPrice * (100 - pairMapper?.[selectedToken]?.limitDownPercentage)) / 100,
    );
    minStlPrice = minStlPrice < priceStep ? priceStep : minStlPrice;

    maxStlPrice = Number(
      (tokenPrice?.settlementPrice * (100 + pairMapper?.[selectedToken ?? 1]?.limitUpPercentage)) / 100,
    );
    maxStlPrice = maxStlPrice < tokenPrice?.settlementPrice ? tokenPrice?.settlementPrice + priceStep : maxStlPrice;
  }
  minStlPrice = Number(formatter.formatNumber(minStlPrice, mainCcyNumDecimals));
  maxStlPrice = Number(formatter.formatNumber(maxStlPrice, mainCcyNumDecimals));

  const { balance: selectedAccountBalance, isLoading: isLoadingAccountBalance } = useAvailableBalance({
    params: { ccyTypeId: mainCcyScId, getRoot: false, userId: selectedAccount?.userId },
    options: { enabled: !!mainCcyScId },
  });

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(rfqFormSchema(minQty, lotToTons, minStlPrice, maxStlPrice)),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      currencyBalance: selectedAccountBalance?.balance,
      fee: 0,
      quantity: 0,
      price: 0,
      tokenType:
        availableToken?.map((tokType) => {
          const name = `${tokType?.fullName} (${tokType?.symbol})`;
          return {
            label: name,
            value: tokType.scId.toString(),
          };
        })[0] ?? {},
      minPrice: minStlPrice,
      maxPrice: maxStlPrice,
      metaData: enabledRFQFilters.reduce((obj: Record<string, Array<string>>, filter) => {
        obj[filter] = [];
        return obj;
      }, {}),
    },
  });

  const quantity = convertTextNumberToValue(watch('quantity')) * lotToTons;
  const price = convertTextNumberToValue(watch('price'));

  const debouncedTokenQty = useDebounce(quantity, 500);
  const debouncedPrice = useDebounce(price, 500);
  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.TRADE_RFQ_FEE,
      assetCategoryId: availableToken?.find((token) => token.scId === selectedToken)?.assetCategoryId,
      tokenQty: debouncedTokenQty,
      totalAmount: debouncedTokenQty * debouncedPrice,
    },
    options: { enabled: debouncedTokenQty > 0 && debouncedPrice > 0 },
  });

  // set balance for RFQ form for validation
  useEffect(() => {
    if (selectedAccountBalance?.balance) setValue('currencyBalance', selectedAccountBalance?.balance);
  }, [selectedAccountBalance?.balance]);

  // set balance for RFQ form for validation
  useEffect(() => {
    if (feeAmount) setValue('fee', feeAmount);
  }, [feeAmount]);

  // indexer api to fetch distinct inventory filters
  const fetchRFQFilters = async (url: string) => {
    return fetch(`${url}?${helpers.serialize({ filters: enabledRFQFilters })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp: Response) => resp.json());
  };

  // TODO: Refine visually correct Labels for Filter Values / Refine data in Indexer
  const { data } = useQuery(`${WEB_INDEXER_API}/inventory-filters`, fetchRFQFilters);
  const filters: any = {};
  if (data) {
    enabledRFQFilters.forEach((filterKey) => {
      const inventoryFilter = Object.keys(data).find((key: string) => key === filterKey);
      if (inventoryFilter) {
        // Get metadataSchema to order filter options
        const filterSchema = carbonProjectMetadataSchema.find((schema: { key: string }) => schema.key === filterKey);
        let sortedValues = data[inventoryFilter];
        if (filterSchema?.options) {
          const options = filterSchema.options;
          if (filterKey === 'LIST_COUNTRY') {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === projects.countryNames[a]) -
                options.findIndex((option: { label: string }) => option.label === projects.countryNames[b])
              );
            });
          } else if (filterKey === 'TXT_BEZERO_RATING') {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === projects.bezeroRatings[a]) -
                options.findIndex((option: { label: string }) => option.label === projects.bezeroRatings[b])
              );
            });
          } else {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === a) -
                options.findIndex((option: { label: string }) => option.label === b)
              );
            });
          }
        } else {
          sortedValues = sortedValues.sort();
        }
        filters[filterKey] = sortedValues
          .filter((value: any) => value !== 'undefined')
          .map((value: any) => {
            let label = String(value);
            if (filterKey === 'LIST_COUNTRY') {
              label = projects.countryNames[value];
            }
            if (filterKey === 'TXT_BEZERO_RATING') {
              label = projects.bezeroRatings[value] ?? label;
            }

            return { label, value };
          });
      }
    });
  }

  const {
    data: { inventory } = {},
  } = useQuery<InventoryResponse>([`${WEB_INDEXER_API}/inventory`, watch('metaData')], (url: string, metaData: any) => {
    const metadataString = Object.keys(metaData || {})
      .filter((key) => metaData[key]?.length > 0)
      .flatMap(
        (key) =>
          metaData[key]?.map(
            (criteriaObj: Record<string, any>) => `${key}[]=${encodeURIComponent(criteriaObj.value)}`,
          ) ?? [],
      )
      .join('&');

    logger.debug('getSupply', metadataString);
    if (metadataString !== '') url += `?${metadataString}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp: Response) => resp.json());
  });

  const [mutate] = useMutation(async (formData: FormValues) => {
    const detail: Array<{
      filterName: string;
      filterValue: string;
    }> = [];

    enabledRFQFilters.forEach((keyName: any) => {
      formData.metaData?.[keyName]?.forEach((item: { value: any }) => {
        detail.push({
          filterName: keyName,
          filterValue: item.value,
        });
      });
    });

    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    return fetch(`/api/account/user/${selectedAccount.userId}/rfq`, {
      method: 'POST',
      body: JSON.stringify({
        quantity: Number(formData.quantity),
        price: Number(formData.price),
        ccyTypeId,
        tokenTypeId: Number(formData.tokenType.value),
        fillType: 'ALLOW_PARTIAL', // For now just Allow Partial
        detail,
      }),
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
  });

  const onSubmitHandler = async (formData: FormValues, evt: any) => {
    try {
      logger.warn(formData, 'onSubmitHandler');
      const data = await mutate({ ...formData, quantity: convertTextNumberToValue(formData.quantity) * lotToTons });
      if (data?.ok) {
        showToast({
          variant: ToastVariant.Success,
          message: 'Your bid has been placed.',
        });
        queryCache.invalidateQueries('account-balance');
        onSubmit();
      } else {
        logger.warn(data);
        const error = await data?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
        logger.warn({ error });
      }
    } catch (error) {
      showToast({
        variant: ToastVariant.Danger,
        message: (error as Error).message,
      });
    }
  };

  if (isLoadingMarketSettings || isLoadingTokenPrice) return <Loading />;
  if (error) return <div className="error">{error}</div>;
  return (
    <Simplebar>
      <Wrapper screenSize={screenSize}>
        {marketSettings?.rfqOrderEntryEnabled === 0 && (
          <div className="container p-1 mr-4 ml-4 bg-red-500">Placing BID is disabled</div>
        )}
        <FormDevTool control={control} />
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <input type="hidden" id="currencyBalance" {...register('currencyBalance')} />
          <input type="hidden" id="minPrice" {...register('minPrice')} />
          <input type="hidden" id="maxPrice" {...register('maxPrice')} />
          <input type="hidden" id="hasFilter" {...register('hasFilter')} />
          <input type="hidden" id="ccyTokenId" {...register('ccyTokenId')} />
          <div className="flex flex-wrap w-full min-h-full">
            <div className="w-full formTitle">
              <Text variant={TypographyVariant.h6Title}>Place Bid by Project Criteria</Text>
            </div>
            <CloseXButton onClick={onClose} />
            <div className="flex-1">
              <CriteriaFields screenSize={screenSize}>
                <div className={`grid ${screenSize === 'small' ? 'grid-cols-1' : 'grid-cols-2'} p-large gap-base`}>
                  <MetadataFilters control={control} enabledFilters={enabledRFQFilters} />
                </div>
                <Inventory screenSize={screenSize} className="w-full">
                  <Text variant={TypographyVariant.body2}>Supply Based on criteria selected</Text>
                  {availableToken?.map((tokType) => {
                    const tokenSupply = inventory?.byTokenType.find(
                      (typeSupply) => Number(typeSupply.tokTypeId) === Number(tokType.scId),
                    );

                    const qty = (tokenSupply?.totalQty ?? 0) / tokType?.uom?.scRatio;
                    return (
                      <InventoryItem
                        key={`inventory-item-${tokType.scId}`}
                        className={formatter.formatNumber(qty, 0) !== '0' ? 'available' : 'not-available'}
                        onClick={() => {
                          const name = `${tokType?.fullName} (${tokType?.symbol})`;
                          setValue('tokenType', {
                            label: name,
                            value: tokType.scId.toString(),
                          });
                          setSelectedToken(tokType.scId);
                          setValue('minPrice', Number(formatter.formatNumber(minStlPrice, mainCcyNumDecimals)));
                          setValue('maxPrice', Number(formatter.formatNumber(maxStlPrice, mainCcyNumDecimals)));
                          setValue('price', 0);
                        }}
                        type="button"
                      >
                        <div className="tokenName">{tokType.symbol}</div>
                        <div>{formatter.formatNumber(qty, 0) !== '0' ? 'Available' : 'Not Available'}</div>
                      </InventoryItem>
                    );
                  })}
                </Inventory>
              </CriteriaFields>
            </div>
            <div className={`relative ${screenSize === 'small' || screenSize === 'medium' ? 'w-full' : 'max-w-md'}`}>
              <div className="flex flex-col gap-base p-large">
                {canTradeRfqObo() && (
                  <div className="flex flex-col w-full gap-xs">
                    <Text variant={TypographyVariant.body2}>On Behalf Of</Text>
                    <SelectOboAccount
                      name="OBO"
                      inputValue={selectedAccount.account}
                      setFieldValue={(_selectedField, value) => {
                        setSelectedAccount({ account: value.value, userId: value.user_id, fullName: value.fullName });
                      }}
                    />
                    <div className="flex">
                      <Text variant={TypographyVariant.caption}>Available Balance:</Text>
                      <div className="relative pl-2">
                        {isLoadingAccountBalance ? (
                          <Loading size="small" />
                        ) : (
                          <Text as={TextAs.span}>
                            {mainCcyCode}
                            {formatter.formatNumber(selectedAccountBalance?.availableAmount ?? 0, mainCcyNumDecimals)}
                          </Text>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col w-full gap-xs">
                  <Select
                    label="Asset Type"
                    items={
                      availableToken?.map((tokType) => {
                        const name = `${tokType?.fullName} (${tokType?.symbol})`;
                        return {
                          title: name,
                          value: tokType.scId.toString(),
                        };
                      }) || []
                    }
                    value={getValues('tokenType').value}
                    onChange={({ value }) => {
                      const selectedItem = availableToken?.find((token) => token.id === Number(value));

                      if (selectedItem)
                        setValue('tokenType', {
                          label: selectedItem.name,
                          value: selectedItem.id.toString(),
                        });
                      setSelectedToken(Number(selectedItem?.id));
                      setValue('minPrice', Number(formatter.formatNumber(minStlPrice, mainCcyNumDecimals)));
                      setValue('maxPrice', Number(formatter.formatNumber(maxStlPrice, mainCcyNumDecimals)));
                      setValue('price', 0);
                      setValue('price', 0);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-base">
                  <div className="flex flex-col w-full gap-xs">
                    <Text variant={TypographyVariant.body2}>
                      Quantity (Lots)
                      <Tooltip value={`One lot is equal to 1000 ${availableToken?.[0]?.uom?.name}.`}>
                        <svg
                          className="inline-block ml-1 w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </Tooltip>
                    </Text>
                    <Input
                      name="quantity"
                      config={{
                        color: 'gray',
                        validation: {
                          type: 'integer',
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                      }}
                      onChange={(event) => {
                        setValue('quantity', Number((event?.target as any)?.rawValue || 0), {
                          shouldValidate: true,
                        });
                      }}
                      value={getValues('quantity')}
                      placeholder="Enter Quantity"
                      autoComplete="off"
                    />
                    {errors.quantity && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.quantity.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-col w-full gap-xs">
                    <Text variant={TypographyVariant.body2}>Bid Price</Text>
                    <Input
                      name="price"
                      config={{
                        color: 'gray',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                      }}
                      onChange={(event) => {
                        setValue('price', (event?.target as any)?.rawValue, {
                          shouldValidate: true,
                        });
                      }}
                      value={getValues('price')}
                      placeholder="Enter Price"
                      autoComplete="off"
                    />
                    {errors.price && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.price.message}
                      </Text>
                    )}
                  </div>
                  <Slider
                    side="Buy"
                    selectedValue={Number(price)}
                    divider={6}
                    showDivider={true}
                    onChange={(value) => {
                      if (value)
                        setValue('price', Number.parseFloat(value.toString()).toFixed(2) as any, {
                          shouldValidate: true,
                        });
                    }}
                    list={[
                      {
                        label: !isNaN(minStlPrice) ? formatter.formatNumber(minStlPrice, mainCcyNumDecimals) : '-',
                        value: !isNaN(minStlPrice)
                          ? Number(formatter.formatNumber(minStlPrice, mainCcyNumDecimals))
                          : undefined,
                      },
                      {
                        label:
                          tokenPrice?.settlementPrice && !isNaN(tokenPrice?.settlementPrice)
                            ? formatter.formatNumber(tokenPrice?.settlementPrice, mainCcyNumDecimals)
                            : '-',
                        value:
                          tokenPrice?.settlementPrice && !isNaN(tokenPrice?.settlementPrice)
                            ? Number(formatter.formatNumber(tokenPrice?.settlementPrice, mainCcyNumDecimals))
                            : undefined,
                      },
                      {
                        label: !isNaN(maxStlPrice) ? formatter.formatNumber(maxStlPrice, mainCcyNumDecimals) : '-',
                        value: !isNaN(maxStlPrice)
                          ? Number(formatter.formatNumber(maxStlPrice, mainCcyNumDecimals))
                          : undefined,
                      },
                    ]}
                  />
                </div>
                <div className="mt-2">
                  <div className="flex flex-row justify-between">
                    <Text variant={TypographyVariant.body2}>Bid Amount: </Text>
                    <Text variant={TypographyVariant.subtitle2}>
                      {mainCcyCode}
                      {formatter.formatNumber(quantity * price, mainCcyNumDecimals)} {mainCcySymbol}
                    </Text>
                  </div>
                  <div className="flex flex-row justify-between">
                    <Text variant={TypographyVariant.body2}>
                      Fee:{isLoadingFeeAmount && <Loading color={TextColor.secondary} isOverLay={false} size="small" />}
                    </Text>
                    <Text variant={TypographyVariant.body2}>
                      {mainCcyCode}
                      {formatter.formatNumber(feeAmount, mainCcyNumDecimals)} {mainCcySymbol}
                    </Text>
                  </div>
                  <div className="flex flex-row justify-between">
                    <Text variant={TypographyVariant.body2}>Total:</Text>
                    <Text variant={TypographyVariant.body2}>
                      {mainCcyCode}
                      {formatter.formatNumber(quantity * price + feeAmount, mainCcyNumDecimals)} {mainCcySymbol}
                    </Text>
                  </div>
                </div>
                {isSubmitting && Object.keys(errors).length === 0 && (
                  <div className="block fixed top-0 left-0 z-50 w-full h-full bg-gray-200 opacity-50">
                    <Loading />
                  </div>
                )}
                {canTradeRFQ() && (
                  <Button
                    className="mt-6 mb-4 section-btn"
                    type={ButtonType.Submit}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || marketSettings?.rfqOrderEntryEnabled === 0 || isLoadingFeeAmount}
                  >
                    Place Bid
                  </Button>
                )}
                {errors.hasFilter && <InputError>{errors.hasFilter.message}</InputError>}
                <ul className="list-square">
                  <Text variant={TypographyVariant.body2}>Bids can be partially filled </Text>
                  <Text variant={TypographyVariant.body2}>
                    Please make sure your account holds sufficient funds to settle the purchase. If your balance drops
                    below the bid's amount it will be automatically cancelled.
                  </Text>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
    </Simplebar>
  );
};

export default RequestForQuoteForm;

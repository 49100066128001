import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CopyInput } from 'refreshed-component/atoms/CopyInput';

import {
  IconName,
  Tabs,
  TabsSize,
  TabsVariant,
  Text,
  TextAlign,
  TextColor,
  TypographyVariant,
  styled,
  toLayerBackground,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';

import { getTheme, setTheme } from 'pages/account/trading/components/ThemeMode';

import { User, logout } from 'state/user';

import { useSettingsLink } from 'hooks/useSettingsLink';

import emitter from 'utils/emitter';

export type ContentHolderProps = {};
const ProfileMenuRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  > .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => toSpacing(theme)(2)};
    align-self: stretch;
    white-space: pre;
  }
  > .item {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(6)};
    white-space: pre;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const ProfileMenu = () => {
  const theme = getTheme();
  const { spacing } = useSpacing();
  const {
    selector: { getFullName, getUserProfile, getAccountAddress },
  } = User.useContainer();
  const settingsLink = useSettingsLink();
  const history = useHistory();
  const userProfile = getUserProfile();
  const themesList = useMemo(() => {
    if (!!window.matchMedia) {
      return [
        {
          id: 'dark',
          icon: IconName.Moon,
          label: 'Dark',
        },
        {
          id: 'light',
          icon: IconName.Sun,
          label: 'Light',
        },
        {
          id: 'system',
          label: 'System',
        },
      ];
    }

    return [
      {
        id: 'dark',
        icon: IconName.Moon,
        label: 'Dark',
      },
      {
        id: 'light',
        icon: IconName.Sun,
        label: 'Light',
      },
    ];
  }, []);

  return (
    <ProfileMenuRoot>
      <div className="flex flex-col title gap-small">
        <Text variant={TypographyVariant.subtitle1}>{getFullName()}</Text>
        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
          {userProfile.account_type}
        </Text>
        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
          {userProfile.user_name}
        </Text>
      </div>
      {getAccountAddress() && (
        <StyledAddressCopyWrapper>
          <CopyInput
            prefix={
              <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                Address:
              </Text>
            }
            className="flex-1"
            marginHorizontal={spacing(8)}
            marginVertical={spacing(4)}
            color={TextColor.primary}
            svgColor={TextColor.secondary}
            variant={TypographyVariant.body2}
            text={getAccountAddress()}
            length={10}
            align={TextAlign.center}
          />
        </StyledAddressCopyWrapper>
      )}
      <StyledTabs
        activeItemId={theme}
        variant={TabsVariant.Contained}
        size={TabsSize.m}
        onPressTab={(tab) => {
          setTheme(tab.id);
          emitter.emit('trading-screen-theme-changed');
        }}
        items={themesList}
      />
      {settingsLink && (
        <div
          onClick={() => {
            history.push(settingsLink);
          }}
          className="item"
        >
          <Text>Settings</Text>
        </div>
      )}

      <div
        onClick={async () => {
          await logout();
        }}
        className="item"
      >
        <Text color={TextColor.error}>Logout</Text>
      </div>
    </ProfileMenuRoot>
  );
};

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const StyledAddressCopyWrapper = styled.div`
  position: relative;
  margin: 0 -${({ theme }) => toSpacing(theme)(8)};
  width: calc(100% + ${({ theme }) => toSpacing(theme)(16)});
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
`;

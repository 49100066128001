import { queryCache, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Countdown from 'refreshed-component/atoms/CountDown';
import Loading from 'refreshed-component/molecules/Loading';
import { PageNavigationHolder } from 'refreshed-component/molecules/PageNavigation';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import {
  Badge,
  Card,
  CardColor,
  Layer,
  Text,
  TextAs,
  TextColor,
  TypographyVariant,
  styled,
  toLayerBackground,
} from '@aircarbon/ui';
import { formatter, logger } from '@aircarbon/utils-common';

import useCurrencies from 'hooks/useCurrencies';

import { fetchMyCMBAsk } from 'data-provider/market-board/fetchMyCMBAsk';

import { formatDate } from 'utils/helpers';

import { MyListingAskBids } from './MyListingAskBids';
import { askStatusLabel } from './MyListingAsks';

export const MyListingAskInfo = () => {
  const { askId } = useParams<{ askId: string }>();
  const askUrl = `/api/user/carbon/my-cmb-ask/${askId}`;
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  const { data: ask, isLoading } = useQuery(askUrl, async () => {
    try {
      const response = await fetchMyCMBAsk(askId);
      return response;
    } catch (error) {
      logger.error(error);
    }
  });

  if (!ask) <Loading />;

  const tokenAsset = ask?.__tokenAsset__;
  const tokenUnit = tokenAsset?.__uom__?.name ?? '';
  const ccyAsset = ask && currenciesObjById?.[ask?.quoteAssetId];

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageNavigationHolder
            list={[
              {
                label: 'Marketplace',
                path: '/account/auctions/marketplace',
              },
              {
                label: 'My Listings',
                path: '/account/auctions/my-listings',
              },
              {
                label: `Project #${askId}`,
              },
            ]}
          />
          <Layer>
            {ask && (
              <MarketPlaceProjectCard
                info={{
                  carbonProject: { ...ask?.__carbonProject__, vintageYear: ask?.carbonProjectVintageYear },
                  id: `Project #${ask?.id}`,
                  carbonProjectVintageYear: ask?.carbonProjectVintageYear,
                }}
                type="white"
                controls={
                  <>
                    <div className="flex flex-row justify-between whitespace-pre gap-base">
                      <Badge value={askStatusLabel(ask)} />
                    </div>
                    <div className="flex flex-row justify-between whitespace-pre gap-base">
                      <div className="flex flex-col gap-xs">
                        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                          Price ({ccyAsset?.symbol})
                        </Text>
                        <Text variant={TypographyVariant.h5Title}>
                          {ccyAsset?.code}
                          {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                        </Text>
                      </div>
                      <div className="flex flex-col gap-xs">
                        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                          Listed Qty ({ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                        </Text>
                        <Text variant={TypographyVariant.h5Title}>
                          {formatter.formatNumber(ask?.quantity - ask?.openBidsTotalQty, 0)}{' '}
                        </Text>
                      </div>
                    </div>
                    <StyledCountdownCard
                      color={CardColor.Field}
                      footer={
                        ask.status === 'LIST' && (
                          <CountdownContainer className="p-base">
                            <Countdown
                              date={new Date(ask.endDate)}
                              endLabel={'This auction has ended.'}
                              onZero={() => queryCache.invalidateQueries(askUrl)}
                            />
                          </CountdownContainer>
                        )
                      }
                    >
                      <div className="flex flex-col justify-between whitespace-pre">
                        <div className="flex flex-col p-base">
                          <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                            Started on:{' '}
                            <Text as={TextAs.span} variant={TypographyVariant.subtitle2}>
                              {formatDate(ask.startDate)}
                            </Text>
                          </Text>
                          <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                            Ends on:{' '}
                            <Text as={TextAs.span} variant={TypographyVariant.subtitle2}>
                              {formatDate(ask.endDate)}
                            </Text>
                          </Text>
                        </div>
                      </div>
                    </StyledCountdownCard>
                  </>
                }
              />
            )}
          </Layer>
        </PageSections>
        <PageSections>
          <SummaryHolder>
            <SummaryCard title={'Total Bids'} value={ask?.__carbonEmbBids__.length.toString() ?? ''} />
            <SummaryCard
              title={'Accepted Bids'}
              value={
                ask?.__carbonEmbBids__
                  // TODO: Add dto enum for bids status
                  .filter((item) => ['CONFIRMED_BY_SELLER', 'DONE'].includes(item.status?.toUpperCase()))
                  .length.toString() ?? ''
              }
            />
            <SummaryCard
              title={'Open Quantity'}
              value={ask?.openBidsTotalQty ? formatter.formatNumber(ask.openBidsTotalQty, 0) : '-'}
            />
          </SummaryHolder>
        </PageSections>
        <PageSections>
          {ask && <MyListingAskBids ask={ask} bids={ask?.__carbonEmbBids__} tokenUnit={tokenUnit} />}
          {isLoading && (
            <div className="p-large">
              <Loading isOverLay={false} />
            </div>
          )}
        </PageSections>
      </PageHolder>
    </>
  );
};

const StyledCountdownCard = styled(Card)`
  overflow: hidden;
`;

const CountdownContainer = styled.div`
  background: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
`;

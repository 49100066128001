import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createContainer } from 'unstated-next';

import { DesktopGridLayout } from './layouts/GridLayout';
import MediumDesktopLayout from './layouts/MediumDesktopLayout';
import MobileLayout from './layouts/MobileLayout';
import TabletLayout from './layouts/TabletLayout';
import TradingHook from './layouts/trading.hook';

export interface TradingProps {
  projectId?: string;
  projectName?: string;
  assetCategoryId?: string;
}

export const TradingHookContainer = createContainer<ReturnType<typeof TradingHook>, TradingProps>((initialState) => {
  return TradingHook(initialState);
});

const Trading: React.FC<TradingProps> = (props) => {
  const [viewPort, setViewPort] = useState<string | undefined>();

  useEffect(() => {
    const onResize = () => {
      const viewPortTypes = ['sm', 'md', 'lg-2', 'lg-1', 'xl', '2xl'];
      const viewPortWidth = [640, 768, 930, 1024, 1280, 1536];
      const width = window.innerWidth - 1;
      // eslint-disable-next-line array-callback-return
      const viewPortIndex = viewPortWidth.findIndex((from, index) => {
        const to = viewPortWidth[index + 1];
        if ((width > from && width < to) || width === to || (width > from && to === undefined)) {
          return true;
        }
      });
      const foundViewPort = viewPortTypes[viewPortIndex === -1 ? 0 : viewPortIndex + 1];
      if (foundViewPort !== viewPort) {
        setViewPort(foundViewPort);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layout = () => {
    if (viewPort && ['sm'].includes(viewPort)) {
      return <MobileLayout viewPort={viewPort} />;
    }
    if (viewPort && ['md', 'lg-2'].includes(viewPort)) {
      return <TabletLayout viewPort={viewPort} />;
    }
    if (viewPort && ['lg-1'].includes(viewPort)) {
      return <MediumDesktopLayout viewPort={viewPort} />;
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <TradingHookContainer.Provider initialState={props}>
          <DesktopGridLayout />
        </TradingHookContainer.Provider>
      </DndProvider>
    );
  };

  return <>{layout()}</>;
};

export default Trading;

import type React from 'react';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, ButtonSize, ButtonVariant, IconName, Text, TypographyVariant, styled, toSpacing } from '@aircarbon/ui';

const PageNavigationRoot = styled.div`
  gap: ${({ theme }) => toSpacing(theme)(4)};
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  > .path {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(4)};
    justify-content: center;
    align-items: center;
    width: min-content;
  }
  > .back {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(4)};
    cursor: pointer;
  }
`;

interface Item {
  label: string | ReactNode;
  path?: string;
}

interface Props {
  list: Item[];
  suffix?: React.ReactNode;
}

export const PageNavigationHolder = (props: Props) => {
  // TODO: Pavlo this needs to be properly implemented. Even discuss with Nour to come to agreement. I think we should have the name of the project (or any item) as title as I did here, replacing the GO BACK action.
  const history = useHistory();
  const secondLastItem = props.list.length >= 2 ? props.list[props.list.length - 2] : undefined;
  const lastItem = props.list[props.list.length - 1];
  return (
    <StyledPageNavigationHolder>
      <PageNavigationRoot>
        <div className="path">
          {props.list.map((item, index, list) => {
            const isLastItem = index === list.length - 1;
            const isSecondLastItem = index === list.length - 2;
            if (isLastItem) return null;

            return (
              <Button
                isDisabled={isLastItem}
                variant={ButtonVariant.ghost}
                size={ButtonSize.xs}
                onPress={() => {
                  if (item.path) history.push(item.path);
                }}
                startIcon={!isSecondLastItem ? IconName.ChevronStart : undefined}
              >
                {item.label}
              </Button>
            );
          })}
        </div>
        {secondLastItem && (
          <div
            className="my-2 back"
            onClick={() => {
              if (secondLastItem.path) history.push(secondLastItem.path);
            }}
          >
            <Text variant={TypographyVariant.h4Title}>{lastItem.label}</Text>
          </div>
        )}
      </PageNavigationRoot>
      {props.suffix}
    </StyledPageNavigationHolder>
  );
};

const StyledPageNavigationHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => toSpacing(theme)(12)};

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'refreshed-component/atoms/Input';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import {
  Alert,
  AlertColor,
  AlertVariant,
  Button,
  Checkbox,
  IconName,
  Text,
  TextColor,
  ToastVariant,
  Toggle,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import { CmbStatus, formatter, logger } from '@aircarbon/utils-common';

import type { CmbAsk } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FormDevTool from 'components/FormDevTool';
import Loading from 'components/styled/Loading';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';

import { convertTextNumberToValue } from 'utils/helpers';

import type { FormData } from './AddAskProjectForm';

type Props = {
  ask: CmbAsk;
  onSubmit: () => void;
  lotToTons: number;
  minLotQty: number;
  maxLotQty: number;
  bidMinLots: number;
};

const editAskFormSchema = ({
  minQty,
  maxQty,
  minBidQty,
  bidQtyMultiplier,
}: {
  minQty: number;
  maxQty: number;
  minBidQty: number;
  bidQtyMultiplier: number;
}) =>
  yup.object().shape({
    quantity: yup
      .number()
      .required()
      .min(minQty, `Quantity must be greater than or equal to ${minQty}.`)
      .max(maxQty, `Maximum quantity is ${formatter.formatNumber(maxQty, 0)}.`)
      .test('isInMultiplesOf', `Should be multiple of ${bidQtyMultiplier}`, function isMultipleOf(quantity: any) {
        if (bidQtyMultiplier && quantity) {
          return quantity % bidQtyMultiplier === 0;
        }
        return true;
      })
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      }),
    price: yup
      .number()
      .required()
      .moreThan(0, 'Please enter price')
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      }),
    minBidQty: yup
      .number()
      .required('Please enter minimum bid quantity')
      .min(minBidQty, `Minimum bid quantity must be greater than or equal to ${formatter.formatNumber(minBidQty, 0)}.`)
      .test('isInMultiplesOf', `Should be multiple of ${bidQtyMultiplier}`, function isMultipleOf(quantity: any) {
        if (bidQtyMultiplier && quantity) {
          return quantity % bidQtyMultiplier === 0;
        }
        return true;
      }),
    maxBidQty: yup
      .number()
      .required('Please enter maximum bid quantity')
      .test('maxQty', 'Maximum quantity should be more than minimum quantity', function hasMaxQty(quantity: any) {
        return quantity >= minBidQty;
      })
      .test('maxQty', 'Maximum quantity should be lower than available quantity', function hasMaxQty(quantity: any) {
        return quantity <= this.parent.quantity;
      })
      .test('isInMultiplesOf', `Should be multiple of ${bidQtyMultiplier}`, function isMultipleOf(quantity: any) {
        if (bidQtyMultiplier && quantity) {
          return quantity % bidQtyMultiplier === 0;
        }
        return true;
      }),
  });

const EditAskForm = ({ ask, onSubmit, bidMinLots, minLotQty, maxLotQty, lotToTons }: Props) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const [changedStatus, setChangedStatus] = useState<CmbStatus | undefined>(undefined);

  const cmbOfferLabel = getSetting('web_settings_cmb_offer_label') ?? 'Market Board';
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
  const tokenUnit = ask?.__tokenAsset__?.__uom__?.code;
  const minQty = minLotQty * lotToTons;
  const maxQty = maxLotQty * lotToTons;
  const minBidQty = bidMinLots * lotToTons;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    resolver: yupResolver(editAskFormSchema({ minQty, maxQty, minBidQty, bidQtyMultiplier: ask.bidQtyMultiplier })),
    defaultValues: {
      askId: ask?.id,
      price: ask?.price,
      status: ask?.status,
      maxBidQty: ask?.maxBidQty ?? 0,
      minBidQty: ask?.minBidQty ?? 0,
      quantity: ask.quantity,
      disallowPartialFilling: ask.disallowPartialFilling,
      isAuction: ask.isAuction,
      isListed: ask.status === 'LIST',
    },
  });

  const onSubmitHandler = async (formData: FormData) => {
    try {
      const askData: Record<string, any> = {
        ...formData,
        quantity: convertTextNumberToValue(formData.quantity),
        price: Number(formData.price),
        status: changedStatus ? changedStatus : ask.status,
        disallowPartialFilling: formData.disallowPartialFilling ? 1 : 0,
      };

      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const updateAsk = await fetch('/api/user/carbon/cmb-ask', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(askData),
      });

      if (updateAsk?.ok) {
        showToast({
          variant: ToastVariant.Success,
          message: `${cmbOfferLabel} project listing updated.`,
        });
        onSubmit();
      } else {
        logger.warn(updateAsk);
        const error = await updateAsk?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
        logger.warn({ error });
      }
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error?.message ?? 'Something went wrong!',
      });
    }
  };
  watch();
  if (isLoadingMarketSettings) return <Loading />;

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
      <FormDevTool control={control} />
      <ModalContent>
        {marketSettings?.otcEditEnabled === 0 && (
          <Alert variant={AlertVariant.Secondary} color={AlertColor.Danger} title="OTC Edit is disabled" />
        )}

        <div className="flex flex-col gap-base">
          {[CmbStatus.LIST as string, CmbStatus.UNLIST as string].includes(ask.status) && (
            <div className="flex flex-col w-full sm:flex-row gap-base">
              <Toggle
                label="Listed"
                isOn={getValues('isListed')}
                onChange={(isOn) => {
                  setValue('isListed', isOn);
                  setChangedStatus(isOn ? CmbStatus.LIST : CmbStatus.UNLIST);
                }}
              />
            </div>
          )}
          <div className="flex flex-col w-full sm:flex-row gap-base">
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>
                Available Quantity (Min: {formatter.formatNumber(minQty, 0)} {ask?.__tokenAsset__?.__uom__?.code})
              </Text>
              <Input
                config={{
                  color: 'gray',
                  size: 'base',
                  validation: {
                    type: 'integer',
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                  },
                  postfix: tokenUnit,
                }}
                value={getValues('quantity')}
                onChange={(event) => {
                  setValue('quantity', (event?.target as any)?.rawValue || undefined, {
                    shouldValidate: true,
                  });
                }}
              />
              {errors.quantity?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.quantity.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Price Per {ask?.__tokenAsset__?.__uom__?.code}</Text>
              <Input
                config={{
                  color: 'gray',
                  size: 'base',
                  validation: {
                    type: 'float',
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                  },
                  postfix: ccyAsset?.symbol,
                }}
                value={getValues('price')}
                onChange={(event) => {
                  setValue('price', ((event?.target as any)?.rawValue as any) || undefined, {
                    shouldValidate: true,
                  });
                }}
              />
              {errors.price?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.price.message}
                </Text>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full sm:flex-row gap-base">
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Minimum Bid Qty</Text>
              <Input
                config={{
                  color: 'gray',
                  size: 'base',
                  validation: {
                    type: 'float',
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                  },
                  postfix: tokenUnit,
                }}
                value={getValues('minBidQty')}
                onChange={(event) => {
                  setValue('minBidQty', (event?.target as any)?.rawValue || undefined, {
                    shouldValidate: true,
                  });
                }}
              />
              {errors.minBidQty?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.minBidQty.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col flex-1 gap-xs">
              <Text variant={TypographyVariant.body2}>Maximum Bid Qty</Text>
              <Input
                config={{
                  color: 'gray',
                  size: 'base',
                  validation: {
                    type: 'float',
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                  },
                  postfix: tokenUnit,
                }}
                value={getValues('maxBidQty')}
                onChange={(event) => {
                  setValue('maxBidQty', (event?.target as any)?.rawValue || undefined, {
                    shouldValidate: true,
                  });
                }}
              />
              {errors.maxBidQty?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.maxBidQty.message}
                </Text>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-xs">
            <Checkbox
              isChecked={getValues('disallowPartialFilling') === 1}
              label="Disallow Partial Filling"
              onChange={({ isChecked }) => setValue('disallowPartialFilling', isChecked ? 1 : 0)}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button className="flex-1" isLoading={isSubmitting} endIcon={IconName.ArrowEnd}>
          Submit
        </Button>
      </ModalFooter>
    </form>
  );
};

export default EditAskForm;

import Loading from 'refreshed-component/molecules/Loading';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { Table } from 'refreshed-component/templates/Table';

import { Badge, Card, Layer, Pagination, Tabs, styled, toSpacing } from '@aircarbon/ui';

import type { GERHistoryData } from './hooks/GERHistoryData';
import { useGERHistory } from './hooks/useGERHistory';
import { toBadgeVariant } from './utils/toBadgeVariant';
import { toTableColumns } from './utils/toTableColumns';

export const GERHistory = () => {
  const {
    history,
    meta: { assetsAllocationsMap },
    pagination,
    isLoading,
    changePagination,
  } = useGERHistory();

  const tableColumns = toTableColumns({ history, assetsAllocationsMap });
  const tableRows = toTableRows({ history });

  const onChangePagination = (page: number, pageSize: number) => {
    changePagination({
      currentPage: page,
      perPage: pageSize,
    });
  };

  return (
    <>
      <PageHeader title="History" margin="24px" />
      <Layer>
        <StyledCard
          header={
            <Tabs
              activeItemId="retirement"
              onPressTab={() => {}}
              items={[
                {
                  id: 'retirement',
                  label: 'Retirement',
                },
              ]}
            />
          }
        >
          {!!pagination.total && (
            <div>
              <Pagination
                currentPage={pagination.currentPage}
                pagesCount={Math.ceil(pagination.total / pagination.perPage)}
                onChange={(currentPage) => {
                  onChangePagination(currentPage, pagination.perPage);
                }}
              />
            </div>
          )}

          <div className="relative">
            {isLoading ? (
              <Loading isOverLay={false} />
            ) : (
              <Table
                config={{
                  columns: tableColumns,
                  rows: tableRows,
                }}
              />
            )}
          </div>

          {!!pagination.total && (
            <div>
              <Pagination
                currentPage={pagination.currentPage}
                pagesCount={Math.ceil(pagination.total / pagination.perPage)}
                onChange={(currentPage) => {
                  onChangePagination(currentPage, pagination.perPage);
                }}
              />
            </div>
          )}
        </StyledCard>
      </Layer>
    </>
  );
};

const toTableRows = (props: { history: GERHistoryData }) => {
  const numberFormatter = new Intl.NumberFormat('en-US');
  switch (props.history.operation) {
    case 'pack':
      return props.history.history.map((item, index) => ({
        _key: '' + index,
        id: item.id,
        packedGer: '+' + numberFormatter.format(item.packedGer),
        gerBefore: numberFormatter.format(item.gerBefore),
        gerAfter: numberFormatter.format(item.gerAfter),
        bcc: '-' + numberFormatter.format(item.bcc),
        fcc: '-' + numberFormatter.format(item.fcc),
        pcc: '-' + numberFormatter.format(item.pcc),
        ccc: '-' + numberFormatter.format(item.ccc),
        submittedOn: new Intl.DateTimeFormat('en-US', {
          timeStyle: 'medium',
          dateStyle: 'medium',
        }).format(item.submittedOn),
        status: <Badge value={item.status.label} />,
      }));
    case 'unpack':
      return props.history.history.map((item, index) => ({
        _key: '' + index,
        id: item.id,
        unpackedGer: '+' + numberFormatter.format(item.unpackedGer),
        gerBefore: numberFormatter.format(item.gerBefore),
        gerAfter: numberFormatter.format(item.gerAfter),
        bcc: '-' + numberFormatter.format(item.bcc),
        fcc: '-' + numberFormatter.format(item.fcc),
        pcc: '-' + numberFormatter.format(item.pcc),
        ccc: '-' + numberFormatter.format(item.ccc),
        submittedOn: new Intl.DateTimeFormat('en-US', {
          timeStyle: 'medium',
          dateStyle: 'medium',
        }).format(item.submittedOn),
        status: <Badge value={item.status.label} />,
      }));
    case 'retirement':
      return props.history.history.map((item, index) => {
        const badgeSettings = toBadgeVariant(item.status.value);

        return {
          _key: '' + index,
          batchId: item.batchId,
          gerQty: numberFormatter.format(item.gerQty),
          bcc: numberFormatter.format(item.bcc),
          fcc: numberFormatter.format(item.fcc),
          pcc: numberFormatter.format(item.pcc),
          ccc: numberFormatter.format(item.ccc),
          submittedOn: new Intl.DateTimeFormat('en-US', {
            timeStyle: 'medium',
            dateStyle: 'medium',
          }).format(item.submittedOn),
          status: <Badge variant={badgeSettings} value={item.status.label} />,
        };
      });
  }
};

const StyledCard = styled(Card)`
  margin: 0 24px;
  height: auto;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

import { useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, useHistory } from 'react-router-dom';

import {
  Card,
  Layer,
  Tabs,
  TabsOrientation,
  TabsSize,
  TabsVariant,
  Text,
  TypographyVariant,
  styled,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

import Simplebar from 'pages/account/trading/components/Simplebar';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useTokenTypes from 'hooks/useTokenTypes';

import { fetchAvailableBalance } from 'data-provider/user/fetchAvailableBalance';

import RFQAllResponses from '../../pages/account/RFQ/AllResponses';
import BalanceSummary from '../../pages/account/RFQ/BalanceSummary';
import RequestForQuoteForm from '../../pages/account/RFQ/Form';
import RFQList from '../../pages/account/RFQ/List';
import { BalanceSummaryWrapper, PricesWrapper, Sidebar, Wrapper } from '../../pages/account/RFQ/Styled';
import TokenPrices from '../../pages/account/RFQ/TokenPrices';
import { useEntityTokenPrice, usePairs } from '../../pages/account/trading/hooks';

export const RFQ = () => {
  const {
    selector: { getAccountAddress, getUserId },
    status: { canTradeRFQ },
  } = User.useContainer();

  const { getSetting, screenSize } = UI.useContainer();
  const {
    selector: { mainCcyScId },
  } = Entity.useContainer();
  const [selectedRFQ, setSelectedRFQ] = useState<number | null>(null);
  const history = useHistory();
  const { isLoadingEntityTokenPrice, entityTokenPrice } = useEntityTokenPrice({
    type: 'BUY',
  });
  const { activeTokenTypesNonGER } = useTokenTypes({});

  const activeTab = history.location.pathname;

  const { pairs: pairsWatchList } = usePairs({
    assetCategories: ['token'],
    includeMarketData: true,
  });

  const { data: accountBalance } = useQuery(['account-balance'], async () => {
    try {
      const response = await fetchAvailableBalance({ getRootBalance: 'no', ccyTypeId: mainCcyScId });
      return response;
    } catch (error) {
      console.log(error);
    }
  });

  const onFormSubmit = () => {
    history.push('/account/carbon-finder/my-bids');
  };

  const balanceAndPricing = (
    <>
      <PricesWrapper screenSize={screenSize}>
        <StyledBoxTitleText variant={TypographyVariant.subtitle1}>Allowed Bid Pricing Range</StyledBoxTitleText>
        <TokenPrices
          isLoadingEntityTokenPrice={isLoadingEntityTokenPrice}
          entityTokenPrice={entityTokenPrice}
          activeTokens={activeTokenTypesNonGER()}
          pairsWatchList={pairsWatchList}
        />
      </PricesWrapper>
      <BalanceSummaryWrapper>
        <StyledBoxTitleText variant={TypographyVariant.subtitle1}>My Balance</StyledBoxTitleText>
        <Layer>
          <BalanceSummary
            tokenTypes={activeTokenTypesNonGER()}
            tokens={accountBalance?.tokens ?? []}
            balance={accountBalance?.balance ?? 0}
            available={accountBalance?.availableAmount ?? 0}
            openOrders={accountBalance?.openOrders ?? []}
            pendingTransactionAmount={accountBalance?.pendingTransactionAmount ?? 0}
            pendingTransactionTokens={accountBalance?.pendingTransactionTokens ?? []}
          />
        </Layer>
      </BalanceSummaryWrapper>
    </>
  );

  return (
    <Wrapper screenSize={screenSize}>
      <Layer>
        <Sidebar screenSize={screenSize}>
          <Simplebar
            content={{
              minHeight: '100%',
              position: 'absolute',
            }}
          >
            <SimplebarContent>
              <Header />
              <StyledTabs
                size={TabsSize.m}
                activeItemId={activeTab}
                onPressTab={(tab) => {
                  history.push(tab.id);
                }}
                variant={TabsVariant.Contained}
                orientation={TabsOrientation.Vertical}
                items={[
                  {
                    label: 'Balance & Pricing',
                    id: '/account/carbon-finder/balance-and-pricing',
                  },
                  canTradeRFQ() && {
                    label: 'Place Bid +',
                    id: '/account/carbon-finder/place-bid',
                  },
                  {
                    label: 'My Bids',
                    id: '/account/carbon-finder/my-bids',
                  },
                  {
                    label: 'Bids I Can Fill',
                    id: '/account/carbon-finder/bids-to-me',
                  },
                ].filter((v) => !!v)}
              />
              {!(screenSize === 'small' || screenSize === 'medium') ? (
                balanceAndPricing
              ) : (
                <Route path="/account/carbon-finder/balance-and-pricing">{balanceAndPricing}</Route>
              )}
            </SimplebarContent>
          </Simplebar>
        </Sidebar>
        <MainCard>
          <Route path="/account/carbon-finder">
            {screenSize === 'small' || screenSize === 'medium' ? (
              <Redirect to="/account/carbon-finder/balance-and-pricing" />
            ) : (
              <Redirect to="/account/carbon-finder/my-bids" />
            )}
          </Route>
          {canTradeRFQ() && (
            <Route exact path="/account/carbon-finder/place-bid">
              <RequestForQuoteForm
                ccyTypeId={mainCcyScId}
                minQty={Number(getSetting('global_carbonfinder_bidMinLots') || 1)}
                onSubmit={onFormSubmit}
                onClose={() => {
                  history.push(
                    screenSize === 'small' || screenSize === 'medium'
                      ? '/account/carbon-finder/balance-and-pricing'
                      : '/account/carbon-finder/my-bids',
                  );
                }}
                pairsWatchList={pairsWatchList}
                activeTokens={activeTokenTypesNonGER()}
              />
            </Route>
          )}
          <Route exact path={['/account/carbon-finder/my-bids', '/account/carbon-finder/bids-to-me']}>
            <Route exact path="/account/carbon-finder/my-bids">
              <div className={`mainContent ${selectedRFQ ? 'rfqSelected' : ''}`}>
                <div className="absolute w-full h-full">
                  <Simplebar>
                    <RFQList
                      addr={getAccountAddress()}
                      userId={getUserId()}
                      filter="my-bids"
                      onSelect={setSelectedRFQ}
                      pairsWatchList={pairsWatchList}
                    />
                    <div className="flex flex-col pb-large pl-large pr-large gap-large">
                      <Text>Trade Confirmations</Text>
                      <RFQAllResponses userId={getUserId()} />
                    </div>
                  </Simplebar>
                </div>
              </div>
            </Route>
            <Route exact path="/account/carbon-finder/bids-to-me">
              <div className={`mainContent ${selectedRFQ ? 'rfqSelected' : ''}`}>
                <div className="absolute w-full h-full">
                  <Simplebar>
                    <RFQList
                      addr={getAccountAddress()}
                      userId={getUserId()}
                      filter="bids-to-me"
                      onSelect={setSelectedRFQ}
                      pairsWatchList={pairsWatchList}
                    />
                    <div className="flex flex-col pb-large pl-large pr-large gap-large">
                      <Text>Trade Confirmations</Text>
                      <RFQAllResponses userId={getUserId()} />
                    </div>
                  </Simplebar>
                </div>
              </div>
            </Route>
          </Route>
        </MainCard>
      </Layer>
    </Wrapper>
  );
};

const Header = () => (
  <div className="flex flex-col w-full mb-base gap-xs">
    <Text variant={TypographyVariant.h6Title}>CarbonFinder</Text>
    <Text variant={TypographyVariant.body2}>Bid by project criteria</Text>
  </div>
);

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-bottom: ${({ theme }) => toSpacing(theme)(4)};
`;

const MainCard = styled(Card)`
  overflow: hidden;
  flex-grow: 1;
`;

const SimplebarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledBoxTitleText = styled(Text)`
  background: ${({ theme }) => toLayerBackground(theme)('layerAccent')};
  padding: ${({ theme }) => `${toSpacing(theme)(6)} ${toSpacing(theme)(8)}`};
  border-top-left-radius: ${({ theme }) => theme.system.border.radius.s};
  border-top-right-radius: ${({ theme }) => theme.system.border.radius.s};
`;

import React from 'react';

import { BorderColor, Card, TextColor, styled, toBorderColor, toLayerBackground, toTextColor } from '@aircarbon/ui';

import type { ScreenSizes } from 'state/ui';

export const Capitalize = styled.div`
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const UnitStyle = styled.span`
  font-size: 90%;
`;

export const Toolbar = styled.div<{ screenSize?: ScreenSizes }>`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  position: relative;

  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `
        margin-left: 20px;
        margin-right: 20px;
      `;
    }
  }}

  @media (max-width: 460px) {
    flex-direction: column;
    margin-left: 1rem;
  }
`;

export const PageTitle = styled.h1`
  margin: 3rem 0 2rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
`;
export const Title = styled.h2`
  margin: 0 0 1.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
`;
export const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6;
`;

export const WhiteBox = styled(Card)`
  padding: 2em;
  margin-bottom: 2rem;
`;

export const InnerLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 5px;
  color: #717171;
`;

export const InputLabel = styled.label`
  display: block;
  font-size: 0.85rem;
  margin-bottom: 5px;
  line-height: 1;
  font-weight: 500;
`;
export const LabelDescription = styled.div`
  font-size: 0.75rem;
  font-style: italic;
`;
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 1rem;
  width: 100%;

  label {
    margin: 0 !important;
  }
`;

const InputWrapper = styled.div<{ isQuantityInput?: boolean }>`
  ${({ isQuantityInput }) =>
    isQuantityInput
      ? `
          > div {
            background: ${({ theme }) => toLayerBackground(theme)('field')};
          }
        `
      : ``}

  display: contents;
  input {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    background: ${({ theme }) => toLayerBackground(theme)('field')};
    padding: 12px;
    height: 47px;
    display: inline-flex;
    width: 100%;
    border-radius: ${({ theme }) => theme.system.border.radius.m};
    appearance: none;
    transition:
      background-color 200ms ease-in-out,
      border-color 200ms ease-in-out,
      outline-color 200ms ease-in-out;
    outline: rgba(0, 0, 0, 0) solid 0px !important;

    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')} !important;
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }

    &:hover {
      background: ${({ theme }) => toLayerBackground(theme)('fieldHover')};
    }

    &:focus {
      background: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};

      border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.active)};
    }

    &::placeholder {
      font-weight: 300;
      letter-spacing: 0.5px;
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
  }
`;

export const InputWrapperWithThemeColors = React.forwardRef((props: any, ref: any) => {
  return <InputWrapper {...props} ref={ref} />;
}) as unknown as typeof InputWrapper;

export const InputError = styled.div`
  color: ${({ theme }) => toTextColor(theme)(TextColor.error)};
  font-size: 13px;
`;

export const StyledLink2 = styled.span`
  cursor: pointer;
  text-decoration: underline;
  display: block;
`;

export const ErrorMessage = styled.div`
  background: red;
  color: white;
  padding: 5px 1em;
  border-radius: 30px;
`;

export const TransactionTable = styled.table`
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
  padding: 10px;
  text-align: left;

  th {
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    border: 0 !important;
    padding: 15px 1.5rem;
  }

  tr {
    height: 100%;
    &.expanded {
      background: ${({ theme }) => toLayerBackground(theme)('layerAccent')};

      .innerLabel {
        color: #ededed;
      }
    }

    &:not(.expanded) {
      border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }
  }

  td {
    text-overflow: unset;
    border-left: 0;
    border-right: 0;
    position: relative;
    padding: 1rem 1.5rem;
    height: 100%;

    & > div:not(.blockchain-detail) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .blockchain-detail {
      display: inline-flex;
      flex-direction: row;
      font-size: 0.85rem;

      & > div {
        margin-right: 1rem;
      }
    }
  }

  .tx-name {
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
  }

  .tx {
    width: 25%;
    min-width: 250px;

    .txInfo {
      width: 100%;
    }

    .txDetail {
      font-size: 1.2rem;
      font-weight: 200;

      .price {
        font-weight: 400;
      }

      .unit {
        font-size: 0.9rem;
      }
    }
  }

  .datetime {
    width: 165px;
    min-width: 165px;
  }

  .address {
    width: 25%;
    min-width: 230px;
  }

  .txEvents {
    font-size: 11px;
    color: #828282;
    font-style: italic;
  }

  .innerLabel {
    font-size: 14px;
    text-transform: uppercase;
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    font-weight: 300;
    line-height: 1;
    margin-right: 5px;
  }

  .hiddenName {
    color: ${({ theme }) => toTextColor(theme)(TextColor.secondary)};
    font-style: italic;
  }

  .id {
    min-width: 80px;
  }
  .tokenName {
    min-width: 300px;
  }

  .tableActions {
    min-width: 120px;
    height: 100%;
    overflow: auto;
    padding: 0;
    position: relative;
  }

  .tableButton {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    width: 100%;
    height: 100%;
    transition: all 0.2s;

    &:first-child {
      margin-left: 0;
    }
  }

  .numEvents {
    font-size: 12px;
    text-transform: none;
    margin-top: 3px;

    button {
      text-transform: uppercase;
      background: #caecdc;
      color: black;
      font-size: 10px;
      border-radius: 7px;
      padding: 3px 5px;
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-right: 3px;
    }
  }

  .expandedInfo {
    padding: 1em 3em;
    background: #e8e8e8;

    .title {
      text-transform: uppercase;
      font-weight: 500;
    }

    .metaWrapper {
      border: 1px solid #ededed;
      padding: 1rem 0;
    }

    .expandedRow {
      border-top: 1px solid #ededed;
      border-color: #9fb9ac;
      padding: 1em 0;

      .address {
        max-width: 170px;
        min-width: 170px;
      }

      &:first-child {
        border-top: 0;
      }

      & > div {
        &:not(:first-child) {
          margin-left: 3em;
        }
      }

      .expandedEventName {
        width: 180px;
      }

      .expandedEventValue {
        font-weight: 500;
      }
    }

    .tokenType {
      max-width: 200px;

      .expandedEventValue {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 460px) {
    display: block;
    overflow-x: auto;
  }
`;

import { type ApiResponse, externalApi } from '../fetcher';

export type FooterMenuItem = {
  id: string;
  name: string;
  url: string;
  openInNewPage: boolean;
};

export type BrandingEntity = {
  bankAccount: string;
  theme: {
    admin: {
      favFile: string;
      logoUrl: string;
      brandColor: string;
      brandRbgColor: string;
      backgroundColor: string;
      secondaryBackgroundColor: string;
      secondaryColor: string;
    };
  };
  config?: {
    name: string;
    phone: string;
    address: string;
    description: string;
    helpEnable: string;
    registerLink: string;
    email: {
      info: string;
      support: string;
      onboarding: string;
      operations: string;
      trusteeFiat: string;
    };
    footer: {
      loggedIn: FooterMenuItem[];
      loggedOut: FooterMenuItem[];
    };
  };
};

export const fetchBrandingEntity = () => {
  const res = externalApi.get('/entity');
  return res.json<
    ApiResponse<{
      entity: BrandingEntity;
    }>
  >();
};

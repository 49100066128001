import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';

import { Select, Text, TypographyVariant } from '@aircarbon/ui';
import { helpers, projects } from '@aircarbon/utils-common';

import useTokenTypes from 'hooks/useTokenTypes';

const { carbonProjectMetadataSchema } = projects;
const { WEB_INDEXER_API } = process.env;

type Props = {
  control: any;
  enabledFilters: Array<string>;
  withTokenType?: boolean;
};

const MetadataFiltersFromIndexer: React.FC<Props> = ({ control, enabledFilters, withTokenType }) => {
  const { tokenTypes } = useTokenTypes({ options: { enabled: withTokenType } });
  const fetchRFQFilters = async (url: string) => {
    return fetch(`${url}?${helpers.serialize({ filters: enabledFilters })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp: Response) => resp.json());
  };

  const { data } = useQuery(`${WEB_INDEXER_API}/inventory-filters`, fetchRFQFilters);
  const filters: any = {};
  if (data) {
    enabledFilters.forEach((filterKey) => {
      const inventoryFilter = Object.keys(data).find((key: string) => key === filterKey);
      if (inventoryFilter) {
        // Get metadataSchema to order filter options
        const filterSchema = carbonProjectMetadataSchema.find((schema: { key: string }) => schema.key === filterKey);
        let sortedValues = data[inventoryFilter];
        if (filterSchema?.options) {
          const options = filterSchema.options;
          if (filterKey === 'LIST_COUNTRY') {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === projects.countryNames[a]) -
                options.findIndex((option: { label: string }) => option.label === projects.countryNames[b])
              );
            });
          } else if (filterKey === 'TXT_BEZERO_RATING') {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === projects.bezeroRatings[a]) -
                options.findIndex((option: { label: string }) => option.label === projects.bezeroRatings[b])
              );
            });
          } else {
            sortedValues = data[inventoryFilter].sort((a: string, b: string) => {
              return (
                options.findIndex((option: { label: string }) => option.label === a) -
                options.findIndex((option: { label: string }) => option.label === b)
              );
            });
          }
        } else {
          sortedValues = sortedValues.sort();
        }
        filters[filterKey] = sortedValues
          .filter((value: any) => value !== 'undefined')
          .map((value: any) => {
            let label = String(value);
            if (filterKey === 'LIST_COUNTRY') {
              label = projects.countryNames[value];
            }
            if (filterKey === 'TXT_BEZERO_RATING') {
              label = projects.bezeroRatings[value] ?? label;
            }

            return { label, value };
          });
      }
    });
  }

  return (
    <>
      {filters &&
        Object.keys(filters).map((key) => (
          <div key={`filter-div-${key}`}>
            <div className="flex flex-col gap-xs">
              <Controller
                name={`metaData.${key}` as any}
                key={`controller-${key}`}
                control={control}
                render={({ field: { name, onBlur, ref, ...field } }) => {
                  return (
                    <Select
                      label={projects.projectLabel[key]}
                      isMultiple
                      value={field.value.join(',')}
                      items={filters[key]
                        .map((filter) => {
                          return {
                            value: String(filter.value),
                            title: filter.label,
                          };
                        })
                        .filter((f) => !!f.title && !!f.value)}
                      onChange={({ value }) => {
                        field.onChange(value.split(','));
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
        ))}
      {withTokenType && tokenTypes && (
        <div className={`mb-2 w-1/2`}>
          <div className="flex flex-col gap-xs">
            <Text variant={TypographyVariant.body2}>Asset Type</Text>
            <Controller
              name="tokenType"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  items={
                    tokenTypes.map((tokType) => {
                      const name = `${tokType.fullName} (${tokType.symbol})`;
                      return {
                        title: name,
                        value: String(tokType.id),
                      };
                    }) ?? []
                  }
                  onChange={({ value }) => onChange(Number(value))}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MetadataFiltersFromIndexer;

import { useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import { Empty } from 'refreshed-component/atoms/Empty';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Input } from 'refreshed-component/atoms/Input';
import AddAskProjectForm from 'refreshed-component/forms/AddAskProjectForm';
import EditAskForm from 'refreshed-component/forms/EditAskForm';
import { FilterDropdown, type FilterRadioBox, FilterSelections } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import Modal from 'refreshed-component/molecules/Modal';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import {
  Badge,
  Button,
  ButtonVariant,
  Card,
  Icon,
  IconName,
  Layer,
  Pagination,
  Text,
  TextColor,
  TypographyVariant,
} from '@aircarbon/ui';
import { type AssetCategory, formatter } from '@aircarbon/utils-common';

import type { MyEmbAsk } from 'pages/account/carbon/CMB/types';
import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';
import { usePagination } from 'pages/account/trading/hooks/usePagination';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useCurrencies from 'hooks/useCurrencies';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { fetchMyCMBAsks } from 'data-provider/market-board';

import { toAssetMinMaxLots } from 'utils/toAssetMinMaxLots';

export const askStatusLabel = (ask: MyEmbAsk) => {
  if (ask?.status === 'NEW' || ask.__carbonProject__?.statusCode === 'NEW') return 'PENDING APPROVAL';
  if (ask?.status === 'REJECTED' || ask.__carbonProject__?.statusCode === 'REJECTED') return 'REJECTED';
  switch (ask.status) {
    case 'LIST':
      return 'LISTED';

    case 'UNLIST':
      return 'UNLISTED';
  }
  return ask.status;
};

export const MyListingAsks = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const { product } = useMarketplaceProduct();
  const [isListNewProjectModalVisible, setIsListNewProjectModalVisible] = useState(false);

  const {
    status: { canBidCmbAsk, canEditCmbAsk, canManageCmbBid },
  } = User.useContainer();

  const { getSetting } = UI.useContainer();

  const { askMinLots, askMaxLots, bidMinLots } = toAssetMinMaxLots({
    assetCategory: Number(product),
    getSetting,
  });
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const pagination = usePagination();
  const tokenUnit = getSetting('web_settings_tokenUnit');
  const cmbOfferLabel = getSetting('web_settings_cmb_offer_label') ?? 'Market Board';
  const filters: {
    status: FilterRadioBox;
  } = {
    status: {
      type: 'radio-box',
      label: 'Status',
      list: [
        {
          id: 'listed',
          label: 'Listed Projects',
        },
        {
          id: 'requests',
          label: 'Projects With Bids',
        },
        {
          id: 'unlisted',
          label: 'Unlisted',
        },
        {
          id: 'new',
          label: 'Pending Approval',
        },
        {
          id: 'rejected',
          label: 'Rejected Projects',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});
  const getAskQueryKey = '/api/user/carbon/my-cmb-ask';

  const { data, isLoading, refetch } = useQuery(
    [getAskQueryKey, pagination.page, pagination.pageSize, product, filterSelections?.status?.selection, search],
    async () =>
      fetchMyCMBAsks({
        page: pagination.page,
        limit: pagination.pageSize,
        assetCategoryId: product,
        status: filterSelections?.status?.selection || 'all',
        isAuction: 'no',
        search,
      }),
  );

  const asks = data?.items;

  if (isLoadingMarketSettings) return <Loading />;

  const onPressListNewProject = () => {
    setIsListNewProjectModalVisible(true);
  };

  const onCloseNewProjectModal = () => {
    setIsListNewProjectModalVisible(false);
  };

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="My Listings"
          controls={{
            secondary: (
              <>
                {canBidCmbAsk() && (
                  <div className="flex flex-row justify-start">
                    <Button
                      isDisabled={marketSettings?.otcEntryEnabled === 0}
                      className="flex-1"
                      onPress={onPressListNewProject}
                      startIcon={IconName.PlusCircle}
                    >
                      List New Offer
                    </Button>
                    <Modal
                      title={'List A New Project Offer'}
                      isOpen={isListNewProjectModalVisible}
                      onClose={onCloseNewProjectModal}
                    >
                      {({ onClose, onLoading }) => {
                        return (
                          <AddAskProjectForm
                            minLotQty={askMinLots}
                            maxLotQty={askMaxLots}
                            bidMinLots={bidMinLots}
                            isProjectOffer={true}
                            onSuccess={onClose}
                            onLoading={(isLoading) => onLoading(isLoading)}
                            refetchAsks={refetch}
                            assetCategoryId={Number(product) as AssetCategory}
                          />
                        );
                      }}
                    </Modal>
                  </div>
                )}
              </>
            ),
          }}
        />
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <PageControls
            controls={{
              primary: (
                <>
                  <Input
                    placeholder="Search"
                    config={{
                      size: 'base',
                      color: 'gray',
                      postfix: (
                        <HitArea
                          width={20}
                          height={20}
                          className="cursor-pointer"
                          onClick={() => {
                            setSearch('');
                          }}
                        >
                          <Icon name={IconName.X} size="0.625rem" />
                        </HitArea>
                      ),
                      prefix: <Icon name={IconName.Search} size="0.875rem" />,
                    }}
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value || '');
                    }}
                  />
                  <FilterDropdown
                    selections={filterSelections}
                    onChange={(value) => setFilterSelections(value)}
                    list={filters}
                  />
                </>
              ),
              secondary:
                data?.total > 1 ? (
                  <Pagination
                    currentPage={pagination.page}
                    pagesCount={Math.ceil(data?.total / pagination.pageSize)}
                    onChange={(currentPage) => {
                      pagination.setPage(currentPage);
                    }}
                  />
                ) : undefined,
            }}
          />
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          <Layer>
            <div className="flex flex-col w-full gap-large">
              {asks &&
                (Array.isArray(asks) ? asks : [])
                  .filter((ask) => {
                    return search
                      ? ask?.__carbonProject__?.name
                          ?.toLocaleLowerCase()
                          ?.startsWith?.(search.trim().toLocaleLowerCase())
                      : true;
                  })
                  .map((ask) => {
                    const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
                    const newBids = ask?.__carbonEmbBids__?.filter((bid: { status: string }) => bid.status === 'NEW');
                    const confirmedBids = ask?.__carbonEmbBids__?.filter((bid: { status: string }) =>
                      ['CONFIRMED_BY_SELLER', 'DONE'].includes(bid.status),
                    );
                    const project = { ...ask?.__carbonProject__, vintageYear: ask?.carbonProjectVintageYear };
                    const isObo = ask.userId !== ask.createdBy; // if bid was placed by member
                    const tokenAsset = (ask.__tokenAsset__ as any | undefined) || {};
                    return (
                      <MarketPlaceProjectCard
                        key={ask.id}
                        info={{
                          onClick: () => {
                            canManageCmbBid() && history.push(`/account/market-board/my-listings/${ask.id}`);
                          },
                          tokenAsset,
                          carbonProject: project,
                          id: `Project #${ask.id}`,
                          carbonProjectVintageYear: ask?.carbonProjectVintageYear,
                          indicators: [
                            `New Bids: ${newBids?.length || 0}`,
                            `Accepted Bids: ${confirmedBids?.length || 0}`,
                          ],
                        }}
                        controls={
                          <>
                            <div className="flex flex-row justify-between whitespace-pre gap-base">
                              <Badge value={askStatusLabel(ask)} />
                            </div>

                            <div className="flex flex-row justify-between whitespace-pre gap-base">
                              <div className="flex flex-col gap-xs">
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Price ({ccyAsset?.symbol})
                                </Text>
                                <Text variant={TypographyVariant.h5Title}>
                                  {ccyAsset?.code}
                                  {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                                </Text>
                              </div>
                              <div className="flex flex-col gap-xs">
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Listed Qty ({ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                                </Text>
                                <Text variant={TypographyVariant.h5Title}>
                                  {formatter.formatNumber(ask?.quantity - ask?.openBidsTotalQty, 0)}{' '}
                                </Text>
                              </div>
                            </div>
                            <Layer>
                              <Card className=" w-full p-small gap-3xs">
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Initial: {formatter.formatNumber(ask?.quantity, 0)}{' '}
                                  {ask?.__tokenAsset__?.__uom__?.code || tokenUnit}
                                </Text>
                                <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                  Open: {formatter.formatNumber(ask?.openBidsTotalQty, 0)}{' '}
                                  {ask?.__tokenAsset__?.__uom__?.code || tokenUnit}
                                </Text>
                              </Card>
                            </Layer>

                            <div className="flex flex-col">
                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Account: {ask.__user__?.firstName} {ask.__user__?.lastName} [{ask.userId}]
                              </Text>

                              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                Placed by: {ask.__createdByUser__?.firstName} {ask.__createdByUser__?.lastName} [
                                {ask.createdBy}]
                              </Text>
                            </div>
                            <div className="flex flex-row justify-between whitespace-pre gap-base">
                              {canEditCmbAsk() && (
                                <div className="flex flex-row flex-1 gap-base">
                                  <Modal
                                    title={'Edit Project Offering'}
                                    action={
                                      <Button
                                        className="flex-1"
                                        isDisabled={marketSettings?.otcEditEnabled === 0}
                                        variant={ButtonVariant.outlined}
                                        endIcon={IconName.Pencil}
                                      >
                                        Edit
                                      </Button>
                                    }
                                  >
                                    {({ onClose }) => {
                                      return (
                                        <EditAskForm
                                          lotToTons={ask?.__tokenAsset__?.lotQtySize || 1}
                                          minLotQty={askMinLots}
                                          maxLotQty={askMaxLots}
                                          bidMinLots={bidMinLots}
                                          ask={ask}
                                          onSubmit={() => {
                                            onClose();
                                            queryCache.invalidateQueries(getAskQueryKey);
                                          }}
                                        />
                                      );
                                    }}
                                  </Modal>
                                </div>
                              )}
                              {canManageCmbBid() && (
                                <div className="flex flex-row flex-1 gap-base">
                                  <Button
                                    className="flex-1"
                                    variant={ButtonVariant.secondary}
                                    onPress={() => {
                                      history.push(`/account/market-board/my-listings/${ask.id}`);
                                    }}
                                    endIcon={IconName.Bids}
                                  >
                                    Bids
                                  </Button>
                                </div>
                              )}
                            </div>
                          </>
                        }
                      />
                    );
                  })}
              {(isLoading || isLoadingMarketSettings) && (
                <div className="p-large">
                  <Loading isOverLay={false} />
                </div>
              )}
              {!isLoading && (Array.isArray(asks) ? asks : []).length === 0 && (
                <Empty
                  title="No Listings"
                  description={`You have no listings yet. Your ${cmbOfferLabel} listings will show up here.`}
                />
              )}
              {/* TODO: better control this scenario. Perhaps telling the Pagination component if location is top or bottom. */}
              {/* If total items outside of view show bottom pagination */}
              {(data?.total ?? 0) > 4 && (
                <PageControls
                  controls={{
                    secondary:
                      data?.total > 1 ? (
                        <Pagination
                          currentPage={pagination.page}
                          pagesCount={Math.ceil(data?.total / pagination.pageSize)}
                          onChange={(currentPage) => {
                            pagination.setPage(currentPage);
                          }}
                        />
                      ) : undefined,
                  }}
                />
              )}
            </div>
          </Layer>
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};

import { useContext } from 'react';
import { ResponsiveContainerContext, ResponsiveContainerWrapper } from 'refreshed-component/atoms/ResponsiveContainer';

import {
  BorderColor,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toSpacing,
  toTextColor,
  useBorderColor,
  useLayerBackground,
} from '@aircarbon/ui';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import FooterLinks from './FooterLinks';
import { MarketStatus } from './MarketStatus';

export type FooterProps = { notLogged?: boolean };
const FooterRoot = styled.div<{
  background: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ background }) => background};
  overflow-x: auto;
  > .item {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => toSpacing(theme)(2)} ${({ theme }) => toSpacing(theme)(8)};
    box-sizing: border-box;
    gap: ${({ theme }) => toSpacing(theme)(8)};
    > .section {
      display: flex;
      flex-direction: row;
      gap: ${({ theme }) => toSpacing(theme)(12)};
      flex: 1 1 0;
      justify-content: center;
      min-width: fit-content;
      &:first-child {
        justify-content: start;
      }
      &:last-child {
        justify-content: end;
      }

      > .separator {
        width: 1px;
        align-self: stretch;
        margin-top: ${({ theme }) => toSpacing(theme)(2)};
        margin-bottom: ${({ theme }) => toSpacing(theme)(2)};
        background-color: ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      }
      > .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: ${({ theme }) => toSpacing(theme)(4)};
        svg.coverage {
          path {
            fill: ${({ theme }) => toTextColor(theme)(TextColor.success)} !important;
          }
        }
      }
    }
  }
`;

export const PoweredACXByContent = () => {
  const { borderColor } = useBorderColor();
  return (
    <>
      <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
        Powered by
      </Text>
      <svg width="39" height="15" viewBox="0 0 39 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.25 14.203H19.6729C16.8239 14.203 14.3481 12.4352 13.3571 9.92015V14.2452C13.3571 14.2476 13.3547 14.25 13.3524 14.25H11.6363C11.4094 14.2497 11.1848 14.2046 10.9753 14.1173C10.7659 14.0299 10.5756 13.902 10.4154 13.7409C10.2552 13.5798 10.1282 13.3886 10.0416 13.1782C9.95508 12.9678 9.9107 12.7424 9.91101 12.5149V7.60061C9.91101 5.9472 8.71501 4.49609 7.08549 4.29414C4.98477 4.03362 3.21029 5.81814 3.46931 7.93606C3.66961 9.57755 5.11072 10.7821 6.75416 10.7821H8.39556C8.39793 10.7821 8.40031 10.7845 8.40031 10.7869V14.0031C8.40031 14.1393 8.29066 14.2497 8.15554 14.2497H6.80338C3.10539 14.252 -0.0236294 11.2044 0.000134477 7.48278C0.020843 3.80817 2.98657 0.821139 6.63534 0.797641C9.70121 0.781294 12.3033 2.94383 13.1028 5.80383C13.8001 3.09163 16.1548 1.01594 19.0037 0.774142C22.7156 0.459468 25.8256 3.26089 26.2082 6.8347C26.2127 6.86971 26.2097 6.90528 26.1995 6.93905C26.1893 6.97283 26.172 7.00404 26.1489 7.03065C26.1258 7.05725 26.0973 7.07865 26.0653 7.09342C26.0334 7.10819 25.9987 7.116 25.9635 7.11634H22.7761C22.5758 5.52934 21.2701 4.25634 19.645 4.22127C17.7962 4.18108 16.2855 5.71222 16.3412 7.57644C16.3924 9.34938 17.8919 10.7324 19.6545 10.7324H22.8042C24.7087 10.7324 26.25 12.2867 26.25 14.2027"
          fill={borderColor(BorderColor.active)}
        />
        <path
          d="M38.789 10.7803C38.8468 10.785 38.9007 10.8119 38.9399 10.8554C38.979 10.899 39.0005 10.956 39 11.015V14.2493C36.4614 14.2497 34.2581 12.7871 33.1511 10.6421C33.1367 10.6139 33.115 10.5904 33.0884 10.5739C33.0617 10.5575 33.0312 10.5488 33 10.5488C32.9688 10.5488 32.9383 10.5575 32.9116 10.5739C32.885 10.5904 32.8633 10.6139 32.8489 10.6421C31.7422 12.7871 29.5389 14.25 27 14.25V11.0177C27 10.8941 27.0928 10.7888 27.2137 10.7807C28.8896 10.6682 30.2186 9.2396 30.2186 7.50018C30.2186 5.77977 28.9183 4.36336 27.2684 4.2241C27.1952 4.21779 27.1271 4.18374 27.0774 4.12869C27.0277 4.07364 27.0002 4.00161 27.0003 3.92689V0.750342C29.5399 0.750342 31.7442 2.2143 32.8506 4.36099C32.8649 4.38882 32.8864 4.41213 32.9128 4.42841C32.9392 4.44468 32.9695 4.45328 33.0003 4.45328C33.0312 4.45328 33.0615 4.44468 33.0879 4.42841C33.1143 4.41213 33.1358 4.38882 33.1501 4.36099C34.2561 2.2143 36.4601 0.75034 39 0.75V3.986C39.0005 4.04456 38.9792 4.10116 38.9404 4.14439C38.9015 4.18763 38.8481 4.21429 38.7907 4.21901C37.1128 4.32974 35.7814 5.75905 35.7814 7.49983C35.7814 9.23994 37.1121 10.6689 38.789 10.7803Z"
          fill={'#328FA9'}
        />
      </svg>
    </>
  );
};

export const Footer = ResponsiveContainerWrapper(({ notLogged }: FooterProps) => {
  const { size } = useContext(ResponsiveContainerContext);
  const { layerBackground } = useLayerBackground();
  const { entity } = Entity.useContainer();
  const {
    selector: { getUserId },
  } = User.useContainer();
  const { uiStatus } = UI.useContainer();
  const loggedInFooterItems = entity?.config?.footer?.loggedIn;
  const loggedOutFooterItems = entity?.config?.footer?.loggedOut;

  const isLoggedIn = !(!getUserId() && uiStatus.isReady());
  const isSmallSize = size === 'small' || size === 'xs';

  const poweredByACX = (
    <div className="section">
      <div className="item">
        <PoweredACXByContent />
      </div>
    </div>
  );

  const marketStatus = (
    <div className="item">
      <MarketStatus />
    </div>
  );

  return (
    <FooterRoot background={layerBackground('layer')}>
      <div id="sticky-bottom" />
      <div className="item">
        {isLoggedIn && !notLogged && <div className="section">{!isSmallSize && marketStatus}</div>}
        {!isSmallSize || !isLoggedIn ? poweredByACX : <div className="section">{marketStatus}</div>}
        <div className="section">
          {!isLoggedIn || notLogged ? (
            <FooterLinks items={loggedOutFooterItems || []} />
          ) : (
            <FooterLinks items={loggedInFooterItems || []} />
          )}
        </div>
      </div>
    </FooterRoot>
  );
});

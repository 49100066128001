import { useState } from 'react';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import styled from 'styled-components';

import { Button, ButtonVariant, Card, IconName, Layer } from '@aircarbon/ui';

import { User } from 'state/user';

import { Batches } from './components/Batches';
import { GERBalance } from './components/GERBalance';
import { PackGER } from './components/PackGER';
import { UnpackGER } from './components/UnpackGER';

export const Ger = () => {
  const [isPackGERVisible, setIsPackGERVisible] = useState(false);
  const [isUnpackGERVisible, setIsUnpackGERVisible] = useState(false);
  const {
    status: { canPackGER, canUnpackGER },
  } = User.useContainer();
  const onPressPackGER = () => {
    setIsPackGERVisible(true);
  };

  const onCloseUnpackGER = () => {
    setIsUnpackGERVisible(false);
  };

  const onClosePackGER = () => {
    setIsPackGERVisible(false);
  };

  const onPressUnpackGER = () => {
    setIsUnpackGERVisible(true);
  };

  return (
    <>
      <PageHeader
        title="Dashboard"
        margin="24px"
        suffix={
          <Actions>
            {canUnpackGER() && (
              <Button variant={ButtonVariant.outlined} endIcon={IconName.ViewGrid} onPress={onPressUnpackGER}>
                Unpack GER
              </Button>
            )}
            {canPackGER() && (
              <Button endIcon={IconName.ViewBoards} onPress={onPressPackGER}>
                Pack GER
              </Button>
            )}
          </Actions>
        }
      />
      <Layer>
        <StyledCard>
          <GERBalance />
          <Batches />
        </StyledCard>
      </Layer>

      <UnpackGER isVisible={isUnpackGERVisible} onClose={onCloseUnpackGER} />
      <PackGER isVisible={isPackGERVisible} onClose={onClosePackGER} />
    </>
  );
};

const StyledCard = styled(Card)`
  flex-direction: column;
  margin: 0 24px;
  container-type: inline-size;
  height: auto;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

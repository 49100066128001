import { type ReactNode, useMemo, useState } from 'react';
import { Empty } from 'refreshed-component/atoms/Empty';
import { CorpTransferForm } from 'refreshed-component/forms/CorpTransferForm';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import {
  Button,
  Icon,
  IconName,
  Layer,
  Select,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
  useLayerBackground,
  useTextColor,
} from '@aircarbon/ui';
import { AssetCategory, type AssetCategoryCode, formatter } from '@aircarbon/utils-common';
import type { Pair } from '@aircarbon/utils-common/src/dto';

import { usePairs } from 'pages/account/trading/hooks/usePairs';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAccountBalances from 'hooks/useAccountBalances';

import { getRoleName } from 'utils/helpers';

import { DepositRequestsContainer } from './deposit-requests/components/DepositRequestsContainer';
import { DepositRequestsContextProvider } from './deposit-requests/components/DepositRequestsContainer/components/DepositRequestsContextProvider';

const { formatNumber } = formatter;

const TokenContainer = styled.div`
  width: 44px;
  height: 44px;
`;

const ControlContainer = styled.div`
  > * {
    min-width: 200px;
  }
`;

export const Dashboard = () => {
  const { accountUsers, consolidatedTokens, isLoading } = Account.useContainer();
  const {
    selector: { getUserId },
    status: {
      canTransferAssets,
      checkHasDepositPermission,
      canDeposit: checkIfCanDeposit,
      canAccessRecs,
      canAccessAssetsDepositFeature,
    },
  } = User.useContainer();
  const { textColor } = useTextColor();
  const { layerBackground } = useLayerBackground();
  const isRecsEnabled = canAccessRecs();
  const isAssetsDepositFeatureEnabled = canAccessAssetsDepositFeature();
  const canDeposit = checkIfCanDeposit();
  const hasDepositPermission = checkHasDepositPermission();

  const assetCategories = useMemo(() => {
    if (isRecsEnabled) {
      return [AssetCategory[AssetCategory.token], AssetCategory[AssetCategory.rec]];
    }

    return [AssetCategory[AssetCategory.token]];
  }, [isRecsEnabled]);

  const {
    balances,
    selector: {
      totalOpenQuantities,
      getTotalAvailableAmount,
      getAvailableAmountByAddress,
      getTotalBalance,
      getTotalCurrencyBalanceByAddress,
      totalTokenQuantity,
    },
  } = useAccountBalances(accountUsers.filter((user) => !!user.account).map((user) => user.user_id));
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol },
  } = Entity.useContainer();
  const { getPairByAssetId } = usePairs({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
    includeMarketData: true,
  });

  const userIdAddressMap: Record<number, string> = useMemo(
    () =>
      accountUsers.reduce((previousValue, currentValue) => {
        return {
          ...previousValue,
          [currentValue.user_id]: currentValue.account,
        };
      }, {}),
    [accountUsers],
  );

  const [balanceUserDropdownItem, setUserDropdownItemForBalance] = useState<{
    id: number | string;
    label: ReactNode;
  } | null>(null);
  const [tokenDropdownItem, setDropdownItemForToken] = useState<{ id: number | string; label: ReactNode } | null>(null);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);

  const filteredBalances = useMemo(
    () =>
      balanceUserDropdownItem?.id
        ? balances?.filter((item) => item.accountAddress === userIdAddressMap[balanceUserDropdownItem?.id as number])
        : balances,
    [balances, balanceUserDropdownItem, userIdAddressMap],
  );

  const totalOpenAsset = useMemo(() => {
    const pair = tokenDropdownItem?.id ? getPairByAssetId(tokenDropdownItem?.id as number) : undefined;
    const baseTokenTypeId = pair?.baseAsset?.scId;

    return totalOpenQuantities({
      accountAddress: balanceUserDropdownItem?.id ? userIdAddressMap[balanceUserDropdownItem?.id as number] : undefined,
      ...(pair !== undefined && baseTokenTypeId !== undefined
        ? { pair: { symbol: pair.symbol, baseTokenTypeId } }
        : undefined),
    });
  }, [balanceUserDropdownItem, tokenDropdownItem, userIdAddressMap, getPairByAssetId, totalOpenQuantities]);

  const totalCash = useMemo(
    () =>
      filteredBalances?.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.balance;
      }, 0),
    [filteredBalances],
  );

  const totalOpenCash = useMemo(
    () =>
      filteredBalances?.reduce((previousValue, currentValue) => {
        const openAmount = currentValue?.balance - (currentValue?.availableAmount ?? 0);
        return previousValue + openAmount;
      }, 0),
    [filteredBalances],
  );

  const finalTokens = useMemo(
    () => Object.values(consolidatedTokens).filter((item) => !item.isCurrency && item.qty),
    [consolidatedTokens],
  );

  const filteredTokens = useMemo(
    () => (tokenDropdownItem?.id ? finalTokens.filter((item) => item.asset.id === tokenDropdownItem?.id) : finalTokens),
    [finalTokens, tokenDropdownItem],
  );

  const mainCcyAssets = useMemo(
    () => Object.values(consolidatedTokens).filter((item) => item.isCurrency && item.asset.symbol === mainCcySymbol),
    [consolidatedTokens, mainCcySymbol],
  );

  const assetValue = useMemo(
    () =>
      filteredTokens.reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const accountAddress = balanceUserDropdownItem?.id
          ? userIdAddressMap[balanceUserDropdownItem?.id as number]
          : undefined;
        const assetQuantity = currentValue.isCurrency
          ? accountAddress
            ? getTotalCurrencyBalanceByAddress(accountAddress)
            : getTotalBalance() || 0
          : totalTokenQuantity(currentValue.asset.scId, currentValue.asset.uom.scRatio, accountAddress);

        return previousValue + (pair?.marketData?.referencePrice || 0) * assetQuantity;
      }, 0),
    [
      filteredTokens,
      balanceUserDropdownItem,
      getPairByAssetId,
      getTotalCurrencyBalanceByAddress,
      getTotalBalance,
      totalTokenQuantity,
    ],
  );

  const openAssetValue = useMemo(
    () =>
      filteredTokens.reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const accountAddress = balanceUserDropdownItem?.id
          ? userIdAddressMap[balanceUserDropdownItem?.id as number]
          : undefined;
        const qty = totalOpenQuantities({
          accountAddress,
          ...(pair?.symbol ? { pair: { symbol: pair.symbol, baseTokenTypeId: currentValue.asset.scId } } : undefined),
        });

        return previousValue + (pair?.marketData?.referencePrice || 0) * qty;
      }, 0),
    [filteredTokens, balanceUserDropdownItem, getPairByAssetId, totalOpenQuantities, userIdAddressMap],
  );

  const totalAssetQty = useMemo(
    () =>
      filteredTokens.reduce((previousValue, currentValue) => {
        const accountAddress = balanceUserDropdownItem?.id
          ? userIdAddressMap[balanceUserDropdownItem?.id as number]
          : undefined;
        const totalTokenQty = totalTokenQuantity(
          currentValue.asset.scId,
          currentValue.asset.uom.scRatio,
          accountAddress,
        );
        return previousValue + (currentValue.isCurrency ? 0 : totalTokenQty);
      }, 0),
    [filteredTokens, balanceUserDropdownItem],
  );

  const currentUserId = getUserId();

  const emptyComponent = (
    <Empty
      title="You have no balance"
      description="You can start by depositing cash. Sub accounts can request a corporate admin to transfer cash/assets to their accounts."
      buttonLabel={canDeposit ? 'Cash Deposit' : undefined}
      buttonLink={canDeposit ? '/account/cash' : undefined}
    />
  );

  const renderTable = () => {
    if (balanceUserDropdownItem?.id) {
      const userId = balanceUserDropdownItem?.id;
      const accountAddress = userIdAddressMap[balanceUserDropdownItem.id as number];
      return (
        <Table
          config={{
            sticky: {
              left: ['id'],
              right: [],
            },
            columns: {
              id: {
                label: '#',
                minWidth: 50,
              },
              asset: {
                label: 'ASSET',
              },
              quantity: {
                label: 'QUANTITY',
              },
              marketPrice: {
                label: 'MARKET PRICE',
              },
              value: {
                label: `VALUE (${mainCcySymbol})`,
              },
            },
            rows: [...mainCcyAssets, ...filteredTokens]
              .filter(
                (token) =>
                  token.qty && token.users.find((user) => user.user?.user_id.toString() === userId.toString())?.qty,
              )
              .map((token, index) => {
                let marketPrice = 0;
                let ccyValue = 0;
                let openAssetQty = 0;
                let openAssetValue = 0;
                let pair: Pair | undefined;

                const assetQuantity = token.isCurrency
                  ? getTotalCurrencyBalanceByAddress(accountAddress)
                  : totalTokenQuantity(token.asset.scId, token.asset.uom.scRatio, accountAddress) || 0;

                if (token.isCurrency) {
                  marketPrice = 1;
                  ccyValue = assetQuantity;
                  const availableAmount = getAvailableAmountByAddress(accountAddress) || 0;

                  openAssetQty = ccyValue - availableAmount;
                  openAssetValue = openAssetQty;
                } else {
                  pair = getPairByAssetId(token.asset.id);
                  marketPrice = pair?.marketData?.referencePrice || 0;
                  ccyValue = marketPrice * assetQuantity;

                  openAssetQty = pair?.symbol
                    ? totalOpenQuantities({
                        accountAddress,
                        pair: { symbol: pair.symbol, baseTokenTypeId: token.asset.scId },
                      })
                    : 0;
                  openAssetValue = (marketPrice || 0) * openAssetQty;
                }
                return {
                  id: (index + 1).toString(),
                  asset: (
                    <div className="flex flex-row gap-2 items-center">
                      <TokenContainer>
                        <img src={`/logos/${token.name}.png`} alt={''} />
                      </TokenContainer>
                      <div className="flex flex-col justify-start items-start">
                        <Text variant={TypographyVariant.subtitle2}>{token.asset.name}</Text>
                        <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                          {token.asset.fullName}
                        </Text>
                      </div>
                    </div>
                  ),
                  marketPrice: `${mainCcyCode}${formatNumber(marketPrice, mainCcyNumDecimals)}`,
                  quantity: (
                    <div className="flex flex-row gap-2 items-center">
                      <div className="flex flex-col justify-start items-start">
                        <Text variant={TypographyVariant.subtitle2}>
                          {formatNumber(assetQuantity, token.asset.numDecimals)}
                        </Text>
                        {openAssetQty > 0 && (
                          <Text variant={TypographyVariant.body2} color={TextColor.error}>
                            {`-${formatNumber(openAssetQty, 0)}`}
                          </Text>
                        )}
                      </div>
                    </div>
                  ),
                  value: (
                    <div className="flex flex-row gap-2 items-center">
                      <div className="flex flex-col justify-start items-start">
                        <Text variant={TypographyVariant.subtitle2}>
                          {`${mainCcyCode}${formatNumber(ccyValue, mainCcyNumDecimals)}`}
                        </Text>
                        {openAssetValue > 0 && (
                          <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                            {`-${formatNumber(openAssetValue, 0)}`}
                          </Text>
                        )}
                      </div>
                    </div>
                  ),
                  _key: index.toString(),
                };
              }),
            loading: !balances || isLoading,
            whenNoData: emptyComponent,
          }}
        />
      );
    }

    return (
      <Table
        config={{
          sticky: {
            left: ['id'],
            right: [],
          },
          columns: {
            id: {
              label: '#',
              minWidth: 50,
            },
            asset: {
              label: 'ASSET',
              minWidth: 300,
            },
            quantity: {
              label: 'QUANTITY',
              minWidth: 150,
            },
            marketPrice: {
              label: 'MARKET PRICE',
              minWidth: 150,
            },
            value: {
              label: `VALUE (${mainCcySymbol})`,
              minWidth: 150,
            },
          },
          rows: [...mainCcyAssets, ...filteredTokens]
            .filter((token) => token.qty)
            .map((token, index) => {
              let marketPrice = 0;
              let ccyValue = 0;
              let openAssetQty = 0;
              let openAssetValue = 0;
              let pair: Pair | undefined;

              const assetQuantity =
                (token.isCurrency
                  ? getTotalBalance()
                  : totalTokenQuantity(token.asset.scId, token.asset.uom.scRatio)) || 0;

              if (token.isCurrency) {
                marketPrice = 1;
                ccyValue = assetQuantity;
                const availableAmount = getTotalAvailableAmount() ?? 0;
                openAssetQty = ccyValue - availableAmount;
                openAssetValue = openAssetQty;
              } else {
                pair = getPairByAssetId(token.asset.id);
                marketPrice = pair?.marketData?.referencePrice || 0;
                ccyValue = marketPrice * assetQuantity;
                const baseTokenTypeId = pair?.baseAsset?.scId;

                openAssetQty =
                  pair?.symbol && baseTokenTypeId !== undefined
                    ? totalOpenQuantities({ pair: { symbol: pair.symbol, baseTokenTypeId } })
                    : 0;
                openAssetValue = (pair?.marketData?.referencePrice || 0) * openAssetQty;
              }

              return {
                id: (index + 1).toString(),
                asset: (
                  <div className="flex flex-row gap-2 items-center">
                    <TokenContainer>
                      <img src={`/logos/${token.name}.png`} alt={''} />
                    </TokenContainer>
                    <div className="flex flex-col justify-start items-start">
                      <Text variant={TypographyVariant.subtitle2}>{token.asset.name}</Text>
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                        {token.asset.fullName}
                      </Text>
                    </div>
                  </div>
                ),
                marketPrice: `${mainCcyCode}${formatNumber(marketPrice, mainCcyNumDecimals)}`,
                quantity: (
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex flex-col justify-start items-start">
                      <Text variant={TypographyVariant.subtitle2}>
                        {formatNumber(assetQuantity, token?.asset?.numDecimals)}
                      </Text>
                      {openAssetQty > 0 && (
                        <Text variant={TypographyVariant.body2} color={TextColor.error}>
                          {`-${formatNumber(openAssetQty, 0)}`}
                        </Text>
                      )}
                    </div>
                  </div>
                ),
                value: (
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex flex-col justify-start items-start">
                      <Text variant={TypographyVariant.subtitle2}>
                        {`${mainCcyCode}${formatNumber(ccyValue, mainCcyNumDecimals)}`}
                      </Text>
                      {openAssetValue > 0 && (
                        <Text variant={TypographyVariant.body2} color={TextColor.error}>
                          {`-${formatNumber(openAssetValue, 0)}`}
                        </Text>
                      )}
                    </div>
                  </div>
                ),
                _key: index.toString(),
                _extras: {
                  nestedTable: [
                    {
                      id: '',
                      asset: 'Account Name',
                      marketPrice: 'Market Price',
                      quantity: `Quantity (${token?.name})`,
                      value: `Value (${mainCcySymbol})`,
                      _key: `${index.toString()}-header`,
                      _extras: {
                        height: 50,
                        background: layerBackground('field'),
                        color: textColor(TextColor.secondary),
                      },
                    },
                    ...token.users.map((item) => {
                      const assetQuantity = !item.user?.user_id
                        ? item.qty
                        : token.isCurrency
                          ? getTotalCurrencyBalanceByAddress(userIdAddressMap[Number(item.user.user_id)])
                          : totalTokenQuantity(
                              token.asset.scId,
                              token.asset.uom.scRatio,
                              userIdAddressMap[Number(item.user.user_id) as number],
                            ) || 0;

                      const ccyValue = marketPrice * assetQuantity;

                      let openAssetQty = 0;
                      let openAssetValue = 0;
                      if (token.isCurrency) {
                        const userAvailableAmount = item.user?.user_id
                          ? getAvailableAmountByAddress(userIdAddressMap[item.user.user_id as number])
                          : 0;

                        openAssetQty = ccyValue - userAvailableAmount;
                        openAssetValue = openAssetQty;
                      } else {
                        openAssetQty = pair?.symbol
                          ? totalOpenQuantities({
                              accountAddress: item.user?.user_id
                                ? userIdAddressMap[item.user?.user_id as number]
                                : undefined,
                              pair: { symbol: pair.symbol, baseTokenTypeId: token.asset.scId },
                            })
                          : 0;
                        openAssetValue = (marketPrice || 0) * openAssetQty;
                      }
                      return {
                        id: '',
                        asset: (
                          <div>
                            <Text variant={TypographyVariant.subtitle2}>
                              {item.user?.first_name || ''} {item.user?.last_name || ''}
                            </Text>
                            <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                              {item.user
                                ? getRoleName({
                                    accountType: item.user.account_type,
                                    isRoot: !item.user.parent_id,
                                    isMember: item.user.is_member === 1 || item.user.is_parent_member === 1,
                                  }).name
                                : '-'}
                            </Text>
                          </div>
                        ),
                        marketPrice: `${mainCcyCode}${formatNumber(marketPrice, mainCcyNumDecimals)}`,
                        quantity: (
                          <div className="flex flex-row gap-2 items-center">
                            <div className="flex flex-col justify-start items-start">
                              <Text variant={TypographyVariant.subtitle2}>
                                {formatNumber(assetQuantity, token?.asset?.numDecimals)}
                              </Text>
                              {openAssetQty > 0 && (
                                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                                  {`-${formatNumber(openAssetQty, 0)}`}
                                </Text>
                              )}
                            </div>
                          </div>
                        ),
                        value: (
                          <div className="flex flex-row gap-2 items-center">
                            <div className="flex flex-col justify-start items-start">
                              <Text variant={TypographyVariant.subtitle2}>
                                {`${mainCcyCode}${formatNumber(ccyValue, mainCcyNumDecimals)}`}
                              </Text>
                              {openAssetValue > 0 && (
                                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                                  {`-${formatNumber(openAssetValue, 0)}`}
                                </Text>
                              )}
                            </div>
                          </div>
                        ),
                        _key: `${index.toString()}+${item.user?.user_id || ''}`,
                      };
                    }),
                  ],
                },
              };
            }),
          loading: !balances || isLoading,
          whenNoData: emptyComponent,
        }}
      />
    );
  };

  const onPressTransfer = () => {
    setIsTransferModalVisible(true);
  };

  return (
    <>
      {canTransferAssets() && (
        <CorpTransferForm
          isVisible={isTransferModalVisible}
          onClose={() => setIsTransferModalVisible(false)}
          onTransferSuccess={() => setIsTransferModalVisible(false)}
          userIds={accountUsers?.filter((user) => !!user.account)?.map((user) => user.user_id) ?? []}
        />
      )}
      <PageHolder>
        <PageSections>
          <PageControls
            title="Consolidated Balance"
            controls={{
              secondary: canTransferAssets() && (
                <ControlContainer>
                  <Button className="transfer" endIcon={IconName.ArrowEnd} onPress={onPressTransfer}>
                    Transfer
                  </Button>
                </ControlContainer>
              ),
            }}
          />
        </PageSections>
        <PageSections>
          <Filters>
            <ControlContainer>
              <StyledSelect
                items={[
                  {
                    title: 'All accounts',
                    value: '0',
                  },
                  ...accountUsers.map((user) => {
                    return {
                      value: '' + user.user_id,
                      title: `${user.fullName} ${currentUserId === user.user_id ? '(Me)' : ''}`,
                    };
                  }),
                ]}
                isSearchable
                label={'Account'}
                onChange={({ value }) => {
                  const selectedUser = accountUsers.find((user) => user.user_id === Number(value));

                  if (!selectedUser) {
                    setUserDropdownItemForBalance(null);
                    return;
                  }

                  setUserDropdownItemForBalance({
                    id: selectedUser.user_id,
                    label: `${selectedUser.fullName} ${currentUserId === selectedUser.user_id ? '(Me)' : ''}`,
                  });
                }}
                value={balanceUserDropdownItem ? '' + balanceUserDropdownItem.id : '0'}
              />
            </ControlContainer>
            <ControlContainer>
              <StyledSelect
                className="asset-type"
                label="Asset"
                isSearchable
                items={[
                  {
                    value: '0',
                    title: 'All assets',
                  },
                  ...finalTokens.map((token) => {
                    return {
                      value: '' + token.asset.id,
                      title: token.name,
                    };
                  }),
                ]}
                value={tokenDropdownItem?.id ? '' + tokenDropdownItem.id : '0'}
                onChange={({ value }) => {
                  const selectedToken = finalTokens.find((token) => token.asset.id === Number(value));

                  if (!selectedToken) {
                    setDropdownItemForToken(null);
                    return;
                  }

                  setDropdownItemForToken({
                    id: selectedToken.asset.id,
                    label: selectedToken.name,
                  });
                }}
              />
            </ControlContainer>
          </Filters>
          <SummaryHolder>
            <SummaryCard
              title="Cash"
              value={`${mainCcyCode}${formatNumber(totalCash, mainCcyNumDecimals)}`}
              icon={<Icon name={IconName.Wallet} />}
              secondaryValue={
                totalOpenCash > 0 ? `-${mainCcyCode}${formatNumber(totalOpenCash, mainCcyNumDecimals)}` : undefined
              }
              secondaryValueColor={TextColor.error}
              secondaryTooltip={`Reserved Cash Amount`}
            />
            <SummaryCard
              title="Assets Value"
              tooltip={`Total assets value in ${mainCcySymbol}`}
              value={`${mainCcyCode}${formatNumber(assetValue, mainCcyNumDecimals)}`}
              icon={<Icon name={IconName.Bids} />}
              secondaryValue={
                openAssetValue ? `-${mainCcyCode}${formatNumber(openAssetValue, mainCcyNumDecimals)}` : undefined
              }
              secondaryValueColor={TextColor.error}
              secondaryTooltip={`Reserved Asset Value`}
            />
            <SummaryCard
              title="Assets Qty"
              tooltip={'Total quantity for all contracts'}
              value={formatNumber(totalAssetQty, 0) ?? ''}
              icon={<Icon name={IconName.Database} />}
              secondaryValue={totalOpenAsset > 0 ? (`-${formatNumber(totalOpenAsset, 0)}` ?? '') : undefined}
              secondaryValueColor={TextColor.error}
              secondaryTooltip={`Reserved Asset Quantity`}
            />
          </SummaryHolder>
        </PageSections>
        <PageSections className="flex flex-col">
          <Layer>{renderTable()}</Layer>
        </PageSections>
        {isAssetsDepositFeatureEnabled && hasDepositPermission ? (
          <DepositRequestsContextProvider>
            <DepositRequestsContainer />
          </DepositRequestsContextProvider>
        ) : undefined}
      </PageHolder>
    </>
  );
};

const Filters = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(8)};

  @media (max-width: 475px) {
    flex-direction: column;
  }

  & > * {
    @media (max-width: 475px) {
      width: 100%;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

import { useState } from 'react';
import type { CheckBoxProps } from 'refreshed-component/atoms/CheckBox';
import { Empty } from 'refreshed-component/atoms/Empty';
import { FilterDropdown, FilterSelections } from 'refreshed-component/molecules/Filter';

import { Card, Layer, Text, TextColor, TypographyVariant, styled, toSpacing } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import type { MyEmbAsk, MyEmbAskBid } from 'pages/account/carbon/CMB/types';

import useCurrencies from 'hooks/useCurrencies';

import { formatDate } from 'utils/helpers';

import { DetailedCardDescriptionItemWithContentList } from '../DetailedCard';

const MyListingAskBidsRoot = styled.div`
  position: relative;
  width: 100%;
`;

export const MyListingAskBids = ({
  bids,
  ask,
  tokenUnit,
}: {
  bids: MyEmbAskBid[];
  ask: MyEmbAsk;
  tokenUnit: string;
}) => {
  const filters: {
    status: {
      type: 'check-box';
      label: string;
      list: CheckBoxProps[];
    };
  } = {
    status: {
      type: 'check-box',
      label: 'Status',
      list: [
        {
          id: 1,
          label: 'New',
        },
        {
          id: 2,
          label: 'Accepted',
        },
        {
          id: 3,
          label: 'Rejected',
        },
      ],
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>();
  const filteredBid = bids.filter((item) => {
    const statusSelection = filterSelections?.status?.selection;
    if (!statusSelection?.length) return true;
    if (statusSelection.includes(1) && item.status === 'NEW') {
      return true;
    }
    if (statusSelection.includes(2) && item.status === 'CONFIRMED_BY_SELLER') {
      return true;
    }
    if (statusSelection.includes(3) && item.status === 'REJECTED_BY_SELLER') {
      return true;
    }
    return false;
  });

  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  let remainingQty = ask.quantity;
  const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
  let isQtyAllocated = false;

  return (
    <MyListingAskBidsRoot>
      <Layer>
        <StyledCard>
          <div className="flex flex-row flex-1 justify-between items-center">
            <Text variant={TypographyVariant.h6Title}>Project Bids</Text>
            <div className="flex flex-row gap-4 justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          <div className="flex flex-col gap-4 w-full h-full">
            {filteredBid.map((bid) => {
              let fillQty = 0;
              if (!isQtyAllocated) {
                remainingQty -= bid.quantity;
                if (remainingQty < 0) {
                  fillQty = bid.quantity + remainingQty;
                  isQtyAllocated = true;
                } else {
                  fillQty = bid.quantity;
                }
              }
              return (
                <DetailedCardDescriptionItemWithContentList key={bid.id}>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Bid
                    </Text>
                    <Text color={TextColor.secondary}>#{bid.id}</Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Price (Per {tokenUnit})
                    </Text>
                    <Text color={TextColor.secondary}>
                      {formatter.formatNumber(bid.price, ccyAsset?.numDecimals)} {ccyAsset?.symbol}
                    </Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Qty ({tokenUnit})
                    </Text>
                    <Text color={TextColor.secondary}>
                      {formatter.formatNumber(bid.quantity, 0)} {tokenUnit}
                    </Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Date
                    </Text>
                    <Text color={TextColor.secondary}>{formatDate(bid.createdUtc)}</Text>
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                      Status
                    </Text>
                    <Text color={TextColor.secondary}>{bid?.status}</Text>
                  </div>
                </DetailedCardDescriptionItemWithContentList>
              );
            })}
            {filteredBid.length === 0 && (
              <Empty title="No bids placed" description="There are no bids placed yet on this listing" />
            )}
          </div>
        </StyledCard>
      </Layer>
    </MyListingAskBidsRoot>
  );
};

const StyledCard = styled(Card)`
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

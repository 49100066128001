import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Text, TextColor, TypographyVariant, useLayerBackground, useTextColor } from '@aircarbon/ui';
import { formatter, trades } from '@aircarbon/utils-common';

import Loading from 'components/styled/Loading';

import { User } from 'state/user';

import SimpleBar from '../components/Simplebar';
import { useFobTypes } from '../hooks/useFobTypes';
import CellWithDropdown from './CellWithDropdown';
import { Bar, Cell, CurrentPrice, CurrentPriceWrapper, type OrderBookProps, Row } from './OrderBook';
import { PlaceOrderContext } from './PlaceOrderCarbon';

type RowData = {
  orderId: number;
  price: string;
  qty: string;
  total: string;
  _qty: string;
  _price: string;
  outsidePriceLimit: boolean;
  shippingType: string;
  fobType: string;
  userName: string;
  shippingTypeId: number;
  fobTypeId: number;
  userId: number;
  barValue: number;
};

function findDifferentPosition(a: string, b: string) {
  if (a.length < b.length) [a, b] = [b, a];
  return [...a].findIndex((chr, i) => chr !== b[i]);
}

function transformRows(orders: any[] = [], ccyNumDecimals: number, sumOfOrderQtyPrice: number): RowData[] {
  let previousCumulativeQty = 0;

  return orders.map(
    ({
      price,
      qty,
      order_id,
      outside_price_limit,
      fob_type,
      shipping_type,
      user_name,
      user_id,
      shipping_id,
      fob_id,
    }: any) => {
      const cumulativeQty = Number.parseFloat(qty) + previousCumulativeQty;
      const barValue = Math.ceil(sumOfOrderQtyPrice * cumulativeQty);
      previousCumulativeQty = cumulativeQty;

      return {
        orderId: order_id,
        price: formatter.formatNumber(price, ccyNumDecimals),
        qty: formatter.thousandFormatter(qty, 1),
        total: formatter.thousandFormatter(price * qty, 1),
        outsidePriceLimit: outside_price_limit,
        shippingType: shipping_type || 'Any',
        fobType: fob_type || 'Any',
        fobTypeId: Number(fob_id),
        userName: user_name || 'Any',
        userId: Array.isArray(user_id) ? Number(user_id[0]) : Number(user_id),
        shippingTypeId: Number(shipping_id),
        barValue,
        _price: price,
        _qty: qty,
      };
    },
  );
}

function getBuyOrders(orderBook: any) {
  return (orderBook?.itemsL3 ?? []).filter((item: any) => orderBook.sides[item.side_id] !== 'Sell');
}

function getSellOrders(orderBook: any) {
  return (orderBook?.itemsL3 ?? []).filter((item: any) => orderBook.sides[item.side_id] === 'Sell');
}

const OrderBookBiofuel = ({
  orderBook,
  link,
  isLoading: loading,
  view,
  showCumulative,
  pair,
  isPublic,
}: OrderBookProps) => {
  const {
    status: { canTradeBiofuel },
  } = User.useContainer();
  const { layerBackground } = useLayerBackground();
  const { textColor } = useTextColor();
  const rowHolder = useRef<HTMLDivElement | null>(null);
  const { setPlaceOrderData } = useContext(PlaceOrderContext);
  const ccyNumDecimals = pair?.quoteAsset?.numDecimals ?? 2;
  const currentRow = {
    theme: 'gray',
    currentLastTradedPrice: orderBook?.lastTradedPrice || '',
  };
  const { fobTypes } = useFobTypes(pair?.baseAsset?.id ?? Number.NaN);
  const shippingTypes = Object.entries(trades.ShippingTypeSelector).map(([key, value]) => ({ key, value }));

  const [shippingTypeSellFilters, setShippingTypeSellFilters] = useState<string[]>([]);
  const [fobTypeSellFilters, setFobTypeSellFilters] = useState<number[]>([]);
  const [shippingTypeBuyFilters, setShippingTypeBuyFilters] = useState<string[]>([]);
  const [fobTypeBuyFilters, setFobTypeBuyFilters] = useState<number[]>([]);

  const sellOrdersRaw = getSellOrders(orderBook);
  const buyOrdersRaw = getBuyOrders(orderBook);

  const sumOfSellQtyPrice =
    100 / sellOrdersRaw.map((item: any) => Number.parseFloat(item.qty)).reduce((a: number, b: number) => a + b, 0);
  const sumOfBuyQtyPrice =
    100 / buyOrdersRaw.map((item: any) => Number.parseFloat(item.qty)).reduce((a: number, b: number) => a + b, 0);

  const filteredBuyOrders = transformRows(buyOrdersRaw, ccyNumDecimals, sumOfBuyQtyPrice)?.filter(
    (o) =>
      (shippingTypeBuyFilters?.length ? shippingTypeBuyFilters.includes(o.shippingTypeId.toString()) : true) &&
      (fobTypeBuyFilters?.length ? fobTypeBuyFilters.includes(o.fobTypeId) : true),
  );
  const filteredSellOrders = transformRows(sellOrdersRaw, ccyNumDecimals, sumOfSellQtyPrice)?.filter(
    (o) =>
      (shippingTypeSellFilters?.length ? shippingTypeSellFilters.includes(o.shippingTypeId.toString()) : true) &&
      (fobTypeSellFilters?.length ? fobTypeSellFilters.includes(o.fobTypeId) : true),
  );

  useEffect(() => {
    setShippingTypeBuyFilters([]);
    setShippingTypeSellFilters([]);
    setFobTypeSellFilters([]);
    setFobTypeBuyFilters([]);
  }, [view, orderBook?.itemsL3]);

  const isLeftAndRightView = view === 'LeftAndRight';

  function getStringDiffSplit(previousString: string, currentString: string, isLast: boolean) {
    if (!previousString || isLast) return currentString;
    const diffPosition = findDifferentPosition(previousString, currentString);
    if (diffPosition === -1) return <span>{currentString}</span>;
    const [left, right] = [currentString.slice(0, diffPosition), currentString.slice(diffPosition)];
    return (
      <>
        <span>{left}</span>
        {right}
      </>
    );
  }

  const renderSellOrders = () => {
    let previousSell: string;

    if (filteredSellOrders.length) {
      const rows = filteredSellOrders.map((row: RowData, index: number, list: RowData[]) => {
        const isLast = list.length === index + 1;
        const decoratedPrice = getStringDiffSplit(previousSell || '', row.price, isLast);
        previousSell = row.price;
        return isLeftAndRightView ? (
          <Row
            isPublic={isPublic}
            key={index.toString()}
            className={`code ${row.outsidePriceLimit ? 'order-disabled' : ''}`}
            onClick={() =>
              !isPublic &&
              canTradeBiofuel() &&
              setPlaceOrderData?.({
                price: Number(row._price),
                quantity: Number(row._qty),
                side: 'Buy',
                fobTypeId: row.fobTypeId,
                shippingTypeId: row.shippingTypeId,
              })
            }
          >
            <div className="flex flex-col w-full h-auto">
              <div className="flex flex-row w-full h-auto">
                <Cell $theme={!row.outsidePriceLimit ? 'red' : undefined} className={`cell w-1/4 flex-auto`}>
                  {decoratedPrice}
                </Cell>
                <Cell className={`cell w-1/3 flex-auto whitespace-pre`}>{row.fobType}</Cell>
                <Cell className={`cell w-1/3 flex-auto whitespace-pre`}>{row.shippingType}</Cell>
                <Cell className={`cell w-1/6 flex-auto text-left`}>{row.qty}</Cell>
                {!isPublic && (
                  <Cell
                    className={`cell w-1/4 flex-auto ${
                      !isLeftAndRightView || showCumulative ? 'text-right' : 'text-center'
                    }`}
                  >
                    {row.orderId}
                  </Cell>
                )}
                {!row.outsidePriceLimit && (
                  <Bar $theme={'red'} value={row.barValue} align={isLeftAndRightView ? 'left' : 'right'} />
                )}
              </div>
            </div>
          </Row>
        ) : (
          <Row
            isPublic={isPublic}
            key={index.toString()}
            className={`code ${row.outsidePriceLimit ? 'order-disabled' : ''}`}
            onClick={() =>
              !isPublic &&
              canTradeBiofuel() &&
              setPlaceOrderData?.({
                price: Number(row._price),
                quantity: Number(row._qty),
                side: 'Buy',
                fobTypeId: row.fobTypeId,
                shippingTypeId: row.shippingTypeId,
              })
            }
          >
            <div className="flex flex-col w-full h-auto">
              <div className="flex flex-row w-full h-auto">
                <Cell $theme={!row.outsidePriceLimit ? 'red' : undefined} className={`cell w-1/4 flex-auto`}>
                  {decoratedPrice}
                </Cell>
                <Cell className={`cell w-1/6 flex-auto text-left align-right`}>{row.qty}</Cell>
                <Cell className={`cell w-1/3 flex-auto text-right align-right whitespace-pre`}>{row.fobType}</Cell>
                <Cell className={`cell w-1/3 flex-auto text-right align-right whitespace-pre`}>{row.shippingType}</Cell>
                {!isPublic && (
                  <Cell
                    className={`cell w-1/4 flex-auto ${
                      !isLeftAndRightView || showCumulative ? 'text-right' : 'text-center'
                    }`}
                  >
                    {row.orderId}
                  </Cell>
                )}
                {!row.outsidePriceLimit && (
                  <Bar $theme={'red'} value={row.barValue} align={isLeftAndRightView ? 'left' : 'right'} />
                )}
              </div>
            </div>
          </Row>
        );
      });
      return isLeftAndRightView ? rows : rows.reverse();
    }
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Text color={TextColor.error} variant={TypographyVariant.caption}>
          No Sell Orders
        </Text>
      </div>
    );
  };

  const renderBuyOrders = () => {
    let previousBuy: string;

    return filteredBuyOrders.length ? (
      filteredBuyOrders.map((row: RowData, index: number, list: RowData[]) => {
        const isLast = list.length === index + 1;
        const decoratedPrice = getStringDiffSplit(previousBuy || '', row.price, isLast);
        previousBuy = row.price;
        return (
          <Row
            isPublic={isPublic}
            key={index.toString()}
            className={`code h-auto ${row.outsidePriceLimit ? 'order-disabled' : ''}`}
            onClick={() =>
              !isPublic &&
              canTradeBiofuel() &&
              setPlaceOrderData?.({
                price: Number(row._price),
                quantity: Number(row._qty),
                side: 'Sell',
                fobTypeId: row.fobTypeId,
                shippingTypeId: row.shippingTypeId,
              })
            }
          >
            {isLeftAndRightView ? (
              <div className="flex flex-col w-full h-auto">
                <div className="flex flex-row w-full h-auto">
                  {!isPublic && <Cell className={`cell w-1/4 flex-auto`}>{row.orderId}</Cell>}
                  <Cell className={`cell w-1/6 flex-auto text-right`}>{row.qty}</Cell>
                  <Cell className={`cell w-1/3 flex-auto text-right align-right whitespace-pre`}>
                    {row.shippingType}
                  </Cell>
                  <Cell className={`cell w-1/3 flex-auto text-right align-right whitespace-pre`}>{row.fobType}</Cell>
                  <Cell
                    $theme={!row.outsidePriceLimit ? 'green' : undefined}
                    className={`cell w-1/3 flex-auto text-right`}
                  >
                    {decoratedPrice}
                  </Cell>
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-full h-auto">
                <div className="flex flex-row w-full h-auto">
                  <Cell $theme={!row.outsidePriceLimit ? 'green' : undefined} className="flex-auto w-1/4 cell">
                    {decoratedPrice}
                  </Cell>
                  <Cell className={`cell w-1/6 flex-auto text-right align-right whitespace-pre`}>{row.qty}</Cell>
                  <Cell className={`cell w-1/3 flex-auto text-right align-right whitespace-pre`}>{row.fobType}</Cell>
                  <Cell className={`cell w-1/3 flex-auto text-right align-right whitespace-pre`}>
                    {row.shippingType}
                  </Cell>
                  {!isPublic && <Cell className={`cell w-1/4 flex-auto text-right`}>{row.orderId}</Cell>}
                </div>
              </div>
            )}
            {!row.outsidePriceLimit && <Bar $theme={'green'} value={row.barValue} />}
          </Row>
        );
      })
    ) : (
      <div
        style={{
          background: layerBackground('layer'),
        }}
        className="flex justify-center items-center w-full h-full"
      >
        <Text color={TextColor.success} variant={TypographyVariant.caption}>
          No Buy Orders
        </Text>
      </div>
    );
  };

  const drawTopAndBottom = () => {
    return (
      <div className="flex flex-col gap-3 w-full h-full">
        <div className="flex relative flex-row flex-1 gap-1 w-full">
          <div
            style={{
              position: 'absolute',
              left: '-10px',
              right: '-10px',
              top: 0,
              bottom: 0,
            }}
          >
            <SimpleBar className="w-full h-full">
              <div className="flex sticky top-0 z-10 flex-row gap-1 w-full min-h-full">
                <div className="flex flex-col flex-1">
                  <Row isPublic={isPublic} isHeader={true}>
                    <Cell variant={TypographyVariant.subtitle2} $theme={'light'} className="flex-auto w-1/3">
                      Price
                    </Cell>
                    <Cell $theme={'light'} variant={TypographyVariant.subtitle2} className="flex-auto w-1/6 text-left">
                      Size
                    </Cell>
                    <CellWithDropdown
                      options={
                        fobTypes?.map((item) => ({
                          key: item.id,
                          value: item.name,
                        })) ?? []
                      }
                      selectedOptions={[...fobTypeBuyFilters, ...fobTypeSellFilters]}
                      cellClassName="w-1/3 flex-auto text-right align-right dropdown"
                      onSelectOptions={(newOptions: any[]) => {
                        setFobTypeBuyFilters(newOptions);
                        setFobTypeSellFilters(newOptions);
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 17C13.4183 17 17 13.4183 17 9M9 17C4.58172 17 1 13.4183 1 9M9 17C10.7673 17 12.2 
                          13.4183 12.2 9C12.2 4.58172 10.7673 1 9 1M9 17C7.23268 17 5.8 13.4183 5.8 9C5.8 4.58172 7.23268 
                          1 9 1M17 9C17 4.58172 13.4183 1 9 1M17 9H1M1 9C1 4.58172 4.58172 1 9 1M3.34316 3.45684C4.79088
                          4.90456 6.79088 5.8 9 5.8C11.2092 5.8 13.2092 4.90456 14.6569 3.45684M14.6569 14.5431C13.2092 
                          13.0954 11.2092 12.2 9 12.2C6.79088 12.2 4.79088 13.0954 3.34316 14.5431"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                      </svg>
                    </CellWithDropdown>
                    <CellWithDropdown
                      options={shippingTypes}
                      selectedOptions={[...shippingTypeBuyFilters, ...shippingTypeSellFilters]}
                      cellClassName="w-1/3 flex-auto text-right align-right dropdown"
                      onSelectOptions={(newOptions: any[]) => {
                        setShippingTypeBuyFilters(newOptions);
                        setShippingTypeSellFilters(newOptions);
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.2 2.59998H1.8C1.35817 2.59998 1 2.95815 1 3.39997V16.1999C1 16.6418 1.35817 16.9999 1.8 16.9999H16.2C16.6418 16.9999 17 16.6418 17 16.1999V3.39997C17 2.95815 16.6418 2.59998 16.2 2.59998Z"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M1 7.40002H17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M1 12.2H17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M6.20007 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M11.7999 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M6.20007 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M11.7999 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M17 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="0.916667"
                          strokeLinecap="square"
                        />
                        <path
                          d="M1 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M5 17H13"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                      </svg>
                    </CellWithDropdown>
                    {!isPublic && (
                      <Cell
                        $theme={'light'}
                        variant={TypographyVariant.subtitle2}
                        className="flex-auto w-1/3 text-right"
                      >
                        Order ID
                      </Cell>
                    )}
                  </Row>
                </div>
              </div>
              <div style={{ minHeight: '96px' }} className="flex relative flex-col flex-auto items-center mt-1 w-full">
                {loading ? (
                  <div className="flex relative flex-row justify-center items-center w-full h-full text-gray-300">
                    <Loading />
                  </div>
                ) : (
                  <>
                    <div ref={rowHolder} className="flex flex-col flex-1 justify-end mb-1 w-full">
                      <div className="relative w-full">
                        <div className="w-full">{renderSellOrders()}</div>
                      </div>
                    </div>
                    <div className="flex flex-row flex-shrink gap-1 items-center w-full">
                      <CurrentPriceWrapper className="flex w-full">
                        <div className={`${currentRow.currentLastTradedPrice ? '' : 'flex-1'}`}>
                          {currentRow.currentLastTradedPrice ? (
                            <>
                              <CurrentPrice className="flex flex-row flex-auto gap-2 justify-center items-center">
                                <div className="price code">
                                  {currentRow.currentLastTradedPrice || 'Empty Orderbook'}
                                </div>
                              </CurrentPrice>
                              {!!link && (
                                <Link
                                  to={link.url}
                                  style={{
                                    color: textColor(TextColor.primary),
                                  }}
                                  className="cursor-pointer"
                                >
                                  {link.label}
                                </Link>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                color: textColor(TextColor.primary),
                              }}
                              className="flex-1 w-full text-center"
                            >
                              Empty Orderbook
                            </div>
                          )}
                        </div>
                        {!!currentRow.currentLastTradedPrice && (
                          <div
                            style={{
                              color: textColor(TextColor.primary),
                            }}
                            className="ml-"
                          >
                            Last Traded Price
                          </div>
                        )}
                      </CurrentPriceWrapper>
                    </div>
                    <div className="flex flex-col flex-1 items-start mt-1 w-full">
                      <div className="relative w-full">
                        <div className="top-0 w-full">{renderBuyOrders()}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    );
  };

  const drawLeftAndRight = () => {
    return (
      <div className="flex flex-col gap-3 w-full h-full">
        <div className="flex relative flex-row flex-1 gap-1 w-full">
          <div
            style={{
              position: 'absolute',
              left: '-10px',
              right: '-10px',
              top: 0,
              bottom: 0,
            }}
          >
            <SimpleBar className="w-full h-full">
              <div
                className="flex sticky top-0 z-10 flex-row gap-1 w-full min-h-full"
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <div className="flex flex-col flex-1">
                  <Row isPublic={isPublic} className="sticky" isHeader={true}>
                    {!isPublic && (
                      <Cell
                        $theme={'light'}
                        variant={TypographyVariant.subtitle2}
                        className={`w-1/3 flex-auto text-left`}
                      >
                        Order&nbsp;ID
                      </Cell>
                    )}
                    <Cell
                      $theme={'light'}
                      variant={TypographyVariant.subtitle2}
                      className={`w-1/6 flex-auto text-right`}
                    >
                      Size
                    </Cell>
                    <CellWithDropdown
                      options={shippingTypes}
                      selectedOptions={[...shippingTypeBuyFilters]}
                      cellClassName="w-1/3 flex-auto text-right align-right dropdown"
                      onSelectOptions={(newOptions: any[]) => {
                        setShippingTypeBuyFilters(newOptions);
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.2 2.59998H1.8C1.35817 2.59998 1 2.95815 1 3.39997V16.1999C1 16.6418 1.35817 16.9999 1.8 16.9999H16.2C16.6418 16.9999 17 16.6418 17 16.1999V3.39997C17 2.95815 16.6418 2.59998 16.2 2.59998Z"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M1 7.40002H17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M1 12.2H17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M6.20007 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M11.7999 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M6.20007 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M11.7999 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M17 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M1 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M5 17H13"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                      </svg>
                    </CellWithDropdown>
                    <CellWithDropdown
                      options={
                        fobTypes?.map((item) => ({
                          key: item.id,
                          value: item.name,
                        })) ?? []
                      }
                      selectedOptions={[...fobTypeBuyFilters]}
                      cellClassName="w-1/3 flex-auto text-right align-right dropdown"
                      onSelectOptions={(newOptions: any[]) => {
                        setFobTypeBuyFilters(newOptions);
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 17C13.4183 17 17 13.4183 17 9M9 17C4.58172 17 1 13.4183 1 9M9 17C10.7673 17 12.2 
                          13.4183 12.2 9C12.2 4.58172 10.7673 1 9 1M9 17C7.23268 17 5.8 13.4183 5.8 9C5.8 4.58172 7.23268 
                          1 9 1M17 9C17 4.58172 13.4183 1 9 1M17 9H1M1 9C1 4.58172 4.58172 1 9 1M3.34316 3.45684C4.79088
                          4.90456 6.79088 5.8 9 5.8C11.2092 5.8 13.2092 4.90456 14.6569 3.45684M14.6569 14.5431C13.2092 
                          13.0954 11.2092 12.2 9 12.2C6.79088 12.2 4.79088 13.0954 3.34316 14.5431"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                      </svg>
                    </CellWithDropdown>
                    <Cell
                      $theme={'light'}
                      variant={TypographyVariant.subtitle2}
                      className={`w-1/3 flex-auto text-right`}
                    >
                      Bid
                    </Cell>
                  </Row>
                </div>
                <div className="flex flex-col flex-1">
                  <Row isPublic={isPublic} className="sticky" isHeader={true}>
                    <Cell
                      $theme={'light'}
                      variant={TypographyVariant.subtitle2}
                      className={`w-1/4 flex-auto text-left`}
                    >
                      Offer
                    </Cell>
                    <CellWithDropdown
                      options={
                        fobTypes?.map((item) => ({
                          key: item.id,
                          value: item.name,
                        })) ?? []
                      }
                      selectedOptions={[...fobTypeSellFilters]}
                      cellClassName="w-1/3 flex-auto text-right align-left dropdown"
                      onSelectOptions={(newOptions: any[]) => {
                        setFobTypeSellFilters(newOptions);
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9 17C13.4183 17 17 13.4183 17 9M9 17C4.58172 17 1 13.4183 1 9M9 17C10.7673 17 12.2 
                          13.4183 12.2 9C12.2 4.58172 10.7673 1 9 1M9 17C7.23268 17 5.8 13.4183 5.8 9C5.8 4.58172 7.23268 
                          1 9 1M17 9C17 4.58172 13.4183 1 9 1M17 9H1M1 9C1 4.58172 4.58172 1 9 1M3.34316 3.45684C4.79088
                          4.90456 6.79088 5.8 9 5.8C11.2092 5.8 13.2092 4.90456 14.6569 3.45684M14.6569 14.5431C13.2092 
                          13.0954 11.2092 12.2 9 12.2C6.79088 12.2 4.79088 13.0954 3.34316 14.5431"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                      </svg>
                    </CellWithDropdown>
                    <CellWithDropdown
                      options={shippingTypes}
                      selectedOptions={[...shippingTypeSellFilters]}
                      cellClassName="w-1/3 flex-auto text-right align-left dropdown"
                      onSelectOptions={(newOptions: any[]) => {
                        setShippingTypeSellFilters(newOptions);
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.2 2.59998H1.8C1.35817 2.59998 1 2.95815 1 3.39997V16.1999C1 16.6418 1.35817 16.9999 1.8 16.9999H16.2C16.6418 16.9999 17 16.6418 17 16.1999V3.39997C17 2.95815 16.6418 2.59998 16.2 2.59998Z"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M1 7.40002H17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M1 12.2H17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M6.20007 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M11.7999 1V4.19999"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M6.20007 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M11.7999 7.40002V17"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M17 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M1 4.59998V15"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                        <path
                          d="M5 17H13"
                          stroke={textColor(TextColor.primary)}
                          stroke-width="0.916667"
                          stroke-linecap="square"
                        />
                      </svg>
                    </CellWithDropdown>
                    <Cell
                      $theme={'light'}
                      variant={TypographyVariant.subtitle2}
                      className={`w-1/6 flex-auto text-left`}
                    >
                      Size
                    </Cell>
                    {!isPublic && (
                      <Cell
                        $theme={'light'}
                        variant={TypographyVariant.subtitle2}
                        className={`w-1/4 flex-auto text-right`}
                      >
                        Order&nbsp;ID
                      </Cell>
                    )}
                  </Row>
                </div>
              </div>
              <div
                className="flex flex-row gap-1 w-full min-h-full"
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <div style={{ width: '50%' }} className="flex flex-col flex-1">
                  <div className="flex relative flex-col flex-auto items-center mt-1 w-full">
                    {loading ? (
                      <div className="flex relative flex-row justify-center items-center w-full h-full text-gray-300">
                        <Loading />
                      </div>
                    ) : (
                      <div ref={rowHolder} className="flex flex-col flex-1 justify-start mb-2 w-full">
                        {renderBuyOrders()}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ width: '50%' }} className="flex flex-col flex-1">
                  <div className="flex relative flex-col flex-auto items-center mt-1 w-full">
                    {loading ? (
                      <div className="flex relative flex-row justify-center items-center w-full h-full text-gray-300">
                        <Loading />
                      </div>
                    ) : (
                      <div ref={rowHolder} className="flex flex-col flex-1 justify-start mb-2 w-full">
                        {renderSellOrders()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    );
  };

  return isLeftAndRightView ? drawLeftAndRight() : drawTopAndBottom();
};

export default OrderBookBiofuel;

import type React from 'react';
import type { ReactNode } from 'react';

import { Text, TypographyVariant, styled, toSpacing } from '@aircarbon/ui';

const PageRoot = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: ${({ theme }) => toSpacing(theme)(12)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(12)};
`;

export const Sections = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(12)};

  &:last-child {
    flex: auto;
  }
  &:empty {
    display: none;
  }
`;

export const PageSections = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return <Sections className={className}>{children}</Sections>;
};

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  align-items: center;
`;

const StyledText = styled(Text)`
  position: relative;
`;

const Controls = styled.div`
  position: relative;
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(8)};
  }
`;

export const PageHolder = ({
  title,
  controls,
  children,
}: {
  title?: string;
  controls?: {
    primary?: ReactNode;
    secondary?: ReactNode;
  };
  children: React.ReactElement<typeof PageSections> | (React.ReactElement<typeof PageSections> | undefined)[];
}) => {
  return (
    <PageRoot>
      {title && controls?.primary && controls.secondary && (
        <Sections>
          <Header>
            {title && <StyledText variant={TypographyVariant.subtitle1}>{title}</StyledText>}
            <Controls>
              <div>{controls?.primary}</div>
              <div>{controls?.secondary}</div>
            </Controls>
          </Header>
        </Sections>
      )}
      {children}
    </PageRoot>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { Input } from 'refreshed-component/atoms/Input';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import {
  Button,
  ButtonType,
  Checkbox,
  IconName,
  Select,
  Text,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import { AssetCategory, formatter, logger } from '@aircarbon/utils-common';
import type { Pair } from '@aircarbon/utils-common/src/dto';

import type { AssetCategoryCode } from 'pages/account/trading/layouts/trading.hook';

import SelectOboAccount, { type AccountDetails } from 'components/SelectOboAccount';

import { UI } from 'state/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import FormDevTool from '../../components/FormDevTool';
import type { CarbonProject } from '../../pages/account/carbon/types';
import { usePairs } from '../../pages/account/trading/hooks';
import { User } from '../../state/user';
import { convertTextNumberToValue } from '../../utils/helpers';
import { AddCarbonProject } from './AddCarbonProject';
import { SelectProjectAndContract } from './SelectProjectAndContract';
import type { SelectProjectAndContractValue } from './SelectProjectAndContract/hooks/useProjectAndContractSelector';

export type FormData = {
  carbonProjectId?: number;
  askId: number;
  pairId?: number;
  quantity: number;
  price: number;
  disallowPartialFilling: number;
  vintageYear: number;
  status: string;
  isAuction: number;
  startDate?: Date;
  endDate?: Date;
  minBidQty: number;
  maxBidQty: number;
  showHighestBid: number | null;
  showBidVolume: number | null;
  findProject: boolean;
  isListed?: boolean;
};

const addProjectFormSchema = (minBidQty: number, minQty: number, maxQty: number, qtyUnit = 'tCO2e') =>
  yup.object().shape({
    carbonProjectId: yup.string().required('You must select a carbon project'),
    vintageYear: yup.string().required('You must select a vintage year'),
    quantity: yup
      .number()
      .required()
      .max(maxQty, `Maximum quantity is ${formatter.formatNumber(maxQty, 0)} ${qtyUnit}.`)
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .test('minQty', `Minimum quantity is ${formatter.formatNumber(minQty, 0)}`, function hasMinQty(quantity: any) {
        return quantity >= minQty;
      }),
    pairId: yup.number().required('You must select a contract'),
    price: yup
      .number()
      .required()
      .moreThan(0, 'Please enter price')
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      }),
    minBidQty: yup
      .number()
      .required('Please enter minimum bid quantity')
      .test('minQty', 'Minimum quantity must be lower than available quantity', function hasMaxQty(quantity: any) {
        return quantity <= this.parent.quantity;
      })
      .test(
        'minQty',
        `Minimum bid quantity is ${formatter.formatNumber(minBidQty, 0)}`,
        function hasMaxQty(quantity: any) {
          return quantity >= minBidQty;
        },
      ),
    maxBidQty: yup
      .number()
      .required('Please enter maximum bid quantity')
      .test('maxQty', 'Maximum quantity should be more than minimum quantity', function hasMaxQty(quantity: any) {
        return quantity >= this.parent.minBidQty;
      })
      .test('maxQty', 'Maximum quantity should be lower than available quantity', function hasMaxQty(quantity: any) {
        return quantity <= this.parent.quantity;
      }),
  });

type Props = {
  minLotQty: number;
  maxLotQty: number;
  bidMinLots: number;
  isProjectOffer: boolean;
  assetCategoryId?: AssetCategory;
  onSuccess?: () => void;
  onLoading?: (isLoading: boolean) => void;
  refetchAsks?: () => void;
};

const AddAskProjectForm = ({
  bidMinLots,
  minLotQty,
  maxLotQty,
  isProjectOffer,
  assetCategoryId = AssetCategory.token,
  onSuccess,
  refetchAsks,
  onLoading,
}: Props) => {
  const {
    selector: { getAuthToken, getAccountAddress, getUserId, getFullName },
    status: { canCmbOfferObo },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();
  const cmbOfferLabel = getSetting('web_settings_cmb_offer_label') ?? 'Market Board';
  const { pairsSelectOptions: pairsOptions, getPairById } = usePairs({
    filterByMarket: 'block',
    assetCategories: [AssetCategory[assetCategoryId]] as Array<AssetCategoryCode>,
  });
  const { product } = useMarketplaceProduct();

  const blockTradeDisclaimer = getSetting(
    Number(product) === Number(AssetCategory.token)
      ? 'web_settings_blockTrade_carbon_disclaimer'
      : 'web_settings_blockTrade_disclaimer',
  );

  const uniformPriceAuctionEnabled = Number(getSetting('web_settings_uniform_price_auction')) === 1;
  const [showConfirmModal, setConfirmModal] = useState(false);

  const defaultPair = getPairById(pairsOptions[0]?.value | 0) ?? null;
  const [selectedPair, setSelectedPair] = useState<Pair | null>(defaultPair);
  const lotToTons = selectedPair?.baseAsset?.lotQtySize || defaultPair?.baseAsset?.lotQtySize || 1;
  const minQty = minLotQty * lotToTons;
  const maxQty = maxLotQty * lotToTons;
  const minBidQty = bidMinLots * lotToTons;

  // Used for Members
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });
  const quantityUnit = selectedPair?.baseAsset?.uom?.code;

  const formProperties = useForm<FormData>({
    resolver: yupResolver(addProjectFormSchema(minBidQty, minQty, maxQty, quantityUnit)),
    defaultValues: {
      showBidVolume: !isProjectOffer && uniformPriceAuctionEnabled ? 0 : 1,
      showHighestBid: !isProjectOffer && uniformPriceAuctionEnabled ? 0 : 1,
    },
  });

  const priceSymbol = selectedPair?.quoteAsset?.symbol;

  const {
    getValues,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = formProperties;

  const selectProjectAndContractError = useMemo(() => {
    const errorsObject: Record<string, string> = {};

    if (errors.carbonProjectId?.message) {
      errorsObject.projectId = errors.carbonProjectId?.message;
    }

    if (errors.pairId?.message) {
      errorsObject.contractId = errors.pairId?.message;
    }

    if (errors.vintageYear?.message) {
      errorsObject.vintage = errors.vintageYear?.message;
    }

    return errorsObject;
  }, [errors]);

  const onSelectCarbonProject = (project: CarbonProject) => {
    setValue('carbonProjectId', project.id, {
      shouldValidate: true,
    });
  };

  const onChangeProjectAndContract = (projectAndContractValue: SelectProjectAndContractValue) => {
    setValue('carbonProjectId', projectAndContractValue.projectId);
    setValue('pairId', projectAndContractValue.contractId);
    setValue('vintageYear', projectAndContractValue.vintage as number);
  };

  const onRemoveCarbonProject = () => {
    setValue('carbonProjectId', undefined);
  };

  const onSubmitHandler = async (formData: FormData) => {
    if (formData.isAuction && (!formData.startDate || !formData.endDate)) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Please select start and end date',
      });
      return;
    }
    showToast({
      variant: ToastVariant.Info,
      message: 'Submitting your project..',
    });
    onLoading?.(true);

    // NOTE: Use tons for both cmb / auction.
    const qtyInTons = convertTextNumberToValue(formData.quantity);

    try {
      let project: Record<string, any> = {
        ...formData,
        quantity: qtyInTons,
        price: Number(formData.price),
        disallowPartialFilling: formData.disallowPartialFilling ? 1 : 0,
        vintageYear: Number(formData.vintageYear),
        carbonProjectId: Number(carbonProjectId),
        pairId: Number(formData.pairId),
        minBidQty: convertTextNumberToValue(formData.minBidQty),
        maxBidQty: convertTextNumberToValue(formData.maxBidQty),
        sellerUserId: selectedAccount.userId,
      };

      if (formData.isAuction === 1) {
        project = {
          ...project,
          sellerTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          showHighestBid: formData.showHighestBid ? 1 : 0,
          showBidVolume: formData.showBidVolume ? 1 : 0,
        };
      }

      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const addProject = await fetch('/api/user/carbon/cmb-project', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(project),
      });

      if (addProject?.ok) {
        showToast({
          variant: ToastVariant.Success,
          message: `Your project has been submitted for review. We will notify you once it's updated.`,
        });
        refetchAsks?.();
        onSuccess?.();
      } else {
        logger.warn(addProject);
        const error = await addProject?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
      }
    } catch (error: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: error?.message ?? 'Something went wrong!',
      });
    }
    onLoading?.(false);
  };

  const { carbonProjectId, pairId, isAuction } = watch();

  useEffect(() => {
    setValue('isAuction', isProjectOffer ? 0 : 1);
  }, [isProjectOffer, setValue]);

  useEffect(() => {
    if (pairsOptions.length > 0 && !pairId && assetCategoryId !== AssetCategory.fct) {
      setValue('pairId', pairsOptions[0].value);
      setSelectedPair(getPairById(pairsOptions[0].value) ?? null);
    }
  }, [pairsOptions, pairId, assetCategoryId, setValue, getPairById]);

  const years = Array(50)
    .fill(1)
    .map((_, i) => 2000 + i);
  const tokenUnit = selectedPair?.baseAsset?.uom?.name ?? defaultPair?.baseAsset?.uom?.name ?? '';

  const trySubmit = async () => {
    const isValid = await trigger();
    if (isValid === true) {
      if (isAuction || showConfirmModal) {
        if (showConfirmModal) setConfirmModal(false);
        onSubmitHandler(getValues());
      } else {
        setConfirmModal(true);
      }
    }
  };

  const environmentalCreditName = assetCategoryId === AssetCategory.rec ? 'certificates' : 'credits';

  return (
    <>
      <FormDevTool control={control} />
      <form
        onSubmit={(event) => {
          event.stopPropagation();
        }}
        autoComplete="off"
      >
        <ModalContent>
          <div className="flex flex-col gap-base">
            {canCmbOfferObo() && (
              <div className="flex flex-col gap-xs">
                <div className="flex flex-col flex-1 gap-2xs">
                  <Text variant={TypographyVariant.body2}>On Behalf Of</Text>
                  <SelectOboAccount
                    name="OBO"
                    inputValue={selectedAccount.account}
                    setFieldValue={(_selectedField, value) => {
                      setSelectedAccount({ account: value.value, userId: value.user_id, fullName: value.fullName });
                    }}
                  />
                </div>
              </div>
            )}
            {assetCategoryId === AssetCategory.fct ? (
              <SelectProjectAndContract onChange={onChangeProjectAndContract} error={selectProjectAndContractError} />
            ) : (
              <>
                <div className="flex flex-col gap-xs">
                  <Text variant={TypographyVariant.body2}>Project</Text>
                  <AddCarbonProject
                    assetCategoryId={assetCategoryId}
                    onChange={(project) => {
                      project ? onSelectCarbonProject(project) : onRemoveCarbonProject();
                    }}
                  />
                  {errors.carbonProjectId?.message && (
                    <Text variant={TypographyVariant.body2} color={TextColor.error}>
                      {errors.carbonProjectId?.message}
                    </Text>
                  )}
                </div>
                <div className="flex flex-col gap-xs">
                  <Select
                    label="Contract"
                    placeholder="Select contract"
                    error={errors.pairId?.message}
                    items={pairsOptions.map(({ value, label }) => ({ value: String(value), title: label }))}
                    value={getValues('pairId') ? String(getValues('pairId')) : ''}
                    onChange={({ value }) => {
                      setSelectedPair(getPairById(Number.parseInt(value) || 0) ?? null);
                      setValue('pairId', Number(value) || undefined, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                    {blockTradeDisclaimer}
                  </Text>
                </div>
                <div className="flex flex-col gap-xs">
                  <Select
                    label="Vintage Year"
                    placeholder="Select vintage year"
                    items={
                      years?.map((year: number) => ({
                        title: String(year),
                        value: String(year),
                      })) || []
                    }
                    onChange={({ value }) => {
                      setValue('vintageYear', Number(value) || undefined, {
                        shouldValidate: true,
                      });
                    }}
                    error={errors.vintageYear?.message}
                  />
                </div>
              </>
            )}

            {isAuction === 0 ? (
              <>
                <div className="flex flex-col w-full sm:flex-row gap-base">
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>
                      Available Quantity (Minimum: {formatter.formatNumber(minQty, 0)} {quantityUnit})
                    </Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'integer',
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: quantityUnit,
                      }}
                      value={getValues('quantity')}
                      onChange={(event) => {
                        setValue('quantity', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.quantity?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.quantity.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>Price Per {quantityUnit}</Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: priceSymbol,
                      }}
                      value={getValues('price')}
                      onChange={(event) => {
                        setValue('price', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.price?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.price.message}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full sm:flex-row gap-base">
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>Minimum Bid Qty</Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: quantityUnit,
                      }}
                      value={getValues('minBidQty')}
                      onChange={(event) => {
                        setValue('minBidQty', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.minBidQty?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.minBidQty.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>Maximum Bid Qty</Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: quantityUnit,
                      }}
                      value={getValues('maxBidQty')}
                      onChange={(event) => {
                        setValue('maxBidQty', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.maxBidQty?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.maxBidQty.message}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-xs">
                  <Checkbox
                    label="Disallow Partial Filling"
                    isChecked={getValues('disallowPartialFilling') === 1}
                    onChange={({ isChecked }) => setValue('disallowPartialFilling', isChecked ? 1 : 0)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row w-full gap-base">
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>
                      Quantity (Minimum: {formatter.formatNumber(minQty, 0)} {quantityUnit})
                    </Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: quantityUnit,
                      }}
                      value={getValues('quantity')}
                      onChange={(event) => {
                        setValue('quantity', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.quantity?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.quantity.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>Reserved Price</Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: selectedPair?.quoteAsset?.symbol,
                      }}
                      value={getValues('price')}
                      onChange={(event) => {
                        setValue('price', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.price?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.price.message}
                      </Text>
                    )}
                  </div>
                </div>
                <Text className="mt-base" variant={TypographyVariant.subtitle2}>
                  Auction Settings
                </Text>
                <div className="flex flex-row w-full gap-base">
                  <div className="flex flex-col flex-1">
                    <Text className="mb-xs" variant={TypographyVariant.body2}>
                      Start Date
                    </Text>
                    <DatePicker
                      selected={getValues('startDate')}
                      maxDate={getValues('endDate')}
                      showTimeSelect
                      onChange={(date) => {
                        setValue('startDate', date ?? undefined);
                      }}
                      onClear={() => {
                        setValue('startDate', undefined);
                      }}
                      placeholderText="Select Start Date"
                    />
                    {errors.startDate?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.startDate.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-col flex-1">
                    <Text className="mb-xs" variant={TypographyVariant.body2}>
                      End Date
                    </Text>
                    <DatePicker
                      minDate={getValues('startDate')}
                      selected={getValues('endDate')}
                      showTimeSelect
                      onChange={(date) => {
                        setValue('endDate', date ?? undefined);
                      }}
                      onClear={() => {
                        setValue('endDate', undefined);
                      }}
                      placeholderText="Select End Date"
                    />
                    {errors.endDate?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.endDate.message}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="flex flex-row w-full gap-base">
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>Minimum Bid Qty</Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: quantityUnit,
                      }}
                      value={getValues('minBidQty')}
                      onChange={(event) => {
                        setValue('minBidQty', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.minBidQty?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.minBidQty.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex flex-col flex-1 gap-xs">
                    <Text variant={TypographyVariant.body2}>Maximum Bid Qty</Text>
                    <Input
                      config={{
                        color: 'gray',
                        size: 'base',
                        validation: {
                          type: 'float',
                          numeralDecimalScale: 2,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        },
                        postfix: quantityUnit,
                      }}
                      value={getValues('maxBidQty')}
                      onChange={(event) => {
                        setValue('maxBidQty', (event?.target as any)?.rawValue || undefined, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.maxBidQty?.message && (
                      <Text variant={TypographyVariant.body2} color={TextColor.error}>
                        {errors.maxBidQty.message}
                      </Text>
                    )}
                  </div>
                </div>
                {(!isAuction || (isAuction && !uniformPriceAuctionEnabled)) && (
                  <div className="flex flex-row w-full gap-base">
                    <div className="flex flex-col flex-1 gap-xs">
                      <Text variant={TypographyVariant.body2}>Show Highest Bid</Text>
                      <Checkbox
                        isChecked={getValues('showHighestBid') === 1}
                        label="Highest bid will be visible to all bidders"
                        onChange={({ isChecked }) => setValue('showHighestBid', isChecked ? 1 : 0)}
                      />
                    </div>
                    <div className="flex flex-col flex-1 gap-xs">
                      <Text variant={TypographyVariant.body2}>Show Bid Volume</Text>
                      <Checkbox
                        isChecked={getValues('showBidVolume') === 1}
                        label="Bid volume will be visible to all bidders"
                        onChange={({ isChecked }) => setValue('showBidVolume', isChecked ? 1 : 0)}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            onPress={() => {
              trySubmit();
            }}
            type={ButtonType.Button}
            className="flex-1"
            isLoading={isSubmitting}
            endIcon={IconName.ArrowEnd}
          >
            Submit for Review
          </Button>
          <ConfirmModal
            isOpen={showConfirmModal}
            title="Disclaimer"
            onClose={() => {
              setConfirmModal(false);
            }}
            accept={{
              label: 'Accept and Submit',
              callback() {
                trySubmit();
              },
            }}
          >
            <div>
              <Text variant={TypographyVariant.subtitle1}>
                {`This project and your offer is subject for review and approval. Once approved it will be listed on the ${cmbOfferLabel}.`}
              </Text>
              <div className="flex flex-col mt-4 gap-base">
                {lotToTons > 0 && (
                  <>
                    <Text>
                      &#x2022; Please enter your {environmentalCreditName} quantity in {tokenUnit} (1 lot ={' '}
                      {formatter.formatNumber(lotToTons, 0)} {tokenUnit}).
                    </Text>

                    <Text>&#x2022; Bids against your project will be also in {tokenUnit}.</Text>
                  </>
                )}
                <Text>&#x2022; Bids may be for a lesser quantity (partial filling).</Text>
                <Text>
                  Contract type is indicative until seller confirms availability of {environmentalCreditName}: (a) bids
                  are confirmed, (b) {environmentalCreditName} are acquired
                </Text>
              </div>
            </div>
          </ConfirmModal>
        </ModalFooter>
      </form>
    </>
  );
};

export default AddAskProjectForm;

import BankAccount from 'refreshed-component/forms/BankAccount';

import { Card, Layer, styled, toSpacing } from '@aircarbon/ui';

const DashboardRoot = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(12)};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const BankDetails = () => {
  return (
    <DashboardRoot>
      <Layer>
        <Card>
          <BankAccount />
        </Card>
      </Layer>
    </DashboardRoot>
  );
};

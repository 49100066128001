import { Layer, styled } from '@aircarbon/ui';

import { BalanceCard } from './components/BalanceCard';
import { useGERBalance } from './hooks/useGERBalance';

export const GERBalance = () => {
  const { gerBalance, gerLogo, assetAllocations, isLoading } = useGERBalance();

  if (isLoading) {
    return null;
  }

  return (
    <StyledGERBalance>
      <BalanceCard label="GER" isTransparent logo={gerLogo}>
        {gerBalance}
      </BalanceCard>
      <Layer>
        {assetAllocations.map((item) => (
          <BalanceCard label={item.label}>{item.value}</BalanceCard>
        ))}
      </Layer>
    </StyledGERBalance>
  );
};

const StyledGERBalance = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 24px;
  align-self: flex-start;
  width: 100%;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 539px) {
    grid-template-columns: 1fr;
  }
`;

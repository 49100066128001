import { default as SimpleBarOrg } from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { styled, useLayerBackground } from '@aircarbon/ui';

const List = styled.div<{
  backgroundColor: string;
  opacity: number;
  content?: {
    minHeight?: string;
    minWidth?: string;
    flex?: boolean;
    position?: 'absolute' | 'relative';
    background?: string;
  };
}>`
  display: contents;

  > div {
    width: 100%;
    height: 100%;

    > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper > .simplebar-content {
      ${({ content }) => (content?.position ? `position: ${content?.position}` : '')};
      ${({ content }) => (content?.minHeight ? `min-height: ${content?.minHeight}` : '')};
      ${({ content }) => (content?.minWidth ? `min-width: ${content?.minHeight}` : '')};
      ${({ content }) => (content?.background ? `min-width: ${content?.background}` : '')};
      ${({ content }) => (content?.flex ? `display: flex` : '')};
    }

    > .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
      &.simplebar-visible:before {
        opacity: ${(props) => props.opacity} !important;
      }
      &:before {
        background-color: ${(props) => props.backgroundColor};
      }
    }
    > .simplebar-track.simplebar-vertical .simplebar-scrollbar {
      &.simplebar-visible:before {
        opacity: ${(props) => props.opacity} !important;
      }
      &:before {
        background-color: ${(props) => props.backgroundColor};
      }
    }
  }
`;

function SimpleBar({
  className,
  content,
  ...props
}: SimpleBarOrg.Props & {
  content?: {
    minHeight?: string;
    minWidth?: string;
    flex?: boolean;
    position?: 'absolute' | 'relative';
    background?: string;
  };
}) {
  const { layerBackground } = useLayerBackground();
  return (
    <List content={content} backgroundColor={layerBackground('layerHover')} opacity={0.5}>
      <SimpleBarOrg {...props} className={className} />
    </List>
  );
}

export function PlainSimpleBar({ className, ...props }: SimpleBarOrg.Props & { theme?: 'dark' | 'light' }) {
  return (
    <List backgroundColor={props.theme === 'dark' ? '#000' : '#fff'} opacity={props.theme === 'dark' ? 0.2 : 0.4}>
      <SimpleBarOrg {...props} className={className} />
    </List>
  );
}

export default SimpleBar;

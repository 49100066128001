import { useHistory } from 'react-router-dom';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';

import { Button, ButtonSize, Layer, styled, toSpacing } from '@aircarbon/ui';
import { AssetCategory } from '@aircarbon/utils-common';

import { ProjectInformation, type ProjectInformationProps } from './components/ProjectInformation';
import { Sidebar } from './components/Sidebar';

export interface ProjectDetailProps {
  projectInformation: ProjectInformationProps;
  milestones: Array<[string, string, string]>;
  markets: Array<{
    id: string;
    token: string;
    bid: string;
    ask: string;
    last: string;
  }>;
  title: string;
  creditingPeriod: string;
  onPressTrade?(): void;
  onBack(): void;
}

export const ProjectDetail: React.FC<ProjectDetailProps> = (props) => {
  const { projectInformation, milestones, markets, creditingPeriod, title, onPressTrade, onBack } = props;
  const history = useHistory();
  const onPressAsset = (assetKey: string) => {
    history.push({
      pathname: `/account/trade`,
      search: new URLSearchParams({
        type: 'fct',
        pair: assetKey,
        assetCategoryId: String(AssetCategory.fct),
        projectId: projectInformation.id,
      }).toString(),
    });
  };

  return (
    <>
      <PageHeader
        title={title}
        margin="24px"
        onBack={onBack}
        suffix={
          onPressTrade ? (
            <StyledHeaderButton size={ButtonSize.l} onPress={onPressTrade}>
              Trade
            </StyledHeaderButton>
          ) : undefined
        }
      />
      <DetailsContainer>
        <ProjectInformation {...projectInformation} />
        <Layer>
          <Sidebar
            creditingPeriod={creditingPeriod}
            milestones={milestones}
            markets={markets}
            onPressAsset={onPressAsset}
          />
        </Layer>
      </DetailsContainer>
    </>
  );
};

const StyledHeaderButton = styled(Button)`
  min-width: 170px;
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 ${({ theme }) => toSpacing(theme)(12)};
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

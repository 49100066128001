import { useCallback } from 'react';
import { usePaginatedQuery } from 'react-query';

import { usePagination } from 'pages/account/trading/hooks/usePagination';

import { fetchFiatHistory } from 'data-provider/fiat/fetchFiatHistory';

export enum FiatHistory {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export enum Status {
  ALL = 'ALL',
  PENDING = 'PENDING',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
}

export enum StatusLabel {
  ALL = 'All',
  PENDING = 'Pending',
  DONE = 'Done',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
  PROCESSING = 'Processing',
}

export const useFiatHistory = (type: FiatHistory, status: Status[] = []) => {
  const pagination = usePagination();
  const statusQuery = status.filter((item) => item !== Status.ALL).join(',');
  const fetchData = useCallback(
    async (type) =>
      await fetchFiatHistory({
        type,
        statusQuery: statusQuery ? statusQuery : undefined,
        page: pagination.page,
        pageSize: pagination.pageSize,
      }),
    [statusQuery, pagination.page, pagination.pageSize],
  );

  const {
    status: queryStatus,
    error,
    resolvedData,
    isLoading,
  } = usePaginatedQuery([type, pagination.page, pagination.pageSize, statusQuery], fetchData);

  return {
    pagination,
    data: { status: queryStatus, error, isLoading, resolvedData },
  };
};

import Big from 'big.js';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaExclamationTriangle } from 'react-icons/fa';
import { queryCache } from 'react-query';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { Input } from 'refreshed-component/atoms/Input';

import {
  Alert,
  AlertColor,
  AlertVariant,
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  Select,
  Tabs,
  TabsVariant,
  Text,
  TextAs,
  TextColor,
  ToastVariant,
  Tooltip,
  TypographyVariant,
  showToast,
  styled,
  toSpacing,
  useBorderColor,
  useLayerBackground,
  useTextColor,
} from '@aircarbon/ui';
import { FeeType, formatter, helpers } from '@aircarbon/utils-common';
import type { MarketFlags } from '@aircarbon/utils-common/src/dto';

import Loading from 'components/styled/Loading';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { isTouchDevice } from 'utils/helpers';

import { DialogContext } from '../components/Dialog';
import InfoList from '../components/InfoList';
import SimpleBar from '../components/Simplebar';
import styles from '../components/index.module.scss';
import { Hint } from '../components/styled';
import type { Asset, Pair, TimeInForce } from '../hooks';
import { useExternalMarketAsset } from '../hooks/externalMarketAsset';
import { useExternalBrokerClient } from '../hooks/useExternalBrokerClient';
import { useFobTypes } from '../hooks/useFobTypes';
import useMarketSettings from '../hooks/useMarketSettings';
import { usePlaceOrder } from '../hooks/usePlaceOrderBiofuel';
import { useShippingTypes } from '../hooks/useShippingTypes';
import { PlaceOrderContext } from './PlaceOrderCarbon';

const Adjust = styled.div`
  position: absolute;
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  padding-left: 16px;
  padding-right: 16px;
  .spinner {
    width: 25px;
    height: 25px;
    top: 30%;
    left: 25%;
    &:after {
      width: 20px;
      height: 20px;
    }
  }
  input {
    font-size: 13px;
  }

  .place-order-button {
    width: 100%;
    height: 40px;
  }
  .dropdown {
    background: #2c2f2f;
    border: none;
  }
`;

const isMarketSettingEnabled = ({
  settingName,
  pairSettingName,
  marketSettings,
  pairSettings,
}: {
  settingName: 'biofuelEntryEnabled' | 'biofuelMatchingEnabled';
  pairSettingName: 'orderEntryEnabled' | 'matchingEngineEnabled';
  pairSettings: Record<string, any>;
  marketSettings?: MarketFlags;
}): boolean => {
  const isOrderEntryEnabled = marketSettings?.[settingName] === 1;
  const isPairOrderEntryEnabled = pairSettings?.[pairSettingName] === 1;

  return isOrderEntryEnabled && isPairOrderEntryEnabled;
};

type Props = {
  pairs: Pair[];
  pair: string | undefined;
  timeInForces: TimeInForce[];
  ccyAsset: Asset | undefined;
  lastTradedPrice: number | undefined;
  pairWatchlist?: Pair;
  height?: 'fixed' | 'auto';
  layout?: string;
};

// TODO: refactor this component to use react-hook-form
const PlaceOrder = ({ pairs, pair, ccyAsset, height, timeInForces }: Props) => {
  const {
    selector: { getAccountAddress },
    status: { canSeeExternalBrokerClientForBiofuel, canTradeBiofuel },
  } = User.useContainer();
  const {
    selector: { mainCcySymbol },
  } = Entity.useContainer();
  const { getSetting } = UI.useContainer();
  const [isSell, setSell] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<string>('Limit');
  const [price, setPrice] = useState<string>();
  const [brokerClient, setBrokerClient] = useState<{
    id: number;
    value: string;
  } | null>(null);
  const [qty, setQty] = useState<string>();
  const [fobType, setFobType] = useState<{
    id: number;
    value: string;
  } | null>(null);
  const [shipping, setShipping] = useState<{
    id: number;
    value: string;
  }>({
    id: 6,
    value: 'Week 5',
  });

  const [timeInForce, setTimeInForce] = useState<string>('');
  const [expiryUtc, setExpiryUtc] = useState<Date | null | undefined>();
  const [tradeFee, setTradeFee] = useState<number>(0);
  const [referencePrice, setReferencePrice] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [validationTriedCount, setValidationTryCount] = useState<number>(0);
  const { showDialog, removeDialog } = useContext(DialogContext);
  const { placeOrderData } = useContext(PlaceOrderContext);
  const { marketSettings } = useMarketSettings({});

  const { data: clientList } = useExternalBrokerClient();

  const token = pair ? pair.split('/')[0] : '';
  const ccy = pair ? pair.split('/')[1] : mainCcySymbol; // As a default

  const pairMapper: Record<string, Record<string, any>> = pairs.reduce((prevPair, currPair) => {
    return {
      ...prevPair,
      [currPair.name]: {
        id: currPair?.id,
        baseAsset: currPair.baseAsset,
        quoteAsset: currPair.quoteAsset,
        scTokenTypeId: currPair?.baseAsset?.scId,
        scCcyTypeId: currPair?.quoteAsset?.scId,
        baseAssetUomScRatio: currPair?.baseAsset?.uom?.scRatio,
        quoteAssetUomScRatio: currPair?.quoteAsset?.uom?.scRatio,
        autoRetireAsset: currPair?.baseAsset?.autoRetire,
        mapLot: currPair?.settings?.orderQtyMultiple || 1000,
        minOrder: currPair?.settings?.minOrderQty || 1000,
        maxOrder: currPair?.settings?.maxOrderQty || 100000,
        limitUpPercentage: currPair?.settings?.limitUpPercentage || 10,
        limitDownPercentage: currPair?.settings?.limitDownPercentage || 10,
        orderEntryEnabled: currPair?.marketFlags?.orderEntryEnabled,
        matchingEngineEnabled: currPair?.marketFlags?.matchingEngineEnabled,
        priceIncrement: currPair?.settings?.priceIncrement,
        lotQtySize: currPair?.baseAsset?.lotQtySize,
      },
    };
  }, {});

  const timeInFormMapper: Record<string, string> = timeInForces.reduce((prevPair, currPair) => {
    return { ...prevPair, [currPair.timeInForce]: currPair.id };
  }, {});

  useEffect(() => {
    if (!timeInForce && timeInForces) setTimeInForce(timeInForces[0]?.timeInForce);
  }, [timeInForce, timeInForces]);

  const pairInfo = pair ? pairMapper[pair] : {};

  const tokenNumDecimals = pair ? pairMapper[pair]?.baseAsset?.numDecimals : 0;
  const ccyNumDecimals = pair ? pairMapper[pair]?.quoteAsset?.numDecimals : 2;
  const mapLotToUom = pair ? pairMapper[pair]?.mapLot : 1;
  const lotQtySize = pair ? pairMapper[pair]?.lotQtySize : 1;
  const minQtyOrder = pair ? pairMapper[pair]?.minOrder : 1000;
  const maxQtyOrder = pair ? pairMapper[pair]?.maxOrder : 100000;
  const unitPriceIncrement = pair ? pairMapper[pair]?.priceIncrement : undefined;

  const { fobTypes } = useFobTypes(pair ? pairMapper[pair]?.baseAsset?.id : Number.NaN);
  const { shippingTypes } = useShippingTypes();
  const { externalMarketICE } = useExternalMarketAsset('ICE');

  const debouncedTokenQty = useDebounce(qty, 500);
  const debouncedPrice = useDebounce(price, 500);
  const debouncedTotalAmount = (Number(debouncedPrice) + referencePrice) * (Number(debouncedTokenQty) * mapLotToUom);

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.TRADE_SPOT_FEE,
      assetCategoryId: pair ? pairMapper[pair]?.baseAsset?.assetCategoryId : undefined,
      tokenQty: Number(debouncedTokenQty) * lotQtySize,
      totalAmount: debouncedTotalAmount,
    },
    options: { enabled: Number(debouncedTokenQty) > 0 && debouncedTotalAmount > 0 },
  });

  useEffect(() => {
    if (externalMarketICE?.price) {
      setReferencePrice(externalMarketICE?.price);
    } else {
      setReferencePrice(0);
    }
  }, [externalMarketICE?.price]);

  const { placeOrder: mutate, isLoading } = usePlaceOrder();

  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const quantityInput = useRef<HTMLInputElement>(null);
  const priceInput = useRef<HTMLInputElement>(null);
  const datePicker = useRef<HTMLButtonElement>(null);

  const pairSettings = pair ? pairMapper[pair] : {};

  const isMarketOrderEntryEnabled = marketSettings?.biofuelEntryEnabled === 1;
  const isPairOrderEntryEnabled = pairSettings?.orderEntryEnabled === 1;

  const isOrderEntryEnabled = isMarketSettingEnabled({
    settingName: 'biofuelEntryEnabled',
    pairSettingName: 'orderEntryEnabled',
    marketSettings,
    pairSettings,
  });

  const isMatchingEngineEnabled = isMarketSettingEnabled({
    settingName: 'biofuelMatchingEnabled',
    pairSettingName: 'matchingEngineEnabled',
    marketSettings,
    pairSettings,
  });

  useEffect(() => {
    if (placeOrderData) {
      const { price, quantity, side, fobTypeId, shippingTypeId } = placeOrderData;
      if (price !== undefined && quantity) {
        if (orderType !== 'Limit') resetOnTypeChange('Limit');
        setPrice(price.toString());
        setQty((quantity / mapLotToUom).toString());
        setSell(side === 'Sell');
        setFobType(
          fobTypes
            ?.map(({ id, name }) => ({
              id,
              value: name,
            }))
            .find((item) => fobTypeId && item.id === Number(fobTypeId)) || null,
        );
        setShipping(
          shippingTypes
            ?.map(({ id, name }) => ({
              id,
              value: name,
            }))
            .find((item) => shippingTypeId && item.id === Number(shippingTypeId)) || shipping,
        );
        setTimeout(() => {
          refSubmitButton.current?.click();
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderData]);

  const reservedBalanceTip = () => 'Absolute Price (FRONT ICE Gasoil + Premium) x Quantity + Fee';
  const iceReferencePriceDisclaimer = getSetting('web_settings_ICEReferencePriceDisclaimer');
  const frontIceGasoilTip = () => iceReferencePriceDisclaimer;

  const checkPlaceOrderValidation = useCallback(() => {
    if (!qty || isNaN(Number(qty)) || Number(qty) <= 0) {
      setError('Please specify a valid quantity.');
      return;
    }
    if (Number(qty) * mapLotToUom < minQtyOrder) {
      setError(`Insufficient quantity, minimum is ${formatter.formatNumber(minQtyOrder, 0)}.`);
      return;
    }
    if (Number(qty) * mapLotToUom > maxQtyOrder) {
      setError(`Exceeds maximum allowed quantity of ${formatter.formatNumber(maxQtyOrder, 0)}.`);
      return;
    }
    if (Number(qty) % 1) {
      setError('Odd lots not allowed.');
      return;
    }
    if (canSeeExternalBrokerClientForBiofuel() && !brokerClient?.value) {
      setError('Please select Client.');
      return;
    }
    if (!fobType?.value) {
      setError('Please select FOB.');
      return;
    }
    if (orderType !== 'Market') {
      if (!price || isNaN(Number(price))) {
        setError('Please specify a valid premium');
        return;
      }
      if ((price || '').toString().split('.')?.[1]?.length > (ccyAsset?.numDecimals || 2)) {
        setError(`Only ${ccyAsset?.numDecimals || 2} decimals is allowed`);
        return;
      }
      if (unitPriceIncrement && +helpers.fmod(Big(price), Big(unitPriceIncrement)) !== 0) {
        setError(`Valid price increment (step) is ${unitPriceIncrement}.`);
        return;
      }
    }
    setError('');
    const refreshedTotal = feeAmount + (Number(price) + referencePrice) * (Number(qty) * mapLotToUom);
    setTradeFee(feeAmount);
    setTotal(refreshedTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ccyAsset?.numDecimals,
    isSell,
    mapLotToUom,
    minQtyOrder,
    orderType,
    price,
    qty,
    fobType,
    fobType?.value,
    brokerClient?.value,
    feeAmount,
  ]);

  useEffect(() => {
    checkPlaceOrderValidation();
  }, [checkPlaceOrderValidation]);
  const { textColor } = useTextColor();
  const { layerBackground } = useLayerBackground();
  const { borderColor } = useBorderColor();

  const placeOrderValidation = (): boolean => {
    if (!qty || Number(qty) <= 0) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Quantity must be greater than zero',
      });
      quantityInput.current?.focus();
      return false;
    }
    if (orderType !== 'Market') {
      if (!price || isNaN(Number(price))) {
        showToast({
          variant: ToastVariant.Danger,
          message: 'Premium must be a valid number',
        });
        priceInput.current?.focus();
        return false;
      }
      if (unitPriceIncrement && +helpers.fmod(Big(price), Big(unitPriceIncrement)) !== 0) {
        showToast({
          variant: ToastVariant.Danger,
          message: `Valid price increment (step) is ${unitPriceIncrement}.`,
        });
        priceInput.current?.focus();
        return false;
      }
    }
    if (isNaN(Number(qty)) || isNaN(Number(price))) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Quantity and Price must be valid numbers',
      });
      return false;
    }
    if (Number(qty) % 1) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Odd lots not allowed',
      });
      return false;
    }
    if (Number(qty) * mapLotToUom < minQtyOrder) {
      showToast({
        variant: ToastVariant.Danger,
        message: `Insufficient quantity, minimum is ${formatter.formatNumber(minQtyOrder, 0)}.`,
      });
      return false;
    }
    if (Number(qty) * mapLotToUom > maxQtyOrder) {
      setError(`Exceeds maximum allowed quantity of ${formatter.formatNumber(maxQtyOrder, 0)}.`);
      return false;
    }
    if (timeInForce === 'Good Till Date' && !expiryUtc) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Expiry date is required, and it must be later than the current date and time.',
      });
      datePicker.current?.focus();
      return false;
    }
    if ((price || '').toString().split('.')?.[1]?.length > (ccyAsset?.numDecimals || 2)) {
      showToast({
        variant: ToastVariant.Danger,
        message: `Only ${ccyAsset?.numDecimals || 2} decimals is allowed`,
      });
      return false;
    }
    if (canSeeExternalBrokerClientForBiofuel() && !brokerClient?.value) {
      showToast({
        variant: ToastVariant.Danger,
        message: `Please select Client.`,
      });
      return false;
    }
    if (!fobType?.value) {
      showToast({
        variant: ToastVariant.Danger,
        message: `Please select FOB.`,
      });
      return false;
    }
    return true;
  };

  const finallyPlaceOrder = () => {
    let order: Record<string, any> = {
      orderType,
      pairId: pair && (pairMapper[pair].id || pairs?.[0]?.id),
      orderSide: isSell ? 'Sell' : 'Buy',
      qty: Number(qty) * mapLotToUom,
      accountAddress: getAccountAddress(),
      timeInForceTypeId: timeInFormMapper[timeInForce],
      extraDetails: {
        type: 'biofuel',
        values: {
          fobId: fobType?.id,
          clientId: brokerClient?.id,
          shippingId: shipping.id,
        },
      },
    };

    if (timeInForce === 'Good Till Date' && expiryUtc) order.expiryUtc = expiryUtc;

    if (orderType !== 'Market') {
      order = {
        ...order,
        price: Number(price),
      };
    }

    mutate(order)
      .then((data) => {
        if (data?.order?.order_id) {
          queryCache.invalidateQueries(['oms-orders']);
          resetOnTypeChange('Limit');
        } else {
          const errorResponse = (data as any)?.json;
          showToast({
            variant: ToastVariant.Danger,
            message: errorResponse ? errorResponse?.message : 'Something went wrong!',
          });
        }
      })
      .catch((err) => {
        showToast({
          variant: ToastVariant.Danger,
          message: err?.message ?? 'Something went wrong!',
        });
      });
  };

  const tryToPlaceOrder = () => {
    if (isOrderEntryEnabled) {
      setValidationTryCount(validationTriedCount + 1);
      const restingOrder = false;
      if (placeOrderValidation()) {
        showDialog?.({
          title: 'Place Order',
          message: (
            <>
              <div className="flex flex-col gap-5 items-center w-full h-auto" style={{ minWidth: '350px' }}>
                <Text>Please confirm order details</Text>
                <div className="flex flex-col flex-shrink gap-1 w-11/12">
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Pair:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>{`${pair}`}</Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Side:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      {isSell ? 'Sell' : 'Buy'}{' '}
                    </Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Type:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>{`${orderType}`}</Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Quantity:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>{`${qty}`}</Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Price:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      {orderType === 'Market' ? 'MKT' : `${price}`}
                    </Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Time In Force:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>{`${timeInForce}`}</Text>
                  </div>
                  {timeInForce === 'Good Till Date' && (
                    <div className="flex flex-row gap-4">
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                        Expiry Date:
                      </Text>
                      <Text
                        variant={TypographyVariant.body2}
                        color={TextColor.secondary}
                      >{`${expiryUtc ? moment(expiryUtc).format('MMMM Do YYYY, h:mm:ss a') : '-'} `}</Text>
                    </div>
                  )}
                  {canSeeExternalBrokerClientForBiofuel() && (
                    <div className="flex flex-row gap-4">
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                        Client:
                      </Text>
                      <Text
                        variant={TypographyVariant.body2}
                        color={TextColor.secondary}
                      >{`${brokerClient?.value || 'Any'}`}</Text>
                    </div>
                  )}
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      FOB:
                    </Text>
                    <Text
                      variant={TypographyVariant.body2}
                      color={TextColor.secondary}
                    >{`${fobType?.value || 'Any'}`}</Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Shipping:
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>{`${shipping.value}`}</Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Reference Price:
                      <span className="inline-block relative top-1 left-1 cursor-pointer">
                        <Tooltip value={frontIceGasoilTip()}>
                          <Icon name={IconName.InfoCircle} />
                        </Tooltip>
                      </span>
                    </Text>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>{`${referencePrice}`}</Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Fee:
                    </Text>
                    <Text>
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary} as={TextAs.span}>
                        {formatter.formatNumber(tradeFee)}{' '}
                      </Text>
                      {pairInfo?.quoteAsset?.uom?.name}
                    </Text>
                  </div>
                  <div className="flex flex-row gap-4">
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary} className="flex-1 text-left">
                      Total:
                    </Text>
                    <Text>
                      <Text variant={TypographyVariant.body2} color={TextColor.secondary} as={TextAs.span}>
                        {isNaN(total) ? 0 : formatter.formatNumber(total)}{' '}
                      </Text>
                      {pairInfo?.quoteAsset?.uom?.name}
                    </Text>
                  </div>
                </div>
              </div>
              {restingOrder && (
                <div className="pt-5">
                  <div
                    className="flex flex-row items-center p-1 text-left"
                    style={{
                      color: textColor(TextColor.fieldPrimary),
                      backgroundColor: layerBackground('field'),
                      border: borderColor(BorderColor.neutral),
                    }}
                  >
                    <FaExclamationTriangle size="45px" className="mr-2 ml-2 text-yellow-300" />
                  </div>
                </div>
              )}
            </>
          ),
          cancel: {
            style: {
              height: '34px',
              minWidth: '150px',
            },
            onClick: () => {
              removeDialog?.();
              if (!isTouchDevice()) refSubmitButton.current?.focus();
            },
          },
          accept: {
            style: {
              height: '34px',
              minWidth: '150px',
            },
            onClick: () => {
              finallyPlaceOrder();
              removeDialog?.();
              if (!isTouchDevice()) quantityInput.current?.focus();
            },
          },
        });
      }
    }
  };

  const resetOnTypeChange = (orderTypeValue: string) => {
    setOrderType(orderTypeValue);
    setQty('');
    setPrice('');
    setFobType(null);
    setBrokerClient(null);
    setShipping({
      id: 6,
      value: 'Week 5',
    });
    if (orderTypeValue !== 'Market') {
      setTimeInForce(timeInForces[0]?.timeInForce);
    } else {
      setTimeInForce('Day');
    }
    setExpiryUtc(null);
    setValidationTryCount(0);
    setError('');
  };

  const setDateExpiry = (date: any) => {
    if (date) {
      if (date?.toDate() > new Date()) {
        setExpiryUtc(date?.toDate());
      } else {
        setExpiryUtc(expiryUtc === null ? undefined : null);
        showToast({
          variant: ToastVariant.Danger,
          message: 'Expiry date must be later than the current date and time.',
        });
      }
    } else {
      setExpiryUtc(expiryUtc === null ? undefined : null);
    }
  };

  const isPlaceOrderButtonDisabled = Boolean(!validationTriedCount ? false : (error ?? false)) || isLoadingFeeAmount;

  if (!marketSettings || pairs.length === 0) return <Loading />;

  const isFormEnabled = isOrderEntryEnabled && canTradeBiofuel();

  const content = (
    <div
      style={{
        display: 'contents',
      }}
    >
      {(!isOrderEntryEnabled || !isMatchingEngineEnabled) && (
        <Wrapper className="flex flex-col mt-2 w-full">
          <div className="relative">
            {!isMarketOrderEntryEnabled && (
              <Alert
                className="mb-1"
                color={AlertColor.Danger}
                variant={AlertVariant.Secondary}
                title="Order entry is disabled"
              />
            )}
            {isMarketOrderEntryEnabled && !isPairOrderEntryEnabled && (
              <Alert
                className="mb-1"
                color={AlertColor.Danger}
                variant={AlertVariant.Secondary}
                title="Order entry is disabled for this contract"
              />
            )}
            {!isMatchingEngineEnabled && (
              <Alert
                className="mb-1"
                color={AlertColor.Danger}
                variant={AlertVariant.Secondary}
                title="Matching of orders is disabled"
              />
            )}
          </div>
        </Wrapper>
      )}
      <Wrapper
        style={{
          opacity: isFormEnabled ? 1 : 0.5,
          pointerEvents: isFormEnabled ? 'auto' : 'none',
          userSelect: isFormEnabled ? 'inherit' : 'none',
        }}
        className="flex flex-col mt-2 w-full"
      >
        <StyledBuySellTabs
          activeItemId={isSell ? 'sell' : 'buy'}
          variant={TabsVariant.Contained}
          onPressTab={(tab) => {
            if (tab.id === 'sell') {
              setSell(true);
              return;
            }
            setSell(false);
          }}
          items={[
            {
              label: 'BUY',
              id: 'buy',
            },
            {
              label: 'SELL',
              id: 'sell',
            },
          ]}
        />
        <div className="flex relative flex-row">
          <div className="flex flex-1 items-center">
            <div>
              <Tabs
                className="order-types"
                onPressTab={(item) => {
                  if (item.id !== orderType) resetOnTypeChange(item.id);
                }}
                activeItemId={orderType}
                items={[
                  {
                    label: 'Limit',
                    id: 'Limit',
                  },
                  {
                    label: 'Market',
                    id: 'Market',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-base">
          <div>
            <div className="flex flex-col gap-2xs">
              <Text variant={TypographyVariant.body2}>Quantity</Text>
              <Input
                ref={quantityInput}
                config={{
                  size: 'sm',
                  postfix: (
                    <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                      LOTS
                    </Text>
                  ),
                  color: 'gray',
                }}
                onEnter={() => tryToPlaceOrder()}
                onKeyDownCapture={(event) => {
                  if (event.key === 'Tab' && event.shiftKey === true) {
                    event.preventDefault();
                    if (refSubmitButton.current) {
                      refSubmitButton.current.focus();
                    }
                  }
                }}
                value={qty}
                onBlur={() => qty?.endsWith('.') && setQty(`${qty}0`)}
                onChange={(e) => {
                  const value = e.target.value === '.' ? '0.' : e.target.value;
                  const re = RegExp(`${tokenNumDecimals ? `(^\\d+\\.\\d{0,${tokenNumDecimals}}$)|` : ``}(^\\d+$)`);
                  if (value === '' || re.test(value)) {
                    setQty(value);
                  }
                }}
              />
              <Hint
                className="mt-1"
                style={{
                  color: textColor(TextColor.secondary),
                }}
              >
                <span className="code">{formatter.formatNumber(Number(qty ?? 0) * mapLotToUom, 0)}</span>{' '}
                {pairInfo?.baseAsset?.uom?.name} of {token}
              </Hint>
            </div>
            {orderType !== 'Market' ? (
              <div className="flex flex-col gap-2xs">
                <Text variant={TypographyVariant.body2}>Premium</Text>
                <Input
                  ref={priceInput}
                  config={{
                    size: 'sm',
                    postfix: (
                      <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                        {pairInfo?.quoteAsset?.uom?.name}
                      </Text>
                    ),
                    color: 'gray',
                  }}
                  value={price}
                  onBlur={() => price?.endsWith('.') && setPrice(`${price}0`)}
                  onChange={(e) => {
                    const value = e.target.value === '.' ? '0.' : e.target.value;
                    const re = RegExp(
                      `${ccyNumDecimals ? `(^-?\\d+\\.\\d{0,${ccyNumDecimals}}$)|` : ``}(^-?\\d+$)|(^-$)`,
                    );
                    if (value === '' || re.test(value)) {
                      setPrice(value);
                    }
                  }}
                  disabled={orderType === 'Market'}
                  onEnter={() => tryToPlaceOrder()}
                />
              </div>
            ) : (
              false
            )}
          </div>
          <div className="flex flex-col gap-2 justify-end">
            {canSeeExternalBrokerClientForBiofuel() && (
              <div className="flex relative flex-1 gap-4 items-center mt-1">
                <Select
                  className="flex-1"
                  items={
                    clientList?.map((item) => ({
                      value: String(item.id),
                      title: item.name,
                    })) ?? []
                  }
                  label="Client"
                  placeholder="Select Client"
                  value={String(brokerClient?.id ?? '')}
                  onChange={({ value }) => {
                    const selectedClient = clientList?.find((client) => client.id === Number(value));
                    setBrokerClient({ id: selectedClient?.id, value: selectedClient?.name ?? '' });
                  }}
                />
              </div>
            )}
            <div className="flex relative flex-1 gap-4 items-center mt-1">
              <Select
                className="flex-1"
                items={
                  fobTypes?.map((item) => ({
                    value: String(item.id),
                    title: item.name,
                  })) ?? []
                }
                label="FOB"
                placeholder="Select FOB"
                value={String(fobType?.id ?? '')}
                onChange={({ value }) => {
                  const selectedFob = fobTypes?.find((client) => client.id === Number(value));

                  // eslint-disable-next-line no-unused-expressions
                  !selectedFob
                    ? setFobType(null)
                    : setFobType({ id: Number(selectedFob.id), value: selectedFob.name ?? '' });
                }}
              />
            </div>
            <div className="flex relative flex-1 gap-4 items-center mt-1">
              <Select
                className="flex-1"
                items={
                  shippingTypes?.map((item) => ({
                    value: String(item.id),
                    title: item.name,
                  })) ?? []
                }
                label="Shipping"
                placeholder="Select Shipping"
                value={String(shipping.id ?? '')}
                onChange={({ value }) => {
                  const selectedShipping = shippingTypes?.find((client) => client.id === Number(value));

                  // eslint-disable-next-line no-unused-expressions
                  !selectedShipping
                    ? setShipping(null)
                    : setShipping({ id: Number(selectedShipping.id), value: selectedShipping.name ?? '' });
                }}
              />
            </div>
            {orderType !== 'Market' && (
              <div className="flex flex-col gap-4 justify-end mt-1">
                <Select
                  items={timeInForces.map((item) => ({
                    value: item.timeInForce.toString(),
                    title: item.timeInForce,
                  }))}
                  value={timeInForce}
                  className="flex-1"
                  label="Time in Force"
                  placeholder="Select..."
                  onChange={({ value }) => {
                    setTimeInForce(value);
                  }}
                />

                {timeInForce === 'Good Till Date' && (
                  <DatePicker
                    ref={datePicker}
                    selected={expiryUtc}
                    onChange={(date) => {
                      setDateExpiry(date ? moment(date) : undefined);
                    }}
                    onClear={() => {
                      setDateExpiry(undefined);
                    }}
                    showTimeSelect={true}
                    disabled={orderType === 'Market'}
                    placeholderText="Expiry Date"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <InfoList
          className="mt-1"
          border={!isFormEnabled ? 'transparent' : isSell ? 'red' : 'green'}
          borderStyle={'solid'}
          info={[
            {
              label: (
                <Text as={TextAs.span} variant={TypographyVariant.body2} color={TextColor.secondary}>
                  Total fee (exc. VAT)
                </Text>
              ),
              value: (
                <Text as={TextAs.span} variant={TypographyVariant.body2} color={TextColor.secondary}>
                  <span>{formatter.formatNumber(tradeFee)} </span>
                  {pairInfo?.quoteAsset?.uom?.name}
                </Text>
              ),
            },
            {
              label: (
                <Text as={TextAs.span} variant={TypographyVariant.body2} color={TextColor.secondary}>
                  FRONT ICE Gasoil
                  <span className="inline-block relative top-1 left-1 cursor-pointer">
                    <Tooltip value={frontIceGasoilTip()} />
                  </span>
                </Text>
              ),
              value: (
                <Text as={TextAs.span} variant={TypographyVariant.body2} color={TextColor.secondary}>
                  <span>{isNaN(referencePrice) ? 0 : formatter.formatNumber(referencePrice)} </span>
                  {pairInfo?.quoteAsset?.uom?.name}
                </Text>
              ),
            },
            {
              label: (
                <Text as={TextAs.span} variant={TypographyVariant.body2} color={TextColor.secondary}>
                  Total
                  <span className="inline-block relative top-1 left-1 cursor-pointer">
                    <Tooltip value={reservedBalanceTip()}>
                      <Icon name={IconName.InfoCircle} />
                    </Tooltip>
                  </span>
                </Text>
              ),
              value: (
                <Text as={TextAs.span} variant={TypographyVariant.body2} color={TextColor.secondary}>
                  <span>{isNaN(total) ? 0 : formatter.formatNumber(total)}</span> {ccy}
                </Text>
              ),
            },
          ]}
        />
        {!!validationTriedCount && (
          <div className="flex relative flex-row flex-auto">
            <span className="text-red-500 text-nowrap">{error}</span>
          </div>
        )}
      </Wrapper>
    </div>
  );

  return height === 'auto' ? (
    <div className="flex flex-col h-full max-h-full">
      <div className="overflow-hidden relative flex-row flex-1">{content}</div>
      <div className="flex relative flex-col flex-auto justify-center">
        {isLoading ? (
          <div className="spinner">{}</div>
        ) : (
          <Button
            ref={refSubmitButton}
            className={`place-order-button ${styles['border-focused-white']}`}
            variant={ButtonVariant.primary}
            size={ButtonSize.l}
            key={`isSell-${isSell}`}
            onPress={tryToPlaceOrder}
            isDisabled={isPlaceOrderButtonDisabled || !isOrderEntryEnabled}
          >
            PLACE ORDER - ${pair}
          </Button>
        )}
      </div>
    </div>
  ) : (
    <Adjust>
      <div className="flex flex-col h-full max-h-full">
        <div className="overflow-hidden relative flex-col flex-1">
          <div className="absolute w-full h-full">
            <SimpleBar>{content}</SimpleBar>
          </div>
        </div>
        <div className="flex relative flex-col justify-center px-base pt-small">
          <Button
            ref={refSubmitButton}
            key={`isSell-${isSell}`}
            variant={ButtonVariant.primary}
            size={ButtonSize.l}
            isLoading={isLoading}
            onPress={tryToPlaceOrder}
            isDisabled={isLoading ? true : isPlaceOrderButtonDisabled || !isOrderEntryEnabled}
          >
            PLACE ORDER - ${pair}
          </Button>
        </div>
      </div>
    </Adjust>
  );
};

export default PlaceOrder;

const StyledBuySellTabs = styled(Tabs)`
  > * {
    flex-grow: 1;
  }
`;

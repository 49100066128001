import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from 'refreshed-component/atoms/Input';
import Loading from 'refreshed-component/molecules/Loading';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Card,
  CardColor,
  Icon,
  IconName,
  InputTextSize,
  LabelWithDescription,
  LabelWithDescriptionSize,
  Layer,
  Select,
  Text,
  TextAs,
  TextColor,
  Tooltip,
  TypographyVariant,
  styled,
  toLayerBackground,
} from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FormDevTool from 'components/FormDevTool';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAccountBalance from 'hooks/useAccountBalance';

import { convertTextNumberToValue } from 'utils/helpers';

interface Props {
  onSubmit: (
    values: Record<string, any>,
    handlers?: {
      onSuccess?: () => void;
    },
  ) => void;
  accountReference: string;
}

type FormData = {
  ccyTypeId: string;
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      return convertTextNumberToValue(originalValue);
    })
    .min(1, `amount must be greater than 0`),
});

const FiatDepositForm: React.FC<Props> = ({ onSubmit, accountReference }: Props) => {
  const [saving, setSaving] = React.useState(false);
  const {
    entity,
    selector: { availableCurrencies, mainCcyScId, mainCcySymbol, mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();
  const { rootUser } = User.useContainer();

  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});

  const { availableAmount, refetchBalance } = useAccountBalance({
    getRootBalance: 'yes',
    ccyTypeId: mainCcyScId,
    ccySymbol: mainCcySymbol,
    queryConfig: {
      refetchInterval: 3000,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ccyTypeId: String(mainCcyScId),
    },
  });

  const onSubmitHandler = (data: FormData, evt: any) => {
    setSaving(true);
    const amount = Number(String(data?.amount ?? 0).replace(/,/g, ''));
    const depositData = {
      ...data,
      amount,
    };
    onSubmit(depositData, {
      onSuccess: () => {
        reset();
        setSaving(false);
        refetchBalance();
      },
    });
  };

  watch();

  const { bank: entityBankAccount } = JSON.parse(entity?.bankAccount ?? '{}');

  if (isLoadingMarketSettings) return <Loading />;

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <ModalContent>
        <FormDevTool control={control} />
        <div className="flex flex-wrap gap-large">
          <Layer>
            <Card className="w-full p-large">
              <LabelWithDescription
                size={LabelWithDescriptionSize.l}
                label={`Consolidated Balance (${mainCcySymbol})`}
                labelSuffix={
                  <Tooltip value="This balance reflects the total of all your current account balances.">
                    <Icon name={IconName.InfoCircle} color={TextColor.secondary} />
                  </Tooltip>
                }
                description={`${mainCcyCode ?? ''}${formatter.formatNumber(availableAmount || 0, mainCcyNumDecimals)}`}
              />
            </Card>
          </Layer>
          <div className="flex flex-row w-full h-auto gap-small">
            <div>
              <div className="flex flex-col gap-xs">
                <Select
                  label="Currency"
                  items={Object.entries(availableCurrencies).map(([id, label]) => {
                    return {
                      value: String(id),
                      title: label,
                    };
                  })}
                  value={String(getValues('ccyTypeId'))}
                  placeholder="Select Currency"
                  size={InputTextSize.s}
                  onChange={({ value }) => {
                    setValue('ccyTypeId', value);
                  }}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col gap-xs">
                <Text variant={TypographyVariant.body2}>Amount *</Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.amount ? 'error' : 'gray',
                    postfix: availableCurrencies?.[getValues('ccyTypeId')] || '',
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    const value = (event?.target as any)?.rawValue;
                    setValue('amount', value as unknown as number);
                  }}
                  value={getValues('amount')}
                />
                {errors.amount && (
                  <Text variant={TypographyVariant.body2} color={TextColor.error}>
                    {errors.amount.message}
                  </Text>
                )}
              </div>
            </div>
          </div>
          <Layer>
            <Card className="w-full">
              <div className="flex flex-col w-full p-small gap-small">
                <div className="flex flex-col gap-3xs">
                  <Text color={TextColor.error} variant={TypographyVariant.subtitle2}>
                    Deposit/Wire Reference
                  </Text>
                  <Text variant={TypographyVariant.subtitle2} className="max-w-full">
                    Reference ID: <Text as={TextAs.span}>{accountReference}</Text>
                  </Text>
                </div>
              </div>
              <StyledBeneficiaryCard className=" w-full p-small gap-small">
                <div className="flex flex-row gap-small">
                  <div className="flex flex-col flex-1 gap-small">
                    <div className="flex flex-col gap-3xs">
                      <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                        Beneficiary Name
                      </Text>
                      <Text variant={TypographyVariant.subtitle2}>
                        {Boolean(entityBankAccount?.beneficiaryName)
                          ? entityBankAccount?.beneficiaryName
                          : rootUser?.tradingName}
                      </Text>
                    </div>
                    <div className="flex flex-col gap-3xs">
                      <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                        Beneficiary Account Number
                      </Text>
                      <Text variant={TypographyVariant.subtitle2}>
                        {Boolean(entityBankAccount?.beneficiaryAccountNumber)
                          ? entityBankAccount?.beneficiaryAccountNumber
                          : rootUser?.idxKseiCashAccount}
                      </Text>
                    </div>
                    <div className="flex flex-col gap-3xs">
                      <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                        Beneficiary Address
                      </Text>
                      <Text variant={TypographyVariant.subtitle2}>{entityBankAccount?.beneficiaryAddress}</Text>
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 gap-small">
                    <div className="flex flex-col gap-3xs">
                      <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                        Bank Name
                      </Text>
                      <Text variant={TypographyVariant.subtitle2}>{entityBankAccount?.bankName}</Text>
                    </div>
                    <div className="flex flex-col gap-3xs">
                      <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                        SWIFT BIC Code
                      </Text>
                      <Text variant={TypographyVariant.subtitle2}>{entityBankAccount?.swiftCode}</Text>
                    </div>
                  </div>
                </div>
              </StyledBeneficiaryCard>
            </Card>
          </Layer>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          isLoading={saving}
          isDisabled={saving || marketSettings?.fiatEntryEnabled === 0}
          variant={ButtonVariant.secondary}
          size={ButtonSize.s}
          endIcon={IconName.ArrowEnd}
          type={ButtonType.Submit}
          className="flex-1"
        >
          Confirm
        </Button>
      </ModalFooter>
    </form>
  );
};

export default FiatDepositForm;

const StyledBeneficiaryCard = styled(Card)`
  background: ${({ theme }) => toLayerBackground(theme)('layerAccent')};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

import { Select } from '@aircarbon/ui';

import {
  type SelectProjectAndContractValue,
  useProjectAndContractSelector,
} from './hooks/useProjectAndContractSelector';

interface SelectProjectAndContractProps {
  error?: Record<string, string>;
  onChange(value: SelectProjectAndContractValue): void;
}

export const SelectProjectAndContract: React.FunctionComponent<SelectProjectAndContractProps> = (props) => {
  const {
    isLoading,
    project,
    contract,
    vintageYear,
    projects,
    contracts,
    vintageYears,
    changeProject,
    changeContract,
    changeVintageYear,
  } = useProjectAndContractSelector({
    onChange: props.onChange,
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-xs">
        <Select
          label="Project"
          placeholder="Select project"
          items={projects.map((projectItem) => ({
            value: String(projectItem.id),
            title: projectItem.label as string,
          }))}
          value={String(project)}
          onChange={({ value }) => {
            changeProject(Number(value));
          }}
          error={props.error?.projectId}
        />
      </div>
      {!!project && (
        <div className="flex flex-col gap-xs">
          <Select
            label="Contract"
            placeholder="Select contract"
            items={contracts.map((contract) => ({
              value: String(contract.id),
              title: contract.label as string,
            }))}
            value={String(contract)}
            onChange={({ value }) => {
              changeContract(Number(value));
            }}
            error={props.error?.contractId}
          />
        </div>
      )}
      {!!contract && (
        <div className="flex flex-col gap-xs">
          <Select
            label="Vintage Year"
            items={vintageYears.map((year) => ({
              title: year.label as string,
              value: String(year.id),
            }))}
            value={String(vintageYear)}
            placeholder="Select vintage year"
            error={props.error?.vintage}
            onChange={({ value }) => {
              changeVintageYear(Number(value));
            }}
          />
        </div>
      )}
    </>
  );
};

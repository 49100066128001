import { useState } from 'react';
import { useQuery } from 'react-query';
import { findGERAsset } from 'refreshed-pages/ger/utils/findGERAsset';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { FeeType, logger } from '@aircarbon/utils-common';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { toAssetBalance } from '../../GERBalance/utils/toAssetBalance';
import { packOrRetireGER } from '../../PackGER/utils/packGER';
import type { RetireBatchFormValue } from '../../RetireBatchForm';
import { toBatchAssetsAllocations } from '../utils/toBatchAssetsAllocations';
import { toValidationErrorsMap } from '../utils/toValidationErrorsMap';

const defaultFormValues: RetireBatchFormValue = {
  retirementReason: 'Voluntary',
  retirementBeneficiary: '',
  offsetScopes: {},
  isPublicRetirement: false,
  offsettingPeriod: {
    from: undefined,
    to: undefined,
  },
  otherNotes: '',
  quantity: '',
};

export const useRetireGER = (props: {
  maxRetireQuantity: number;
  batchId: number;
  onRetired(): void;
}): {
  formValues: RetireBatchFormValue;
  isRetireDisabled: boolean;
  isRetireInProgress: boolean;
  validationErrorsMap: Record<string, string>;
  assets: Array<{ assetType: string; balance: string; packQuantity: string; balanceAfter: string }>;
  transactionFee: {
    total: number;
    isLoading: boolean;
  };
  changeFormValues(formValues: RetireBatchFormValue): void;
  retire(): void;
  resetUserInput(): void;
} => {
  const [isTouched, setIsTouched] = useState(false);
  const [formValues, setFormValues] = useState<RetireBatchFormValue>(defaultFormValues);
  const debouncedQuantity = useDebounce(formValues.quantity, 500);
  const [isRetireInProgress, setIsRetireInProgress] = useState(false);

  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const { tokenTypes } = Contract.useContainer();

  const { data: tokensQty = {} } = useQuery<Record<number, number>>(['ger-tokens-qty'], async () => {
    // TODO: Implement data-provider
    const authToken = await getAuthToken();
    return fetch(`/api/account/basket/token-available`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    }).then((resp) => resp.json());
  });

  const gerAsset = findGERAsset(tokenTypes);

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.GER_RETIRE_FEE,
      assetCategoryId: gerAsset?.assetCategoryId,
      tokenQty: Number(debouncedQuantity || 0),
    },
    options: { enabled: Number(debouncedQuantity || 0) > 0 },
  });

  const assetBalance = toAssetBalance({
    asset: gerAsset,
    assetBalances: tokensQty,
  });

  const assetsAllocations = toBatchAssetsAllocations({
    assetConfigurationJSON: gerAsset?.configuration,
    assets: tokenTypes,
    assetQuantity: props.maxRetireQuantity,
  });

  const packingResult = assetBalance
    ? packOrRetireGER({
        quantity: Number(formValues.quantity || 0),
        currentBalance: assetBalance,
        assetsAllocations,
      })
    : {
        assetBalances: [],
        resultingBalance: 0,
      };

  const validationErrorsMap = toValidationErrorsMap({
    maxQuantity: props.maxRetireQuantity,
    assetsBalances: packingResult.assetBalances,
    formValue: formValues,
  });

  const isRetireDisabled = isLoadingFeeAmount;

  const changeFormValues = (newFormValues: RetireBatchFormValue) => {
    setFormValues(newFormValues);
  };

  const resetUserInput = () => {
    setIsTouched(false);
    setFormValues(defaultFormValues);
  };

  const retire = async () => {
    setIsTouched(true);
    if (validationErrorsMap) {
      return;
    }

    setIsRetireInProgress(true);

    try {
      showToast({
        variant: ToastVariant.Info,
        message: 'Processing ...',
      });
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const response = await fetch('/api/account/basket/retire', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          stId: props.batchId,
          tokTypeId: gerAsset?.scId,
          qtyUnit: Number(formValues.quantity),
          retirementReason: formValues.retirementReason,
          makeRetirementPublic: formValues.isPublicRetirement ? 'yes' : 'no',
          retirementBeneficiary: formValues.retirementBeneficiary,
          retirementReasonComments: formValues.otherNotes,
          offsettingStartDate: formValues.offsettingPeriod.from?.toString(),
          offsettingEndDate: formValues.offsettingPeriod.to?.toString(),
          scopes: formValues.offsetScopes || {},
        }),
      });
      if (response.ok) {
        setIsRetireInProgress(false);
        props.onRetired();
        const result = await response.json();
        logger.warn(result.id, 'TXID');

        showToast({
          variant: ToastVariant.Success,
          message: 'Your retirement request has been submitted.',
        });
        resetUserInput();
      } else {
        setIsRetireInProgress(false);
        const result = await response.json();
        // https://github.com/developit/unfetch#caveats
        throw new Error(result.message || response.statusText);
      }
    } catch (err: any) {
      setIsRetireInProgress(false);
      showToast({
        variant: ToastVariant.Danger,
        message: err.message,
      });
    }
  };

  return {
    formValues,
    isRetireDisabled,
    isRetireInProgress,
    validationErrorsMap: isTouched ? validationErrorsMap || {} : {},
    transactionFee: {
      total: feeAmount,
      isLoading: isLoadingFeeAmount,
    },
    assets: packingResult.assetBalances.map((balance) => ({
      assetType: `${balance.name} (${balance.percentage}%)`,
      balance: new Intl.NumberFormat('en-US').format(balance.availableBalance),
      packQuantity: new Intl.NumberFormat('en-US').format(balance.balanceChange),
      balanceAfter: new Intl.NumberFormat('en-US').format(balance.resultingBalance),
    })),
    changeFormValues,
    resetUserInput,
    retire,
  };
};

import { useRef, useState } from 'react';

import { Popover, TextColor, TypographyVariant, styled, useLayerBackground, useTextColor } from '@aircarbon/ui';
import { hooks } from '@aircarbon/utils-common';

import { Cell } from './OrderBook';

const { useOnClickOutside } = hooks;

const Selection = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const OptionItem = styled.div<{ color: string; hoverBackground: string }>`
  color: ${(props) => props.color};
  width: 100%;
  height: 22px;
  left: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;

  &:hover {
    background-color: ${(props) => props.hoverBackground};
  }
`;

type Props = {
  options: any[];
  selectedOptions: any[];
  onSelectOptions: (newOptions: any[]) => void;
  cellClassName: string;
  children?: React.ReactNode;
};

export default function CellWithDropdown(props: Props) {
  const { options, selectedOptions, onSelectOptions, cellClassName, children } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { textColor } = useTextColor();
  const { layerBackground } = useLayerBackground();
  useOnClickOutside(dropdownRef, () => {
    if (dropdownRef) setShowDropdown(false);
  });

  return (
    <Popover
      isVisible={showDropdown}
      value={
        <div style={{ minWidth: '150px' }} ref={dropdownRef}>
          <Selection>
            <OptionItem
              color={textColor(TextColor.primary)}
              hoverBackground={layerBackground('fieldHover')}
              key={'All'}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={() => {
                onSelectOptions([]);
              }}
              className={`cursor-pointer`}
            >
              <div className="flex flex-row justify-center items-center min-w-full h-full">
                <div className="flex-1">All</div>
                {!selectedOptions?.length && (
                  <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 24L20 34L40 14"
                      stroke={textColor(TextColor.primary)}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
            </OptionItem>

            {options.map((item: any) => {
              const isSelected = selectedOptions?.includes(item.key);
              return (
                <OptionItem
                  color={textColor(TextColor.primary)}
                  hoverBackground={layerBackground('fieldHover')}
                  key={item.key}
                  onClick={() => {
                    const selected = selectedOptions?.includes(item.key)
                      ? selectedOptions.filter((value) => item.key !== value)
                      : [...selectedOptions, item.key];

                    onSelectOptions(selected);
                  }}
                  className={`cursor-pointer ${item.value}`}
                >
                  <div className="flex flex-row justify-center items-center min-w-full h-full">
                    <div className="flex-1">{item.value}</div>
                    {isSelected && (
                      <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10 24L20 34L40 14"
                          stroke={textColor(TextColor.primary)}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </OptionItem>
              );
            })}
          </Selection>
        </div>
      }
    >
      <Cell $theme={'light'} variant={TypographyVariant.subtitle2} className={cellClassName}>
        {children}
        {selectedOptions?.length ? (
          <svg
            className="ml-2"
            width="9"
            height="17"
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              onSelectOptions([]);
            }}
          >
            <path
              d="M4.9498 12.2427L4.2427 12.9498L0 8.70712L0.7071 8.00001L4.9498 12.2427Z"
              fill={textColor(TextColor.primary)}
            />
            <path
              d="M4.2427 12.9498L3.5356 12.2427L7.7783 8L8.4854 8.70711L4.2427 12.9498Z"
              fill={textColor(TextColor.primary)}
            />
            <path
              d="M3.5356 12.2427L4.2428 11.5356L8.4854 15.7782L7.7783 16.4853L3.5356 12.2427Z"
              fill={textColor(TextColor.primary)}
            />
            <path
              d="M4.2428 11.5356L4.9498 12.2427L0.7071 16.4853L0 15.7782L4.2428 11.5356Z"
              fill={textColor(TextColor.primary)}
            />
          </svg>
        ) : (
          <svg
            className="ml-2"
            width="9"
            height="17"
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseDown={(event) => {
              const mouseup = () => {
                setShowDropdown(!showDropdown);
              };
              const clear = () => {
                event.target.removeEventListener('mouseup', mouseup);
                window.removeEventListener('mouseup', clear);
              };
              event.target.addEventListener('mouseup', mouseup);
              window.addEventListener('mouseup', clear);
            }}
          >
            <path
              d="M4.9498 12.2427L4.2427 12.9498L0 8.70712L0.7071 8.00001L4.9498 12.2427Z"
              fill={textColor(TextColor.primary)}
            />
            <path
              d="M4.2427 12.9498L3.5356 12.2427L7.7783 8L8.4854 8.70711L4.2427 12.9498Z"
              fill={textColor(TextColor.primary)}
            />
          </svg>
        )}
      </Cell>
    </Popover>
  );
}

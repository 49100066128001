import { Popover as PopoverAntD, type PopoverProps } from 'antd';
import 'antd/lib/popover/style/index.css';
import { type ReactNode, useState } from 'react';

import { Layer } from '@aircarbon/ui';

import styles from './index.module.scss';

export default function Popover({
  view = 'popover',
  onVisibleChange,
  children,
  ...props
}: PopoverProps & {
  view?: 'popover' | 'sidebarMenu' | 'toolTip' | 'transparent';
  children: ReactNode | ((visible: boolean) => ReactNode);
}) {
  const [isOpen, setOpen] = useState(false);
  return (
    <Layer initialLayer={1}>
      <PopoverAntD
        onVisibleChange={(visible) => {
          onVisibleChange?.(visible);
          setOpen(visible);
        }}
        overlayClassName={styles[view]}
        {...props}
      >
        {typeof children === 'function' ? children(isOpen) : children}
      </PopoverAntD>
    </Layer>
  );
}

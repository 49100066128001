import MimeMatcher from 'mime-matcher';
import { type ChangeEventHandler, type DragEventHandler, useEffect, useRef, useState } from 'react';

import {
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  ProgressBar,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  toTextColor,
  useSpacing,
} from '@aircarbon/ui';
import { hooks } from '@aircarbon/utils-common';

import { FileCard } from './components/FileCard';

enum UploadFileState {
  Idle = 0,
  DragAndDrop = 1,
  UploadError = 2,
}

type UploadFileProps = {
  /**
   * Upload description
   *
   * @example "Max. File Size: 50MB"
   */
  description?: string;

  /**
   * @example "PIN document file is required"
   */
  error?: string;

  /**
   * @example "File is too large! Max. File Size: 50MB"
   */
  uploadError?: string;

  /**
   * List of accepted MIME types
   *
   * @example ["application/pdf", "image/jpeg"]
   */
  accepts?: Array<string>;

  /**
   * Handle file change
   */
  onChange?(file: File): void;
};

interface UploadProgressProps {
  /**
   * Upload progress in %
   */
  percentage: number;

  /**
   * File name
   */
  fileName: string;

  /**
   * Handle cancel file upload
   */
  onPressCancel(): void;
}

export const UploadFile: React.FC<UploadFileProps> & {
  FileCard: typeof FileCard;
  UploadProgress: React.FC<UploadProgressProps>;
} = (props) => {
  const { accepts = [], onChange = () => {}, uploadError, error, description } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadFileState, setUploadFileState] = useState(UploadFileState.Idle);
  const previousUploadState = hooks.usePrevious(uploadFileState);

  useEffect(() => {
    if (!!uploadError) {
      setUploadFileState(UploadFileState.UploadError);
    } else if (UploadFileState.UploadError) {
      setUploadFileState(UploadFileState.Idle);
    }
  }, [uploadError]);

  const onDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadFileState(UploadFileState.DragAndDrop);
  };

  const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadFileState(
      previousUploadState === UploadFileState.UploadError ? UploadFileState.UploadError : UploadFileState.Idle,
    );
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadFileState(
      previousUploadState === UploadFileState.UploadError ? UploadFileState.UploadError : UploadFileState.Idle,
    );

    const files = e.dataTransfer.files;

    if (!files?.length) {
      return;
    }

    const file = files[0];

    if (accepts.length) {
      const mimeMatcher = new MimeMatcher(...accepts);
      if (!mimeMatcher.match(file.type)) {
        return;
      }
    }

    onChange(file);
  };

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    onChange(file);
  };

  const onPressUpload = () => {
    fileInputRef.current?.click();
  };

  const isInErrorState = uploadFileState === UploadFileState.UploadError;

  return (
    <StyledUploadFile
      hasError={!!error}
      state={uploadFileState}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={onPressUpload}
    >
      <Icon
        size="2.125rem"
        color={
          isInErrorState
            ? TextColor.error
            : uploadFileState === UploadFileState.DragAndDrop
              ? TextColor.secondary
              : TextColor.disabled
        }
        name={IconName.Upload}
      />
      {isInErrorState ? (
        <Text color={TextColor.error} variant={TypographyVariant.subtitle2}>
          Failed to Upload
        </Text>
      ) : (
        <StyledClickToUploadText color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
          Click to upload{' '}
          <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
            or drag and drop
          </Text>
        </StyledClickToUploadText>
      )}
      {isInErrorState && (
        <Text variant={TypographyVariant.caption} color={TextColor.error}>
          {uploadError}
        </Text>
      )}
      {!isInErrorState && !!description && (
        <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
          {description}
        </Text>
      )}
      <StyledFileInput ref={fileInputRef} accept={accepts.join(',')} onChange={onChangeFile} />
    </StyledUploadFile>
  );
};

UploadFile.FileCard = FileCard;

const UploadProgress: React.FunctionComponent<UploadProgressProps> = (props) => {
  const { fileName, percentage, onPressCancel } = props;
  const { spacing } = useSpacing();
  return (
    <StyledUploadFile state={UploadFileState.Idle}>
      <Text variant={TypographyVariant.subtitle2}>Uploading...</Text>
      <StyledProgressBar label={fileName} progress={percentage} value={`${Math.round(percentage)}%`} />
      <Button variant={ButtonVariant.outlined} onPress={onPressCancel} size={ButtonSize.s} marginTop={spacing(8)}>
        Cancel
      </Button>
    </StyledUploadFile>
  );
};

UploadFile.UploadProgress = UploadProgress;

const StyledClickToUploadText = styled(Text)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(2)};
`;

const StyledProgressBar = styled(ProgressBar)`
  max-width: 370px;
  margin-top: 8px;
`;

const StyledUploadFile = styled.div<{
  state: UploadFileState;
  hasError?: boolean;
}>`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  border: 2px dashed
    ${({ state, hasError, theme }) =>
      state === UploadFileState.DragAndDrop
        ? toBorderColor(theme)(BorderColor.active)
        : state === UploadFileState.UploadError || hasError
          ? toBorderColor(theme)(BorderColor.error)
          : toBorderColor(theme)(BorderColor.neutral)};
  background: ${({ state, theme }) =>
    state === UploadFileState.DragAndDrop
      ? toLayerBackground(theme)('fieldHover')
      : state === UploadFileState.UploadError
        ? toLayerBackground(theme)('layerDanger')
        : toLayerBackground(theme)('field')};
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 228px;
  color: ${({ theme }) => toTextColor(theme)(TextColor.secondary)};
`;

const StyledFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

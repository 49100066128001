import { isEqual } from 'lodash';
import { type ReactNode, useEffect, useMemo, useState } from 'react';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { AssetCategory, type AssetCategoryCode, helpers, hooks } from '@aircarbon/utils-common';
import { logger } from '@aircarbon/utils-common';
import type { ApxProject } from '@aircarbon/utils-common/src/dto/apx';
import type { Page } from '@aircarbon/utils-common/src/dto/page';

import { usePairs } from 'pages/account/trading/hooks/usePairs';

import { userAuthenticatedApi } from 'data-provider/fetcher';

import { type Option, toOptions } from '../utils/toOptionsMap';

export interface SelectProjectAndContractValue {
  projectId?: number;
  contractId?: number;
  vintage?: number;
}

interface UseProjectAndContractSelectorProps {
  onChange(value: SelectProjectAndContractValue): void;
}

type DropdownItem = { id: number | string; label: ReactNode };

export const useProjectAndContractSelector = (props: UseProjectAndContractSelectorProps) => {
  const [project, setProject] = useState<number>();
  const [contract, setContract] = useState<number>();
  const [vintageYear, setVintageYear] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);

  const [options, setOptions] = useState<Array<Option>>();
  const { pairsSelectOptions: pairsOptions } = usePairs({
    assetCategories: [AssetCategory[AssetCategory.fct] as AssetCategoryCode],
    filterByMarket: 'block',
  });
  const previousPairs = hooks.usePrevious(pairsOptions);

  useEffect(() => {
    const fetchProjectsByPairs = async () => {
      const pairIds = pairsOptions.map((pair) => pair.value);

      if (!pairIds.length) {
        return;
      }

      try {
        setIsLoading(true);
        const queryParams = helpers.objectToQueryString({
          filter: {
            'pair-ids': pairIds,
          },
        });
        const userService = await userAuthenticatedApi();
        // TODO: Migrate to properly fetcher with api client
        const request = userService.url(`/user/apx/projects?${queryParams}`).get();

        const response = (await request.json()) as Page<ApxProject>;

        setOptions(
          toOptions({
            projects: response.data,
            pairIds,
          }),
        );
        setIsLoading(false);
      } catch (e) {
        logger.error(e, 'Failed to set auction project options');
        showToast({
          variant: ToastVariant.Danger,
          message: 'Something went wrong',
        });
      }
    };

    if (isEqual(pairsOptions, previousPairs)) {
      return;
    }

    fetchProjectsByPairs();
  }, [pairsOptions, previousPairs]);

  const { projects, contracts, vintageYears } = useMemo<{
    projects: Array<DropdownItem>;
    contracts: Array<DropdownItem>;
    vintageYears: Array<DropdownItem>;
  }>(() => {
    const selectedProject = options?.find((option) => option.id === project);
    let contracts: Option['contracts'] = [];
    let vintageYears: Array<DropdownItem> = [];

    if (selectedProject) {
      contracts = selectedProject.contracts;
    }

    const selectedContract = contracts.find((availableContract) => availableContract.id === contract);

    if (selectedContract) {
      vintageYears = selectedContract.vintages;
    }

    return {
      projects: options || [],
      contracts,
      vintageYears,
    };
  }, [options, project, contract, vintageYear]);

  useEffect(() => {
    props.onChange({
      projectId: project,
      contractId: contract,
      vintage: vintageYear,
    });
  }, [project, contract, vintageYear]);

  const changeProject = (projectId: number) => {
    setProject(projectId);
    setContract(undefined);
    setVintageYear(undefined);
  };

  const changeContract = (contractId: number) => {
    const selectedProject = options?.find((p) => p.id === project);

    const selectedContract = selectedProject?.contracts.find((c) => c.id === contractId);
    setContract(contractId);
    setVintageYear(selectedContract?.vintages?.[0]?.id || undefined);
  };

  return {
    isLoading,
    project,
    contract,
    vintageYear,
    projects,
    contracts,
    vintageYears,
    changeProject,
    changeContract,
    changeVintageYear: setVintageYear,
  };
};

import React from 'react';

import {
  BorderColor,
  Icon,
  IconName,
  Text,
  TextColor,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

import type { Layouts } from '../../layouts/trading.hook';
import { MarketplaceModal } from './components/MarketplaceModal';

export const ProjectButton: React.FC<{
  layout?: Layouts;
  apxName?: string;
}> = (props) => {
  const { apxName } = props;

  if (!apxName) {
    return null;
  }

  return <MarketplaceModal action={<SelectProject name={apxName} />} />;
};

const SelectProject = React.forwardRef<
  HTMLDivElement,
  {
    name: string;
    onPress?(): void;
  }
>(({ name, onPress }, ref) => {
  return (
    <StyledSelectPair ref={ref} onClick={onPress}>
      <Text>{name}</Text>
      <StyledChevronIcon name={IconName.ChevronDown} color={TextColor.secondary} />
    </StyledSelectPair>
  );
});

const StyledSelectPair = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  box-shadow: inset 0 0 0 1px ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  padding-inline-start: ${({ theme }) => toSpacing(theme)(8)};
  padding-inline-end: ${({ theme }) => toSpacing(theme)(6)};
  gap: ${({ theme }) => toSpacing(theme)(6)};
  height: 3rem;
  transition: box-shadow 0.15s linear;
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  &:hover {
    background: ${({ theme }) => toLayerBackground(theme)('fieldHover')};
  }
`;

const StyledChevronIcon = styled(Icon)`
  flex-shrink: 0;
  margin-inline-start: auto;
`;

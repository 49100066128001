import type { ReactElement } from 'react';

import { Text, TextAlign, TypographyVariant, styled, toLayerBackground } from '@aircarbon/ui';

const Wrapper = styled.div`
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 6rem;
  align-items: stretch;
  justify-content: center;
`;

function PageNotFound(): ReactElement {
  return (
    <Wrapper>
      <Text align={TextAlign.center} variant={TypographyVariant.h5Title}>
        Sorry. Page Not Found
      </Text>
    </Wrapper>
  );
}

export default PageNotFound;

import { type ReactNode, useEffect, useState } from 'react';
import type { CheckBoxProps } from 'refreshed-component/atoms/CheckBox';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';

import {
  Accordion,
  Badge,
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  Divider,
  Icon,
  IconName,
  InputText,
  Layer,
  Popover,
  Radio,
  Text,
  TypographyVariant,
  styled,
  toBorderColor,
  toSpacing,
} from '@aircarbon/ui';

import { formatDate } from 'utils/helpers';

import { InputRange } from './Filter/components/InputRange';

export type FilterCheckBox = {
  type: 'check-box';
  label: string;
  list: CheckBoxProps[];
};

export type FilterRadioBox = {
  type: 'radio-box';
  label: string;
  list: { id: number | string; label: string; isSelected?: boolean }[];
};

export type FilterRangeInput = {
  type: 'range-input';
  label: string;
  range: {
    from: {
      label: string;
      placeholder: string;
    };
    to: {
      label: string;
      placeholder: string;
    };
  };
};

export type FilterDateRangeInput = {
  type: 'date-range-input';
  label: string;
};

export type FilterType = FilterCheckBox | FilterRadioBox | FilterRangeInput | FilterDateRangeInput;

export type Filters = {
  [key: string]: FilterType;
};

export type FilterSelectionType =
  | FilterDateRangeSelection
  | RangeInputSelection
  | RadioBoxSelection
  | CheckBoxSelection;

export type FilterDateRangeSelection = {
  type: 'date-range-input';
  range: {
    startDate: Date;
    endDate: Date;
  };
};

export type RangeInputSelection = {
  type: 'range-input';
  selection: {
    from?: number;
    to?: number;
  };
};

export type RadioBoxSelection = {
  type: 'radio-box';
  selection: string | number | undefined;
};

export type CheckBoxSelection = {
  type: 'check-box';
  selection: (string | number)[] | undefined;
};

export type FilterSelections<T extends Filters> = {
  [K in keyof T]?: T[K] extends FilterCheckBox
    ? CheckBoxSelection
    : T[K] extends FilterRadioBox
      ? RadioBoxSelection
      : T[K] extends FilterRangeInput
        ? RangeInputSelection
        : T[K] extends FilterDateRangeInput
          ? FilterDateRangeSelection
          : any;
};

type Props<T extends Filters> = {
  label?: string;
  list: T;
  onChange?: (selections?: FilterSelections<T>) => void;
  selections?: FilterSelections<T>;
  children?: ReactNode;
};

const FilterRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  > .title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    padding: ${({ theme }) => toSpacing(theme)(8)};
  }
  > .body {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }
  > .footer {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 300px;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    padding: ${({ theme }) => toSpacing(theme)(8)};
    > button {
      flex: 1 1 0%;
    }
  }
`;

export const FilterDropdown = <T extends Filters>({ label, list, children, ...props }: Props<T>) => {
  const [state, setState] = useState<{
    [key: string]:
      | {
          isOpen: boolean;
          selected?: [];
        }
      | undefined;
  }>({});
  const [alwaysVisible, setAlwaysVisible] = useState(false);
  const [selections = {}, setSelections] = useState<FilterSelections<T> | undefined>();
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    setSelections({ ...(props.selections || ({} as FilterSelections<T>)) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selections]);

  const contentHook: {
    close?: (() => void) | undefined;
  } = {};

  const listOfItems = Object.values(list);
  const isHaveOnlyOneItem = listOfItems.length === 1;
  const finalLabel = isHaveOnlyOneItem ? listOfItems[0].label : label;
  const [search, setSearch] = useState<{ [index: string]: string | undefined }>({});

  return (
    <Popover
      isVisible={alwaysVisible}
      onHide={() => setAlwaysVisible(false)}
      onApplySize={(elements) => {
        Object.assign(elements.floating.style, {
          maxWidth: '400px',
        });
      }}
      value={
        <FilterRoot>
          <div className="title">
            <Text variant={TypographyVariant.subtitle1}>{finalLabel || 'Filters'}</Text>
            <Button
              variant={ButtonVariant.ghost}
              onPress={() => {
                isHaveOnlyOneItem
                  ? props.onChange?.({
                      ...(props.selections || ({} as FilterSelections<T>)),
                      [Object.keys(list)[0]]: undefined,
                    })
                  : props.onChange?.({});
              }}
              size={ButtonSize.xs}
            >
              {isHaveOnlyOneItem ? 'Clear' : 'Clear all'}
            </Button>
          </div>
          <div className="body">
            {Object.entries(list).map(([key, itemProps], index) => {
              const selectionsObject = (selections as FilterSelections<T>)?.[key];
              const isOpen = isHaveOnlyOneItem ? true : state[key]?.isOpen === true;

              return (
                <>
                  <Accordion
                    title={itemProps.label}
                    isExpanded={isOpen}
                    onToggle={() =>
                      setState({
                        ...state,
                        [key]: {
                          isOpen: !state[key]?.isOpen,
                          selected: state[key]?.selected,
                        },
                      })
                    }
                  >
                    <Container>
                      {(itemProps.type === 'check-box' || itemProps.type === 'radio-box') &&
                        itemProps.list.length > 10 && (
                          <Layer>
                            <InputText
                              icon={IconName.Search}
                              placeholder="Search..."
                              value={search[key] || ''}
                              onChange={(event) => {
                                setSearch({
                                  ...search,
                                  [key]: event.target.value.trim() || undefined,
                                });
                              }}
                            />
                          </Layer>
                        )}
                      {itemProps.type === 'check-box' &&
                        itemProps.list.map((item) => {
                          const selectionsObject = (selections as FilterSelections<T>)?.[key];
                          const selection =
                            selectionsObject?.type === 'check-box' ? selectionsObject.selection : undefined;
                          const isSelected = selection?.includes(item.id);
                          const searchLabel = search[key]?.toLocaleLowerCase();
                          if (searchLabel && !item.label.toLocaleLowerCase().startsWith(searchLabel)) return <></>;
                          return (
                            <Checkbox
                              key={item.id.toString()}
                              label={item.label}
                              isChecked={isSelected}
                              onChange={({ isChecked }) => {
                                console.log(isChecked);
                                setDirty(true);
                                setSelections({
                                  ...(selections || ({} as FilterSelections<T>)),
                                  [key]: {
                                    type: 'check-box',
                                    selection: !isChecked
                                      ? (selection || []).filter((id: string | number) => id !== item.id)
                                      : [...(selection || []), item.id],
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      {itemProps.type === 'radio-box' &&
                        itemProps.list.map((item) => {
                          const selectionsObject = (selections as FilterSelections<T>)?.[key];
                          const selection =
                            selectionsObject?.type === 'radio-box' ? selectionsObject.selection : undefined;
                          const isSelected = selection === item.id;
                          const searchLabel = search[item.id]?.toLocaleLowerCase();
                          if (searchLabel && !item.label.toLocaleLowerCase().startsWith(searchLabel)) return <></>;

                          return (
                            <Radio
                              key={item.id.toString()}
                              label={item.label}
                              isChecked={isSelected}
                              onChange={() => {
                                setDirty(true);
                                setSelections({
                                  ...(selections || ({} as FilterSelections<T>)),
                                  [key]: {
                                    type: 'radio-box',
                                    selection: isSelected ? undefined : item.id,
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      {itemProps.type === 'range-input' && (
                        <InputRange
                          fromPlaceholder={itemProps.range.from.placeholder}
                          toPlaceholder={itemProps.range.to.placeholder}
                          fromLabel={itemProps.range.from.label}
                          toLabel={itemProps.range.to.label}
                          from={((selections as FilterSelections<T>)?.[key] as RangeInputSelection)?.selection?.from}
                          to={((selections as FilterSelections<T>)?.[key] as RangeInputSelection)?.selection?.to}
                          onChange={(params) => {
                            setDirty(true);
                            setSelections({
                              ...(selections || ({} as FilterSelections<T>)),
                              [key]: {
                                type: 'range-input',
                                selection: params,
                              },
                            });
                          }}
                        />
                      )}
                      {itemProps.type === 'date-range-input' && (
                        <DatePicker
                          onShowChange={(value) => setAlwaysVisible(value)}
                          selectsRange={true}
                          startDate={(selectionsObject as FilterDateRangeSelection | undefined)?.range?.startDate}
                          endDate={(selectionsObject as FilterDateRangeSelection | undefined)?.range?.endDate}
                          onChange={(value) => {
                            setDirty(false);
                            props.onChange?.({
                              ...(selections || ({} as FilterSelections<T>)),
                              [key]: {
                                type: 'date-range-input',
                                range: {
                                  startDate: value[0] || undefined,
                                  endDate: value[1] || undefined,
                                },
                              },
                            });
                          }}
                        />
                      )}
                    </Container>
                  </Accordion>
                  {index !== Object.entries(list).length - 1 && <Divider />}
                </>
              );
            })}
          </div>
          <div className="footer">
            <Button
              variant={ButtonVariant.secondary}
              onPress={() => {
                setDirty(false);
                props.onChange?.({ ...(selections || ({} as FilterSelections<T>)) });
                contentHook.close?.();
              }}
            >
              Apply Filters
            </Button>
          </div>
        </FilterRoot>
      }
    >
      {!!children ? (
        <div onClick={() => setAlwaysVisible(true)}>{children}</div>
      ) : (
        <Button onPress={() => setAlwaysVisible(true)} startIcon={IconName.Filter} variant={ButtonVariant.outlined}>
          {label || 'Filter'}
        </Button>
      )}
    </Popover>
  );
};

const FilterSelectionsRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: ${({ theme }) => toSpacing(theme)(6)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  overflow-y: auto;
  overflow-x: hidden;
  > .body {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(4)};
    flex-wrap: wrap;
    overflow: hidden;
  }
`;

export const FilterSelections = <T extends Filters>({ label, list, ...props }: Props<T>) => {
  const [selections, setSelections] = useState<FilterSelections<T> | undefined>();

  useEffect(() => {
    setSelections({ ...(props.selections || ({} as FilterSelections<T>)) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selections]);

  const haveAnySelection = Object.entries(selections || {}).find(([key, item]: [string, FilterSelectionType]) => {
    if (item?.type === 'check-box') return item.selection?.length;
    if (item?.type === 'radio-box') return item.selection !== undefined;
    if (item?.type === 'date-range-input') return item.range !== undefined;
  });

  return haveAnySelection ? (
    <FilterSelectionsRoot>
      <Icon size="0.75rem" name={IconName.Filter} />
      <Text>{label || 'Filter'}</Text>
      {haveAnySelection && (
        <div className="body">
          {Object.entries(selections || {}).map(([key, item]: [string, FilterSelectionType]) => {
            const config = list[key];
            // eslint-disable-next-line array-callback-return
            if (!item || !config) return;
            if (item.type === 'check-box') {
              const selectionList = (config as FilterCheckBox).list.filter((item) => {
                const selectionsObject = selections?.[key];
                const selection = selectionsObject?.type === 'check-box' ? selectionsObject.selection : undefined;
                return selection?.includes(item.id);
              });
              return selectionList.length ? (
                <FilterDropdown
                  list={{ [key]: config } as Filters as any}
                  selections={props.selections}
                  onChange={props.onChange}
                >
                  <Badge
                    prefix={`${config.label}${selectionList.length > 1 ? ` (${selectionList.length})` : ''}:`}
                    value={selectionList.map((item) => item.label).join(', ')}
                    onRemove={() =>
                      props.onChange?.({
                        ...(selections as FilterSelections<T>),
                        [key]: undefined,
                      })
                    }
                  />
                </FilterDropdown>
              ) : undefined;
            }

            if (item.type === 'radio-box') {
              const selectionList = (config as FilterRadioBox).list.filter((item) => {
                const selectionsObject = selections?.[key];
                const selection = selectionsObject?.type === 'radio-box' ? selectionsObject.selection : undefined;
                return selection === item.id;
              });
              return selectionList.length ? (
                <FilterDropdown
                  list={{ [key]: config } as Filters as any}
                  selections={props.selections}
                  onChange={props.onChange}
                >
                  <div className="item">
                    <div className="label">
                      {config.label} {selectionList.length > 1 ? `(${selectionList.length})` : ''}:{' '}
                      {selectionList
                        .map((item) => {
                          return item.label;
                        })
                        .join(', ')}
                    </div>
                    <div
                      onClick={() => {
                        props.onChange?.({
                          ...(selections as FilterSelections<T>),
                          [key]: undefined,
                        });
                      }}
                    >
                      <Icon size="0.75rem" name={IconName.X} />
                    </div>
                  </div>
                </FilterDropdown>
              ) : undefined;
            }

            if (item.type === 'date-range-input') {
              return item.range ? (
                <FilterDropdown
                  list={{ [key]: config } as Filters as any}
                  selections={props.selections}
                  onChange={props.onChange}
                >
                  <div className="item">
                    <div className="label">
                      {item?.range?.startDate &&
                        item?.range?.endDate &&
                        `Start: ${formatDate(item?.range?.startDate)} - End: ${formatDate(item?.range?.endDate)}`}
                    </div>
                    <div
                      onClick={() => {
                        props.onChange?.({
                          ...(selections as FilterSelections<T>),
                          [key]: undefined,
                        });
                      }}
                    >
                      <Icon size="0.75rem" name={IconName.X} />
                    </div>
                  </div>
                </FilterDropdown>
              ) : undefined;
            }
          })}
        </div>
      )}
    </FilterSelectionsRoot>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

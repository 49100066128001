import styled from 'styled-components';

import { helpers } from '@aircarbon/utils-common';

const Wrapper = styled.div<{ themeMode: 'dark' | 'light' }>`
  margin: 0rem auto;
  background: ${({ themeMode }) => (themeMode === 'light' ? '#fff' : '#151515')};
  color: ${({ themeMode }) => (themeMode === 'light' ? '#151515' : '#fff')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
`;

const Logo = styled.div`
  max-width: 300px;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 20px;
`;

const Content = styled.div<{ themeMode: 'dark' | 'light' }>`
  margin: 0rem auto;
  background: ${({ themeMode }) => (themeMode === 'light' ? '#fff' : '#333')};
  border: 1px solid ${({ themeMode }) => (themeMode === 'light' ? '#ddd' : '#333')};
  padding: 3rem;
  border-radius: 30px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  display: flex;
  gap: 30px;
`;

const ErrorNo = styled.div`
  font-size: 60px;
  color: red;
`;

const Link = styled.a<{ themeMode: 'dark' | 'light' }>`
  font-size: 14px;
  color: red;
  padding: 10px;
  border: 1px solid ${({ themeMode }) => (themeMode === 'light' ? '#ddd' : '#444')};
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 0);
  transition: all 300ms;
  color: ${({ themeMode }) => (themeMode === 'light' ? '#666' : '#999')};
  &:hover {
    border: 1px solid ${({ themeMode }) => (themeMode === 'light' ? '#ddd' : '#666')};
    color: ${({ themeMode }) => (themeMode === 'light' ? '#000' : '#fff')};
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ErrorBoundaryHandler = () => {
  const themeMode = 'light';
  return (
    <Wrapper themeMode={themeMode}>
      <Logo>
        <img
          className="cursor-pointer"
          alt="logo"
          src={`/logo-${helpers.getTopLevelDomainByUrl(window.location.href)}.png`}
          style={{
            transform: 'scale(0.8)',
            maxWidth: '300px',
          }}
        />
      </Logo>
      <Content themeMode={themeMode}>
        <div>
          <ErrorNo>500</ErrorNo>
          <div>An internal has occurred</div>
        </div>
        <div className="flex flex-col justify-end items-end">
          <div className="flex flex-row gap-3 justify-end items-end">
            <Link
              themeMode={themeMode}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload Page
            </Link>
            <Link themeMode={themeMode} href={`mailto:support@${helpers.getTopLevelDomainByUrl(window.location.href)}`}>
              Contact Us
            </Link>
          </div>
        </div>
      </Content>
    </Wrapper>
  );
};

export default ErrorBoundaryHandler;

import { type QueryConfig, queryCache, useQuery } from 'react-query';

import { formatter } from '@aircarbon/utils-common';
import type { BalanceResponse } from '@aircarbon/utils-common/src/dto/user';

import { fetchAccountBalance } from 'data-provider/user/fetchAccountBalance';

export default function useAccountBalance({
  ccyTypeId,
  ccySymbol,
  getRootBalance = 'no',
  queryConfig,
}: {
  ccyTypeId: number;
  ccySymbol: string;
  getRootBalance?: 'yes' | 'no';
  queryConfig?: QueryConfig<BalanceResponse>;
}) {
  const { data, error } = useQuery(
    ['account-balance', getRootBalance, ccyTypeId],
    () => fetchAccountBalance(getRootBalance, ccyTypeId),
    { ...queryConfig },
  );

  const refetchBalance = () => {
    queryCache.invalidateQueries(['account-balance', getRootBalance]);
  };

  const getOrdersByTokenType = (tokenType: string, side: 'sell' | 'buy') => {
    return (
      data?.openOrders.filter((order) => order.symbol === `${tokenType}/${ccySymbol}` && order.side === side) ?? []
    );
  };

  const totalOpenOrdersByTokenType = (tokenType: string, side: 'sell' | 'buy') => {
    return getOrdersByTokenType(tokenType, side).reduce((acc, order) => {
      return acc + Number(order.quantity);
    }, 0);
  };

  const totalTokenQuantityByTokenTypeId = (tokenTypeId: number, scRatio: number) => {
    return (
      (data?.tokens ?? [])
        .filter((token) => formatter.hex2int(token.tokTypeId) === tokenTypeId)
        .reduce((acc, token) => {
          return acc + formatter.hex2int(token.currentQty);
        }, 0) / scRatio
    ); // in tCO2e
  };

  const getBatchesByTokenType = (tokenType: string) => {
    return data?.tokens.filter((token) => token.tokTypeName === tokenType) ?? [];
  };

  const getGerBatches = () => {
    return data?.tokens.filter((token) => token.tokTypeName.startsWith('GER')) ?? [];
  };

  return {
    isLoading: !data,
    error,
    currencyBalance: data?.balance ?? 0,
    availableAmount: data?.availableAmount ?? 0,
    openRFQAmount: data?.openRFQAmount ?? 0,
    refetchBalance,
    selector: {
      getBatchesByTokenType,
      getGerBatches,
      getOrdersByTokenType,
      totalOpenOrdersByTokenType,
      totalTokenQuantityByTokenTypeId,
    },
  };
}
